import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { ITablesCourse } from "src/app/services/tables/type";
import { useEffect, useMemo, useState } from "react";
import { useGetTablesCourseMutation } from "src/app/services/tables";
import { renderEmptyValue } from "src/pages/higher_education/tables/utils";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { CustomTable } from "src/pages/higher_education/tables/tablesUniversities/components/CustomTable";

export function TablesUniversityByEduTypeCourseTable() {
  const { t } = useTranslation();
  const [getFunc, { data, isLoading }] = useGetTablesCourseMutation();
  const getColumnSearchProps = useColumnSearch();
  const [select, setSelect] = useState("all");
  const excludedColumns = ["universityCode", "universityName", "universityOwnership"];

  useEffect(() => {
    getFunc(select);
  }, [select]);

  const payment_type_list = [
    {
      value: "all",
      label: t("tables.total"),
    },
    {
      value: "11",
      label: t("pay_form.grant"),
    },
    {
      value: "12",
      label: t("pay_form.contract"),
    },
  ];

  const dataSourceCourse = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total:
        item.bachelorCourse1 +
        item.bachelorCourse2 +
        item.bachelorCourse3 +
        item.bachelorCourse4 +
        item.bachelorCourse5 +
        item.bachelorCourse6 +
        item.masterCourse1 +
        item.masterCourse2 +
        item.masterCourse3,
    }));

    return newData;
  }, [data]);

  const columns: ColumnsType<ITablesCourse> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      width: 200,
      ...getColumnSearchProps("universityName"),
    },
    {
      title: t("tables.ownership"),
      dataIndex: "universityOwnership",
      width: 80,
    },
    {
      title: t("tables.bachelor"),
      children: [
        {
          title: t("courses.course_1"),
          dataIndex: "bachelorCourse1",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("courses.course_2"),
          dataIndex: "bachelorCourse2",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("courses.course_3"),
          dataIndex: "bachelorCourse3",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("courses.course_4"),
          dataIndex: "bachelorCourse4",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("courses.course_5"),
          dataIndex: "bachelorCourse5",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("courses.course_6"),
          dataIndex: "bachelorCourse6",
          align: "center",
          render: renderEmptyValue,
        },
      ],
    },
    {
      title: t("tables.master"),
      children: [
        {
          title: t("courses.course_1"),
          dataIndex: "masterCourse1",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("courses.course_2"),
          dataIndex: "masterCourse2",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("courses.course_3"),
          dataIndex: "masterCourse3",
          align: "center",
          render: renderEmptyValue,
        },
      ],
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>
      <CustomTable
        title="tables.eduType"
        rowKey="universityCode"
        dataSource={dataSourceCourse}
        columns={columns}
        pagination={false}
        excludedColumns={excludedColumns}
        size="small"
        scroll={{ x: true, y: 500 }}
        loading={isLoading}
        downloadUrl={`student/course?paymentType=${select}`}
        select_list={payment_type_list}
        setSelect={setSelect}
        select={select}
      />
    </div>
  );
}
