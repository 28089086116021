import { useEffect, useState } from "react";
import { useGetStudentsPlaceByGenderStatQuery } from "src/app/services/students";
import { IStudentGender } from "../type";
import { makeStudentGender } from "../util";

function usePlace() {
  // Genders section
  const [gendersData, setGenders] = useState<IStudentGender>();
  const { data: genders } = useGetStudentsPlaceByGenderStatQuery();
  useEffect(() => {
    if (genders) setGenders(makeStudentGender(genders));
  }, [genders]);

  return {
    gendersData,
  };
}

export default usePlace;
