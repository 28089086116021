import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import PieChart from "src/components/chart/PieChart";
import {
  useGetStaffByGenderQuery,
  useGetStaffByYearsQuery,
} from "src/app/services/universities";
import BarChartCategoryColumn from "src/components/chart/BarChartCategoryColumn";
import { genders, teacherAges } from "src/pages/constants";

function LyceumTeacherPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const { data: teacherGenderData } = useGetStaffByGenderQuery();
  const { data: teacherAgeData } = useGetStaffByYearsQuery();

  return (
    <div className="">
      <div>
        {/* Gender */}
        <GraphInfoCard title={t("header.by_gender")}>
          <PieChart
            seriesArr={[
              {
                value: teacherGenderData?.data.male.count,
                name: t("genders.men"),
              },
              {
                value: teacherGenderData?.data.female.count,
                name: t("genders.women"),
              },
            ]}
            color={[colors.light_blue, colors.pink]}
          />
        </GraphInfoCard>

        {/* Age */}
        <div className="graph-row">
          {/* age level */}
          <GraphInfoCard
            title={t("teachers.by_age")}
            stat={[
              {
                name: t("teachers.total_count"),
                count:
                  teacherAgeData &&
                  teacherAgeData.data["<30"].count +
                    teacherAgeData.data["30-35"].count +
                    teacherAgeData.data["35-40"].count +
                    teacherAgeData.data["40-45"].count +
                    teacherAgeData.data["45-50"].count +
                    teacherAgeData.data["50-55"].count +
                    teacherAgeData.data["55-60"].count +
                    teacherAgeData.data["60+"].count,
              },
            ]}
          >
            <BarChartCategoryColumn
              legendData={genders}
              xAxisData={teacherAges}
              seriesArr={[
                {
                  name: t("header.menu.teachers"),
                  data: teacherAgeData && [
                    teacherAgeData.data["<30"].count,
                    teacherAgeData.data["30-35"].count,
                    teacherAgeData.data["35-40"].count,
                    teacherAgeData.data["40-45"].count,
                    teacherAgeData.data["45-50"].count,
                    teacherAgeData.data["50-55"].count,
                    teacherAgeData.data["55-60"].count,
                    teacherAgeData.data["60+"].count,
                  ],
                },
              ]}
              barWidth={40}
              interval={5000}
            />
          </GraphInfoCard>
        </div>
      </div>
      <div className="text_info">
        <p>{t("header.lyceum_info_text")}</p>
      </div>
    </div>
  );
}

export default LyceumTeacherPage;
