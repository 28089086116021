import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { IProfByGender } from "src/app/services/prof_education/type";
import { useTypedSelector } from "src/app/store";
import { BriefcaseIcon, GovermentIcon, KeyIcon } from "src/assets/svg";

interface Props extends IProfByGender {}

function ProfEduTypeCard({ male, female, all, education_type }: Props) {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);

  const edu_type_data = {
    3: {
      title: t("edu_type.vocational_schools"),
      icon: <KeyIcon color={colors.secondary} />,
    },
    4: {
      title: t("edu_type.colleges"),
      icon: <BriefcaseIcon color={colors.secondary} />,
    },
    5: {
      title: t("edu_type.technical_schools"),
      icon: <GovermentIcon color={colors.secondary} />,
    },
  };

  let obj = edu_type_data[education_type as IProfByGender["education_type"]];

  return (
    <div className="edutype-card">
      <div className="edutype-card-content">
        <div className="edutype-card-icon">{obj?.icon}</div>
        <div className="edutype-card-title">{obj?.title}</div>
        <p>{t("edu_type.common")}</p>
        <div className="edutype-card-total">
          <CountUp end={all} duration={2.75} separator=" " />
        </div>
        <div className="edutype-card-counts">
          <div>
            <p>{t("genders.men")}</p>
            <div className="edutype-card-count">
              <h3>{male}</h3> <span className="man">{Math.round((male * 100) / all)}%</span>
            </div>
          </div>
          <div>
            <p>{t("genders.women")}</p>
            <div className="edutype-card-count">
              <h3>{female}</h3> <span className="women">{Math.round((female * 100) / all)}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfEduTypeCard;
