import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import { ages, genders, residencePlaces } from "src/pages/constants";
import { useTranslation } from "react-i18next";
import useAge from "./useAge";

function StudentAgePage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);

  const { gendersData, placesData } = useAge();

  return (
    <div className="eduform">
      <div className="graph-row">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: gendersData?.men_total },
            { name: t("genders.women"), count: gendersData?.women_total },
          ]}
          graphHeight={144}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={ages}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: [...(gendersData?.men_data || [])].reverse(),
              },
              {
                name: t("genders.women"),
                data: [...(gendersData?.women_data || [])].reverse(),
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-128}
            yAxisMargin={160}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            { name: t("residence_places.own_house"), count: placesData?.own_house_total },
            { name: t("residence_places.dormitory"), count: placesData?.dormitory_total },
            { name: t("residence_places.rented_house"), count: placesData?.rented_house_total },
            { name: t("residence_places.relative_house"), count: placesData?.relative_house_total },
            { name: t("residence_places.familiar_house"), count: placesData?.familiar_house_total },
          ]}
        >
          <BarChart
            legendData={residencePlaces}
            xAxisData={ages}
            color={[
              colors.blue,
              colors.pink,
              colors.yellow,
              colors.light_green,
              colors.light_blue,
              colors.orange,
            ]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: placesData?.own_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: placesData?.dormitory_data,
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: placesData?.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: placesData?.relative_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: placesData?.familiar_house_data,
                stack: "one",
              },
            ]}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t('header.students_info_text')}</p>
      </div>
    </div>
  );
}

export default StudentAgePage;
