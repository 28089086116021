import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useGetTablesPermanentRegionQuery } from "src/app/services/prof_education";
import { IPermanentRegion } from "src/app/services/prof_education/type";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { useGetGraduatedStudentsByUniversityQuery } from "src/app/services/private";
import { IGraduatedByUniversity } from "src/app/services/private/type";

export function GraduatedStudentsTable() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetGraduatedStudentsByUniversityQuery();
  const getColumnSearchProps = useColumnSearch();

  const getOwnershipByStatus = (status: number) => {
    switch (status) {
      case 11:
        return "Davlat";
        break;
      case 12:
        return "Nodavlat";
        break;
      case 13:
        return "Xorijiy";
        break;
      case 14:
        return "Qo'shma";
        break;
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Kodi",
      dataIndex: "universityCode",
      width:100,
    },
    {
      title: "Universitet nomi",
      dataIndex: "university",
      align: "center",
      width: "500px",
      ...getColumnSearchProps("university"),
    },
    {
      title: "2020-2021",
      dataIndex: "eduYear20202021",
      align: "center",
    },
    {
      title: "2021-2022",
      dataIndex: "eduYear20212022",
      align: "center",
    },
    {
      title: "2022-2023",
      dataIndex: "eduYear20222023",
      align: "center",
    },
  ];

  return (
    <div className="doctoralOrganization">
      <header className="doctoral_table_header">
        <h3>Bitirgan talabalar o'rtacha GPA foizi jadvali</h3>
      </header>
      <Table
        rowKey="universityCode"
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ x: 0, y: 600 }}
        loading={isLoading}
      />
    </div>
  );
}
