import {
  useGetEduFormQuery,
  useGetPaymentFormQuery,
  useGetUzAsboEmploymentStudentQuery,
} from "src/app/services/private";
import { ConfigProvider, message, Select, Space, Table } from "antd";
import { IUzAsboEmploymentStudent } from "src/app/services/private/type";
import { useEffect, useMemo, useState } from "react";
import { tablePaginationConfig } from "src/app/services/api/const";
import { useUzAsboEduFormColumns } from "src/pages/uzasboStatistics/hooks/useEduFormColumns";
import { DownloadButton } from "src/components/downloadButton";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { useGetTablesGraduatedYearQuery } from "src/app/services/tables";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";

const quarterOptions = [
  {
    label: "1-chorak",
    value: "1",
  },
  {
    label: "2-chorak",
    value: "2",
  },
  {
    label: "3-chorak",
    value: "3",
  },
  {
    label: "4-chorak",
    value: "4",
  },
];

export const yearOptions = [
  {
    label: "2020-2021",
    value: "2020-2021",
  },
  {
    label: "2021-2022",
    value: "2021-2022",
  },
  {
    label: "2022-2023",
    value: "2022-2023",
  },
  {
    label: "2023-2024",
    value: "2023-2024",
  },
  {
    label: "2024-2025",
    value: "2024-2025",
  },
];

export const UzAsboEmploymentStudent = () => {
  const { t } = useTranslation();
  const [eduYear, setEduYear] = useState<string>();
  const [quarter, setQuarter] = useState<string>();
  const [eduForm, setEduForm] = useState<string>();
  const [paymentForm, setPaymentForm] = useState<string>();
  // const { data } = useGetTablesGraduatedYearQuery();
  const { currentData, isFetching, isError, error } = useGetUzAsboEmploymentStudentQuery(
    {
      quarter,
      eduYear,
      eduForm,
      paymentForm,
    },
    {
      skip: !eduYear || !quarter,
    },
  );
  const getColumnSearchProps = useColumnSearch();
  const { data: paymentFormList } = useGetPaymentFormQuery();
  const { data: eudFormList } = useGetEduFormQuery();

  useEffect(() => {
    if (isError) {
      const err = error as FetchBaseQueryError;
      if (err?.status === 404) {
        message.error("Bu chorakdagi hisobot hali shakllanmagan.");
      }
    }
  }, [isError]);

  const columns: ColumnsType<IUzAsboEmploymentStudent> = useMemo(() => {
    return [
      {
        title: t("tables.univerName"),
        dataIndex: "otm",
        ...getColumnSearchProps("otm"),
        align: "center",
        width: 250,
      },
      {
        title: t("tables.bachelor"),
        children: [
          {
            title: "Faqat ishlayotgan",
            dataIndex: "onlyWorkingBachelor",
            width: 100,
            align: "center",
          },
          {
            title: "Faqat o'qiyotgan",
            dataIndex: "onlyStudyingBachelor",
            width: 100,
            align: "center",
          },
          {
            title: "O'qiyotgan va ishlayotgan",
            dataIndex: "studyingWorkingBachelor",
            width: 120,
            align: "center",
          },
          {
            title: "O'qimayotgan va ishlamayotgan",
            dataIndex: "noWorkingAndStudyingBachelor",
            width: 150,
            align: "center",
          },
          {
            title: t("tables.total"),
            dataIndex: "totalBachelor",
            align: "center",
            render: (total: number) => <b>{total}</b>,
            width: 60,
          },
        ],
      },
      {
        title: t("tables.master"),
        children: [
          {
            title: "Faqat ishlayotgan",
            dataIndex: "onlyWorkingMaster",
            width: 100,
            align: "center",
          },
          {
            title: "Faqat o'qiyotgan",
            dataIndex: "onlyStudyingMaster",
            width: 100,
            align: "center",
          },
          {
            title: "O'qiyotgan va ishlayotgan",
            dataIndex: "studyingWorkingMaster",
            width: 120,
            align: "center",
          },
          {
            title: "O'qimayotgan va ishlamayotgan",
            dataIndex: "noWorkingAndStudyingMaster",
            width: 150,
            align: "center",
          },
          {
            title: t("tables.total"),
            dataIndex: "totalMaster",
            align: "center",
            render: (total: number) => <b>{total}</b>,
            width: 60,
          },
        ],
      },
      {
        title: t("tables.total"),
        dataIndex: "total",
        align: "center",
        render: (total: number) => <b>{total}</b>,
        width: 70,
      },
    ] as ColumnsType<IUzAsboEmploymentStudent>;
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowSelectedBg: "#e6f4ff",
            rowSelectedHoverBg: "#bae0ff",
          },
        },
      }}
    >
      <Table
        title={() => (
          <div className="flex-space-center">
            <h3>Bitirgan talabalarni bandlik darajasi</h3>
            <Space>
              <Select
                style={{ width: 160 }}
                placeholder="Bitirgan yili"
                allowClear
                onChange={setEduYear}
                options={yearOptions}
              />
              <Select
                style={{ width: 160 }}
                placeholder="Chorak"
                onChange={setQuarter}
                options={quarterOptions}
                allowClear
              />
              <Select
                style={{ width: 160 }}
                placeholder="Ta'lim shakli"
                onChange={setEduForm}
                allowClear
                options={eudFormList?.map((item) => ({ label: item.name, value: item.code }))}
              />
              <Select
                allowClear
                style={{ width: 160 }}
                placeholder="To'lov shakli"
                onChange={setPaymentForm}
                options={paymentFormList?.map((item) => ({ label: item.name, value: item.code }))}
              />
              <DownloadButton
                downloadUrl="/uzasbohemis/employmentStudent/eduYearAndQuarter/excel"
                params={{
                  quarter,
                  eduYear,
                  eduForm,
                  paymentForm
                }}
              />
            </Space>
          </div>
        )}
        size="small"
        bordered
        virtual
        rowKey="otm"
        loading={isFetching}
        rowSelection={{ type: "radio", columnWidth: 40 }}
        dataSource={currentData}
        columns={columns}
        scroll={{ x: 2200, y: 600 }}
        pagination={tablePaginationConfig}
      />
    </ConfigProvider>
  );
};
