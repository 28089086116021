import i18n from "i18next";
import { PropsWithChildren } from "react";
import { initReactI18next } from "react-i18next";
import { languageEn } from "src/static/language/en";
import { languageRu } from "src/static/language/ru";
import { languageUz } from "src/static/language/uz";
import { language } from "./const";

function LanguageProvider({ children }: PropsWithChildren) {
  i18n.use(initReactI18next).init({
    resources: {
      uz: { translation: languageUz },
      ru: { translation: languageRu },
      en: { translation: languageEn },
    },
    fallbackLng: language || "uz",
    interpolation: { escapeValue: false },
  });
  return <>{children}</>;
}

export default LanguageProvider;
