import { useTranslation } from "react-i18next";
import { prettierNumber } from "src/utils";

interface Props {
  title: string;
  color: string;
  counts: number[];
}

function AdmissionCard({ title, color, counts }: Props) {
  const { t } = useTranslation();
  let max = 0;
  counts.forEach((item) => {
    if (item > max) max = item;
  });

  return (
    <div className="admission-card">
      <div className="admission-card-title">{t(title)}</div>
      <div className="admission-card-info">
        <p>{t("admission.over_years")}</p>
        <p>{t("admission.applications")}</p>
      </div>
      <div className="admission-card-content">
        <div className="admission-card-left">
          {!!counts[0] && (
            <div
              style={{
                backgroundColor: color,
                width: `${(counts[0] * 100) / max}%`,
              }}
            >
              2021-2022
            </div>
          )}
          <div
            style={{
              backgroundColor: color,
              width: `${(counts[1] * 100) / max}%`,
            }}
          >
            2022-2023
          </div>
          <div
            style={{
              backgroundColor: color,
              width: `${(counts[2] * 100) / max}%`,
            }}
          >
            2023-2024
          </div>
          {!!counts[3] && (
            <div
              style={{
                backgroundColor: color,
                width: `${(counts[3] * 100) / max}%`,
              }}
            >
              2024-2025
            </div>
          )}
        </div>
        <div className="admission-card-right">
          {!!counts[0] && <p>{prettierNumber(counts[0])}</p>}
          <p>{prettierNumber(counts[1])}</p>
          <p>{prettierNumber(counts[2])}</p>
          {!!counts[3] && <p>{prettierNumber(counts[3])}</p>}
        </div>
      </div>
    </div>
  );
}

export default AdmissionCard;
