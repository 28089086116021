import { api } from "../api";
import { IHemisAgeRes, IHemisRes } from "./type";

export const universitiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    //Get teachers by position endpoint
    getTeacherByPositionStat: build.query<IHemisRes[], string | void>({
      query: (params = "") => ({ url: "teacher/statistic/position" + params }),
    }),
    //Get teachers by gender endpoint
    getTeacherByGenderStat: build.query<IHemisRes[], string | void>({
      query: (params = "") => ({ url: "teacher/statistic/gender" + params }),
    }),
    //Get teachers by employee form endpoint
    getTeacherByEmployeeFormStat: build.query<IHemisRes[], string | void>({
      query: (params = "") => ({
        url: "teacher/statistic/employeeForm" + params,
      }),
    }),
    //Get teachers by citizenship endpoint
    getTeacherByCitizenshipStat: build.query<IHemisRes[], string | void>({
      query: (params = "") => ({
        url: "teacher/statistic/citizenship" + params,
      }),
    }),
    //Get teachers by age endpoint
    getTeacherByAgeStat: build.query<IHemisAgeRes[], string | void>({
      query: (params = "") => ({
        url: "teacher/statistic/ageGreaterOrLessThan30" + params,
      }),
    }),
    getTeacherByChiefPosition: build.query<IHemisRes[], string | void>({
      query: (params = "") => ({
        url: "teacher/statistic/chiefPosition" + params,
      }),
    }),
    getTeacherByAcademicRank: build.query<IHemisRes[], string | void>({
      query: (params = "") => ({
        url: "teacher/statistic/academicRank" + params,
      }),
    }),
    getTeacherByAcademicDegree: build.query<IHemisRes[], string | void>({
      query: (params = "") => ({
        url: "teacher/statistic/academicDegree" + params,
      }),
    }),
  }),
});

export const {
  useGetTeacherByCitizenshipStatQuery,
  useGetTeacherByEmployeeFormStatQuery,
  useGetTeacherByAgeStatQuery,
  useGetTeacherByGenderStatQuery,
  useGetTeacherByPositionStatQuery,
  useGetTeacherByAcademicDegreeQuery,
  useGetTeacherByAcademicRankQuery,
  useGetTeacherByChiefPositionQuery,
} = universitiesApi;
