import { TablesUniversityData } from 'src/app/services/tables/type';
import { ColumnType } from "antd/es/table";
import { Button, Input, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

type DataIndex = keyof TablesUniversityData;

export const useColumnSearch = () => {
  const { t } = useTranslation();

  return useCallback(
    (dataIndex: DataIndex): ColumnType<TablesUniversityData> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<span>&#128269;</span>}
              size="small"
              style={{ width: 90 }}
            >
              {t("doctoral.search")}
            </Button>
            <Button
              onClick={() => clearFilters && clearFilters()}
              size="small"
              style={{ width: 90 }}
            >
              {t("doctoral.reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              {t("doctoral.filter")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              {t("doctoral.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: () => <span>&#128269;</span>,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
    }),
    []
  );
};
