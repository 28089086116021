import { IHemisAgeRes, IHemisRes } from "src/app/services/teacher/type";
import { findOne } from "src/pages/higher_education/util";
import { ITeacherGender } from "src/pages/higher_education/students/type";

export const makeTeacherAcademicRank = (arr?: IHemisRes[]) => {
  if (arr) {
    let men = fillData(
      arr.filter((el) => el.gender === "Erkak"),
      teacherRankData
    );
    let women = fillData(
      arr.filter((el) => el.gender === "Ayol"),
      teacherRankData
    );

    let obj: ITeacherGender = {
      men_total: men.total,
      men_data: men.data,
      women_total: women.total,
      women_data: women.data,
      total: men.total + women.total,
    };
    return obj;
  }
};

export const makeTeacherAge = (arr?: IHemisAgeRes[]) => {
  if (arr) {
    let men = fillAgeData(
      arr.filter((el) => el.gender === "Erkak"),
      teacherAgeData
    );
    let women = fillAgeData(
      arr.filter((el) => el.gender === "Ayol"),
      teacherAgeData
    );

    let obj: ITeacherGender = {
      men_total: men.total,
      men_data: men.data,
      women_total: women.total,
      women_data: women.data,
      total: men.total + women.total,
    };
    return obj;
  }
};

export const makeTeacherAcademicDegree = (arr?: IHemisRes[]) => {
  if (arr) {
    let men = fillData(
      arr.filter((el) => el.gender === "Erkak"),
      teacherAcademicDegreeData
    );
    let women = fillData(
      arr.filter((el) => el.gender === "Ayol"),
      teacherAcademicDegreeData
    );

    const skilled = arr
      .filter((el) => el.name !== "Darajasiz")
      .reduce((acc, el) => acc + el.count, 0);
    const unskilled = arr
      .filter((el) => el.name === "Darajasiz")
      .reduce((acc, el) => acc + el.count, 0);

    let obj: ITeacherGender = {
      men_total: men.total,
      men_data: men.data,
      women_total: women.total,
      women_data: women.data,
      total: men.total + women.total,
      skilled,
      unskilled,
    };
    return obj;
  }
};

const fillData = (arr: IHemisRes[], arrData: (string | null)[]) => {
  let total = 0;
  const data = arrData.map((item) => {
    const count = findOne(arr, item) || 0;
    total += count;
    return count;
  });

  return {
    total,
    data,
  };
};

const fillAgeData = (arr: IHemisAgeRes[], arrData: (string | null)[]) => {
  let total = 0;
  const data = arrData.map((item) => {
    const count = arr.find((el) => el.age === item)?.count || 0;
    total += count;
    return count;
  });

  return {
    total,
    data,
  };
};

const teacherRankData = [
  "Unvonsiz",
  "Dotsent",
  "Katta ilmiy xodim",
  "Professor",
  null,
];

const teacherAcademicDegreeData = [
  "Darajasiz",
  "Fan nomzodi, PhD",
  "Fan doktori, DSc",
  null,
];

const teacherAgeData = [
  "30 yoshgacha",
  "30-35 yosh",
  "35-40 yosh",
  "40-45 yosh",
  "45-50 yosh",
  "50-55 yosh",
  "55-60 yosh",
  "60 yoshdan yuqori",
];
