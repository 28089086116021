import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChartCategoryColumn from "src/components/chart/BarChartCategoryColumn";
import PieChart from "src/components/chart/PieChart";
import { academic_titles, genders, scientific_levels, teacherAges } from "src/pages/constants";
import { findOne } from "../util";
import { useTeachers } from "src/pages/higher_education/teachers/useTeachers";

function TeachersPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const {
    positionData,
    chiefPositionData,
    genderData,
    employeeFormData,
    citizenshipData,
    academicDegreeData,
    academicRankData,
    ageData,
  } = useTeachers();


  return (
    <div className="">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard title={t("teachers.by_gender")} graphHeight={396}>
          <PieChart
            seriesArr={[
              { value: findOne(genderData, "Erkak"), name: t("genders.men") },
              { value: findOne(genderData, "Ayol"), name: t("genders.women") },
            ]}
            color={[colors.blue, colors.pink]}
          />
        </GraphInfoCard>

        {/* Staff */}
        <GraphInfoCard title={t("teachers.staff.leaders")} graphHeight={396}>
          <PieChart
            seriesArr={[
              {
                value: findOne(chiefPositionData, "Rector"),
                name: t("teachers.staff.prorektor"),
              },
              {
                value: findOne(chiefPositionData, "Dekan"),
                name: t("teachers.staff.dekan"),
              },
              {
                value: findOne(chiefPositionData, "Kafedra mudiri"),
                name: t("teachers.staff.department_header"),
              },
            ]}
            color={[colors.green, colors.blue, colors.yellow]}
          />
        </GraphInfoCard>

        {/* Scientific potential */}
        <GraphInfoCard title={t("teachers.s_potential.title")} graphHeight={396}>
          <PieChart
            seriesArr={[
              {
                value: academicDegreeData?.skilled,
                name: t("teachers.s_potential.skilled"),
              },
              {
                value: academicDegreeData?.unskilled,
                name: t("teachers.s_potential.unskilled"),
              },
            ]}
            color={[colors.green, colors.yellow]}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row-flex">
        {/* Scientific level */}
        <GraphInfoCard
          title={t("teachers.s_level.title")}
          stat={[
            {
              name: t("teachers.total_count"),
              count: academicDegreeData?.total,
            },
          ]}
        >
          <BarChartCategoryColumn
            interval={5000}
            legendData={genders}
            xAxisData={scientific_levels}
            seriesArr={[
              {
                name: t("genders.men"),
                data: academicDegreeData?.men_data,
              },
              {
                name: t("genders.women"),
                data: academicDegreeData?.women_data,
              },
            ]}
            barWidth={40}
          />
        </GraphInfoCard>

        {/* Academic title */}
        <GraphInfoCard
          title={t("teachers.ac_title.title")}
          stat={[
            {
              name: t("teachers.total_count"),
              count: academicRankData?.total,
            },
          ]}
        >
          <BarChartCategoryColumn
            interval={5000}
            legendData={genders}
            xAxisData={academic_titles}
            seriesArr={[
              {
                name: t("genders.men"),
                data: academicRankData?.men_data,
              },
              {
                name: t("genders.women"),
                data: academicRankData?.women_data,
              },
            ]}
            barWidth={40}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row-flex">
        {/* Position */}
        <GraphInfoCard title={t("teachers.position.title")} graphHeight={440}>
          <PieChart
            seriesArr={[
              {
                value: findOne(positionData, "Stajer-o‘qituvchi"),
                name: t("teachers.position.intern"),
              },
              {
                value: findOne(positionData, "Kafedra mudiri"),
                name: t("teachers.staff.department_header"),
              },
              {
                value: findOne(positionData, "Katta o‘qituvchi"),
                name: t("teachers.position.senior"),
              },
              {
                value: findOne(positionData, "Professor"),
                name: t("teachers.ac_title.professor"),
              },
              {
                value: findOne(positionData, "Dotsent"),
                name: t("teachers.ac_title.docent"),
              },
              {
                value: findOne(positionData, "Assistent"),
                name: t("teachers.position.assistent"),
              },
            ]}
            color={[
              colors.green,
              colors.orange,
              colors.pink,
              colors.blue,
              colors.yellow,
              colors.deep_purple,
            ]}
            legendSize={11}
          />
        </GraphInfoCard>

        {/* Staff */}
        <GraphInfoCard title={t("teachers.work_type.title")} graphHeight={440}>
          <PieChart
            seriesArr={[
              {
                value: findOne(employeeFormData, "Asosiy ish joy"),
                name: t("teachers.work_type.main"),
              },
            ]}
            color={[colors.orange]}
            legendSize={11}
          />
        </GraphInfoCard>

        {/* Scientific potential */}
        <GraphInfoCard title={t("teachers.by_citizenship")} graphHeight={440}>
          <PieChart
            seriesArr={[
              {
                value: findOne(citizenshipData, "O‘zbekiston Respublikasi fuqarosi"),
                name: t("citizenships.uzbek"),
              },
              {
                value: findOne(citizenshipData, "Xorijiy davlat fuqarosi"),
                name: t("citizenships.foreigner"),
              },
              {
                value: findOne(citizenshipData, "Fuqaroligi yo‘q shaxslar"),
                name: t("citizenships.stateless"),
              },
            ]}
            color={[colors.blue, colors.orange, colors.deep_purple]}
            legendSize={11}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* age level */}
        <GraphInfoCard
          title={t("teachers.by_age")}
          stat={[{ name: t("teachers.total_count"), count: ageData?.total }]}
        >
          <BarChartCategoryColumn
            legendData={genders}
            xAxisData={teacherAges}
            seriesArr={[
              {
                name: t("genders.men"),
                data: ageData?.men_data,
              },
              {
                name: t("genders.women"),
                data: ageData?.women_data,
              },
            ]}
            barWidth={40}
            interval={5000}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t('header.students_info_text')}</p>
      </div>
    </div>
  );
}

export default TeachersPage;
