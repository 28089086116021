import { TablesUniversityByRegionTable } from "src/pages/higher_education/tables/tablesUniversities/components/TablesUniversityByRegionTable";
import "./tableUniversity.scss";
import { TablesUniversityByGenderTable } from "src/pages/higher_education/tables/tablesUniversities/components/TablesUniversityByGenderTable";
import { TablesUniversityByPaymentTable } from "src/pages/higher_education/tables/tablesUniversities/components/TablesUniversityByPaymentTable";
import { TablesUniversityByEduTypeCourseTable } from "src/pages/higher_education/tables/tablesUniversities/components/TablesUniversityByEduTypeCourseTable";
import { TablesUniversityByEduFormTable } from "src/pages/higher_education/tables/tablesUniversities/components/TablesUniversityByEduFormTable";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TablesUniversityByEduFormByRegion } from "src/pages/higher_education/tables/tablesUniversities/components/TablesUniversityByEduFormByRegion";
import { TablesUniversityByCountry } from "src/pages/higher_education/tables/tablesUniversities/components/TablesUniversityByCountry";

function TablesByUniversities() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const id = params.get("id");

  return (
    <div className="tablesUniversities">
      {id === null && <TablesUniversityByRegionTable />}
      {id === "2" && <TablesUniversityByGenderTable />}
      {id === "3" && <TablesUniversityByPaymentTable />}
      {id === "4" && <TablesUniversityByEduTypeCourseTable />}
      {id === "5" && <TablesUniversityByEduFormTable />}
      {id === "6" && <TablesUniversityByEduFormByRegion />}
      {id === "7" && <TablesUniversityByCountry />}
      <div className="text_info">
        <p>{t("header.students_info_text")}</p>
      </div>
    </div>
  );
}

export default TablesByUniversities;
