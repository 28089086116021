import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useSearchParams } from "react-router-dom";
import { useGetRegionEduFormQuery } from "src/app/services/tables";
import { IRegionEduForm } from "src/app/services/tables/type";
import { useTypedSelector } from "src/app/store";

export function TableBachelorCountByRegionTable() {
  const { t } = useTranslation();
  const { data = [], isLoading: loadingBachelor } = useGetRegionEduFormQuery(11);
  const [_, setParam] = useSearchParams();
  const { darkMode } = useTypedSelector((state) => state.layout);

  const onRegionClick = (regionId: number, eduType: number) => {
    setParam({
      regionId: `${regionId}`,
      eduType: `${eduType}`,
    });
  };

  const render = (value: number) => value || 0;

  const columns: ColumnsType<IRegionEduForm> = [
    {
      title: "Hudud kodi",
      dataIndex: "regionCode",
    },
    {
      title: t("doctoral.placed_region"),
      dataIndex: "region",
      align: "center",
      render,
    },
    {
      title: t("edu_form.daytime"),
      dataIndex: "daytimeCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.evening"),
      dataIndex: "eveningCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.externally"),
      dataIndex: "externalCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.joint"),
      dataIndex: "jointCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.remote"),
      dataIndex: "remoteCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_daytime"),
      dataIndex: "secondDaytimeCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_evening"),
      dataIndex: "secondEveningCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_external"),
      dataIndex: "secondExternalCount",
      align: "center",
      render,
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
    {
      title: t("doctoral.actions"),
      dataIndex: "regionCode",
      render: (code) => (
        <Button
          size="small"
          style={{ color: `${darkMode ? "white" : "black"}` }}
          onClick={() => onRegionClick(code, 11)}
        >
          &#128065;
        </Button>
      ),
    },
  ];

  return (
    <div className="doctoralOrganization">
      <header className="doctoral_table_header">
        <h3>{t("tables.bachelor_by_region")}</h3>
      </header>
      <Table
        rowKey="regionCode"
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ x: true }}
        loading={loadingBachelor}
      />
    </div>
  );
}
