import ReactEcharts, { EChartsOption } from "echarts-for-react";
import { useTypedSelector } from "src/app/store";
import { ISeries } from "./BarChart";

interface Props {
  legendData: string[] | undefined;
  yAxisData: string[] | undefined;
  color: string[];
  seriesArr: ISeries[];
  barWidth?: number;
  xAxisShow?: boolean;
  yAxisMargin?: number;
  gridBottom?: number;
  gridLeft?: number;
}

const calcTotal = (seriesData: { value: number }[]) => {
  if (!seriesData || !seriesData.length) return "";

  const data = seriesData.reduce((acc, seriesData) => {
    return acc + seriesData.value;
  }, 0);
  var n = new Intl.NumberFormat().format(data);
  return n;
};

function BarChartCategoryTwo({
  legendData,
  yAxisData,
  color,
  seriesArr,
  barWidth = 32,
  xAxisShow = true,
  yAxisMargin = 150,
  gridBottom = 40,
  gridLeft = 8,
}: Props) {

  //Chart section
  const { colors } = useTypedSelector((state) => state.layout);

  const emphasisStyle = {
    label: {
      color: colors.primary,
    },
  };

  const seriesItemStyle = {
    borderColor: colors.white,
    borderWidth: 0.5,
    borderRadius: 6,
  };

  const axisLabelStyle = {
    fontSize: 14,
    color: colors.primary,
  };

  const legendTextStyle = {
    color: colors.tertiary,
    fontSize: 14,
  };

  let series: any = [];
  seriesArr?.forEach((item: any) => {
    series?.push({ ...item, type: "bar", emphasis: emphasisStyle, itemStyle: seriesItemStyle });
  });

  const option: EChartsOption = {
    legend: {
      data: legendData,
      align: "auto",
      bottom: 0,
      textStyle: legendTextStyle,
    },
    grid: {
      left: gridLeft,
      right: 8,
      top: 8,
      bottom: gridBottom,
      containLabel: true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          label: {
            formatter: function (params: any) {
              return `${params.value}: ${calcTotal(params.seriesData)}`;
            },
          },
        },
      },
    },
    barWidth: barWidth,
    barGap: barWidth === 12 && "-5%",
    color: color,
    tooltip: {},
    xAxis: {
      type: "value",
      axisLabel: axisLabelStyle,
      show: xAxisShow,
    },
    yAxis: {
      interval: 100,
      type: "category",
      data: yAxisData,
      axisLabel: {
        ...axisLabelStyle,
        align: "left",
        margin: yAxisMargin,
      },
    },
    series: series,
  };

  return <ReactEcharts option={option} />;
}

export default BarChartCategoryTwo;
