import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useGetTablesEmployeeFormQuery } from "src/app/services/tables";
import { ITablesEmployeeForm } from "src/app/services/tables/type";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { CustomTable } from "src/pages/higher_education/tables/tablesUniversities/components/CustomTable";
import { useMemo } from "react";

const excludedColumns = ["universityCode", "universityName", "universityOwnership"];

export function TablesEmployeeForm() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetTablesEmployeeFormQuery();
  const getColumnSearchProps = useColumnSearch();

  const dataSourceEmployee = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total:
        item.mainWorkplace +
        item.partTimeIntroPrimary +
        item.partTimeIntroExtra +
        item.partTimeExternal +
        item.byTime,
    }));

    return newData;
  }, [data]);

  const columns: ColumnsType<ITablesEmployeeForm> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
      width: "80px",

    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      width: "250px",
      ...getColumnSearchProps("universityName"),
    },
    {
      title: t("tables.ownership"),
      width: "130px",
      dataIndex: "universityOwnership",
      align: "center",
    },
    {
      title: t("tables.main_workplace"),
      dataIndex: "mainWorkplace",
      width: "130px",
      align: "center",
    },
    {
      title: t("tables.placement"),
      dataIndex: "partTimeIntroPrimary",
      align: "center",
    },
    {
      title: t("tables.placement_subjunctive"),
      dataIndex: "partTimeIntroExtra",
      align: "center",
    },
    {
      title: t("tables.placement_external"),
      dataIndex: "partTimeExternal",
      align: "center",
    },
    {
      title: t("tables.per_hour"),
      dataIndex: "byTime",
      width: "100px",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      width: "100px",

      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>
      <CustomTable
        title="Professor-o'qituvchilar mehnat shakli bo'yicha"
        rowKey="universityCode"
        dataSource={dataSourceEmployee}
        columns={columns}
        pagination={false}
        size="small"
        loading={isLoading}
        excludedColumns={excludedColumns}
        scroll={{ x: true, y: 500 }}
        downloadUrl="teacher/employeeForm"
      />
    </div>
  );
}
