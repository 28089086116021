import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  ages,
  citizenships,
  courses,
  genders,
  paymentForms,
  paymentForms1,
  residencePlaces,
} from "src/pages/constants";
import usePaymentForm from "./usePaymentForm";

function PaymentFormPage() {
  const { t } = useTranslation();
  const { placesData, gendersData, agesData, citizenshipData, coursesData } =
    usePaymentForm();
  const { colors } = useTypedSelector((state) => state.layout);

  return (
    <div className="eduform">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            {
              name: t("genders.men"),
              count: gendersData?.men_total,
            },
            {
              name: t("genders.women"),
              count: gendersData?.women_total,
            },
          ]}
          graphHeight={144}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={paymentForms}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.women"),
                data: gendersData?.women_data,
              },
              {
                name: t("genders.men"),
                data: gendersData?.men_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-80}
            yAxisMargin={104}
          />
        </GraphInfoCard>

        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            {
              name: t("ages.under_30"),
              count: agesData?.less_total,
            },
            {
              name: t("ages.over_30"),
              count: agesData?.greater_total,
            },
          ]}
          graphHeight={144}
        >
          <BarChartCategory
            legendData={ages}
            yAxisData={paymentForms}
            color={[colors.light_blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("ages.under_30"),
                data: agesData?.less_data,
              },
              {
                name: t("ages.over_30"),
                data: agesData?.greater_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-80}
            yAxisMargin={104}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Citizenships */}
        <GraphInfoCard
          title={t("header.citizenship")}
          stat={[
            {
              name: t("citizenships.uzbek"),
              count: citizenshipData?.uzbek_total,
            },
            {
              name: t("citizenships.foreigner"),
              count: citizenshipData?.foreigner_total,
            },
            {
              name: t("citizenships.stateless"),
              count: citizenshipData?.stateless_total,
            },
          ]}
        >
          <BarChart
            legendData={citizenships}
            xAxisData={paymentForms1}
            color={[colors.light_green, colors.blue, colors.yellow]}
            seriesArr={[
              {
                name: t("citizenships.uzbek"),
                data: citizenshipData?.uzbek_data,
                stack: "one",
              },
              {
                name: t("citizenships.foreigner"),
                data: citizenshipData?.foreigner_data,
                stack: "one",
              },
              {
                name: t("citizenships.stateless"),
                data: citizenshipData?.stateless_data,
                stack: "one",
              },
            ]}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Courses */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            {
              name: t("courses.course_1"),
              count: coursesData?.course_1_total,
            },
            {
              name: t("courses.course_2"),
              count: coursesData?.course_2_total,
            },
            {
              name: t("courses.course_3"),
              count: coursesData?.course_3_total,
            },
            {
              name: t("courses.course_4"),
              count: coursesData?.course_4_total,
            },
            {
              name: t("courses.course_5"),
              count: coursesData?.course_5_total,
            },
            {
              name: t("courses.course_6"),
              count: coursesData?.course_6_total,
            },
          ]}
        >
          <BarChart
            legendData={courses}
            xAxisData={paymentForms1}
            color={[
              colors.blue,
              colors.pink,
              colors.yellow,
              colors.light_green,
              colors.light_blue,
              colors.orange,
            ]}
            seriesArr={[
              {
                name: t("courses.course_1"),
                data: coursesData?.course_1_data,
                stack: "one",
              },
              {
                name: t("courses.course_2"),
                data: coursesData?.course_2_data,
                stack: "one",
              },
              {
                name: t("courses.course_3"),
                data: coursesData?.course_3_data,
                stack: "one",
              },
              {
                name: t("courses.course_4"),
                data: coursesData?.course_4_data,
                stack: "one",
              },
              {
                name: t("courses.course_5"),
                data: coursesData?.course_5_data,
                stack: "one",
              },
              {
                name: t("courses.course_6"),
                data: coursesData?.course_6_data,
                stack: "one",
              },
            ]}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            {
              name: t("residence_places.own_house"),
              count: placesData?.own_house_total,
            },
            {
              name: t("residence_places.dormitory"),
              count: placesData?.dormitory_total,
            },
            {
              name: t("residence_places.rented_house"),
              count: placesData?.rented_house_total,
            },
            {
              name: t("residence_places.relative_house"),
              count: placesData?.relative_house_total,
            },
            {
              name: t("residence_places.familiar_house"),
              count: placesData?.familiar_house_total,
            },
          ]}
          graphHeight={204}
        >
          <BarChartCategory
            legendData={residencePlaces}
            yAxisData={paymentForms}
            color={[
              colors.pink,
              colors.blue,
              colors.orange,
              colors.deep_purple,
              colors.light_blue,
            ]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: placesData?.own_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: placesData?.dormitory_data,
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: placesData?.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: placesData?.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: placesData?.familiar_house_data,
                stack: "one",
              },
            ]}
            gridLeft={-64}
            yAxisMargin={104}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t('header.students_info_text')}</p>
      </div>
    </div>
  );
}

export default PaymentFormPage;
