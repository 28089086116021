import { RootState } from "src/app/store";
import { apiTagTypes, baseUrl_Hemis } from "./const";
import {
  BaseQueryFn,
  FetchArgs,
  createApi,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
// Create our baseQuery instance
// const baseQuery = fetchBaseQuery({
//   baseUrl: "",
//   prepareHeaders: (headers, { getState }) => {
//     const baseUrl = (getState() as RootState).auth.baseUrl;
//     headers.set("Base-URL", baseUrl || baseUrl_Hemis);

//     const token = (getState() as RootState).auth.token;
//     if (token) {
//       headers.set("authorization", `Bearer ${token}`);
//     }
//     return headers;
//   },
// });

const dynamicBaseQuery = async (args: any, api: any, extraOptions: any) => {
  // Access the baseUrl from the state
  const baseUrl = (api.getState() as RootState).auth.baseUrl;
  // Use the standard fetchBaseQuery with the dynamic baseUrl
  const baseQuery = fetchBaseQuery({ baseUrl });
  return baseQuery(args, api, extraOptions);
};

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const api = createApi({
  baseQuery: dynamicBaseQuery,
  tagTypes: apiTagTypes,
  endpoints: () => ({}),
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => "test",
  }),
});

export const AuthBaseQuery = fetchBaseQuery({
  baseUrl: baseUrl_Hemis,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const PrivateBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await AuthBaseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    localStorage.removeItem("token");
    window.history.back();
  }
  return result;
};

export const privateApi = createApi({
  baseQuery: PrivateBaseQuery,
  reducerPath: "privateApi",
  endpoints: () => ({}),
});
