export const findTopMenuDef = (path: string) => {
  const page = path.split("/")[1];
  if (page === "lyceum") return "/lyceum";
  else if (page === "prof") return "/prof";
  else if (page === "admission") return "/admission";
  else if (page === "doctoral") return "/doctoral";
  else if (page === "tables") return "/tables";
  else if (page === "prof-tables") return "/prof-tables";
  else if (page === "uzasbo") return "/uzasbo";
  else return "/";
};

export const findHigherTypeDef = (path: string) => {
  const page = path.split("/")[1];
  if (page === "teachers") return "/teachers";
  else if (page === "universities") return "/universities";
  else if (page === "tables") return "/tables";
  else if (page === "students") return "/students";
  else return "/";
};

export const findLyceumTypeDef = (path: string) => {
  const page = path.split("/")[1];
  if (page === "list") return "/teachers";
  else if (page === "universities") return "/universities";
  else return "/";
};
