import { useTranslation } from "react-i18next";
import { ITablesRegion } from "src/app/services/tables/type";
import { useGetTablesRegionsQuery } from "src/app/services/tables";
import { renderEmptyValue } from "src/pages/higher_education/tables/utils";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import type { ColumnsType } from "antd/es/table";
import { useMemo, useState } from "react";
import { ConfigProvider, Select, Space, Table } from "antd";
import { DownloadButton } from "src/components/downloadButton";

export function TablesUniversityByRegionTable() {
  const { t } = useTranslation();
  const getColumnSearchProps = useColumnSearch();
  const excludedColumns = ["universityCode", "universityName", "universityOwnership"];
  const [select, setSelect] = useState("all");
  const [eduType, setEduType] = useState("all");
  const { data, isFetching } = useGetTablesRegionsQuery({
    paymentType: select,
    eduType: eduType,
  });

  const payment_type_list = [
    {
      value: "all",
      label: t("tables.total"),
    },
    {
      value: "11",
      label: t("pay_form.grant"),
    },
    {
      value: "12",
      label: t("pay_form.contract"),
    },
  ];

  const edu_type_list = [
    {
      value: "all",
      label: t("tables.total"),
    },
    {
      value: "11",
      label: "Bakalavr",
    },
    {
      value: "12",
      label: "Magistr",
    },
    {
      value: "13",
      label: "Ordinatura",
    },
  ];

  const dataSourceRegion = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total:
        item.andijon +
        item.buxoro +
        item.fergana +
        item.jizzax +
        item.namangan +
        item.navoiy +
        item.qashqadaryo +
        item.qoraqalpoq +
        item.samarqand +
        item.sirdaryo +
        item.surxandaryo +
        item.tashkent +
        item.tashkentV +
        item.xorazm,
    }));

    return newData;
  }, [data]);

  const columns: ColumnsType<ITablesRegion> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
      width: 80,
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      width: 150,
      ...getColumnSearchProps("universityName"),
    },
    {
      title: t("tables.ownership"),
      dataIndex: "universityOwnership",
    },
    {
      title: t("regions.andijan"),
      dataIndex: "andijon",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("regions.bukhara"),
      dataIndex: "buxoro",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("regions.fergana"),
      dataIndex: "fergana",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("regions.jizzax"),
      dataIndex: "jizzax",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("regions.namangan"),
      dataIndex: "namangan",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("regions.navai"),
      dataIndex: "navoiy",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("regions.qashqadaryo"),
      dataIndex: "qashqadaryo",
      align: "center",
      render: renderEmptyValue,
      width: 110,
    },
    {
      title: t("regions.qoraqalpoq"),
      dataIndex: "qoraqalpoq",
      align: "center",
      render: renderEmptyValue,
      width: 200,
    },
    {
      title: t("regions.samarqand"),
      dataIndex: "samarqand",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("regions.sirdaryo"),
      dataIndex: "sirdaryo",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("regions.surxondaryo"),
      dataIndex: "surxandaryo",
      align: "center",
      render: renderEmptyValue,
      width: 110,
    },
    {
      title: t("regions.tashkent_city"),
      dataIndex: "tashkent",
      align: "center",
      render: renderEmptyValue,
      width: 120,
    },
    {
      title: t("regions.tashkent_region"),
      dataIndex: "tashkentV",
      align: "center",
      render: renderEmptyValue,
      width: 140,
    },
    {
      title: t("regions.xorazm"),
      dataIndex: "xorazm",
      align: "center",
      render: renderEmptyValue,
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowSelectedBg: "#e6f4ff",
              rowSelectedHoverBg: "#bae0ff",
            },
          },
        }}
      >
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{t("tables.universityByRegion")}</h3>

              <Space>
                <Select
                  style={{ width: 230 }}
                  onChange={setEduType}
                  value={eduType}
                  options={edu_type_list}
                />

                <Select
                  style={{ width: 200 }}
                  onChange={setSelect}
                  value={select}
                  options={payment_type_list}
                />

                <DownloadButton
                  downloadUrl="/excel/student/region"
                  params={{
                    eduType: eduType && eduType,
                    paymentType: select && select,
                  }}
                />
              </Space>
            </div>
          )}
          rowKey="universityCode"
          dataSource={dataSourceRegion}
          columns={columns}
          pagination={false}
          rowSelection={{ type: "radio", columnWidth: 80 }}
          size="small"
          scroll={{ x: 1900, y: 500 }}
          loading={isFetching}
          summary={(pageData) => {
            if (pageData.length === 0) return null;
            //@ts-ignore
            const totalCounts = columns.reduce((acc, { dataIndex, children }) => {
              if (excludedColumns.includes(dataIndex)) return acc;

              if (children) {
                const childrenCount = children.map((child: any) => {
                  return pageData.reduce((acc, next) => {
                    //@ts-ignore
                    return acc + (next[child.dataIndex] || 0);
                  }, 0);
                });

                return acc.concat(childrenCount);
              }

              const totalCount = pageData.reduce((acc, next) => {
                //@ts-ignore
                return acc + (next[dataIndex] || 0);
              }, 0);

              return [...acc, totalCount];
            }, []);

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <b>{t("tables.total")}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  {//@ts-ignore
                  totalCounts?.map((count: number, index: number) => (
                    <Table.Summary.Cell align="center" index={index + 3} key={index}>
                      <b>{count}</b>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );
}
