import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useGetUniversitiesMutation } from "src/app/services/universities";
import { IUniversityTable } from "src/app/services/universities/type";
import RegionFilter from "src/components/filter/RegionFilter";
import { language } from "src/provider/language/const";
// import { handleMakeUrl, handleMakeUrl2, scrollTop } from "src/utils";
import { lyceums } from "./const";

interface IData {
  name: string;
  key?: number;
}

export default function LyceumsListPage() {
  const { t } = useTranslation();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [universityType, setUniversityType] = useState("list");
  // const [getFunc, { data }] = useGetUniversitiesMutation();

  // useEffect(() => {
  //   if (universityType === "search") {
  //     let url = handleMakeUrl(searchParams);
  //     getFunc(url);
  //   } else {
  //     let url = handleMakeUrl2(searchParams);
  //     getFunc("limit=10000" + url);
  //   }
  //   scrollTop();
  // }, [searchParams, universityType]);

  // const [tableData, setTableData] = useState<IUniversityTable[]>([]);

  // const makeTableData = () => {
  //   let size = Number(searchParams.get("size") || 10);
  //   let page = Number(searchParams.get("page") || 1);
  //   let arr: IUniversityTable[] = [];

  //   data?.results.forEach((item, index) => {
  //     arr.push({
  //       key: (page - 1) * size + index + 1,
  //       ...item,
  //     });
  //   });
  //   setTableData([...arr]);
  // };

  // useEffect(() => {
  //   if (data) makeTableData();
  // }, [data]);

  const [dataFake, setDataFake] = useState<IData[]>([]);
  const makeData = () => {
    let arr: IData[] = [...lyceums];
    // arr.sort((a, b) => a.name.localeCompare(b.name));
    arr.forEach((item, index) => {
      item["key"] = index + 1;
    });
    setDataFake([...arr]);
  };

  useEffect(() => {
    makeData();
  }, []);

  //Data section
  const columns: ColumnsType<IData> = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
    },
    {
      title: t("lyceum.name"),
      dataIndex: `name`,
      key: `name`,
      // dataIndex: `name_${language || "uz"}`,
      // key: `name_${language || "uz"}`,
    },
    // {
    //   title: t("university.website"),
    //   dataIndex: "website",
    //   key: "website",
    //   render: (val) => (
    //     <a href={val} target="_blank" rel="noreferrer">
    //       {val?.split("/")[2]}
    //     </a>
    //   ),
    // },
  ];

  return (
    <div className="university">
      <div className="university-title">{t("lyceum.list")}</div>

      <div className="university-filter">
        <RegionFilter />
      </div>
      <div className="university-table">
        <Table dataSource={dataFake} columns={columns} bordered pagination={false} />
      </div>
      <div className="text_info">
        <p>{t("header.lyceum_info_text")}</p>
      </div>
    </div>
  );
}
