import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Table, Select, Button } from "antd";
import "./index.scss";
import {
  useGetDiplomaRegisterYearQuery,
  useGetTablesGraduatedByRegionQuery,
} from "src/app/services/tables";
import { IGraduatedByRegion } from "src/app/services/tables/type";
import { DownloadIcon } from "src/assets/svg";
import { getExcel } from "src/app/services/public";
import { useTypedSelector } from "src/app/store";
export function TablesGraduatedStudentsByRegion() {
  const { t } = useTranslation();

  const { data: yearList } = useGetDiplomaRegisterYearQuery();
  const [valueYearSelect, setValueYearSelect] = useState<string>();
  const { data: admissionTc, isFetching: tcLoading } =
    useGetTablesGraduatedByRegionQuery(
      { eduYearCode: valueYearSelect },
      {
        skip: !valueYearSelect,
      },
    );
  const [loading, setLoading] = useState(false);
  const { colors } = useTypedSelector((state) => state.layout);

  const downloadExcelFile = async () => {
    setLoading(true);
    await getExcel(`graduatedStudent/region?eduYearCode=${valueYearSelect}`);
    setLoading(false);
  };

  useEffect(() => {
    if (yearList) {
      setValueYearSelect(yearList[0].code);
    }
  }, [yearList]);

  const columns: ColumnsType<IGraduatedByRegion> = [
    {
      title: t("prof.province_code"),
      width: "60px",
      dataIndex: "code",
      align: "center",
    },
    {
      title: t("prof.region_name"),
      dataIndex: "name",
      width: "100px",
      align: "center",
    },
    {
      title: t("tables.bachelor_count"),
      dataIndex: "bachelorCount",
      width: "50px",
      align: "center",
    },
    {
      title: t("tables.master_count"),
      dataIndex: "masterCount",
      width: "50px",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      width: "50px",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  const handleYearChange = (value: string) => {
    setValueYearSelect(value);
  };

  const defaultExcludedColumns = ["code", "name"];

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginTop: "40px" }}>
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{t("tables.graduated_select_two")}</h3>

              <div className="filter-container">
                <Select
                  style={{ width: 200 }}
                  onChange={handleYearChange}
                  value={valueYearSelect}
                  options={yearList?.map((item) => ({
                    label: item.name,
                    value: item.code,
                  }))}
                />
                <Button
                  className="download_btn"
                  onClick={downloadExcelFile}
                  loading={loading}
                >
                  {t("header.download")} <DownloadIcon color={colors.primary} />
                </Button>
              </div>
            </div>
          )}
          rowKey="code"
          dataSource={admissionTc}
          columns={columns}
          pagination={false}
          size="small"
          loading={tcLoading}
          summary={(pageData) => {
            if (pageData.length === 0) return null;
            //@ts-ignore
            const totalCounts = columns.reduce((acc, { dataIndex }) => {
              if (defaultExcludedColumns.includes(dataIndex)) return acc;

              const totalCount = pageData.reduce((acc, next) => {
                //@ts-ignore
                return acc + (next[dataIndex] || 0);
              }, 0);

              return [...acc, totalCount];
            }, []);

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} align="center">
                    <b>{t("tables.total")}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  {//@ts-ignore
                  totalCounts?.map((count: number, index: number) => (
                    <Table.Summary.Cell
                      align="center"
                      index={index + 3}
                      key={index}
                    >
                      <b>{count}</b>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </div>
    </div>
  );
}
