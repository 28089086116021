import { language } from "src/provider/language/const";

export const handleMakeUrl = (
  searchParams: URLSearchParams,
  additionalParams?: any
) => {
  let url = ``;
  let size = Number(searchParams.get("size") || 10);
  let page = Number(searchParams.get("page") || 1);

  for (let key of Array.from(searchParams.keys())) {
    if (key === "size") url = url + `&limit=${size}`;
    else if (key === "page") url = url + `&offset=${(page - 1) * size}`;
    else {
      let value = searchParams.get(key);
      url = url + `&${key}=${value}`;
    }
  }

  if (additionalParams) {
    if (url === "") url = "limit=10&offset=0";
    for (let key in additionalParams) {
      url = url + `&${key}=${additionalParams[key]}`;
    }
  }
  if (!url.includes("limit")) url = url + "&limit=10";
  if (!url.includes("offset")) url = url + "&offset=0";
  return url;
};

export const handleMakeUrl2 = (
  searchParams: URLSearchParams,
  additionalParams?: any
) => {
  let url = ``;
  for (let key of Array.from(searchParams.keys())) {
    let value = searchParams.get(key);
    url = url + `&${key}=${value}`;
  }
  return url;
};

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

export const giveLan = (data: any) => {
  if (data) {
    let val =
      language === "ru"
        ? data.name_ru
        : language === "en"
        ? data.name_en
        : data.name_uz;
    return val.replaceAll("‘", "'");
  } else return "";
};

export const prettierNumber = (val: number | undefined) => {
  if (val) {
    let val_arr = val.toString().split(".");
    let number = val_arr[0].split("").reverse();
    let number_rest = val_arr[1] ? "." + val_arr[1] : "";
    let prettier_number_arr: string[] = [];

    number.forEach((item, index) => {
      if (index > 0 && index % 3 === 0) prettier_number_arr.push(" ");
      prettier_number_arr.push(item);
    });

    return prettier_number_arr.reverse().join("") + number_rest;
  } else return "";
};

export type TAgeType = {
  lessThan: number[];
  greaterThan: number[];
};

export type TGenderType = {
  men: number[];
  women: number[];
};

export const generateDataByMenWomen = (
  data: { name: string; count: number }[]
) => {
  return data.reduce(
    (acc, next) => {
      if (next.name === "Erkak") {
        return { ...acc, men: [...acc.men, next.count] };
      }
      if (next.name === "Ayol") {
        return { ...acc, women: [...acc.women, next.count] };
      }
      return acc;
    },
    { men: [], women: [] } as TGenderType
  );
};

export const generateDataByGreaterLess = (
  data: { name: string; count: number }[]
) => {
  return data.reduce(
    (acc, next) => {
      if (next.name === "greaterThan") {
        return { ...acc, greaterThan: [...acc.greaterThan, next.count] };
      }
      if (next.name === "lessThan") {
        return { ...acc, lessThan: [...acc.lessThan, next.count] };
      }
      return acc;
    },
    { lessThan: [], greaterThan: [] } as TAgeType
  );
};

export const generateDataByDynamicName = (
  data: any[],
  value1?: { checkName: string; fieldName: string },
  value2?: { checkName: string; fieldName: string },
  value3?: { checkName: string; fieldName: string },
  value4?: { checkName: string; fieldName: string },
  value5?: { checkName: string; fieldName: string },
  value6?: { checkName: string; fieldName: string }
) => {
  return data.reduce((acc, next) => {
    if (value1 && next.name === value1.checkName) {
      const res = !acc[value1.fieldName]
        ? {
            ...acc,
            [value1.fieldName]: [],
          }
        : acc;

      return {
        ...res,
        [value1.fieldName]: [...res[value1.fieldName], next.count],
      };
    }

    if (value2 && next.name === value2.checkName) {
      const res = !acc[value2.fieldName]
        ? {
            ...acc,
            [value2.fieldName]: [],
          }
        : acc;

      return {
        ...res,
        [value2.fieldName]: [...res[value2.fieldName], next.count],
      };
    }

    if (value3 && next.name === value3.checkName) {
      const res = !acc[value3.fieldName]
        ? {
            ...acc,
            [value3.fieldName]: [],
          }
        : acc;

      return {
        ...res,
        [value3.fieldName]: [...res[value3.fieldName], next.count],
      };
    }
    if (value4 && next.name === value4.checkName) {
      const res = !acc[value4.fieldName]
        ? {
            ...acc,
            [value4.fieldName]: [],
          }
        : acc;

      return {
        ...res,
        [value4.fieldName]: [...res[value4.fieldName], next.count],
      };
    }

    if (value5 && next.name === value5.checkName) {
      const res = !acc[value5.fieldName]
        ? {
            ...acc,
            [value5.fieldName]: [],
          }
        : acc;

      return {
        ...res,
        [value5.fieldName]: [...res[value5.fieldName], next.count],
      };
    }
    if (value6 && next.name === value6.checkName) {
      const res = !acc[value6.fieldName]
        ? { ...acc, [value6.fieldName]: [] }
        : acc;
      return {
        ...res,
        [value6.fieldName]: [...res[value6.fieldName], next.count],
      };
    }

    return acc;
  }, {});
};

export const fillEmptyArrayPlace = (arr: any[], length: number) => {
  if (arr.length === length) {
    return arr;
  }
  const iteration = length - arr.length;
  const emptyArray = new Array(iteration).fill(undefined);

  return [...arr, ...emptyArray];
};
