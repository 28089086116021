interface Props {
  title: string;
  value: number | undefined;
}

function AllTotalCard({ title, value }: Props) {
  return (
    <div className="card">
      <div className="content">
        <div className="title">{title}:</div>
        <div className="total">{value}</div>
      </div>
    </div>
  );
}

export default AllTotalCard;
