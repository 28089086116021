import { useEffect, useState } from "react";
import {
  useGetStudentsRegionByAgeStatQuery,
  useGetStudentsRegionByCitizenshipStatQuery,
  useGetStudentsRegionByCourseStatQuery,
  useGetStudentsRegionByEduFormStatQuery,
  useGetStudentsRegionByEduTypeStatQuery,
  useGetStudentsRegionByGenderStatQuery,
  useGetStudentsRegionByPaymentStatQuery,
  useGetStudentsRegionByPlaceStatQuery,
} from "src/app/services/students";
import {
  IStudentAge,
  IStudentCitizenship,
  IStudentCourse,
  IStudentEduForm,
  IStudentEduType,
  IStudentGender,
  IStudentPayment,
  IStudentPlace,
} from "../type";
import {
  makeStudentAge,
  makeStudentCitizenship,
  makeStudentCourse,
  makeStudentEduForm,
  makeStudentEduType,
  makeStudentGender,
  makeStudentPayment,
  makeStudentPlace,
} from "../util";

function useRegion() {
  // Genders section
  const [gendersData, setGenders] = useState<IStudentGender>();
  const { data: genders } = useGetStudentsRegionByGenderStatQuery();
  useEffect(() => {
    if (genders) setGenders(makeStudentGender(genders));
  }, [genders]);

  // Edu type section
  const [eduTypeData, setEduType] = useState<IStudentEduType>();
  const { data: eduTypes } = useGetStudentsRegionByEduTypeStatQuery();
  useEffect(() => {
    if (eduTypes) setEduType(makeStudentEduType(eduTypes));
  }, [eduTypes]);

  // Ages section
  const [agesData, setAges] = useState<IStudentAge>();
  const { data: ages } = useGetStudentsRegionByAgeStatQuery();
  useEffect(() => {
    if (ages) setAges(makeStudentAge(ages));
  }, [ages]);

  // Payment section
  const [paymentData, setPayment] = useState<IStudentPayment>();
  const { data: payments } = useGetStudentsRegionByPaymentStatQuery();
  useEffect(() => {
    if (payments) setPayment(makeStudentPayment(payments));
  }, [payments]);

  // Courses section
  const [coursesData, setCourses] = useState<IStudentCourse>();
  const { data: courses } = useGetStudentsRegionByCourseStatQuery();
  useEffect(() => {
    if (courses) setCourses(makeStudentCourse(courses));
  }, [courses]);

  // Citizenship section
  const [citizenshipData, setCitizenship] = useState<IStudentCitizenship>();
  const { data: citizenships } = useGetStudentsRegionByCitizenshipStatQuery();
  useEffect(() => {
    if (citizenships) setCitizenship(makeStudentCitizenship(citizenships));
  }, [citizenships]);

  // Places section
  const [placesData, setPlaces] = useState<IStudentPlace>();
  const { data: places } = useGetStudentsRegionByPlaceStatQuery();
  useEffect(() => {
    if (places) setPlaces(makeStudentPlace(places));
  }, [places]);

  // Edu form section
  const [eduFormData, setEduForm] = useState<IStudentEduForm>();
  const { data: eduForms } = useGetStudentsRegionByEduFormStatQuery();
  useEffect(() => {
    if (eduForms) setEduForm(makeStudentEduForm(eduForms));
  }, [eduForms]);

  return {
    gendersData,
    eduTypeData,
    agesData,
    paymentData,
    coursesData,
    citizenshipData,
    placesData,
    eduFormData,
  };
}

export default useRegion;
