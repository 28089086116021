import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProfEduTypeStatMutation } from "src/app/services/prof_education";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  citizenships,
  paymentForms,
  profEducationForms,
  profEducationTypes,
  prof_ages,
  prof_courses,
  residencePlaces,
} from "src/pages/constants";
import "src/pages/higher_education/students/edu_type/edutype.scss";
import { IStatProfEduType } from "src/pages/type";
import { makeProfEduTypeData } from "../util";
import ProfEduTypeCard from "./components/EduTypeCard";

export default function ProfEduTypePage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [getFunc, { data }] = useGetProfEduTypeStatMutation();
  const [stat, setStat] = useState<IStatProfEduType>();

  useEffect(() => {
    getFunc();
  }, []);

  useEffect(() => {
    if (data) {
      setStat(makeProfEduTypeData(data));
    }
  }, [data]);

  return stat ? (
    <div className="edutype">
      <div className="edutype-types">
        {data?.by_gender?.map((item) => {
          return <ProfEduTypeCard {...item} key={item.education_type} />;
        })}
      </div>
      <div className="graph-row-flex">
        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            { name: t("ages.under_20"), count: stat?.age.under_20_total },
            { name: t("ages.over_20"), count: stat?.age.over_20_total },
          ]}
        >
          <BarChart
            legendData={prof_ages}
            xAxisData={profEducationTypes}
            color={[colors.light_blue, colors.green]}
            seriesArr={[
              {
                name: t("ages.under_20"),
                data: stat.age.under_20_data,
                stack: "one",
              },
              {
                name: t("ages.over_20"),
                data: stat.age.over_20_data,
                stack: "one",
              },
            ]}
            gridBottom={60}
          />
        </GraphInfoCard>

        {/* Paymeny type */}
        <GraphInfoCard
          title={t("header.payment_form")}
          stat={[
            { name: t("pay_form.grant"), count: stat.payment_form.grant_total },
            { name: t("pay_form.contract"), count: stat.payment_form.contract_total },
          ]}
        >
          <BarChart
            legendData={paymentForms}
            xAxisData={profEducationTypes}
            color={[colors.orange, colors.light_blue]}
            seriesArr={[
              {
                name: t("pay_form.grant"),
                data: stat.payment_form.grant_data,
                stack: "one",
              },
              {
                name: t("pay_form.contract"),
                data: stat.payment_form.contract_data,
                stack: "one",
              },
            ]}
            gridBottom={60}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row-flex">
        {/* Courses */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            { name: t("courses.course_1"), count: stat.courses.course_1_total },
            { name: t("courses.course_2"), count: stat.courses.course_2_total },
            { name: t("courses.course_3"), count: stat.courses.course_3_total },
          ]}
        >
          <BarChart
            legendData={prof_courses}
            xAxisData={profEducationTypes}
            color={[colors.blue, colors.pink, colors.yellow]}
            seriesArr={[
              {
                name: t("courses.course_1"),
                data: stat.courses.course_1_data,
                stack: "one",
              },
              {
                name: t("courses.course_2"),
                data: stat.courses.course_2_data,
                stack: "one",
              },
              {
                name: t("courses.course_3"),
                data: stat.courses.course_3_data,
                stack: "one",
              },
            ]}
            gridBottom={54}
          />
        </GraphInfoCard>

        {/* Citizenships */}
        <GraphInfoCard
          title={t("header.citizenship")}
          stat={[
            { name: t("citizenships.uzbek"), count: stat.citizenship.uzbek_total },
            { name: t("citizenships.foreigner"), count: stat.citizenship.foreigner_total },
            { name: t("citizenships.stateless"), count: stat.citizenship.stateless_total },
            { name: t("citizenships.teen"), count: stat.citizenship.teen_total },
          ]}
        >
          <BarChart
            legendData={citizenships}
            xAxisData={profEducationTypes}
            color={[colors.yellow, colors.light_green, colors.blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("citizenships.uzbek"),
                data: stat.citizenship.uzbek_data,
                stack: "one",
              },
              {
                name: t("citizenships.foreigner"),
                data: stat.citizenship.foreigner_data,
                stack: "one",
              },
              {
                name: t("citizenships.stateless"),
                data: stat.citizenship.stateless_data,
                stack: "one",
              },
              {
                name: t("citizenships.teen"),
                data: stat.citizenship.teen_data.reverse(),
                stack: "one",
              },
            ]}
            gridBottom={70}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            { name: t("residence_places.own_house"), count: stat.residence_place.own_house_total },
            { name: t("residence_places.dormitory"), count: stat.residence_place.dormitory_total },
            {
              name: t("residence_places.rented_house"),
              count: stat.residence_place.rented_house_total,
            },
            {
              name: t("residence_places.relative_house"),
              count: stat.residence_place.relative_house_total,
            },
            {
              name: t("residence_places.familiar_house"),
              count: stat.residence_place.familiar_house_total,
            },
            { name: t("residence_places.other"), count: stat.residence_place.other_total },
          ]}
          graphHeight={396}
        >
          <BarChartCategory
            legendData={residencePlaces}
            yAxisData={profEducationTypes}
            color={[
              colors.pink,
              colors.blue,
              colors.orange,
              colors.deep_purple,
              colors.light_blue,
              colors.yellow,
            ]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: stat.residence_place.own_house_data.reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: stat.residence_place.dormitory_data.reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: stat.residence_place.rented_house_data.reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: stat.residence_place.relative_house_data.reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: stat.residence_place.familiar_house_data.reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.other"),
                data: stat.residence_place.other_data.reverse(),
                stack: "one",
              },
            ]}
            gridLeft={-122}
            gridBottom={60}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Education form */}
        <GraphInfoCard
          title={t("header.edu_form")}
          stat={[
            { name: t("edu_form.daytime"), count: stat.edu_form.daytime_total },
            { name: t("edu_form.evening"), count: stat.edu_form.evening_total },
            { name: t("edu_form.externally"), count: stat.edu_form.externally_total },
            { name: t("edu_form.dual"), count: stat.edu_form.dual_total },
          ]}
        >
          <BarChart
            legendData={profEducationForms}
            xAxisData={profEducationTypes}
            color={[colors.pink, colors.blue, colors.orange, colors.deep_purple]}
            seriesArr={[
              {
                name: t("edu_form.daytime"),
                data: stat.edu_form.daytime_data,
                stack: "one",
              },
              {
                name: t("edu_form.evening"),
                data: stat.edu_form.evening_data,
                stack: "one",
              },
              {
                name: t("edu_form.externally"),
                data: stat.edu_form.externally_data,
                stack: "one",
              },
              {
                name: t("edu_form.dual"),
                data: stat.edu_form.dual_data,
                stack: "one",
              },
            ]}
            gridBottom={60}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t("header.prof_info_text")}</p>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
