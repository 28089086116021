import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useTypedSelector } from "src/app/store";
import { ArrowDownIcon } from "src/assets/svg";
import { ownerships } from "./constants";

function OwnershipFilter() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [searchParams, setSearchParams] = useSearchParams();
  const { Option } = Select;

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const handleChange = (val: string) => {
    handleMakeParams("ownership_form", val);
    handleMakeParams("page", "1");
  };

  return (
    <Select
      style={{ width: 176 }}
      allowClear
      placeholder={t("filters.ownership")}
      onChange={handleChange}
      defaultValue={searchParams.get("ownership_form")}
      suffixIcon={<ArrowDownIcon color={colors.primary} />}
    >
      {ownerships.map((item) => (
        <Option key={item.id + item.name} value={String(item.id)}>
          {t(item.name)}
        </Option>
      ))}
    </Select>
  );
}

export default OwnershipFilter;
