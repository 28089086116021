import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import CustomRadioButton from "src/components/common/radio/RadioButton";
import { findHigherTypeDef, findTopMenuDef } from "../utill";
import DarkmodeSwitch from "./components/DarkmodeSwitch";
import LanguageButton from "./components/LanguageButton";
import "./header.scss";
import { changeBaseUrl } from "src/app/slices/authSlice";
import { Button, ConfigProvider, Select, Segmented } from "antd";
import { MobileMenuIcon } from "src/assets/svg";
import CustomDropdown from "src/components/common/dropdown";
import type { MenuProps } from "antd";

function Header() {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const isUniversity = pathname === "/university";
  const [scrolled, setScrolled] = useState(false);
  const segmentedValue = pathname;
  const isAutohorized = !!localStorage.getItem("token");

  window.addEventListener("scroll", () =>
    setScrolled(window.pageYOffset > 150),
  );
  const { darkMode } = useTypedSelector((state) => state.layout);

  //data section
  const { t } = useTranslation();
  const higherTypeOptions = [
    { label: "Umumiy", value: "/" },
    { label: t("header.menu.students"), value: "/students" },
    { label: t("header.menu.teachers"), value: "/teachers" },
    { label: t("header.menu.universities"), value: "/universities" },
    { label: t("header.menu.tables"), value: "/tables" },
  ];
  const higherMenuOptions = [
    { label: t("header.edu_type"), value: "/students" },
    { label: t("header.edu_form"), value: "/edu_form" },
    { label: t("header.payment_form"), value: "/payment_form" },
    { label: t("header.citizenship"), value: "/citizenship" },
    { label: t("header.courses"), value: "/courses" },
    { label: t("header.age"), value: "/student_age" },
    { label: t("header.residence_place"), value: "/residence_place" },
    { label: t("header.regions_section"), value: "/regions_section" },
  ];
  const profMenuOptions = [
    { label: t("header.edu_type"), value: "/prof" },
    { label: t("header.edu_form"), value: "/prof/edu_form" },
    { label: t("header.payment_form"), value: "/prof/payment_form" },
    { label: t("header.citizenship"), value: "/prof/citizenship" },
    { label: t("header.courses"), value: "/prof/courses" },
    { label: t("header.age"), value: "/prof/ages" },
    { label: t("header.residence_place"), value: "/prof/residence_place" },
    { label: t("header.regions_section"), value: "/prof/regions_section" },
  ];

  const tablesMenuOptions = [
    { label: t("header.menu.universities"), value: "/tables" },
    { label: t("header.menu.table_second_tab"), value: "/tables/directions" },
  ];
  const lyceumTypeOptions = [
    { label: t("header.menu.learners"), value: "/lyceum" },
    { label: t("header.menu.teachers"), value: "/lyceum/teachers" },
    { label: t("header.menu.lyceums"), value: "/lyceum/list" },
  ];

  const doctoralTypeOptions = [
    { label: t("header.menu.doctoral_common"), value: "/doctoral" },
    { label: t("header.menu.doctoral_by_regions"), value: "/doctoral/regions" },
    {
      label: t("header.menu.doctoral_by_directions"),
      value: "/doctoral/direction",
    },
  ];
  const profTypeOptions = [
    { label: t("header.menu.learners"), value: "/prof" },
    { label: t("header.menu.institutions"), value: "/prof/list" },
    { label: t("header.menu.tables"), value: "/prof-tables" },
  ];

  const headerTopMenuOptions = useMemo(() => {
    const initialHeaderOptions = [
      { label: t("header.menu.higher"), value: "/" },
      // { label: t("header.menu.lyceum"), value: "/lyceum" },
      { label: t("header.menu.prof"), value: "/prof" },
      { label: t("header.menu.admission"), value: "/admission" },
      { label: t("header.menu.doctoral"), value: "/doctoral" },
    ];

    if (isAutohorized) {
      return initialHeaderOptions.concat([
        { label: "Tashqi integratsiya", value: "/uzasbo" },
      ]);
    }

    return initialHeaderOptions;
  }, [isAutohorized]);

  const topMenuDef = findTopMenuDef(pathname);

  useEffect(() => {
    if (topMenuDef === "/prof") dispatch(changeBaseUrl("prof"));
    else dispatch(changeBaseUrl("hemis"));
  }, [topMenuDef]);

  const handleChange = (key: string) => {
    navigate(key);
  };

  const getSubMenuOptions = () => {
    switch (topMenuDef) {
      case "/prof":
        return profMenuOptions;
      case "/tables":
        return tablesMenuOptions;
      default:
        return higherMenuOptions;
    }
  };

  const items = [
    {
      key: "/",
      label: t("header.menu.higher"),
      onClick: () => handleChange("/"),
    },
    {
      key: "/lyceum",
      label: t("header.menu.lyceum"),
      onClick: () => handleChange("/lyceum"),
    },
    {
      key: "/prof",
      label: t("header.menu.prof"),
      onClick: () => handleChange("/prof"),
    },
    {
      key: "/admission",
      label: t("header.menu.admission"),
      onClick: () => handleChange("/admission"),
    },
    {
      key: "/doctoral",
      label: t("header.menu.doctoral"),
      onClick: () => handleChange("/doctoral"),
    },
  ];

  const OtmListitem: MenuProps["items"] = [
    {
      key: "l",
      label: t("tables.universityByRegion"),
      onClick: () => handleChange("/tables"),
    },
    {
      key: "q",
      label: t("tables.title_gender"),
      onClick: () => handleChange("/tables?id=2"),
    },
    {
      key: "a",
      label: t("tables.paymentType"),
      onClick: () => handleChange("/tables?id=3"),
    },
    {
      key: "g",
      label: t("tables.eduType"),
      onClick: () => handleChange("/tables?id=4"),
    },
    {
      key: "c",
      label: t("tables.eduForm"),
      onClick: () => handleChange("/tables?id=5"),
    },
    {
      key: "b",
      label: t("tables.eduFormByRegion"),
      onClick: () => handleChange("/tables?id=6"),
    },
    {
      key: "m",
      label: "Davlatlar kesimida",
      onClick: () => handleChange("/tables?id=7"),
    },
  ];

  const tabledirectionListItems = [
    {
      key: "l",
      label: t("tables.bachelor_by_region"),
      onClick: () => handleChange("/tables/directions"),
    },
    {
      key: "q",
      label: t("tables.master_by_region"),
      onClick: () => handleChange("/tables/directions?id=2"),
    },
    {
      key: "a",
      label: t("tables.count_bachelor_direction"),
      onClick: () => handleChange("/tables/directions?id=3"),
    },
    {
      key: "g",
      label: t("tables.count_master_direction"),
      onClick: () => handleChange("/tables/directions?id=4"),
    },
  ];
  const tableTeachersListItems = [
    {
      key: "l",
      label: t("tables.prof_count_by_work"),
      onClick: () => handleChange("/tables/teachers"),
    },
    {
      key: "q",
      label: t("tables.prof_count_by_academic_degree"),
      onClick: () => handleChange("/tables/teachers?id=2"),
    },
    {
      key: "a",
      label: t("tables.prof_count_by_gender"),
      onClick: () => handleChange("/tables/teachers?id=3"),
    },
    {
      key: "g",
      label: t("tables.prof_count_by_academic_title"),
      onClick: () => handleChange("/tables/teachers?id=4"),
    },
  ];

  const graduatedListItems = [
    {
      key: "l",
      label: t("tables.graduated_table_title"),
      onClick: () => handleChange("/tables/graduated"),
    },
    {
      key: "t",
      label: t("tables.graduated_select_two"),
      onClick: () => handleChange("/tables/graduated-by-region"),
    },
    {
      key: "m",
      label: t("tables.graduated_select_three"),
      onClick: () => handleChange("/tables/graduated-by-speciality"),
    },
  ];

  const TableSegmentedSelectionsOptions = [
    {
      label: (
        <CustomDropdown
          menu={{ items: OtmListitem }}
          arrow={{ pointAtCenter: true }}
          trigger={["click"]}
          placement="bottom"
        >
          <Button
            className={`header-top-btn header-top-btn-${darkMode && "dark"}`}
            size="large"
            type="text"
          >
            {t("header.menu.universities")}
          </Button>
        </CustomDropdown>
      ),
      value: "/tables",
    },
    {
      label: (
        <CustomDropdown
          menu={{ items: tabledirectionListItems }}
          arrow={{ pointAtCenter: true }}
          trigger={["click"]}
          placement="bottom"
        >
          <Button
            className={`header-top-btn header-top-btn-${darkMode && "dark"}`}
            size="large"
            type="text"
          >
            {t("header.menu.table_second_tab")}
          </Button>
        </CustomDropdown>
      ),
      value: "/tables/directions",
    },
    {
      label: (
        <CustomDropdown
          menu={{ items: tableTeachersListItems }}
          arrow={{ pointAtCenter: true }}
          trigger={["click"]}
          placement="bottom"
        >
          <Button
            className={`header-top-btn header-top-btn-${darkMode && "dark"}`}
            size="large"
            type="text"
          >
            {t("header.menu.table_third_tab")}
          </Button>
        </CustomDropdown>
      ),
      value: "/tables/teachers",
    },
    {
      label: (
        <CustomDropdown
          menu={{ items: graduatedListItems }}
          arrow={{ pointAtCenter: true }}
          trigger={["click"]}
          placement="bottom"
        >
          <Button
            className={`header-top-btn header-top-btn-${darkMode && "dark"}`}
            size="large"
            type="text"
          >
            {t("tables.graduated")}
          </Button>
        </CustomDropdown>
      ),
      value: "/tables/graduated",
    },
  ];

  const TableSegmentedProfSelectionsOptions = [
    {
      label: "Viloyatlar kesimida",
      value: "/prof-tables",
    },
    {
      label: "Jinsi kesimida",
      value: "/prof-tables?id=1",
    },
    {
      label: "To'lov kesimida",
      value: "/prof-tables?id=2",
    },
    {
      label: "Kursi kesimida",
      value: "/prof-tables?id=3",
    },
    {
      label: "Talim shakli kesimida",
      value: "/prof-tables?id=4",
    },
  ];
  return (
    <div
      className={`header ${isUniversity && "header-university"} ${scrolled && "header-scrolled"}`}
    >
      <div className="container">
        <div className="header-top">
          <div className="header-top-left">
            <Link to="/">
              <img
                src={require(
                  `src/assets/img/logo_${darkMode ? "dark" : "light"}.png`,
                )}
                alt="error logo"
              />
            </Link>

            <div className="desktop-menu">
              <CustomRadioButton
                buttons={headerTopMenuOptions}
                fontSize={15}
                buttonPaddingInline={12}
                defaultValue={topMenuDef}
                onChange={(ev) => navigate(ev.target.value)}
              />
            </div>
          </div>
          <div className="header-top-right">
            <LanguageButton />
            <DarkmodeSwitch />
            <div className="mobile-menu">
              <CustomDropdown
                menu={{ items }}
                arrow={{ pointAtCenter: true }}
                trigger={["click"]}
                placement="bottom"
              >
                <Button
                  className={`header-top-language header-top-language-${darkMode && "dark"}`}
                  size="large"
                  type="text"
                >
                  <div className="flex-between">
                    <MobileMenuIcon />
                  </div>
                </Button>
              </CustomDropdown>
            </div>
          </div>
        </div>

        {pathname === "/admission" && (
          <div className="header-admission">{t("admission.title")}</div>
        )}

        {topMenuDef === "/lyceum" && (
          <div className="header-statistics" style={{ paddingTop: 24 }}>
            <div className="header-content">
              <div className="header-types" style={{ paddingTop: 0 }}>
                <CustomRadioButton
                  buttons={lyceumTypeOptions}
                  fontSize={24}
                  buttonPaddingInline={8}
                  defaultValue={pathname}
                  onChange={(ev) => navigate(ev.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        {(topMenuDef === "/prof" || topMenuDef === "/prof-tables") && (
          <div
            className="header-types"
            style={{ paddingBottom: 24, paddingTop: 24 }}
          >
            <CustomRadioButton
              buttons={profTypeOptions}
              fontSize={24}
              buttonPaddingInline={8}
              defaultValue={pathname}
              onChange={(ev) => navigate(ev.target.value)}
            />
          </div>
        )}

        {(topMenuDef === "/" ||
          topMenuDef === "/prof" ||
          topMenuDef === "/tables") &&
          pathname !== "/prof/list" &&
          !pathname.includes("/uzasbo") &&
          pathname !== "/otmReports" && (
            <div className="header-statistics">
              {(topMenuDef === "/" || topMenuDef === "/tables") && (
                <div className="header-types">
                  <CustomRadioButton
                    buttons={higherTypeOptions}
                    fontSize={24}
                    buttonPaddingInline={8}
                    defaultValue={findHigherTypeDef(pathname)}
                    onChange={(ev) => navigate(ev.target.value)}
                  />
                </div>
              )}
              {pathname !== "/universities" && (
                <div
                  className="header-content"
                  style={topMenuDef === "/" ? { paddingTop: 24 } : {}}
                >
                  {pathname !== "/teachers" &&
                    pathname !== "/tables" &&
                    pathname !== "/" &&
                    pathname !== "/prof-tables" &&
                    pathname !== "/tables/teachers" &&
                    pathname !== "/tables/graduated" &&
                    pathname !== "/tables/graduated-by-region" &&
                    pathname !== "/tables/graduated-by-speciality" &&
                    pathname !== "/tables/directions" && (
                      <div className="header-content-menu">
                        <div className="header-desktop-content-menu">
                          <CustomRadioButton
                            buttons={getSubMenuOptions()}
                            fontSize={13}
                            buttonPaddingInline={12}
                            controlHeight={36}
                            hasBg
                            onChange={(ev) => navigate(ev.target.value)}
                            defaultValue={pathname}
                          />
                        </div>
                        <div className="header-mobile-content-menu">
                          <ConfigProvider
                            theme={{
                              inherit: false,
                              token: {
                                colorBorder: "none",
                              },
                            }}
                          >
                            <Select
                              defaultValue={t("header.edu_type")}
                              style={{
                                width: 250,
                              }}
                              onChange={handleChange}
                              options={getSubMenuOptions()}
                            />
                          </ConfigProvider>
                        </div>
                      </div>
                    )}
                </div>
              )}

              {(pathname === "/tables" ||
                pathname === "/tables/directions" ||
                pathname === "/tables/graduated" ||
                pathname === "/tables/graduated-by-region" ||
                pathname === "/tables/graduated-by-speciality" ||
                pathname === "/tables/teachers") && (
                <div
                  className="header-content"
                  style={topMenuDef === "/tables" ? { paddingTop: 24 } : {}}
                >
                  <div className="header-content-menu">
                    <div className="header-desktop-content-menu">
                      <Segmented
                        value={segmentedValue}
                        options={TableSegmentedSelectionsOptions}
                      />
                    </div>
                    <div className="header-mobile-content-menu">
                      <ConfigProvider
                        theme={{
                          inherit: false,
                          token: {
                            colorBorder: "none",
                          },
                        }}
                      >
                        <Select
                          defaultValue={t("header.edu_type")}
                          style={{
                            width: 210,
                          }}
                          onChange={handleChange}
                          options={TableSegmentedSelectionsOptions}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

        {pathname === "/prof-tables" && (
          <div
            className="header-content"
            style={topMenuDef === "/prof-tables" ? { paddingBottom: 24 } : {}}
          >
            <div className="header-content-menu">
              <div className="header-desktop-content-menu">
                <CustomRadioButton
                  buttons={TableSegmentedProfSelectionsOptions}
                  fontSize={13}
                  buttonPaddingInline={12}
                  controlHeight={36}
                  hasBg
                  onChange={(ev) => navigate(ev.target.value)}
                  defaultValue={pathname + search}
                />
              </div>
              <div className="header-mobile-content-menu">
                <ConfigProvider
                  theme={{
                    inherit: false,
                    token: {
                      colorBorder: "none",
                    },
                  }}
                >
                  <Select
                    defaultValue={t("header.edu_type")}
                    style={{
                      width: 210,
                    }}
                    onChange={handleChange}
                    options={TableSegmentedProfSelectionsOptions}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        )}

        {topMenuDef === "/doctoral" && (
          <div className="header-statistics" style={{ paddingTop: 24 }}>
            <div className="header-content">
              <div className="header-types" style={{ paddingTop: 0 }}>
                <CustomRadioButton
                  buttons={doctoralTypeOptions}
                  fontSize={24}
                  buttonPaddingInline={8}
                  defaultValue={pathname}
                  onChange={(ev) => navigate(ev.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
