import { DoctoralCountByRegionTable } from "./components/DoctoralCountByRegionTable";
import { DoctoralOrganizationByRegionTable } from "./components/DoctoralOrganizationByRegionTable";
import { DoctoralCountByOrgDirectionTable } from "./components/DoctoralCountByOrgDirectionTable";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DoctoralByRegionsPage() {
  const [params] = useSearchParams();
  const regionId = params.get("regionId");
  const orgId = params.get("orgId");
  const { t } = useTranslation();

  return (
    <div className="doctoral">
      {!regionId && <DoctoralCountByRegionTable />}
      {!orgId && regionId && <DoctoralOrganizationByRegionTable regionId={regionId} />}
      {orgId && <DoctoralCountByOrgDirectionTable orgId={orgId} />}
      <div className="text_info" style={{width:'100%'}}>
        <p>{t("header.doctoral_info_text")}</p>
      </div>
    </div>
  );
}

export default DoctoralByRegionsPage;
