import {
  useGetAccommodationsQuery,
  useGetUniversityOwnershipCodesQuery,
  useGetUzAsboAccomodationQuery,
} from "src/app/services/private";
import { IUzAsboAccomodation } from "src/app/services/private/type";
import { ConfigProvider, DatePicker, message, Select, Space, Table } from "antd";
import { tablePaginationConfig } from "src/app/services/api/const";
import { useUzAsboEduFormColumns } from "src/pages/uzasboStatistics/hooks/useEduFormColumns";
import { useEffect, useState } from "react";
import moment from "moment";
import { uzAsboDateFormat } from "src/pages/uzasboStatistics/uzasboStatistic";
import { DownloadButton } from "src/components/downloadButton";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";

export type TDate = { month: string; year: string };

function disabledDate(current: Date) {
  let customDate = moment().format("2022-09");
  return (
    (current && current < moment(customDate, "YYYY-MM")) || current > moment(new Date(), "YYYY-MM")
  );
}

const all = { label: "Jami", value: "" };

export const addAll = (arr: any) => {
  if (arr) {
    return [all, ...arr];
  }

  return arr;
};

export const UzASBOAccommodation = () => {
  const [accommodation, setAccommodationCode] = useState<string>();
  const [universityOwnershipCodeId, setUniversityOwnershipCode] = useState<string>();
  const { data: accommodations } = useGetAccommodationsQuery();
  const [date, setDate] = useState<TDate>();
  const { data: universityOwnershipCodes } = useGetUniversityOwnershipCodesQuery();
  const {
    data: dataSource,
    isFetching,
    isError,
    error,
  } = useGetUzAsboAccomodationQuery(
    {
      month: date && date.month,
      year: date && date.year,
      accommodationCode: accommodation ? accommodation : undefined,
      universityOwnershipCode: universityOwnershipCodeId ? universityOwnershipCodeId : undefined,
    },
    {
      skip: !date,
    },
  );

  const handleDate = (value: any) => {
    setDate({
      month: moment(value["$d"]).format("M"),
      year: moment(value["$d"]).format("YYYY"),
    });
  };

  useEffect(() => {
    if (isError) {
      const err = error as FetchBaseQueryError;
      if (err?.status === 404) {
        message.error("Bu oydagi hisobot hali shakllanmagan.");
      }
    }
  }, [isError]);

  const columns = useUzAsboEduFormColumns<IUzAsboAccomodation>();

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowSelectedBg: "#e6f4ff",
            rowSelectedHoverBg: "#bae0ff",
          },
        },
      }}
    >
      <Table
        title={() => (
          <div className="flex-space-center">
            <h3>Turar-joy kesimida</h3>
            <Space>
              <DatePicker
                style={{ height: "36px", width: "230px" }}
                disabledDate={disabledDate}
                placeholder="Sanani tanlang"
                format={uzAsboDateFormat}
                picker="month"
                onChange={(e) => handleDate(e)}
              />
              <Select
                style={{ width: 230 }}
                placeholder="Turar-joy"
                onChange={setAccommodationCode}
                options={addAll(
                  accommodations?.map((item) => ({ label: item.name, value: item.code })),
                )}
              />
              <Select
                style={{ width: 230 }}
                placeholder="Mulkchilik"
                onChange={setUniversityOwnershipCode}
                options={addAll(
                  universityOwnershipCodes?.map((item) => ({
                    label: item.name,
                    value: item.code,
                  })),
                )}
              />
              <DownloadButton
                downloadUrl="/uzasbohemis/accommodationByYearAndMonth/excel"
                params={{
                  month: date && date.month,
                  year: date && date.year,
                  accommodationCode: accommodation ? accommodation : undefined,
                  universityOwnershipCode: universityOwnershipCodeId
                    ? universityOwnershipCodeId
                    : undefined,
                }}
              />
            </Space>
          </div>
        )}
        size="small"
        loading={isFetching}
        bordered
        virtual
        rowKey="universityCode"
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 2000, y: 600 }}
        pagination={tablePaginationConfig}
        rowSelection={{ type: "radio", columnWidth: 40 }}
      />
    </ConfigProvider>
  );
};
