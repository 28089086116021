import {
  IProfAgeStat,
  IProfByAdmissionType,
  IProfByAge,
  IProfByCitizenship,
  IProfByCourse,
  IProfByEduForm,
  IProfByEduType,
  IProfByGender,
  IProfByGenderAge,
  IProfByLivePlace,
  IProfByLivePlaceAge,
  IProfCitizenshipStat,
  IProfCourseStat,
  IProfEduFormStat,
  IProfEduTypeStat,
  IProfPaymentFormStat,
  IProfRegionStat,
  IProfResidencePlaceStat,
} from "src/app/services/prof_education/type";

export const makeProfEduTypeData = (data: IProfEduTypeStat) => {
  let age = make_age(data.by_age);
  let payment_form = make_payment_form(data.by_admission_type);
  let courses = make_courses(data.by_course);
  let citizenship = make_citizenship(data.by_citizenship);
  let residence_place = make_residence_place(data.by_current_live_place);
  let edu_form = make_edu_form(data.by_education_form);

  return { age, payment_form, courses, citizenship, residence_place, edu_form };
};

export const makeProfEduFormData = (data: IProfEduFormStat) => {
  let gender = make_gender(data.by_gender);
  let age = make_age(data.by_age);
  let payment_form = make_payment_form(data.by_admission_type);
  let courses = make_courses(data.by_course);
  let citizenship = make_citizenship(data.by_citizenship);
  let residence_place = make_residence_place(data.by_current_live_place);

  return { gender, age, payment_form, courses, citizenship, residence_place };
};

export const makeProfPaymentFormData = (data: IProfPaymentFormStat) => {
  let gender = make_gender(data.by_gender);
  let age = make_age(data.by_age);
  let courses = make_courses(data.by_course);
  let citizenship = make_citizenship(data.by_citizenship);
  let residence_place = make_residence_place(data.by_current_live_place);

  return { gender, age, courses, citizenship, residence_place };
};

export const makeProfCitizenshipData = (data: IProfCitizenshipStat) => {
  let gender = make_gender(data.by_gender);
  let age = make_age(data.by_age);
  let courses = make_courses(data.by_course);
  let residence_place = make_residence_place(data.by_current_live_place);

  return { gender, age, courses, residence_place };
};

export const makeProfCourseData = (data: IProfCourseStat) => {
  let gender = make_gender(data.by_gender);
  let age = make_age(data.by_age);
  let residence_place = make_residence_place(data.by_current_live_place);

  return { gender, age, residence_place };
};

export const makeProfAgeData = (data: IProfAgeStat) => {
  let gender = make_gender_age(data.by_gender);
  let residence_place = make_residence_place_age(data.by_current_live_place);

  return { gender, residence_place };
};

export const makeProfResidencePlaceData = (data: IProfResidencePlaceStat) => {
  let gender = make_gender(data.by_gender);

  return { gender };
};

export const makeProfRegionData = (data: IProfRegionStat) => {
  let gender = make_gender(data.by_gender);
  let age = make_age(data.by_age);
  let payment_form = make_payment_form(data.by_admission_type);
  let courses = make_courses(data.by_course);
  let citizenship = make_citizenship(data.by_citizenship);
  let residence_place = make_residence_place(data.by_current_live_place);
  let edu_form = make_edu_form(data.by_education_form);
  let edu_type = make_edu_type(data.by_education_type);

  return { gender, age, payment_form, courses, citizenship, residence_place, edu_form, edu_type };
};

const make_gender = (data: IProfByGender[]) => {
  let men_total = 0;
  let women_total = 0;
  let men_data: number[] = [];
  let women_data: number[] = [];

  data.forEach((item) => {
    men_total += item.male;
    women_total += item.female;
    men_data.push(item.male);
    women_data.push(item.female);
  });

  return { men_total, women_total, men_data, women_data };
};

const make_gender_age = (data: IProfByGenderAge[]) => {
  let men_total = 0;
  let women_total = 0;
  let men_data: number[] = [];
  let women_data: number[] = [];

  data.forEach((item) => {
    if (item.gender === 1) {
      men_total = item.lte_20 + item.gt_20;
      men_data = [item.lte_20, item.gt_20];
    } else {
      women_total = item.lte_20 + item.gt_20;
      women_data = [item.lte_20, item.gt_20];
    }
  });

  return { men_total, women_total, men_data, women_data };
};

const make_age = (data: IProfByAge[]) => {
  let under_20_total = 0;
  let over_20_total = 0;
  let under_20_data: number[] = [];
  let over_20_data: number[] = [];

  data.forEach((item) => {
    under_20_total += item.lte_20;
    over_20_total += item.gt_20;
    under_20_data.push(item.lte_20);
    over_20_data.push(item.gt_20);
  });

  return { under_20_total, over_20_total, under_20_data, over_20_data };
};

const make_payment_form = (data: IProfByAdmissionType[]) => {
  let grant_total = 0;
  let contract_total = 0;
  let grant_data: number[] = [];
  let contract_data: number[] = [];

  data.forEach((item) => {
    grant_total += item.grand;
    contract_total += item.contract;
    grant_data.push(item.grand);
    contract_data.push(item.contract);
  });

  return { grant_total, contract_total, grant_data, contract_data };
};

const make_courses = (data: IProfByCourse[]) => {
  let course_1_total: number = 0;
  let course_2_total: number = 0;
  let course_3_total: number = 0;
  let course_1_data: number[] = [];
  let course_2_data: number[] = [];
  let course_3_data: number[] = [];

  data.forEach((item) => {
    course_1_total += item.course1;
    course_2_total += item.course2;
    course_3_total += item.course3;
    course_1_data.push(item.course1);
    course_2_data.push(item.course2);
    course_3_data.push(item.course3);
  });

  return { course_1_total, course_2_total, course_3_total, course_1_data, course_2_data, course_3_data };
};

const make_citizenship = (data: IProfByCitizenship[]) => {
  let uzbek_total: number = 0;
  let foreigner_total: number = 0;
  let stateless_total: number = 0;
  let teen_total: number = 0;
  let uzbek_data: number[] = [];
  let foreigner_data: number[] = [];
  let stateless_data: number[] = [];
  let teen_data: number[] = [];

  data.forEach((item) => {
    uzbek_total += item[11];
    foreigner_total += item[12];
    stateless_total += item[13];
    teen_total += item[14];
    uzbek_data.push(item[11]);
    foreigner_data.push(item[12]);
    stateless_data.push(item[13]);
    teen_data.push(item[14]);
  });

  return {
    uzbek_total,
    foreigner_total,
    stateless_total,
    teen_total,
    uzbek_data,
    foreigner_data,
    stateless_data,
    teen_data,
  };
};

const make_residence_place = (data: IProfByLivePlace[]) => {
  let own_house_total: number = 0;
  let dormitory_total: number = 0;
  let rented_house_total: number = 0;
  let relative_house_total: number = 0;
  let familiar_house_total: number = 0;
  let other_total: number = 0;
  let own_house_data: number[] = [];
  let dormitory_data: number[] = [];
  let rented_house_data: number[] = [];
  let relative_house_data: number[] = [];
  let familiar_house_data: number[] = [];
  let other_data: number[] = [];

  data.forEach((item) => {
    own_house_total += item[10];
    dormitory_total += item[14];
    rented_house_total += item[13];
    relative_house_total += item[11];
    familiar_house_total += item[12];
    other_total += item[9];
    own_house_data.push(item[10]);
    dormitory_data.push(item[14]);
    rented_house_data.push(item[13]);
    relative_house_data.push(item[11]);
    familiar_house_data.push(item[12]);
    other_data.push(item[9]);
  });

  return {
    own_house_total,
    dormitory_total,
    rented_house_total,
    relative_house_total,
    familiar_house_total,
    other_total,
    own_house_data,
    dormitory_data,
    rented_house_data,
    relative_house_data,
    familiar_house_data,
    other_data,
  };
};

const make_residence_place_age = (data: IProfByLivePlaceAge[]) => {
  let own_house_total: number = 0;
  let dormitory_total: number = 0;
  let rented_house_total: number = 0;
  let relative_house_total: number = 0;
  let familiar_house_total: number = 0;
  let other_total: number = 0;
  let own_house_data: number[] = [];
  let dormitory_data: number[] = [];
  let rented_house_data: number[] = [];
  let relative_house_data: number[] = [];
  let familiar_house_data: number[] = [];
  let other_data: number[] = [];

  data.forEach((item) => {
    let { current_live_place_code: code, gt_20, lte_20 } = item;
    if (code === "10") {
      own_house_total = lte_20 + lte_20;
      own_house_data = [lte_20, gt_20];
    } else if (code === "11") {
      relative_house_total = lte_20 + gt_20;
      relative_house_data = [lte_20, gt_20];
    } else if (code === "12") {
      familiar_house_total = lte_20 + gt_20;
      familiar_house_data = [lte_20, gt_20];
    } else if (code === "13") {
      rented_house_total = lte_20 + gt_20;
      rented_house_data = [lte_20, gt_20];
    } else if (code === "14") {
      dormitory_total = lte_20 + gt_20;
      dormitory_data = [lte_20, gt_20];
    } else if (code === "9") {
      other_total = lte_20 + gt_20;
      other_data = [lte_20, gt_20];
    }
  });

  return {
    own_house_total,
    dormitory_total,
    rented_house_total,
    relative_house_total,
    familiar_house_total,
    other_total,
    own_house_data,
    dormitory_data,
    rented_house_data,
    relative_house_data,
    familiar_house_data,
    other_data,
  };
};

const make_edu_form = (data: IProfByEduForm[]) => {
  let daytime_total: number = 0;
  let evening_total: number = 0;
  let externally_total: number = 0;
  let dual_total: number = 0;
  let daytime_data: number[] = [];
  let evening_data: number[] = [];
  let externally_data: number[] = [];
  let dual_data: number[] = [];

  data.forEach((item) => {
    daytime_total += item.day_time;
    evening_total += item.evening;
    externally_total += item.external;
    dual_total += item.dual;
    daytime_data.push(item.day_time);
    evening_data.push(item.evening);
    externally_data.push(item.external);
    dual_data.push(item.dual);
  });

  return {
    daytime_total,
    evening_total,
    externally_total,
    dual_total,
    daytime_data,
    evening_data,
    externally_data,
    dual_data,
  };
};

const make_edu_type = (data: IProfByEduType[]) => {
  let khm_total: number = 0;
  let college_total: number = 0;
  let technical_total: number = 0;
  let khm_data: number[] = [];
  let college_data: number[] = [];
  let technical_data: number[] = [];

  data.forEach((item) => {
    khm_total += item.khm;
    college_total += item.college;
    technical_total += item.technical;
    khm_data.push(item.khm);
    college_data.push(item.college);
    technical_data.push(item.technical);
  });

  return {
    khm_total,
    college_total,
    technical_total,
    khm_data,
    college_data,
    technical_data,
  };
};
