import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CustomRadioButton from "src/components/common/radio/RadioButton";

const options = [
  {
    label: "Talabalar kesimida",
    value: "/uzasbo",
  },
  {
    label: "Ta'lim shakli kesimida",
    value: "/uzasbo/eduForm",
  },
  {
    label: "Turar-joy kesimida",
    value: "/uzasbo/accommodation",
  },
  {
    label: "Bandlik kesimida",
    value: "/uzasbo/employment",
  },
  {
    label: "Kontrakt",
    value: "/uzasbo/contract",
  },
  {
    label: "Ijara",
    value: "/uzasbo/rent",
  },
  {
    label: "Hemis",
    value: "/uzasbo/showAndChangeHemis",
  },
  {
    label: "Nogironlik",
    value: "/uzasbo/disability",
  },
  {
    label: "Bitirganlar",
    value: "/uzasbo/graduated_section",
  },
];

export function UzAsboLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div
        style={{
          padding: "12px 6px",
          backgroundColor: "white",
          marginBottom: "16px",
          borderRadius: "8px",
        }}
      >
        <CustomRadioButton
          defaultValue={pathname}
          buttons={options}
          fontSize={18}
          buttonPaddingInline={8}
          onChange={(ev) => navigate(ev.target.value)}
          otherBg={true}
        />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
