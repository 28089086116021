export const ownerships = [
  {
    name: "filters.state",
    id: 11,
  },
  {
    name: "filters.non_state",
    id: 12,
  },
  {
    name: "filters.foreign",
    id: 13,
  },
  // {
  //   name: "filters.joint",
  //   id: 14,
  // },
];
export const findOwnership = (id: number) => {
  return ownerships.find((el) => el.id === id)?.name || "";
};
export const profEduTypes = [
  {
    name: "edu_type.vocational_school",
    id: 3,
  },
  {
    name: "edu_type.college",
    id: 4,
  },
  {
    name: "edu_type.technical_school",
    id: 5,
  },
];
