import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { baseUrl_Prof } from "src/app/services/api/const";
import { IBaseObj } from "src/app/services/api/type";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  ages,
  citizenships,
  courses,
  educationForms,
  educationTypes,
  genders,
  paymentForms,
  residencePlaces,
} from "src/pages/constants";
import { giveLan } from "src/utils";
import useRegion from "./useRegion";

function RegionsSectionPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [regions, setRegions] = useState<string[]>([]);

  const makeData = async () => {
    try {
      const response = await fetch(baseUrl_Prof + "universities/region/");
      const result = await response.json();
      if (result) {
        let arr: string[] = [];
        result.forEach((item: IBaseObj) => {
          arr.push(giveLan(item));
        });
        setRegions(arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    makeData();
  }, []);

  const {
    gendersData,
    eduTypeData,
    agesData,
    paymentData,
    coursesData,
    citizenshipData,
    placesData,
    eduFormData,
  } = useRegion();

  return (
    <div className="eduform">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: gendersData?.men_total },
            { name: t("genders.women"), count: gendersData?.women_total },
          ]}
          graphHeight={720}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={regions}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: [...(gendersData?.men_data || [])].reverse(),
              },
              {
                name: t("genders.women"),
                data: [...(gendersData?.women_data || [])].reverse(),
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-178}
            yAxisMargin={215}
          />
        </GraphInfoCard>

        {/* Education type */}
        <GraphInfoCard
          title={t("header.edu_type")}
          stat={[
            { name: t("edu_type.bachelor"), count: eduTypeData?.bachelor_total },
            { name: t("edu_type.master"), count: eduTypeData?.master_total },
            { name: t("edu_type.ordinance"), count: eduTypeData?.ordinance_total },
          ]}
          graphHeight={720}
        >
          <BarChartCategory
            legendData={educationTypes}
            yAxisData={regions}
            color={[colors.light_blue, colors.deep_purple, colors.light_green]}
            seriesArr={[
              {
                name: t("edu_type.bachelor"),
                data: [...(eduTypeData?.bachelor_data || [])].reverse(),
              },
              {
                name: t("edu_type.master"),
                data: [...(eduTypeData?.master_data || [])].reverse(),
              },
              {
                name: t("edu_type.ordinance"),
                data: [...(eduTypeData?.ordinance_data || [])].reverse(),
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-178}
            yAxisMargin={215}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            { name: t("ages.under_30"), count: agesData?.less_total },
            { name: t("ages.over_30"), count: agesData?.greater_total },
          ]}
          graphHeight={336}
        >
          <BarChart
            legendData={ages}
            xAxisData={regions}
            color={[colors.light_blue, colors.green]}
            seriesArr={[
              {
                name: t("ages.under_30"),
                data: agesData?.less_data,
                stack: "one",
              },
              {
                name: t("ages.over_30"),
                data: agesData?.greater_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Paymeny type */}
        <GraphInfoCard
          title={t("header.payment_form")}
          stat={[
            { name: t("pay_form.grant"), count: paymentData?.grant_total },
            { name: t("pay_form.contract"), count: paymentData?.contract_total },
          ]}
          graphHeight={336}
        >
          <BarChart
            legendData={paymentForms}
            xAxisData={regions}
            color={[colors.orange, colors.blue]}
            seriesArr={[
              {
                name: t("pay_form.grant"),
                data: paymentData?.grant_data,
                stack: "one",
              },
              {
                name: t("pay_form.contract"),
                data: paymentData?.contract_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Courses */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            { name: t("courses.course_1"), count: coursesData?.course_1_total },
            { name: t("courses.course_2"), count: coursesData?.course_2_total },
            { name: t("courses.course_3"), count: coursesData?.course_3_total },
            { name: t("courses.course_4"), count: coursesData?.course_4_total },
            { name: t("courses.course_5"), count: coursesData?.course_5_total },
            { name: t("courses.course_6"), count: coursesData?.course_6_total },
          ]}
          graphHeight={460}
        >
          <BarChart
            legendData={courses}
            xAxisData={regions}
            color={[
              colors.blue,
              colors.pink,
              colors.yellow,
              colors.light_green,
              colors.light_blue,
              colors.orange,
            ]}
            seriesArr={[
              {
                name: t("courses.course_1"),
                data: coursesData?.course_1_data,
                stack: "one",
              },
              {
                name: t("courses.course_2"),
                data: coursesData?.course_2_data,
                stack: "one",
              },
              {
                name: t("courses.course_3"),
                data: coursesData?.course_3_data,
                stack: "one",
              },
              {
                name: t("courses.course_4"),
                data: coursesData?.course_4_data,
                stack: "one",
              },
              {
                name: t("courses.course_5"),
                data: coursesData?.course_5_data,
                stack: "one",
              },
              {
                name: t("courses.course_6"),
                data: coursesData?.course_6_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Citizenships */}
        <GraphInfoCard
          title={t("header.citizenship")}
          stat={[
            { name: t("citizenships.uzbek"), count: citizenshipData?.uzbek_total },
            { name: t("citizenships.foreigner"), count: citizenshipData?.foreigner_total },
            { name: t("citizenships.stateless"), count: citizenshipData?.stateless_total },
          ]}
          graphHeight={396}
        >
          <BarChart
            legendData={citizenships}
            xAxisData={regions}
            color={[colors.yellow, colors.light_green, colors.blue]}
            seriesArr={[
              {
                name: t("citizenships.uzbek"),
                data: citizenshipData?.uzbek_data,
                stack: "one",
              },
              {
                name: t("citizenships.foreigner"),
                data: citizenshipData?.foreigner_data,
                stack: "one",
              },
              {
                name: t("citizenships.stateless"),
                data: citizenshipData?.stateless_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            { name: t("residence_places.own_house"), count: placesData?.own_house_total },
            { name: t("residence_places.dormitory"), count: placesData?.dormitory_total },
            { name: t("residence_places.rented_house"), count: placesData?.rented_house_total },
            { name: t("residence_places.relative_house"), count: placesData?.relative_house_total },
            { name: t("residence_places.familiar_house"), count: placesData?.familiar_house_total },
          ]}
          graphHeight={649}
        >
          <BarChartCategory
            legendData={residencePlaces}
            yAxisData={regions}
            color={[colors.pink, colors.blue, colors.orange, colors.deep_purple, colors.light_blue]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: [...(placesData?.own_house_data || [])].reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: [...(placesData?.dormitory_data || [])].reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: [...(placesData?.rented_house_data || [])].reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: [...(placesData?.relative_house_data || [])].reverse(),
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: [...(placesData?.familiar_house_data || [])].reverse(),
                stack: "one",
              },
            ]}
            barWidth={16}
            gridLeft={-178}
            yAxisMargin={215}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Education form */}
        <GraphInfoCard
          title={t("header.edu_form")}
          stat={[
            { name: t("edu_form.externally"), count: eduFormData?.externally_total },
            { name: t("edu_form.joint"), count: eduFormData?.joint_total },
            {
              name: t("edu_form.special_externally"),
              count: eduFormData?.special_externally_total,
            },
            { name: t("edu_form.remote"), count: eduFormData?.remote_total },
            { name: t("edu_form.daytime"), count: eduFormData?.daytime_total },
            { name: t("edu_form.evening"), count: eduFormData?.evening_total },
            { name: t("edu_form.second_external"), count: eduFormData?.second_external_total },
            { name: t("edu_form.second_daytime"), count: eduFormData?.second_daytime_total },
            { name: t("edu_form.second_evening"), count: eduFormData?.second_evening_total },
          ]}
          graphHeight={460}
        >
          <BarChart
            legendData={educationForms}
            xAxisData={regions}
            color={[
              colors.pink,
              colors.green,
              colors.blue,
              colors.orange,
              colors.deep_purple,
              colors.light_blue,
              colors.yellow,
              colors.light_green,
              colors.light_orange,
            ]}
            seriesArr={[
              {
                name: t("edu_form.externally"),
                data: eduFormData?.externally_data,
                stack: "one",
              },
              {
                name: t("edu_form.joint"),
                data: eduFormData?.joint_data,
                stack: "one",
              },
              {
                name: t("edu_form.special_externally"),
                data: eduFormData?.special_externally_data,
                stack: "one",
              },
              {
                name: t("edu_form.remote"),
                data: eduFormData?.remote_data,
                stack: "one",
              },
              {
                name: t("edu_form.daytime"),
                data: eduFormData?.daytime_data,
                stack: "one",
              },
              {
                name: t("edu_form.evening"),
                data: eduFormData?.evening_data,
                stack: "one",
              },
              {
                name: t("edu_form.second_external"),
                data: eduFormData?.second_external_data,
                stack: "one",
              },
              {
                name: t("edu_form.second_daytime"),
                data: eduFormData?.second_daytime_data,
                stack: "one",
              },
              {
                name: t("edu_form.second_evening"),
                data: eduFormData?.second_evening_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t("header.students_info_text")}</p>
      </div>
    </div>
  );
}

export default RegionsSectionPage;
