import { getRootState } from "src/app/store";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const colors = getRootState().layout.colors;

export const DownloadIcon = ({ color = colors.primary }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8335 3.33335C5.61248 3.33335 5.40052 3.42115 5.24424 3.57743C5.08796 3.73371 5.00016 3.94567 5.00016 4.16669V15.8334C5.00016 16.0544 5.08796 16.2663 5.24424 16.4226C5.40052 16.5789 5.61248 16.6667 5.8335 16.6667H14.1668C14.3878 16.6667 14.5998 16.5789 14.7561 16.4226C14.9124 16.2663 15.0002 16.0544 15.0002 15.8334L15.0002 7.8452L10.4884 3.33344L5.8335 3.33335ZM4.06573 2.39892C4.53457 1.93008 5.17045 1.66669 5.8335 1.66669H10.4885C10.9304 1.66678 11.3544 1.8424 11.6669 2.15493M11.6669 2.15493L16.1785 6.66651C16.491 6.97899 16.6667 7.40289 16.6668 7.84484V15.8334C16.6668 16.4964 16.4034 17.1323 15.9346 17.6011C15.4658 18.07 14.8299 18.3334 14.1668 18.3334H5.8335C5.17045 18.3334 4.53457 18.07 4.06573 17.6011C3.59689 17.1323 3.3335 16.4964 3.3335 15.8334V4.16669C3.3335 3.50365 3.59689 2.86776 4.06573 2.39892M10.0002 7.50002C10.4604 7.50002 10.8335 7.87312 10.8335 8.33335V11.3215L11.9109 10.2441C12.2363 9.91866 12.764 9.91866 13.0894 10.2441C13.4149 10.5695 13.4149 11.0972 13.0894 11.4226L10.5894 13.9226C10.264 14.248 9.73634 14.248 9.41091 13.9226L6.91091 11.4226C6.58547 11.0972 6.58547 10.5695 6.91091 10.2441C7.23634 9.91866 7.76398 9.91866 8.08942 10.2441L9.16683 11.3215V8.33335C9.16683 7.87312 9.53993 7.50002 10.0002 7.50002Z"
      fill={color}
    />
  </svg>
);

export const ArrowDownIcon = ({ color = colors.primary }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7071 13.2929C10.3166 13.6834 9.68342 13.6834 9.29289 13.2929L5.70711 9.70711C5.07714 9.07714 5.52331 8 6.41421 8H13.5858C14.4767 8 14.9229 9.07714 14.2929 9.70711L10.7071 13.2929Z"
      fill={color}
    />
  </svg>
);
export const ChevronDownIcon = ({ color = colors.primary }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5889 6.9107C15.9144 7.23614 15.9144 7.76378 15.5889 8.08922L10.5889 13.0892C10.2635 13.4147 9.73586 13.4147 9.41042 13.0892L4.41042 8.08922C4.08498 7.76378 4.08498 7.23614 4.41042 6.9107C4.73586 6.58527 5.26349 6.58527 5.58893 6.9107L9.99967 11.3214L14.4104 6.9107C14.7359 6.58527 15.2635 6.58527 15.5889 6.9107Z"
      fill={color}
    />
  </svg>
);

export const ArrowUpIcon = ({ color = colors.white }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 15.8332V4.1665"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.1665 9.99984L9.99984 4.1665L15.8332 9.99984"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const KeyIcon = ({ color = colors.secondary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7074 1.29289C22.0979 1.68342 22.0979 2.31658 21.7074 2.70711L20.4145 4L22.7074 6.29289C23.0979 6.68342 23.0979 7.31658 22.7074 7.70711L19.2074 11.2071C18.8169 11.5976 18.1837 11.5976 17.7932 11.2071L15.5003 8.91421L12.7538 11.6607C13.0613 12.0765 13.3187 12.5285 13.52 13.0075C13.852 13.7978 14.0244 14.646 14.0273 15.5033C14.0302 16.3605 13.8634 17.2099 13.5367 18.0024C13.21 18.795 12.7297 19.5151 12.1235 20.1213C11.5174 20.7274 10.7973 21.2077 10.0047 21.5344C9.21216 21.8612 8.36281 22.0279 7.50556 22.025C6.64831 22.0221 5.80009 21.8497 5.00974 21.5177C4.2194 21.1857 3.50254 20.7006 2.90045 20.0904L2.89295 20.0828C1.70892 18.8568 1.0538 17.2149 1.06861 15.5106C1.08341 13.8063 1.76701 12.176 2.97217 10.9709C4.17732 9.76574 5.8076 9.08214 7.51188 9.06733C8.8847 9.0554 10.2171 9.47819 11.3241 10.2619L20.2932 1.29289C20.6837 0.902369 21.3169 0.902369 21.7074 1.29289ZM10.7221 12.3559C10.7128 12.3476 10.7036 12.339 10.6946 12.3303C9.84585 11.5106 8.70914 11.057 7.52926 11.0673C6.34937 11.0775 5.22072 11.5508 4.38638 12.3851C3.55204 13.2194 3.07878 14.3481 3.06853 15.528C3.05829 16.7061 3.51049 17.8412 4.32787 18.6895C4.74393 19.1101 5.23887 19.4446 5.78439 19.6738C6.33155 19.9037 6.91877 20.023 7.51226 20.025C8.10574 20.027 8.69375 19.9116 9.24244 19.6854C9.79113 19.4592 10.2897 19.1267 10.7093 18.707C11.129 18.2874 11.4615 17.7889 11.6877 17.2402C11.9139 16.6915 12.0293 16.1035 12.0273 15.51C12.0253 14.9165 11.906 14.3293 11.6761 13.7821C11.4525 13.2498 11.1285 12.7656 10.7221 12.3559ZM16.9145 7.5L18.5003 9.08579L20.5861 7L19.0003 5.41421L16.9145 7.5Z"
      fill={color}
    />
  </svg>
);

export const BriefcaseIcon = ({ color = colors.secondary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4004 2C9.60474 2 8.84168 2.31607 8.27907 2.87868C7.71646 3.44129 7.40039 4.20435 7.40039 5V6H4.40039C2.74354 6 1.40039 7.34315 1.40039 9V19C1.40039 20.6569 2.74354 22 4.40039 22H8.40039H16.4004H20.4004C22.0572 22 23.4004 20.6569 23.4004 19V9C23.4004 7.34315 22.0572 6 20.4004 6H17.4004V5C17.4004 4.20435 17.0843 3.44129 16.5217 2.87868C15.9591 2.31607 15.196 2 14.4004 2H10.4004ZM15.4004 6V5C15.4004 4.73478 15.295 4.48043 15.1075 4.29289C14.92 4.10536 14.6656 4 14.4004 4H10.4004C10.1352 4 9.88082 4.10536 9.69328 4.29289C9.50575 4.48043 9.40039 4.73478 9.40039 5V6H15.4004ZM9.40039 8H15.4004V20H9.40039V8ZM7.40039 8H4.40039C3.84811 8 3.40039 8.44772 3.40039 9V19C3.40039 19.5523 3.84811 20 4.40039 20H7.40039V8ZM17.4004 20V8H20.4004C20.9527 8 21.4004 8.44772 21.4004 9V19C21.4004 19.5523 20.9527 20 20.4004 20H17.4004Z"
      fill={color}
    />
  </svg>
);

export const GovermentIcon = ({ color = colors.secondary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill={color}>
    <path
      d="M20.7998 6H23.7998V8H22.7998V19H23.7998V21H1.7998V19H2.7998V8H1.7998V6H4.7998V4C4.7998 3.44772 5.24752 3 5.7998 3H19.7998C20.3521 3 20.7998 3.44772 20.7998 4V6ZM20.7998 8H4.7998V19H7.7998V12H9.7998V19H11.7998V12H13.7998V19H15.7998V12H17.7998V19H20.7998V8ZM6.7998 5V6H18.7998V5H6.7998Z"
      fill={color}
    />
  </svg>
);

export const ZapIcon = ({ color = colors.secondary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5984 1.08269C14.0056 1.25946 14.2475 1.68353 14.1925 2.12403L13.333 9H21.2002C21.5882 9 21.9412 9.22445 22.1058 9.57584C22.2704 9.92723 22.2168 10.3421 21.9684 10.6402L11.9684 22.6402C11.6842 22.9812 11.2092 23.0941 10.802 22.9173C10.3948 22.7405 10.1529 22.3165 10.2079 21.876L11.0674 15H3.20021C2.81219 15 2.4592 14.7755 2.29462 14.4242C2.13004 14.0728 2.18358 13.6579 2.43199 13.3598L12.432 1.35981C12.7162 1.01878 13.1912 0.905925 13.5984 1.08269ZM5.33525 13H12.2002C12.487 13 12.7601 13.1232 12.9499 13.3382C13.1397 13.5532 13.2281 13.8394 13.1925 14.124L12.6155 18.7395L19.0652 11H12.2002C11.9134 11 11.6404 10.8768 11.4505 10.6618C11.2607 10.4468 11.1724 10.1606 11.2079 9.87596L11.7849 5.26045L5.33525 13Z"
      fill={color}
    />
  </svg>
);

export const BookIcon = ({ color = colors.secondary }: Props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6001 2C2.04781 2 1.6001 2.44772 1.6001 3V18C1.6001 18.5523 2.04781 19 2.6001 19H9.6001C10.1305 19 10.6392 19.2107 11.0143 19.5858C11.3894 19.9609 11.6001 20.4696 11.6001 21C11.6001 21.5523 12.0478 22 12.6001 22C13.1524 22 13.6001 21.5523 13.6001 21C13.6001 20.4696 13.8108 19.9609 14.1859 19.5858C14.561 19.2107 15.0697 19 15.6001 19H22.6001C23.1524 19 23.6001 18.5523 23.6001 18V3C23.6001 2.44772 23.1524 2 22.6001 2H16.6001C15.274 2 14.0022 2.52678 13.0646 3.46447C12.8963 3.63275 12.7412 3.81178 12.6001 3.99997C12.459 3.81178 12.3039 3.63275 12.1356 3.46447C11.1979 2.52678 9.92618 2 8.6001 2H2.6001ZM13.6001 17.5359C14.203 17.1878 14.8919 17 15.6001 17H21.6001V4H16.6001C15.8044 4 15.0414 4.31607 14.4788 4.87868C13.9162 5.44129 13.6001 6.20435 13.6001 7V17.5359ZM11.6001 17.5359V7C11.6001 6.20435 11.284 5.44129 10.7214 4.87868C10.1588 4.31607 9.39575 4 8.6001 4H3.6001V17H9.6001C10.3083 17 10.9972 17.1878 11.6001 17.5359Z"
      fill={color}
    />
  </svg>
);

export const LocationIcon = ({ color = colors.primary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93181 14.4386C5.24473 13.0715 3.33334 11.5227 3.33334 8.33289C3.33334 4.64978 6.31793 1.66663 9.9996 1.66663C13.6813 1.66663 16.6658 4.65121 16.6658 8.33289C16.6658 11.5287 14.7555 13.0793 13.0683 14.4488C11.8227 15.46 10.6988 16.3724 10.5551 17.7751L10.5489 17.8357C10.52 18.1178 10.2824 18.3323 9.99884 18.3323C9.71434 18.3323 9.47649 18.116 9.44957 17.8328L9.44408 17.7751C9.31058 16.3661 8.18288 15.4524 6.93181 14.4386ZM10.0007 10.6939C11.1512 10.6939 12.0839 9.76117 12.0839 8.61065C12.0839 7.46013 11.1512 6.52744 10.0007 6.52744C8.85017 6.52744 7.91748 7.46013 7.91748 8.61065C7.91748 9.76117 8.85017 10.6939 10.0007 10.6939Z"
      fill={color}
    />
  </svg>
);

export const PhoneIcon = ({ color = colors.primary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={color}>
    <path
      d="M2.93492 2.77573C-0.0112123 5.4474 2.64521 10.4193 6.11087 13.885C9.57544 17.3496 14.555 20.0125 17.2267 17.0664C17.4118 16.8616 17.7255 16.4129 18.1655 15.7235C18.3204 15.4798 18.3722 15.1846 18.3095 14.9028C18.2468 14.6209 18.0747 14.3755 17.8311 14.2205L15.178 12.6957C14.9402 12.5595 14.6598 12.5179 14.3927 12.5793C14.1257 12.6406 13.8915 12.8004 13.7371 13.0268C13.5334 13.3328 13.3014 13.6203 13.0454 13.8839C11.5827 15.3401 9.73445 14.0429 7.8437 12.1522C5.95185 10.2603 4.66231 8.41968 6.11849 6.95695C6.33959 6.73477 6.62494 6.50387 6.97456 6.26535C7.20118 6.11084 7.36117 5.87651 7.42255 5.60918C7.48393 5.34186 7.44219 5.06121 7.30566 4.82332L5.78195 2.17017C5.62673 1.92677 5.38124 1.75495 5.09941 1.69246C4.81758 1.62996 4.52247 1.68191 4.27893 1.83689C3.58841 2.2769 3.14077 2.5884 2.93492 2.77573Z"
      fill={color}
    />
  </svg>
);

export const EarthIcon = ({ color = colors.primary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={color}>
    <path
      d="M9.99999 1.66663C14.6023 1.66663 18.3333 5.39758 18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99999 18.3333C5.39761 18.3333 1.66666 14.6023 1.66666 9.99996C1.66666 5.39758 5.39761 1.66663 9.99999 1.66663ZM13.3369 10.7314C13.0491 10.2944 12.8414 9.97879 12.0518 10.104C10.5597 10.3408 10.3943 10.602 10.3231 11.0312L10.303 11.1619L10.2827 11.2997C10.2013 11.869 10.2044 12.0843 10.4657 12.359C11.5201 13.4651 12.1517 14.2625 12.343 14.7288C12.4364 14.957 12.6766 15.6459 12.5113 16.3272C13.531 15.9221 14.4297 15.2776 15.1377 14.4632C15.2296 14.1517 15.2959 13.7638 15.2959 13.2931V13.206C15.2959 12.4372 15.2959 12.0866 14.7526 11.7761C14.5233 11.6459 14.3522 11.5675 14.2152 11.5053C13.9092 11.3661 13.7066 11.2747 13.4332 10.8749C13.4006 10.8273 13.3687 10.7798 13.3369 10.7314ZM9.99999 3.1944C8.06882 3.1944 6.32551 3.99878 5.08682 5.29076C5.23429 5.39318 5.3628 5.53707 5.45106 5.73543C5.62106 6.11687 5.62106 6.50929 5.62106 6.85633C5.62072 7.13013 5.62034 7.38929 5.70826 7.57748C5.82868 7.83441 6.34699 7.94352 6.80456 8.03943C6.96804 8.07385 7.13661 8.10898 7.28996 8.15143C7.7117 8.26857 8.03794 8.64704 8.29947 8.95096C8.40799 9.07754 8.56824 9.26354 8.64857 9.30971C8.69049 9.27963 8.82499 9.13396 8.89157 8.89454C8.94224 8.71221 8.92782 8.54971 8.85324 8.46188C8.38732 7.912 8.41266 6.85263 8.55666 6.46242C8.78349 5.84668 9.49207 5.89211 10.01 5.92577C10.2032 5.9382 10.3855 5.95015 10.5214 5.93309C11.0402 5.86801 11.1997 5.07944 11.3125 4.92496C11.5559 4.59147 12.3006 4.08921 12.7632 3.77874C11.9187 3.40314 10.9837 3.1944 9.99999 3.1944Z"
      fill={color}
    />
  </svg>
);

export const TelegramIcon = ({ color = colors.primary }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.02272 10.1678L10.9499 6.07761C11.9298 5.65156 15.2532 4.28827 15.2532 4.28827C15.2532 4.28827 16.787 3.69184 16.6591 5.14032C16.6165 5.73675 16.2759 7.82445 15.9348 10.0824L14.8698 16.7713C14.8698 16.7713 14.7845 17.7512 14.0589 17.9216C13.3335 18.0921 12.1417 17.3253 11.9289 17.1547C11.7585 17.027 8.73341 15.1098 7.62555 14.1725C7.32724 13.9169 6.98647 13.4055 7.66819 12.8091C9.202 11.4031 11.0341 9.65621 12.1417 8.54853C12.653 8.03729 13.1643 6.84433 11.034 8.29305L5.0266 12.3405C5.0266 12.3405 4.34479 12.7666 3.06675 12.3832C1.78866 11.9998 0.29744 11.4885 0.29744 11.4885C0.29744 11.4885 -0.725126 10.8494 1.02173 10.1679L1.02272 10.1678Z"
      fill={color}
    />
  </svg>
);

export const InsragramIcon = ({ color = colors.primary }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_839_2597)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 0.833374C3.0719 0.833374 0.833328 3.07195 0.833328 5.83337V14.1667C0.833328 16.9281 3.0719 19.1667 5.83333 19.1667H14.1667C16.9281 19.1667 19.1667 16.9281 19.1667 14.1667V5.83337C19.1667 3.07195 16.9281 0.833374 14.1667 0.833374H5.83333ZM8.84857 7.78099C9.31532 7.5388 9.84655 7.44995 10.3667 7.52709C10.8973 7.60576 11.3885 7.853 11.7678 8.23228C12.147 8.61155 12.3943 9.10276 12.473 9.63334C12.5501 10.1535 12.4612 10.6847 12.2191 11.1515C11.9769 11.6182 11.5937 11.9967 11.124 12.2331C10.6543 12.4695 10.122 12.5518 9.60282 12.4683C9.08366 12.3848 8.60406 12.1396 8.23223 11.7678C7.86041 11.396 7.61529 10.9164 7.53175 10.3972C7.44821 9.87806 7.5305 9.34577 7.76691 8.87607C8.00333 8.40637 8.38182 8.02317 8.84857 7.78099ZM10.6112 5.87845C9.74426 5.74989 8.85887 5.89797 8.08096 6.30162C7.30304 6.70526 6.67221 7.34392 6.27819 8.12675C5.88417 8.90959 5.74702 9.79673 5.88625 10.662C6.02549 11.5273 6.43401 12.3266 7.05372 12.9463C7.67343 13.566 8.47277 13.9746 9.33804 14.1138C10.2033 14.253 11.0905 14.1159 11.8733 13.7219C12.6561 13.3278 13.2948 12.697 13.6984 11.9191C14.1021 11.1412 14.2502 10.2558 14.1216 9.38886C13.9905 8.50457 13.5784 7.68589 12.9463 7.05377C12.3141 6.42164 11.4955 6.00958 10.6112 5.87845ZM13.75 5.41671C13.75 4.95647 14.1212 4.58337 14.5792 4.58337H14.5875C15.0454 4.58337 15.4167 4.95647 15.4167 5.41671C15.4167 5.87695 15.0454 6.25004 14.5875 6.25004H14.5792C14.1212 6.25004 13.75 5.87695 13.75 5.41671Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_839_2597">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const YoutubeIcon = ({ color = colors.primary }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2032 3.33337C10.6483 3.33582 11.7619 3.34659 12.9451 3.39398L13.3646 3.41227C14.5558 3.46867 15.7461 3.56502 16.3364 3.72962C17.1238 3.95083 17.7427 4.59629 17.9519 5.41447C18.285 6.71372 18.3267 9.24954 18.3318 9.86321L18.3326 9.99037V9.99929C18.3326 9.99929 18.3326 10.0024 18.3326 10.0083L18.3318 10.1355C18.3267 10.7491 18.285 13.285 17.9519 14.5842C17.7398 15.4054 17.1209 16.0509 16.3364 16.269C15.7461 16.4336 14.5558 16.53 13.3646 16.5864L12.9451 16.6046C11.7619 16.652 10.6483 16.6628 10.2032 16.6653L10.0079 16.666H9.99924C9.99924 16.666 9.99632 16.666 9.99057 16.666L9.79541 16.6653C8.85341 16.6601 4.91476 16.6175 3.66208 16.269C2.87466 16.0478 2.25576 15.4024 2.04655 14.5842C1.71349 13.285 1.67186 10.7491 1.66666 10.1355V9.86321C1.67186 9.24954 1.71349 6.71372 2.04655 5.41447C2.25866 4.59326 2.87756 3.94781 3.66208 3.72962C4.91476 3.38107 8.85341 3.33856 9.79541 3.33337H10.2032ZM8.33258 7.08266V12.916L13.3326 9.99929L8.33258 7.08266Z"
      fill={color}
    />
  </svg>
);

export const FacebookIcon = ({ color = colors.primary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={color}>
    <path
      d="M10.0008 1.66663C5.39844 1.66663 1.66748 5.39758 1.66748 9.99996C1.66748 14.1594 4.71486 17.6069 8.69875 18.232V12.4088H6.58284V9.99996H8.69875V8.16403C8.69875 6.07548 9.94283 4.92183 11.8463 4.92183C12.7581 4.92183 13.7117 5.08459 13.7117 5.08459V7.13538H12.6609C11.6257 7.13538 11.3029 7.77774 11.3029 8.43679V9.99996H13.6141L13.2447 12.4088H11.3029V18.232C15.2867 17.6069 18.3342 14.1594 18.3342 9.99996C18.3342 5.39758 14.6032 1.66663 10.0008 1.66663Z"
      fill={color}
    />
  </svg>
);

export const FileIcon = ({ color = colors.primary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.23223 1.56561C3.70107 1.09677 4.33696 0.833374 5 0.833374H11.6667C11.8877 0.833374 12.0996 0.921171 12.2559 1.07745L17.2559 6.07745C17.4122 6.23373 17.5 6.44569 17.5 6.66671V16.6667C17.5 17.3297 17.2366 17.9656 16.7678 18.4345C16.2989 18.9033 15.663 19.1667 15 19.1667H5C4.33696 19.1667 3.70107 18.9033 3.23223 18.4345C2.76339 17.9656 2.5 17.3297 2.5 16.6667V3.33337C2.5 2.67033 2.76339 2.03445 3.23223 1.56561ZM5 2.50004C4.77899 2.50004 4.56702 2.58784 4.41074 2.74412C4.25446 2.9004 4.16667 3.11236 4.16667 3.33337V16.6667C4.16667 16.8877 4.25446 17.0997 4.41074 17.256C4.56702 17.4122 4.77899 17.5 5 17.5H15C15.221 17.5 15.433 17.4122 15.5893 17.256C15.7455 17.0997 15.8333 16.8877 15.8333 16.6667V7.01188L11.3215 2.50004H5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83334 10.8333C5.83334 10.3731 6.20644 10 6.66668 10H13.3333C13.7936 10 14.1667 10.3731 14.1667 10.8333C14.1667 11.2936 13.7936 11.6667 13.3333 11.6667H6.66668C6.20644 11.6667 5.83334 11.2936 5.83334 10.8333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83334 14.1667C5.83334 13.7065 6.20644 13.3334 6.66668 13.3334H13.3333C13.7936 13.3334 14.1667 13.7065 14.1667 14.1667C14.1667 14.6269 13.7936 15 13.3333 15H6.66668C6.20644 15 5.83334 14.6269 5.83334 14.1667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83334 7.49996C5.83334 7.03972 6.20644 6.66663 6.66668 6.66663H8.33334C8.79358 6.66663 9.16668 7.03972 9.16668 7.49996C9.16668 7.9602 8.79358 8.33329 8.33334 8.33329H6.66668C6.20644 8.33329 5.83334 7.9602 5.83334 7.49996Z"
      fill={color}
    />
  </svg>
);

export const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-upload-cloud"
  >
    <polyline points="16 16 12 12 8 16"></polyline>
    <line x1="12" y1="12" x2="12" y2="21"></line>
    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
    <polyline points="16 16 12 12 8 16"></polyline>
  </svg>
);

export const SearchIcon = ({ color = colors.primary }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 10C0.5 4.75329 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.75329 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10ZM10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9038 15.9038C16.2943 15.5132 16.9275 15.5132 17.318 15.9038L21.5606 20.1464C21.9512 20.5369 21.9512 21.1701 21.5606 21.5606C21.1701 21.9511 20.537 21.9511 20.1464 21.5606L15.9038 17.318C15.5133 16.9275 15.5133 16.2943 15.9038 15.9038Z"
      fill={color}
    />
  </svg>
);

export const LightModeSvg = () => (
  <svg
    style={{ cursor: "pointer" }}
    width="56"
    height="32"
    viewBox="0 0 56 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="32" rx="16" fill="#F0F3F7" />
    <rect x="4" y="4" width="24" height="24" rx="12" fill="white" />
    <path
      d="M14.6668 12.6666C14.6668 15.2439 16.7561 17.3332 19.3335 17.3332C20.1926 17.3332 20.9976 17.1301 21.689 16.7449C22.0813 16.5263 22.6324 16.785 22.549 17.2263C21.9638 20.3223 19.2631 22.6665 16.0001 22.6665C12.3183 22.6665 9.3335 19.6817 9.3335 15.9999C9.3335 12.7341 11.6818 10.0167 14.782 9.44434C15.2225 9.36302 15.4783 9.91233 15.2595 10.3032C14.8715 10.9964 14.6668 11.8042 14.6668 12.6666ZM10.6668 15.9999C10.6668 18.9454 13.0546 21.3332 16.0001 21.3332C18.039 21.3332 19.8107 20.1891 20.7081 18.5079C20.2631 18.6123 19.8025 18.6665 19.3335 18.6665C16.0197 18.6665 13.3335 15.9803 13.3335 12.6666C13.3335 12.1976 13.3877 11.7369 13.4921 11.292C11.8109 12.1894 10.6668 13.9611 10.6668 15.9999Z"
      fill="#303940"
    />
  </svg>
);

export const DarkModeSvg = () => (
  <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="32" rx="16" fill="#15202B" />
    <rect x="28" y="4" width="24" height="24" rx="12" fill="#1D9BF0" />
    <path
      d="M45.1497 18.7435L45.67 17.7689L44.5944 18.0212C44.1867 18.1169 43.7644 18.1666 43.334 18.1666C40.2964 18.1666 37.834 15.7042 37.834 12.6667C37.834 12.2363 37.8837 11.8139 37.9794 11.4062L38.2316 10.3307L37.2571 10.8509C35.4196 11.8319 34.1673 13.7693 34.1673 16C34.1673 19.2216 36.779 21.8332 40.0006 21.8332C42.2313 21.8332 44.1688 20.581 45.1497 18.7435ZM38.1673 12.6667C38.1673 15.5201 40.4805 17.8333 43.334 17.8333C44.2722 17.8333 45.1618 17.6113 45.9328 17.1818C45.9503 17.172 45.9727 17.1658 45.9991 17.1659C46.0187 17.166 46.0363 17.1696 46.0503 17.1744C45.4926 20.0205 43.0023 22.1666 40.0006 22.1666C36.5949 22.1666 33.834 19.4057 33.834 16C33.834 12.9942 35.985 10.4898 38.8318 9.94391C38.8363 9.95749 38.8396 9.97444 38.8396 9.99319C38.8396 10.0193 38.8334 10.0416 38.8237 10.059C38.391 10.832 38.1673 11.725 38.1673 12.6667Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export const MobileMenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3 12H21"
      stroke="#0A2540"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 6H21"
      stroke="#0A2540"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 18H21"
      stroke="#0A2540"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
