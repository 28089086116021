import { useEffect, useState } from "react";
import { useGetStudentsAgeByGenderStatQuery, useGetStudentsAgeByPlaceStatQuery } from "src/app/services/students";
import { IStudentGender, IStudentPlace } from "../type";
import { makeStudentGender, makeStudentPlace } from "../util";

function useAge() {
  // Genders section
  const [gendersData, setGenders] = useState<IStudentGender>();
  const { data: genders } = useGetStudentsAgeByGenderStatQuery();
  useEffect(() => {
    if (genders) setGenders(makeStudentGender(genders));
  }, [genders]);

  // Places section
  const [placesData, setPlaces] = useState<IStudentPlace>();
  const { data: places } = useGetStudentsAgeByPlaceStatQuery();
  useEffect(() => {
    if (places) setPlaces(makeStudentPlace(places));
  }, [places]);

  return {
    gendersData,
    placesData,
  };
}

export default useAge;
