import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useTypedSelector } from "src/app/store";
import { SearchIcon } from "src/assets/svg";
import CustomInput from "../common/input";

function SearchFilter() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };
  const [value, setValue] = useState(searchParams.get("search") || "");
  const onSearch = () => {
    handleMakeParams("search", value);
    handleMakeParams("page", "1");
  };

  return (
    <div className="university-search">
      <CustomInput
        controlHeight={56}
        placeholder={t("filters.search")}
        prefix={<SearchIcon color={colors.primary} />}
        onChange={(ev) => {
          setValue(ev.target.value);
          if (!ev.target.value) handleMakeParams("search", "");
        }}
        value={value}
        allowClear
        onKeyDown={(ev) => {
          if (ev.code === "Enter") onSearch();
        }}
      />
    </div>
  );
}

export default SearchFilter;
