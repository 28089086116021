import {
  useDisabilityReportQuery,
  useGetPaymentFormQuery,
  useGetStudentStatusTypeQuery,
} from "src/app/services/private";
import { ConfigProvider, DatePicker, message, Select, Space, Table } from "antd";
import { IUzAsboEduForm } from "src/app/services/private/type";
import { useEffect, useState } from "react";
import { tablePaginationConfig } from "src/app/services/api/const";
import { useUzAsboEduFormColumns } from "src/pages/uzasboStatistics/hooks/useEduFormColumns";
import moment from "moment";
import { uzAsboDateFormat } from "src/pages/uzasboStatistics/uzasboStatistic";
import { addAll, TDate } from "src/pages/uzasboStatistics/UzASBOAccomodation";
import { DownloadButton } from "src/components/downloadButton";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";

function disabledDate(current: Date) {
  let customDate = moment().format("2022-09");
  return (
    (current && current < moment(customDate, "YYYY-MM")) || current > moment(new Date(), "YYYY-MM")
  );
}

export const Disability = () => {
  const [date, setDate] = useState<TDate>();
  const [paymentFormCodeId, setPaymentFormCode] = useState<string>();
  const [studentStatus, setStudentStatus] = useState<string>();
  const { data: paymentForms } = useGetPaymentFormQuery();
  const { data: studentStatusList } = useGetStudentStatusTypeQuery();
  const {
    data: dataSource,
    isFetching,
    isError,
    error,
  } = useDisabilityReportQuery(
    {
      month: date && date.month,
      year: date && date.year,
      paymentFormCode: paymentFormCodeId ? paymentFormCodeId : undefined,
      studentStatusCode: studentStatus,
    },
    {
      skip: !date,
    },
  );

  useEffect(() => {
    if (isError) {
      const err = error as FetchBaseQueryError;
      if (err?.status === 404) {
        message.error("Bu oydagi hisobot hali shakllanmagan.");
      }
    }
  }, [isError]);

  const handleDate = (value: any) => {
    setDate({
      month: moment(value["$d"]).format("M"),
      year: moment(value["$d"]).format("YYYY"),
    });
  };

  const columns = useUzAsboEduFormColumns<IUzAsboEduForm>();

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowSelectedBg: "#e6f4ff",
            rowSelectedHoverBg: "#bae0ff",
          },
        },
      }}
    >
      <Table
        title={() => (
          <div className="flex-space-center">
            <h3>Nogironligi bo'lgan talabalar ta'lim shakli kesimida</h3>
            <Space>
              <DatePicker
                style={{ height: "36px", width: "230px" }}
                disabledDate={disabledDate}
                placeholder="Sanani tanlang"
                format={uzAsboDateFormat}
                picker="month"
                onChange={(e) => handleDate(e)}
              />
              <Select
                style={{ width: 230 }}
                placeholder="To'lov shakli"
                onChange={setPaymentFormCode}
                options={addAll(
                  paymentForms?.map((item) => ({ label: item.name, value: item.code })),
                )}
              />
              <Select
                style={{ width: 230 }}
                placeholder="Talaba status"
                onChange={setStudentStatus}
                allowClear
                options={studentStatusList?.map((item) => ({ label: item.name, value: item.code }))}
              />
              <DownloadButton
                downloadUrl="/uzasbohemis/disability/eduFormByYearAndMonth/excel"
                disabled={!date}
                params={{
                  month: date && date.month,
                  year: date && date.year,
                  paymentFormCode: paymentFormCodeId ? paymentFormCodeId : undefined,
                  studentStatusCode: studentStatus,
                }}
              />
            </Space>
          </div>
        )}
        size="small"
        bordered
        rowSelection={{ type: "radio", columnWidth: 40 }}
        virtual
        rowKey="universityCode"
        loading={isFetching}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 2000, y: 600 }}
        pagination={tablePaginationConfig}
      />
    </ConfigProvider>
  );
};
