import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useGetTablesAcademicDegreeQuery } from "src/app/services/tables";
import { ITablesAcademicDegree } from "src/app/services/tables/type";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { CustomTable } from "src/pages/higher_education/tables/tablesUniversities/components/CustomTable";
import { useMemo } from "react";
export function TablesAcademicDegreeForm() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetTablesAcademicDegreeQuery();
  const getColumnSearchProps = useColumnSearch();

  const dataSourceRank = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total: item.withoutDegreeCount + item.scienceDoctorCount + item.scienceCandidateCount,
    }));

    return newData;
  }, [data]);

  const excludedColumns = ["universityCode", "universityName", "universityOwnership"];

  const columns: ColumnsType<ITablesAcademicDegree> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      ...getColumnSearchProps("universityName"),
      width: "300px",
    },
    {
      title: t("tables.ownership"),
      dataIndex: "universityOwnership",
      align: "center",
    },
    {
      title:  t("tables.without_rank"),
      dataIndex: "withoutDegreeCount",
      align: "center",
    },
    {
      title: t("tables.doctor_of_science"),
      dataIndex: "scienceDoctorCount",
      align: "center",
    },
    {
      title: t("tables.candidate_of_science"),
      dataIndex: "scienceCandidateCount",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>
      <CustomTable
        title="Professor-o'qituvchilar ilmiy daraja bo'yicha"
        rowKey="universityCode"
        dataSource={dataSourceRank}
        columns={columns}
        pagination={false}
        size="small"
        loading={isLoading}
        excludedColumns={excludedColumns}
        scroll={{ x: true, y: 500 }}
        downloadUrl="teacher/rank"
      />
    </div>
  );
}
