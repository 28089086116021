import logo from "../../assets/img/logo_dark.png";
import "./login.scss";

const Login = () => {
  return (
    <div className="loginContainer">
      <aside>
        <header>
          <img src={logo} alt="logo" />
          <h2>Stat.edu.uz</h2>
        </header>
        <div className="contentBox">
          <div className="content">
            <img src={logo} alt="logo" />
            <h1>O‘zbekiston Respublikasi Oliy ta’lim, fan va innovatsiyalar vazirligi</h1>
          </div>
        </div>
      </aside>
      <main>
        <div className="formBox">
          <h1>Tizimga kirish</h1>
          <div className="loginBtn">
            <a href="https://stat.edu.uz/api/privateUser/oneId">
              <div className="loginTitle">ONE ID orqali kirish</div>
            </a>
            <div className="pulse-css">
              <div className="first-circle"></div>
              <div className="second-circle"></div>
              <div className="third-circle"></div>
            </div>
          </div>
          <p>
            O‘zbekiston Respublikasi qonunchiligi asosida, shaxsingiz to‘g’risidagi ma’lumotlarni
            id.egov.uz tizimidan olinganini ma’lum qilamiz
          </p>
        </div>
      </main>
    </div>
  );
};

export default Login;
