export const languageUz = {
  header: {
    statistics: "Statistika",
    university: "Universitet",
    graphic_visible: "Grafik ko'rinish",
    table_visible: "Jadval ko'rinish",
    edu_type: "Ta'lim turi",
    edu_form: "Ta'lim shakli",
    payment_form: "To'lov shakli",
    citizenship: "Fuqaroligi",
    courses: "Kurslar",
    age: "Yoshi",
    residence_place: "Yashash joyi",
    regions_section: "Hududlar kesimida",
    by_gender: "Jinsi bo'yicha",
    dark_mode: "Dark mode",
    download: "Yuklab olish",
    download_as_png: "PNG formatda yuklash",
    download_as_csv: "CSV formatda yuklash",
    download_as_pdf: "PDF formatda yuklash",
    students_info_text:
      "Ma’lumotlar real vaqt rejimida HEMIS axborot tizimidan olinmoqda",
    lyceum_info_text:
      "Ma’lumotlar real vaqt rejimida emis.edu.uz axborot tizimidan olinmoqda",
    prof_info_text:
      "Ma’lumotlar real vaqt rejimida prof.edu.uz axborot tizimidan olinmoqda",
    doctoral_info_text:
      "Ma’lumotlar real vaqt rejimida phd.mininnovation.uz axborot tizimidan olinmoqda",
    menu: {
      higher: "Oliy ta'lim",
      lyceum: "Akademik litsey",
      prof: "Professional ta'lim",
      admission: "Qabul",
      students: "Talabalar",
      teachers: "O'qituvchilar",
      universities: "OTMlar ro'yxati",
      table_second_tab: "Yo'nalishlar kesimida",
      table_third_tab: "O'qituvchilar kesimida",
      learners: "O'quvchilar",
      lyceums: "Akademik litseylar",
      institutions: "Muassasalar",
      doctoral: "Doktorantura",
      doctoral_common: "Umumiy",
      doctoral_by_regions: "Viloyatlar kesimida",
      doctoral_by_directions: "Yo'nalishlar bo'yicha",
      tables: "Jadvallar",
    },
  },
  footer: {
    contact_center: "Aloqa markazi",
    privacy_policy: "Maxfiylik siyosati",
    ministry: "O'zRes oliy ta'lim, fan va innovatsiya vazirligi 2020-2023",
    owner: "Raqamli ta'lim texnologiyalarini rivojlantirish markazi",
    producer: "Ishlab chiqaruvchi",
  },
  edu_type: {
    bachelor: "Bakalavr",
    master: "Magistratura",
    ordinance: "Ordinatura",
    doctorate_phd: "Doktorantura PhD",
    doctorate_dsc: "Doktorantura DSc",
    common: "Umumiy",
    vocational_schools: "Kasb-hunar maktablar",
    colleges: "Kollejlar",
    technical_schools: "Texnikumlar",
    vocational_school: "Kasb-hunar maktabi",
    college: "Kollej",
    technical_school: "Texnikum",
    lyceum: "Akademik litsey",
    transfer: "O'qishni ko'chirish",
    second_specialty: "Ikkinchi mutaxassislik",
    bachelor_technical_school:
      "Bakalavr (suhbat asosida, texnikum bitiruvchilari)",
  },
  edu_form: {
    daytime: "Kunduzgi",
    evening: "Kechki",
    externally: "Sirtqi",
    special_externally: "Maxsus sirtqi",
    joint: "Qo'shma",
    remote: "Masofaviy",
    dual: "Dual",
    second_evening: "Ikkinchi oliy (kechki)",
    second_daytime: "Ikkinchi oliy (kunduzgi)",
    second_external: "Ikkinchi oliy (sirtqi)",
  },
  pay_form: {
    grant: "Davlat granti",
    contract: "To'lov-kontrakt",
  },
  citizenships: {
    uzbek: "O'zbekiston fuqarosi",
    foreigner: "Xorijiy davlat fuqarosi",
    stateless: "Fuqaroligi yo'q shaxs",
    teen: "Voyaga yetmagan shaxs",
  },
  courses: {
    course_1: "1-kurs",
    course_2: "2-kurs",
    course_3: "3-kurs",
    course_4: "4-kurs",
    course_5: "5-kurs",
    course_6: "6-kurs",
  },
  ages: {
    under_30: "30 yoshdan kichiklar",
    over_30: "30 yoshdan oshganlar",
    under_20: "20 yoshdan kichiklar",
    over_20: "20 yoshdan oshganlar",
    under_17: "17 yoshdan kichiklar",
    over_17: "17 yoshdan oshganlar",
    until_30: "30 yoshgacha",
    age_30_35: "30-35 yosh",
    age_35_40: "35-40 yosh",
    age_40_45: "40-45 yosh",
    age_45_50: "45-50 yosh",
    age_50_55: "50-55 yosh",
    age_55_60: "55-60 yosh",
    over_60: "60+ yosh",
  },
  residence_places: {
    own_house: "O'z uyida",
    dormitory: "Talabalar turar joyida",
    rented_house: "Ijaradagi uyida",
    relative_house: "Qarindoshining uyida",
    familiar_house: "Tanishining uyida",
    other: "Boshqa",
  },
  genders: {
    men: "Erkaklar",
    women: "Ayollar",
  },
  filters: {
    region: "Joylashgan viloyati",
    search: "Qidirish uchun universitet nomini kiriting",
    ownership: "Mulkchilik shakli",
    state: "Davlat",
    non_state: "Nodavlat",
    foreign: "Xorijiy",
    joint: "Qo'shma",
  },
  teachers: {
    by_gender: "O'qituvchilar (Jins bo'yicha)",
    total_count: "Umumiy soni",
    staff: {
      leaders: "Rahbar xodimlar",
      prorektor: "Prorektor",
      dekan: "Dekan",
      department_header: "Kafedra mudiri",
    },
    s_potential: {
      title: "O'qituvchilar ilmiy salohiyati bo'yicha",
      skilled: "Darajali",
      unskilled: "Darajasiz",
    },
    s_level: {
      title: "O'qituvchilar ilmiy daraja bo'yicha",
      candidate_phd: "Fan nomzodi, PhD",
      doctor_dcs: "Fan doktori, DSc",
    },
    ac_title: {
      title: "O'qituvchilar ilmiy unvon bo'yicha",
      untitled: "Unvonsiz",
      docent: "Dotsent",
      senior: "Katta ilmiy xodim",
      professor: "Professor",
    },
    position: {
      title: "Lavozimi bo'yicha",
      intern: "Stajer-o'qituvchi",
      assistent: "Assistent",
      senior: "Katta o'qituvchi",
    },
    work_type: {
      title: "Mehnat shakli bo'yicha",
      main: "Asosiy ish",
      seat_external: "O'rindoshlik (tashqi)",
      per_hour: "Soatbay",
      seat_internal_add: "O'rindoshlik(ichki-qo'shimcha)",
      seat_internal_main: "O'rindoshlik(ichki asosiy)",
    },
    by_citizenship: "O'qituvchilar fuqarolik bo'yicha",
    by_age: "O'qituvchilar yoshi bo'yicha",
  },
  university: {
    name: "Oliy ta'lim muassasi nomi",
    list: "Oliy ta'lim muassasalari ro'yxati",
    website: "Veb sayt",
    license_pdf: "Litsenziya.PDF",

    types: {
      list: "Universitetlar ro'yxati",
      search: "Universitet qidirish",
    },
  },
  admission: {
    title: "2021-2023 yillar uchun arizalar statistikasi",
    over_years: "Yillar kesimida",
    applications: "Arizalar soni",
  },
  lyceum: {
    list: "Akademik litseylar ro'yxati",
    name: "Akademik litsey nomi",
  },
  prof: {
    list: "Professional ta'lim muassasalari ro‘yxati",
    name: "Muassasi nomi",
    type: "Muassasi turi",
    code: "Muassasi kodi",
    by_region: "Viloyat bo'yicha",
    region_name: "Viloyat nomi",
    province_code: "Viloyat kodi",
  },
  doctoral: {
    doctoralStat: "Statistika doktorantura",
    dc_count: "Izlanuvchilar soni (DSc)",
    dc_count_woman: "Ayol izlanuvchilar soni (DSc)",
    dc_count_man: "Erkak izlanuvchilar soni (DSc)",
    phd_count: "Izlanuvchilar soni (PhD)",
    phd_count_man: "Erkak izlanuvchilar soni (PhD)",
    phd_count_woman: "Ayol izlanuvchilar soni (PhD)",
    all_count: "umumiy izlanuvchilar soni",
    all: "Hammasi",
    under_40: "40 yoshdan oshmagan",
    organization: {
      org_type: "TASHKILOT TURI",
      org_count: "TASHKILOT SONI",
      usr_org_count: "BIRIKTIRILGAN ADMINLAR SONI",
      100: "Oliy ta'lim muassasalari",
      200: "Ilmiy tashkilotlar",
      300: "Vazirliklar",
    },
    organizations: "Tashkilotlar",
    org: "TASHKILOT",
    org_count_by_region: "Viloyatdagi OTMlar va ilmiy tashkilotlar soni",
    count_by_researcher_region:
      "Viloyatdagi doktorantlar va ilmiy rahbarlar soni",
    count_org_researcher_region:
      "Tashkilotdagi doktorantlar soni viloyat kesimida",
    count_doc_by_org_direction:
      "Tashkilot yo'nalishi bo'yicha doktorantlar soni",
    count_stu_by_org_direction: "Tashkilot yo'nalishi bo'yicha talabalar soni",
    count_doc_by_direction: "Yo‘nalishlar bo‘yicha doktorantlar soni",
    higher_education: "Oliy ta'lim muassasasi",
    scientific_education: "Ilmiy tashkilot",
    placed_region: "JOYLASHGAN HUDUD",
    itm_scientific_count: "ITMDA ILMIY RAHBARLAR SONI",
    otm_scientific_count: "OTMDA ILMIY RAHBARLAR SONI",
    itm_doctoral_count: "ITMDA DOKTORANTLAR SONI",
    otm_doctoral_count: "OTMDA DOKTORANTLAR SONI",
    doctoral: "DOKTORANTURA",
    support_doctoral: "TAYANCH DOKTORANTURA",
    intern_researcher: "STAJYOR-TADQIQOTCHI",
    dc_independent_researcher: "MUSTAQIL TADQIQOTCHI,DSC",
    phd_independent_researcher: "MUSTAQIL TADQIQOTCHI,PHD",
    dc_purposeful_doctoral: "MAQSADLI DOKTORANTURA,DSC",
    phd_purposeful_doctoral: "MAQSADLI TAYANCH DOKTORANTURA,PHD",
    total: "JAMI",
    actions: "HARAKATLAR",
    direction: "IXTISOSLIK",
    direction_code: "Ixtisoslik kodi",
    science: "FAN",
    code: "KOD",
    search: "Qidirish",
    reset: "Tozalash",
    close: "Yopish",
    filter: "Filterlash",
  },
  regions: {
    andijan: "Andijon",
    bukhara: "Buxoro",
    fergana: "Farg‘ona",
    jizzax: "Jizzax",
    namangan: "Namangan",
    navai: "Navoiy",
    qashqadaryo: "Qashqadaryo",
    samarqand: "Samarqand",
    qoraqalpoq: "Qoraqalpog‘iston Resp",
    sirdaryo: "Sirdaryo",
    surxondaryo: "Surxondaryo",
    tashkent_city: "Toshkent shahri",
    tashkent_region: "Toshkent viloyati",
    xorazm: "Xorazm",
  },
  tables: {
    univerName: "OTM nomi",
    ownership: "Mulkchilik",
    univerCode: "OTM kodi",
    total: "JAMI",
    universityByRegion: "Doimiy yashash manzili viloyatlar kesimida",
    title_bachelor: "Yo'nalishlar Bakalavr kesimida",
    title_magistr: "Yo'nalishlar Magistr kesimida",
    title_gender: "Ta'lim turi va jins bo'yicha",
    universityByGender: "Jinsi",
    man: "Erkak",
    woman: "Ayol",
    bachelor: "Bakalavr",
    master: "Magistr",
    paymentType: "To'lov turi",
    eduType: "Ta'lim turi (kurslar kesimida)",
    eduForm: "Ta'lim shakli",
    eduFormByRegion: "Ta'lim shakli va OTM joylashgan hudud kesimida",
    edu_direction: "Ta'lim yo'nalishi",
    direction_code: "Yo'nalish kodi",
    speciality: "Mutaxassislik",
    speciality_code: "Mutaxassislik kodi",
    bachelor_by_region: "Bakalavrlar soni viloyatlar kesmida",
    bachelor_count: "Bakalavrlar soni",
    master_count: "Magistrlar soni",
    master_by_region: "Magistrlar soni viloyatlar kesmida",
    count_bachelor_direction: "Jami bakalavr yo'nalishlari",
    count_master_direction: "Jami magistratura mutaxassisliklari",
    candidate_of_science: "Fan nomzodi, PhD",
    doctor_of_science: "Fan doktori, DSc",
    without_rank: "Darajasiz",
    dotsent: "Dotsent",
    senior_researcher: "Katta ilmiy xodim",
    professor: "Professor",
    untitled: "Unvonsiz",
    intern_teacher: "Stajer-o‘qituvchi",
    assistant: "Assistent",
    head_teacher: "Katta o‘qituvchi",
    head_of_department: "Kafedra mudiri",
    main_workplace: "Asosiy ish joy",
    placement: "O‘rindoshlik (ichki-asosiy)",
    placement_subjunctive: "O‘rindoshlik (ichki-qo'shimcha)",
    placement_external: "O‘rindoshlik (tashqi)",
    per_hour: "Soatbay",
    name_of_tablePaymentType: "Talabalar soni to'lov kesimida",
    basic_contract: "Bazaviy shartnoma",
    student_count: "Talabalar soni",
    withOutStipendCount: "Stipendiyasiz",
    withStipendCount: "Stipendiyali",
    super_contract: "Super shartnoma",
    contract_foreign: "Shartnoma (xorijiy)",
    hyper: "Giper",
    pedagogyAndEducationCount: "Pedagogika va ta'lim sohasi",
    mathematicsCount: "Matematika sohasi",
    naturalSciencesCount: "Tabiy fanlar sohasi",
    artCount: "Sa'nat sohasi",
    religiousStudiesCount: "Dinshunoslik",
    economicAndSocialCount: "Iqtisodiyot va Ijtimoiy soha",
    journalismAndInformationCount: "Jurnalistika va axborot sohasi",
    lawCount: "Huquq sohasi",
    businessAndManagementCount: "Biznes va boshqaruv sohasi",
    engineeringWorkCount: "Muhandislik ishi  sohasi",
    informationCommunicationTechnologiesCount:
      "Axborot-kommunikatsiya texnologiyalari sohasi",
    agricultureAndForestryCount: "Qishloq va ormon xo'jaligi sohasi",
    fisheryCount: "Baliq xo‘jaligi sohasi",
    veterinaryMedicineCount: "Veterinariya sohasi",
    healthCount: "Sog‘liqni saqlash",
    socialSecurity: "Ijtimoiy ta’minot",
    serviceCount: "Xizmat ko‘rsatish sohasi",
    transportCount: "Transport sohasi",
    hygieneAndEnvironmentalProtectionCount:
      "Gigiena va atrog-muxit muhofazasi sohasi",
    ensuringLegalOrderCount: "Huquqiy tartibotni ta’minlash ",
    productionAndProcessingCount: "Ishlab chiqarish va ishlov berish sohalari",
    architectureAndConstructionCount: "Arxitektura va qurilish",
    humanityCount: "Gumanitar fanlar sohasi",
    languagesCount: "Tillar sohasi",
    graduated_table_title: "Bitiruvchilar soni yillar va sohalar kesimida",
    foreignUniversitySpecialtiesCount: "Xorijiy OTM mutaxassisliklar sohasi",
    graduated_select_two: "Bitiruvchilar soni doimiy yashash joyi kesimida",
    graduated_select_three: "Bitiruvchilar soni mutaxassislik kesimida",
    graduated: "Bitiruvchilar kesimida",
    prof_count_by_work: "Professor-o'qituvchilar mehnat shakli bo'yicha",
    prof_count_by_academic_degree:
      "Professor-o'qituvchilar ilmiy daraja bo'yicha",
    prof_count_by_gender: "Professor-o'qituvchilar lavozim va jins bo'yicha",
    prof_count_by_academic_title:
      "Professor-o'qituvchilar ilmiy unvon bo'yicha",
  },
};
