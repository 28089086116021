import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";

interface Props {
  color?: string[];
  seriesArr: any;
  legendSize?: number;
  gridBottom?: number;
  marginTop?: number;
}

export default function PieChart({
  color,
  seriesArr,
  legendSize = 12,
  gridBottom = 0,
  marginTop = -40,
}: Props) {
  //Translation section
  const { t } = useTranslation();

  //Chart section
  const { colors } = useTypedSelector((state) => state.layout);

  const legendTextStyle = {
    color: colors.tertiary,
    fontSize: legendSize,
    rich: {
      term: {
        color: colors.primary,
        fontWeight: "bold",
      },
    },
  };

  const calcPercentage = (part: number, total: number) => {
    return ((part / total) * 100).toFixed(2);
  };

  interface ISeries {
    value: number;
    name: string;
  }

  let series: any = [];
  seriesArr?.forEach((item: any) => {
    series?.push({ value: item?.value, name: t(item?.name) });
  });

  const obj: any = {};
  series.forEach((element: ISeries) => {
    obj[`${element.name}`] = element.value;
  });

  const total = series.reduce((acc: number, box: any) => acc + box.value, 0);

  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      bottom: gridBottom,
      textStyle: legendTextStyle,
      formatter: (e: string) => {
        return `${e} {term|${calcPercentage(obj[`${e}`], total)}%}`;
      },
    },
    title: {
      text: seriesArr.reduce((acc: any, next: any) => acc + next.value || 0, 0),
      left: "center",
      top: "center",
    },
    color: color || [colors.light_blue, colors.pink, colors.green, colors.yellow],
    series: [
      {
        // name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 0,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {},
        labelLine: {
          show: false,
        },
        data: series || [],
      },
    ],
  };

  return <ReactEcharts option={option} style={{ marginTop: marginTop }} />;
}
