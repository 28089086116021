import {Select, Space, Table} from "antd";
import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import {useGetSpecialitiesEduFormQuery} from "src/app/services/tables";
import {ISpecialitiesEduForm} from "src/app/services/tables/type";
import {useState} from "react";
import {tablePaginationConfig} from "src/app/services/api/const";
import {useColumnSearch} from "src/pages/higher_education/tables/useColumnSearch";
import {DownloadButton} from "src/components/downloadButton";
import {useGetGenderQuery} from "src/app/services/private";

export function TableAllSpecialitiesForBachelor() {
    const {t} = useTranslation();

    const getColumnSearchProps = useColumnSearch();
    const [gender, setGender] = useState<string>();
    const {data: bachelor, isFetching} = useGetSpecialitiesEduFormQuery({
        gender: gender,
        eduTypeCode: 11,
    });

    const {data: genderList} = useGetGenderQuery();

    const render = (value: number) => value || 0;

    const columns: ColumnsType<ISpecialitiesEduForm> = [
        {
            title: t("tables.direction_code"),
            dataIndex: "specialityCode",
        },
        {
            //@ts-ignore
            title: t("tables.edu_direction"),
            dataIndex: "speciality",
            ...getColumnSearchProps("speciality"),
            render,
        },
        {
            title: t("edu_form.daytime"),
            dataIndex: "daytimeCount",
            align: "center",
            render,
        },
        {
            title: t("edu_form.evening"),
            dataIndex: "eveningCount",
            align: "center",
            render,
        },
        {
            title: t("edu_form.externally"),
            dataIndex: "externalCount",
            align: "center",
            render,
        },
        {
            title: t("edu_form.joint"),
            dataIndex: "jointCount",
            align: "center",
            render,
        },
        {
            title: t("edu_form.remote"),
            dataIndex: "remoteCount",
            align: "center",
            render,
        },
        {
            title: t("edu_form.second_daytime"),
            dataIndex: "secondDaytimeCount",
            align: "center",
            render,
        },
        {
            title: t("edu_form.second_evening"),
            dataIndex: "secondEveningCount",
            align: "center",
            render,
        },
        {
            title: t("edu_form.second_external"),
            dataIndex: "secondExternalCount",
            align: "center",
            render,
        },
        {
            title: t("tables.total"),
            dataIndex: "total",
            align: "center",
            render: (total) => <b>{total}</b>,
        },
    ];

    return (
        <div className="doctoralOrganization">
            <Table
                title={() => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h3>{t("tables.count_bachelor_direction")}</h3>

                        <Space>
                            <Select
                                style={{width: 230}}
                                placeholder="Jinsi bo'yicha"
                                onChange={setGender}
                                defaultValue="all"
                                options={
                                    genderList && [
                                        {label: "JAMI", value: "all"},
                                        ...genderList?.map((item) => ({label: item.name, value: item.code})),
                                    ]
                                }
                            />

                            <DownloadButton
                                downloadUrl="/excel/speciality/bachelor"
                                params={{
                                    gender: gender && gender,
                                }}
                            />
                        </Space>
                    </div>
                )}
                rowKey="speciality"
                dataSource={bachelor}
                columns={columns}
                pagination={tablePaginationConfig}
                size="small"
                scroll={{x: true}}
                loading={isFetching}
            />
        </div>
    );
}
