import { api } from "../api";
import {
  IPaymentTypeByTotal,
  IStudent,
  IStudentCitizenRes,
  IStudentCountByCourse,
  IStudentCountByEduForm,
  IStudentCountByEduType,
  IStudentCountByOwnership,
  IStudentCountByPaymentType,
  IStudentRegion,
  ITeacherCuntByAcademicDegree,
  ITeachersCuntByAcademicRank,
} from "./type";
import { ITotalByType } from "src/pages/type";

export const studentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    /////////////// Edu form ///////////////
    //with gender endpoint
    getStudentsEduFormByGenderStat: build.query<IStudent[], void>({
      query: () => ({
        url: "student/statistic/eduForm/educationFormAndGender",
      }),
    }),
    //with age endpoint
    getStudentsEduFormByAgeStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/eduForm/educationFormAndAge" }),
    }),
    //with citizenship endpoint
    getStudentsEduFormByCitizenshipStat: build.query<IStudent[], void>({
      query: () => ({
        url: "student/statistic/eduForm/educationFormAndCitizenship",
      }),
    }),
    //with payment endpoint
    getStudentsEduFormByPaymentStat: build.query<IStudent[], void>({
      query: () => ({
        url: "student/statistic/eduForm/educationFormAndPaymentForm",
      }),
    }),
    //with course endpoint
    getStudentsEduFormByCourseStat: build.query<IStudent[], void>({
      query: () => ({
        url: "student/statistic/eduForm/educationFormAndCourse",
      }),
    }),
    //with place endpoint
    getStudentsEduFormByPlaceStat: build.query<IStudent[], void>({
      query: () => ({
        url: "student/statistic/eduForm/educationFormAndAccommodation",
      }),
    }),

    /////////////// Course ///////////////
    //with gender endpoint
    getStudentsCourseByGenderStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/course/courseAndGender" }),
    }),
    //with age endpoint
    getStudentsCourseByAgeStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/course/courseAndAge" }),
    }),
    //with place endpoint
    getStudentsCourseByPlaceStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/course/courseAndAccomodation" }),
    }),

    /////////////// Age ///////////////
    //with gender endpoint
    getStudentsAgeByGenderStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/age/ageAndGender" }),
    }),
    //with place endpoint
    getStudentsAgeByPlaceStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/age/ageAndAccomodation" }),
    }),

    /////////////// Place ///////////////
    //with gender endpoint
    getStudentsPlaceByGenderStat: build.query<IStudent[], void>({
      query: () => ({
        url: "student/statistic/accommodation/accommodationAndGender",
      }),
    }),

    /////////////// Region ///////////////
    //with gender endpoint
    getStudentsRegionByGenderStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/region/regionAndGender" }),
    }),
    //with edu type endpoint
    getStudentsRegionByEduTypeStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/region/regionAndEduType" }),
    }),
    //with age endpoint
    getStudentsRegionByAgeStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/region/regionAndAge" }),
    }),
    //with payment endpoint
    getStudentsRegionByPaymentStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/region/regionAndPaymentType" }),
    }),
    //with course endpoint
    getStudentsRegionByCourseStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/region/regionAndCourse" }),
    }),
    //with citizenship endpoint
    getStudentsRegionByCitizenshipStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/region/regionAndCitizenship" }),
    }),
    //with place endpoint
    getStudentsRegionByPlaceStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/region/regionAndAccommodation" }),
    }),
    //with edu form endpoint
    getStudentsRegionByEduFormStat: build.query<IStudent[], void>({
      query: () => ({ url: "student/statistic/region/regionAndEduForm" }),
    }),

    ////////////////////////////  Edu Type   ////////////////////////////////////
    //Get edu type and gender endpoint
    getEduTypeAndAge: build.query<ITotalByType[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/eduType/eduTypeAndAge" + params,
      }),
    }),
    //Get edu type and age endpoint
    getEduTypeAndGender: build.query<ITotalByType[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/eduType/eduTypeAndGender" + params,
      }),
    }),
    //Get edu type and  payment type endpoint
    getEduTypeAndPaymentType: build.query<ITotalByType[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/eduType/eduTypeAndPaymentType" + params,
      }),
    }),
    //Get edu type and course endpoint
    getEduTypeByCourse: build.query<ITotalByType[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/eduType/eduTypeAndCourse" + params,
      }),
    }),
    //Get type and citizenship endpoint
    getTypeAndCitizenship: build.query<ITotalByType[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/eduType/eduTypeAndCitizenship" + params,
      }),
    }),
    //Get total type and  accommodation endpoint
    getTypeAndAccommodation: build.query<ITotalByType[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/eduType/eduTypeAndAccommodation" + params,
      }),
    }),
    //Get eudType and eduForm  endpoint
    getEduTypeAndEduForm: build.query<ITotalByType[], void>({
      query: () => ({
        url: "student/statistic/eduType/eduTypeAndEduForm",
      }),
    }),
    /////////////////////////////  Payment Form  ///////////////////////////////////
    //Get payment type and gender endpoint
    getPaymentTypeAndGender: build.query<IPaymentTypeByTotal[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/paymentType/paymentTypeAndGender" + params,
      }),
    }),
    //Get payment type and age endpoint
    getPaymentTypeAndAge: build.query<IPaymentTypeByTotal[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/paymentType/paymentTypeAndAge" + params,
      }),
    }),
    //Get payment type and citizenship endpoint
    getPaymentTypeAndCitizenship: build.query<IPaymentTypeByTotal[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/paymentType/paymentTypeAndCitizenship" + params,
      }),
    }),
    //Get payment type and course endpoint
    getPaymentTypeAndCourse: build.query<IPaymentTypeByTotal[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/paymentType/paymentTypeAndCourse" + params,
      }),
    }),
    //Get payment type and accommodtion endpoint
    getPaymentTypeAndAccommodation: build.query<IPaymentTypeByTotal[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/paymentType/paymentTypeAndAccommodation" + params,
      }),
    }),

    ////////////////////////// CitizenShip Api  //////////////////////////////////////

    //Get citizenship and gender endpoint
    getCitizenshipAndGender: build.query<IStudentCitizenRes[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/citizenship/citizenshipAndGender" + params,
      }),
    }),
    //Get citizenship and Age endpoint
    getCitizenshipAndAge: build.query<IStudentCitizenRes[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/citizenship/citizenshipAndAge" + params,
      }),
    }),
    //Get citizenship and course endpoint
    getCitizenshipAndCourse: build.query<IStudentCitizenRes[], string | void>({
      query: (params = "") => ({
        url: "student/statistic/citizenship/citizenshipAndCourse" + params,
      }),
    }),

    //Common statistics apis
    // Get common university ownership
    getCommonUniversityOwnership: build.query<IStudent[], void>({
      query: () => ({
        url: "statistic/common/university/ownership",
      }),
    }),
    // Get common university universityType
    getCommonUniversityUniversityType: build.query<IStudent[], void>({
      query: () => ({
        url: "statistic/common/university/universityType",
      }),
    }),
    // Get common university address
    getCommonUniversityAddress: build.query<IStudentRegion[], void>({
      query: () => ({
        url: "statistic/common/student/universityAddress",
      }),
    }),
    // Get common student address
    getCommonStudentAddress: build.query<IStudentRegion[], void>({
      query: () => ({
        url: "statistic/common/student/studentAddress",
      }),
    }),
    // Get common students top 5 university
    getStudentsTopFiveOtm: build.query<IStudent[], void>({
      query: () => ({
        url: "statistic/common/student/topFiveUniversity",
      }),
    }),
    // Get common students by ownership
    getStudentsByOwnership: build.query<IStudent[], void>({
      query: () => ({
        url: "statistic/common/student/ownership",
      }),
    }),
    getUniversitiesByRegion: build.query<IStudent[], void>({
      query: () => ({
        url: "statistic/common/university/region",
      }),
    }),
    // Get common students count by gender and ownership
    getStudentsCountByGenderAndOwnership: build.query<IStudentCountByOwnership[], void>({
      query: () => ({
        url: "statistic/common/student/ownershipAndGender",
      }),
    }),
    // Get common students count by EduType
    getStudentsCountByEduType: build.query<IStudentCountByEduType[], void>({
      query: () => ({
        url: "statistic/common/student/ownershipAndEduType",
      }),
    }),
    // Get common students count by Course
    getStudentsCountByCourse: build.query<IStudentCountByCourse[], void>({
      query: () => ({
        url: "statistic/common/student/ownershipAndCourse",
      }),
    }),
    // Get common students count by PaymentType
    getStudentsCountByPaymentType: build.query<IStudentCountByPaymentType[], void>({
      query: () => ({
        url: "statistic/common/student/ownershipAndPaymentType",
      }),
    }),
    // Get common students count by EduForm
    getStudentsCountByEduTypeAndOwnership: build.query<IStudentCountByEduForm[], void>({
      query: () => ({
        url: "statistic/common/student/ownershipAndEduForm",
      }),
    }),
    // Get common Teachers count by gender
    getTeachersCountByGender: build.query<IStudentCountByOwnership[], void>({
      query: () => ({
        url: "statistic/common/teacher/ownershipAndGender",
      }),
    }),
    // Get common Teachers count by Academic Rank
    getTeachersCountByAcademicRank: build.query<ITeachersCuntByAcademicRank[], void>({
      query: () => ({
        url: "statistic/common/teacher/ownershipAndAcademicRank",
      }),
    }),
    // Get common Teachers count by Academic Degree
    getTeachersCountByAcademicDegree: build.query<ITeacherCuntByAcademicDegree[], void>({
      query: () => ({
        url: "statistic/common/teacher/ownershipAndAcademicDegree",
      }),
    }),
  }),
});

export const {
  // Edu form
  useGetStudentsEduFormByGenderStatQuery,
  useGetStudentsEduFormByAgeStatQuery,
  useGetStudentsEduFormByCitizenshipStatQuery,
  useGetStudentsEduFormByPaymentStatQuery,
  useGetStudentsEduFormByCourseStatQuery,
  useGetStudentsEduFormByPlaceStatQuery,
  // Course
  useGetStudentsCourseByGenderStatQuery,
  useGetStudentsCourseByAgeStatQuery,
  useGetStudentsCourseByPlaceStatQuery,
  // Age
  useGetStudentsAgeByGenderStatQuery,
  useGetStudentsAgeByPlaceStatQuery,
  // Place
  useGetStudentsPlaceByGenderStatQuery,
  // Region
  useGetStudentsRegionByGenderStatQuery,
  useGetStudentsRegionByEduTypeStatQuery,
  useGetStudentsRegionByAgeStatQuery,
  useGetStudentsRegionByPaymentStatQuery,
  useGetStudentsRegionByCourseStatQuery,
  useGetStudentsRegionByCitizenshipStatQuery,
  useGetStudentsRegionByPlaceStatQuery,
  useGetStudentsRegionByEduFormStatQuery,
  //EduType
  useGetEduTypeAndGenderQuery,
  useGetEduTypeAndAgeQuery,
  useGetEduTypeAndPaymentTypeQuery,
  useGetEduTypeByCourseQuery,
  useGetTypeAndCitizenshipQuery,
  useGetTypeAndAccommodationQuery,
  useGetEduTypeAndEduFormQuery,
  //Payment Form
  useGetPaymentTypeAndGenderQuery,
  useGetPaymentTypeAndAgeQuery,
  useGetPaymentTypeAndCitizenshipQuery,
  useGetPaymentTypeAndCourseQuery,
  useGetPaymentTypeAndAccommodationQuery,
  // Citizenship API
  useGetCitizenshipAndGenderQuery,
  useGetCitizenshipAndAgeQuery,
  useGetCitizenshipAndCourseQuery,

  // common statistics api
  useGetCommonUniversityOwnershipQuery,
  useGetCommonUniversityUniversityTypeQuery,
  useGetCommonUniversityAddressQuery,
  useGetCommonStudentAddressQuery,
  useGetStudentsTopFiveOtmQuery,
  useGetStudentsByOwnershipQuery,
  useGetUniversitiesByRegionQuery,
  useGetStudentsCountByGenderAndOwnershipQuery,
  useGetStudentsCountByEduTypeQuery,
  useGetStudentsCountByCourseQuery,
  useGetStudentsCountByPaymentTypeQuery,
  useGetStudentsCountByEduTypeAndOwnershipQuery,
  useGetTeachersCountByGenderQuery,
  useGetTeachersCountByAcademicRankQuery,
  useGetTeachersCountByAcademicDegreeQuery,
} = studentsApi;
