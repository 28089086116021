import { IHemisRes } from "src/app/services/teacher/type";

export const findOne = (data: IHemisRes[] | undefined, name: string | null) => {
  if (data) {
    let val = data.find((el) => el.name === name)?.count;
    return val;
  }
};

export const findList = (data: IHemisRes[] | undefined) => {
  if (data) {
    let arr: number[] = [];
    data.forEach((item) => {
      arr.push(item.count);
    });
    return arr;
  }
};
