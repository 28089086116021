import { Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  ages,
  citizenships,
  courses,
  eduTypeGenderColumns,
  educationForms,
  educationTypes,
  paymentForms,
  placePaymentColumns,
  residencePlaces,
  educationTypes1,
  educationFormsMaster,
} from "src/pages/constants";
import { IPlacePayment, IEduTypeGender } from "src/pages/type";
import EduTypeCard from "./components/EduTypeCard";
import "./edutype.scss";
import useEduType from "./useEduType";
import { filterSeriesData } from "src/pages/higher_education/students/util";

const calcTotal = (type: string, array?: { count: number; eduType: string }[]) => {
  if (!array || array.length === 0) return "";

  const num = array
    .filter((el) => el.eduType === type)
    .reduce((acc, next) => {
      return acc + next.count;
    }, 0);

  return new Intl.NumberFormat("ru").format(num);
};

function EduTypePage() {
  const { t } = useTranslation();
  const { colors, viewOption } = useTypedSelector((state) => state.layout);
  const [placePaymentData, setPlacePaymentData] = useState<IPlacePayment[]>();
  const [eduTypeGenderData, setEduTypeGenderData] = useState<IEduTypeGender[]>();

  const {
    agesData,
    citizenshipData,
    paymentData,
    placesData,
    coursesData,
    eduFormData,
    eduTypeData,
    courses: coursesRes,
    eduForm: eduFormRes,
  } = useEduType();

  const makeData = () => {
    let arr_place_patment: IPlacePayment[] = [];
    educationForms.forEach((item) => {
      arr_place_patment.push({
        name: item,
        own_grant: 12345678,
        own_contract: 12345678,
        relative_grant: 12345678,
        relative_contract: 12345678,
        familiar_grant: 12345678,
        familiar_contract: 12345678,
        dormitory_grant: 12345678,
        dormitory_contract: 12345678,
      });
    });
    setPlacePaymentData(arr_place_patment);

    let arr_edutype_gender: IEduTypeGender[] = [];
    ["Erkaklar", "Ayollar"].forEach((item) => {
      arr_edutype_gender.push({
        name: item,
        kunduzgi: 12345678,
        kechki: 12345678,
        sirtqi: 12345678,
        maxsus_sirtqi: 12345678,
        qoshma: 12345678,
        masofaviy: 12345678,
      });
    });
    setEduTypeGenderData(arr_edutype_gender);
  };

  useEffect(() => {
    makeData();
  }, []);

  return (
    <div className="edutype">
      {viewOption === "table" ? (
        <>
          <div className="table-card">
            {/* Place of residence */}
            <Table
              dataSource={placePaymentData}
              columns={placePaymentColumns}
              bordered
              pagination={false}
            />
          </div>
          <div className="table-card">
            {/* Place of residence */}
            <Table
              dataSource={eduTypeGenderData}
              columns={eduTypeGenderColumns}
              bordered
              pagination={false}
            />
          </div>
        </>
      ) : (
        <>
          <div className="edutype-types">
            {eduTypeData?.map((item) => {
              return <EduTypeCard {...item} key={item.type} />;
            })}
          </div>
          <div className="graph-row-flex">
            {/* Age */}
            <GraphInfoCard
              title={t("header.age")}
              stat={[
                {
                  name: t("ages.under_30"),
                  count: agesData?.less_total,
                },
                {
                  name: t("ages.over_30"),
                  count: agesData?.greater_total,
                },
              ]}
            >
              <BarChart
                legendData={ages}
                xAxisData={educationTypes}
                color={["#7D7AFF", "#30DB5B"]}
                seriesArr={[
                  {
                    name: t("ages.under_30"),
                    data: agesData?.less_data,
                    stack: "one",
                  },
                  {
                    name: t("ages.over_30"),
                    data: agesData?.greater_data,
                    stack: "one",
                  },
                ]}
                xAxisRotate={8}
              />
            </GraphInfoCard>

            {/* Paymeny type */}
            <GraphInfoCard
              title={t("header.payment_form")}
              stat={[
                {
                  name: t("pay_form.grant"),
                  count: paymentData?.grant_total,
                },
                {
                  name: t("pay_form.contract"),
                  count: paymentData?.contract_total,
                },
              ]}
            >
              <BarChart
                legendData={paymentForms}
                xAxisData={educationTypes}
                color={["#DA8FFF", "#FF6482"]}
                seriesArr={[
                  {
                    name: t("pay_form.grant"),
                    data: paymentData?.grant_data,
                    stack: "one",
                  },
                  {
                    name: t("pay_form.contract"),
                    data: paymentData?.contract_data,
                    stack: "one",
                  },
                ]}
                xAxisRotate={8}
              />
            </GraphInfoCard>
          </div>

          <div className="graph-row">
            {/* Courses */}
            <GraphInfoCard
              title={t("header.courses")}
              stat={[
                {
                  name: t("courses.course_1"),
                  count: coursesData?.course_1_total,
                },
                {
                  name: t("courses.course_2"),
                  count: coursesData?.course_2_total,
                },
                {
                  name: t("courses.course_3"),
                  count: coursesData?.course_3_total,
                },
                {
                  name: t("courses.course_4"),
                  count: coursesData?.course_4_total,
                },
                {
                  name: t("courses.course_5"),
                  count: coursesData?.course_5_total,
                },
                {
                  name: t("courses.course_6"),
                  count: coursesData?.course_6_total,
                },
              ]}
            >
              <BarChart
                legendData={courses}
                xAxisData={educationTypes}
                color={[
                  colors.blue,
                  colors.pink,
                  colors.yellow,
                  colors.light_green,
                  colors.light_blue,
                  colors.orange,
                ]}
                seriesArr={[
                  {
                    name: t("courses.course_1"),
                    data: coursesData?.course_1_data,
                    stack: "one",
                  },
                  {
                    name: t("courses.course_2"),
                    data: coursesData?.course_2_data,
                    stack: "one",
                  },
                  {
                    name: t("courses.course_3"),
                    data: coursesData?.course_3_data,
                    stack: "one",
                  },
                  {
                    name: t("courses.course_4"),
                    data: coursesData?.course_4_data,
                    stack: "one",
                  },
                  {
                    name: t("courses.course_5"),
                    data: coursesData?.course_5_data,
                    stack: "one",
                  },
                  {
                    name: t("courses.course_6"),
                    data: coursesData?.course_6_data,
                    stack: "one",
                  },
                ]}
              />
            </GraphInfoCard>
          </div>
          <div className="graph-row">
            {/* Courses bachelor */}
            <GraphInfoCard
              title={`${t("header.courses")} (${t("edu_type.bachelor")}) ${calcTotal(
                "Bakalavr",
                coursesRes,
              )}`}
            >
              <BarChart
                legendData={[]}
                xAxisData={courses}
                color={[colors.light_green]}
                seriesArr={[
                  {
                    name: t("header.courses"),
                    data: coursesRes
                      ?.filter((el) => el.eduType === "Bakalavr")
                      .map((el) => el.count),
                  },
                ]}
              />
            </GraphInfoCard>
          </div>
          <div className="graph-row">
            {/* Courses master */}
            <GraphInfoCard
              title={`${t("header.courses")} (${t("edu_type.master")}) ${calcTotal(
                "Magistr",
                coursesRes,
              )}`}
            >
              <BarChart
                legendData={[]}
                xAxisData={courses.slice(0, 3)}
                color={[colors.light_blue]}
                seriesArr={[
                  {
                    name: t("header.courses"),
                    data: coursesRes
                      ?.filter((el) => el.eduType === "Magistr")
                      .map((el) => el.count),
                  },
                ]}
              />
            </GraphInfoCard>
          </div>

          <div className="graph-row">
            {/* Citizenships */}
            <GraphInfoCard
              title={t("header.citizenship")}
              stat={[
                {
                  name: t("citizenships.uzbek"),
                  count: citizenshipData?.uzbek_total,
                },
                {
                  name: t("citizenships.foreigner"),
                  count: citizenshipData?.foreigner_total,
                },
                {
                  name: t("citizenships.stateless"),
                  count: citizenshipData?.stateless_total,
                },
              ]}
            >
              <BarChart
                legendData={citizenships}
                xAxisData={educationTypes}
                color={["#FFD426"]}
                seriesArr={[
                  {
                    name: t("citizenships.uzbek"),
                    data: citizenshipData?.uzbek_data,
                    stack: "one",
                  },
                  {
                    name: t("citizenships.foreigner"),
                    data: citizenshipData?.foreigner_data,
                    stack: "one",
                  },
                  {
                    name: t("citizenships.stateless"),
                    data: citizenshipData?.stateless_data,
                    stack: "one",
                  },
                ]}
              />
            </GraphInfoCard>
          </div>

          <div className="graph-row">
            {/* Place of residence */}
            <GraphInfoCard
              title={t("header.residence_place")}
              stat={[
                {
                  name: t("residence_places.own_house"),
                  count: placesData?.own_house_total,
                },
                {
                  name: t("residence_places.dormitory"),
                  count: placesData?.dormitory_total,
                },
                {
                  name: t("residence_places.rented_house"),
                  count: placesData?.rented_house_total,
                },
                {
                  name: t("residence_places.relative_house"),
                  count: placesData?.relative_house_total,
                },
                {
                  name: t("residence_places.familiar_house"),
                  count: placesData?.familiar_house_total,
                },
              ]}
              graphHeight={396}
            >
              <BarChartCategory
                legendData={residencePlaces}
                yAxisData={educationTypes1}
                color={[
                  colors.pink,
                  colors.blue,
                  colors.orange,
                  colors.deep_purple,
                  colors.light_blue,
                ]}
                seriesArr={[
                  {
                    name: t("residence_places.own_house"),
                    data: placesData?.own_house_data,
                    stack: "one",
                  },
                  {
                    name: t("residence_places.dormitory"),
                    data: placesData?.dormitory_data,
                    stack: "one",
                  },
                  {
                    name: t("residence_places.rented_house"),
                    data: placesData?.rented_house_data,
                    stack: "one",
                  },
                  {
                    name: t("residence_places.relative_house"),
                    data: placesData?.relative_house_data,
                    stack: "one",
                  },
                  {
                    name: t("residence_places.familiar_house"),
                    data: placesData?.familiar_house_data,
                    stack: "one",
                  },
                ]}
                gridLeft={-70}
              />
            </GraphInfoCard>
          </div>

          <div className="graph-row">
            {/* Education form */}
            <GraphInfoCard
              title={t("header.edu_form")}
              stat={[
                {
                  name: t("edu_form.daytime"),
                  count: eduFormData?.daytime_total,
                },
                {
                  name: t("edu_form.evening"),
                  count: eduFormData?.evening_total,
                },
                {
                  name: t("edu_form.externally"),
                  count: eduFormData?.externally_total,
                },
                {
                  name: t("edu_form.special_externally"),
                  count: eduFormData?.second_external_total,
                },
                {
                  name: t("edu_form.joint"),
                  count: eduFormData?.joint_total,
                },
                {
                  name: t("edu_form.remote"),
                  count: eduFormData?.remote_total,
                },
                {
                  name: t("edu_form.second_evening"),
                  count: eduFormData?.second_evening_total,
                },
                {
                  name: t("edu_form.second_daytime"),
                  count: eduFormData?.second_daytime_total,
                },
                {
                  name: t("edu_form.second_external"),
                  count: eduFormData?.second_external_total,
                },
              ]}
            >
              <BarChart
                legendData={educationForms}
                xAxisData={educationTypes}
                color={[
                  colors.pink,
                  colors.blue,
                  colors.orange,
                  colors.deep_purple,
                  colors.light_blue,
                  colors.green,
                  colors.yellow,
                  colors.light_green,
                  colors.black,
                  colors.green,
                ]}
                seriesArr={[
                  {
                    name: t("edu_form.daytime"),
                    data: eduFormData?.daytime_data,
                    stack: "one",
                  },
                  {
                    name: t("edu_form.evening"),
                    data: eduFormData?.evening_data,
                    stack: "one",
                  },
                  {
                    name: t("edu_form.externally"),
                    data: eduFormData?.externally_data,
                    stack: "one",
                  },
                  {
                    name: t("edu_form.special_externally"),
                    data: eduFormData?.special_externally_data,
                    stack: "one",
                  },
                  {
                    name: t("edu_form.joint"),
                    data: eduFormData?.joint_data,
                    stack: "one",
                  },
                  {
                    name: t("edu_form.remote"),
                    data: eduFormData?.remote_data,
                    stack: "one",
                  },
                  {
                    name: t("edu_form.second_evening"),
                    data: eduFormData?.second_evening_data,
                    stack: "one",
                  },
                  {
                    name: t("edu_form.second_daytime"),
                    data: eduFormData?.second_daytime_data,
                    stack: "one",
                  },
                  {
                    name: t("edu_form.second_external"),
                    data: eduFormData?.second_external_data,
                    stack: "one",
                  },
                ]}
              />
            </GraphInfoCard>
          </div>

          <div className="graph-row">
            {/* Education form bachelor */}
            <GraphInfoCard
              graphHeight={460}
              title={`${t("header.edu_form")} (${t("edu_type.bachelor")}) ${calcTotal(
                "Bakalavr",
                eduFormRes,
              )}`}
            >
              <BarChart
                legendData={[]}
                xAxisData={educationForms}
                color={[colors.orange]}
                seriesArr={[
                  {
                    name: t("header.edu_form"),
                    data: filterSeriesData("Bakalavr", eduFormRes, true),
                  },
                ]}
                xAxisRotate={15}
              />
            </GraphInfoCard>
          </div>
          <div className="graph-row">
            {/* Education form master */}
            <GraphInfoCard
              graphHeight={460}
              title={`${t("header.edu_form")} (${t("edu_type.master")}) ${calcTotal(
                "Magistr",
                eduFormRes,
              )}`}
            >
              <BarChart
                legendData={[]}
                xAxisData={educationFormsMaster}
                color={[colors.green]}
                seriesArr={[
                  {
                    name: t("header.edu_form"),
                    data: filterSeriesData("Magistr", eduFormRes),
                  },
                ]}
                xAxisRotate={15}
              />
            </GraphInfoCard>
          </div>
        </>
      )}
      <div className="text_info">
        <p>{t("header.students_info_text")}</p>
      </div>
    </div>
  );
}

export default EduTypePage;
