import { Table, TableProps, Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "src/assets/svg";
import { useTypedSelector } from "src/app/store";
import { getExcel } from "src/app/services/public";
import { useState } from "react";
import { ISelect } from "src/app/services/prof_education/type";

interface Props extends Omit<TableProps<any>, "title"> {
  columns: any[];
  dataSource: any[] | undefined;
  title: string;
  downloadUrl?: string;
  excludedColumns?: string[];
  select_list?: ISelect[];
  setSelect?: any;
  select?: string;
}

const defaultExcludedColumns = ["univerCode", "univerName", "ownerShip"];

export const CustomTable = ({
  columns,
  dataSource,
  title,
  downloadUrl,
  excludedColumns = defaultExcludedColumns,
  select_list,
  setSelect,
  select,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [loading, setLoading] = useState(false);
  const downloadExcelFile = async () => {
    setLoading(true);
    await getExcel(downloadUrl);
    setLoading(false);
  };

  const handleSelectChange = (value: string) => {
    setSelect(value);
  };

  return (
    <Table
      title={() => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>{t(title)}</h3>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {select_list && (
              <Select
                style={{ width: 200 }}
                onChange={handleSelectChange}
                value={select}
                options={select_list}
              />
            )}
            {downloadUrl && (
              <Button className="download_btn" onClick={downloadExcelFile} loading={loading}>
                {t("header.download")} <DownloadIcon color={colors.primary} />
              </Button>
            )}
          </div>
        </div>
      )}
      dataSource={dataSource}
      columns={columns}
      summary={(pageData) => {
        if (pageData.length === 0) return null;

        const totalCounts = columns.reduce((acc, { dataIndex, children }) => {
          if (excludedColumns.includes(dataIndex)) return acc;

          if (children) {
            const childrenCount = children.map((child: any) => {
              return pageData.reduce((acc, next) => {
                return acc + (next[child.dataIndex] || 0);
              }, 0);
            });

            return acc.concat(childrenCount);
          }

          const totalCount = pageData.reduce((acc, next) => {
            return acc + (next[dataIndex] || 0);
          }, 0);

          return [...acc, totalCount];
        }, []);

        return (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <b>{t("tables.total")}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              {totalCounts.map((count: number, index: number) => (
                <Table.Summary.Cell align="center" index={index + 3} key={index}>
                  <b>{count}</b>
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
      {...props}
    />
  );
};
