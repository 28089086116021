import { TableOrganizationByRegionTable } from "./components/TableOrganizationByRegionTable";
import { TableCountByOrgDirectionTable } from "./components/TableCountByOrgDirectionTable";
import { useSearchParams } from "react-router-dom";
import { TableBachelorCountByRegionTable } from "src/pages/higher_education/tables/tablesDirections/components/TableBachelorbyRegion";
import { TableMasterCountByRegionTable } from "src/pages/higher_education/tables/tablesDirections/components/TableMasterByRegion";
import { TableAllSpecialitiesForMaster } from "src/pages/higher_education/tables/tablesDirections/components/TableAllSpecialitiesForMaster";
import { TableAllSpecialitiesForBachelor } from "src/pages/higher_education/tables/tablesDirections/components/TableAllSpecialitiesForBachelor";
import { useTranslation } from "react-i18next";

function TablesByDirections() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const regionId = params.get("regionId");
  const eduType = params.get("eduType");
  const orgId = params.get("orgId");
  const id = params.get("id");

  return (
    <div className="doctoral">
      {!regionId && id === null && <TableBachelorCountByRegionTable />}
      {!regionId && id === "2" && <TableMasterCountByRegionTable />}
      {!regionId && id === "3" && <TableAllSpecialitiesForBachelor />}
      {!regionId && id === "4" && <TableAllSpecialitiesForMaster />}
      {!orgId && regionId && eduType && (
        <TableOrganizationByRegionTable regionId={regionId} eduType={parseInt(eduType)} />
      )}
      {orgId && eduType && (
        <TableCountByOrgDirectionTable orgId={orgId} eduType={parseInt(eduType)} />
      )}
      <div className="text_info" style={{ width: "100%" }}>
        <p>{t("header.students_info_text")}</p>
      </div>
    </div>
  );
}

export default TablesByDirections;
