import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useGetTablesPositionAndGenderQuery } from "src/app/services/tables";
import { ITablesPositionAndGender } from "src/app/services/tables/type";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { CustomTable } from "src/pages/higher_education/tables/tablesUniversities/components/CustomTable";
import { useMemo } from "react";
export function TablesPositionAndGender() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetTablesPositionAndGenderQuery();
  const getColumnSearchProps = useColumnSearch();

  const excludedColumns = ["universityCode", "universityName", "universityOwnership"];

  const dataSourceGenderAndPosition = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total:
        item.internshipCountF +
        item.internshipCountM +
        item.assistantCountF +
        item.assistantCountM +
        item.dotsentCountF +
        item.dotsentCountM +
        item.professorCountF +
        item.professorCountM +
        item.cafedraChiefCountF +
        item.cafedraChiefCountM +
        item.seniorTeacherCountM +
        item.seniorTeacherCountF,
    }));

    return newData;
  }, [data]);

  const columns: ColumnsType<ITablesPositionAndGender> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      ...getColumnSearchProps("universityName"),
      width: "300px",
    },
    {
      title:  t("tables.ownership"),
      dataIndex: "universityOwnership",
      width: "130px",
      align: "center",
    },
    {
      title: t("tables.intern_teacher"),
      children: [
        {
          title:t("tables.woman"),
          dataIndex: "internshipCountF",
          align: "center",
        },
        {
          title: t("tables.man"),
          dataIndex: "internshipCountM",
          align: "center",
        },
      ],
    },
    {
      title: t("tables.assistant"),
      children: [
        {
          title: t("tables.woman"),
          dataIndex: "assistantCountF",
          align: "center",
        },
        {
          title: t("tables.man"),
          dataIndex: "assistantCountM",
          align: "center",
        },
      ],
    },
    {
      title: t("tables.head_teacher"),
      children: [
        {
          title: t("tables.woman"),
          dataIndex: "seniorTeacherCountF",
          align: "center",
        },
        {
          title: t("tables.man"),
          dataIndex: "seniorTeacherCountM",
          align: "center",
        },
      ],
    },
    {
      title: t("tables.dotsent"),
      children: [
        {
          title: t("tables.woman"),
          dataIndex: "dotsentCountF",
          align: "center",
        },
        {
          title:t("tables.man"),
          dataIndex: "dotsentCountM",
          align: "center",
        },
      ],
    },
    {
      title: t("tables.professor"),
      children: [
        {
          title: t("tables.woman"),
          dataIndex: "professorCountF",
          align: "center",
        },
        {
          title: t("tables.man"),
          dataIndex: "professorCountM",
          align: "center",
        },
      ],
    },
    {
      title: t("tables.head_of_department"),
      children: [
        {
          title: t("tables.woman"),
          dataIndex: "cafedraChiefCountF",
          align: "center",
        },
        {
          title: t("tables.man"),
          dataIndex: "cafedraChiefCountM",
          align: "center",
        },
      ],
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>
      <CustomTable
        title="Professor-o'qituvchilar lavozim va jins bo'yicha"
        rowKey="universityCode"
        dataSource={dataSourceGenderAndPosition}
        columns={columns}
        pagination={false}
        size="small"
        loading={isLoading}
        excludedColumns={excludedColumns}
        scroll={{ x: true, y: 500 }}
        downloadUrl="teacher/positionAndGenderExcel"
      />
    </div>
  );
}
