import { ConfigProvider, Input, InputProps } from "antd";
import { useTypedSelector } from "src/app/store";

interface Props extends InputProps {
  controlHeight?: number;
}

function CustomInput({ controlHeight, ...rest }: Props) {
  const { colors } = useTypedSelector((state) => state.layout);

  return (
    <div className="radio-button">
      <ConfigProvider
        theme={{
          token: {
            colorBgBase: colors.bg_secondary,
            colorTextPlaceholder: colors.tertiary,
            fontSize: 16,
            controlHeight: controlHeight,
            paddingContentHorizontal: 20,
            borderRadius: 12,
            colorBorder: "transparent",
          },
        }}
      >
        <Input {...rest} />
      </ConfigProvider>
    </div>
  );
}

export default CustomInput;
