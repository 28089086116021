import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { ITablesGender } from "src/app/services/tables/type";
import { useEffect, useMemo, useState } from "react";
import { useGetTablesGenderMutation } from "src/app/services/tables";
import { renderEmptyValue } from "src/pages/higher_education/tables/utils";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { CustomTable } from "src/pages/higher_education/tables/tablesUniversities/components/CustomTable";

export function TablesUniversityByGenderTable() {
  const { t } = useTranslation();
  const [getFunc, { data, isLoading }] = useGetTablesGenderMutation();
  const getColumnSearchProps = useColumnSearch();
  const excludedColumns = ["universityCode", "universityName", "universityOwnership"];
  const [select, setSelect] = useState("all");

  useEffect(() => {
    getFunc(select);
  }, [select]);

  const payment_type_list = [
    {
      value: "all",
      label: t("tables.total"),
    },
    {
      value: "11",
      label: t("pay_form.grant"),
    },
    {
      value: "12",
      label: t("pay_form.contract"),
    },
  ];

  const dataSourceGender = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total: item.bachelorF + item.bachelorM + item.masterF + item.masterM,
    }));

    return newData;
  }, [data]);

  const columns: ColumnsType<ITablesGender> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      width: 400,
      ...getColumnSearchProps("univerName"),
    },
    {
      title: t("tables.ownership"),
      dataIndex: "universityOwnership",
    },
    {
      title: t("tables.bachelor"),
      children: [
        {
          title: t("tables.woman"),
          dataIndex: "bachelorF",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("tables.man"),
          dataIndex: "bachelorM",
          align: "center",
          render: renderEmptyValue,
        },
      ],
    },
    {
      title: t("tables.master"),
      children: [
        {
          title: t("tables.woman"),
          dataIndex: "masterF",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("tables.man"),
          dataIndex: "masterM",
          align: "center",
          render: renderEmptyValue,
        },
      ],
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>
      <CustomTable
        title={"tables.title_gender"}
        rowKey="universityCode"
        dataSource={dataSourceGender}
        columns={columns}
        excludedColumns={excludedColumns}
        pagination={false}
        size="small"
        scroll={{ x: true, y: 500 }}
        loading={isLoading}
        downloadUrl={`student/genderAndEduType?paymentType=${select}`}
        select_list={payment_type_list}
        setSelect={setSelect}
        select={select}
      />
    </div>
  );
}
