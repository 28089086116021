import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProfRegionStatMutation } from "src/app/services/prof_education";
import { useGetRegionsQuery } from "src/app/services/universities";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  citizenships,
  genders,
  paymentForms,
  profEducationForms,
  profEducationTypes,
  prof_ages,
  prof_courses,
  residencePlaces,
} from "src/pages/constants";
import { IStatRegion } from "src/pages/type";
import { giveLan } from "src/utils";
import { makeProfRegionData } from "../util";

export default function ProfRegionsSectionPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const { data } = useGetRegionsQuery();
  const [regions, setRegions] = useState<string[]>([]);

  const makeData = () => {
    if (data) {
      let arr: string[] = [];
      let arr_sorted = [...data];
      arr_sorted.sort((a, b) => a.name_uz.localeCompare(b.name_uz));
      arr_sorted.forEach((item) => {
        arr.push(giveLan(item));
      });
      console.log("arr", arr);
      setRegions(arr.reverse());
    }
  };

  useEffect(() => {
    makeData();
  }, [data]);

  //Fetch stat data section
  const [getFunc, { data: statData }] = useGetProfRegionStatMutation();
  const [stat, setStat] = useState<IStatRegion>();

  useEffect(() => {
    getFunc();
  }, []);

  useEffect(() => {
    if (statData) {
      setStat(makeProfRegionData(statData));
    }
  }, [statData]);


  return stat ? (
    <div className="eduform">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: stat.gender.men_total },
            { name: t("genders.women"), count: stat.gender.women_total },
          ]}
          graphHeight={720}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={regions}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: stat.gender.men_data,
              },
              {
                name: t("genders.women"),
                data: stat.gender.women_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-178}
            yAxisMargin={220}
          />
        </GraphInfoCard>

        {/* Education type */}
        <GraphInfoCard
          title={t("header.edu_type")}
          stat={[
            { name: t("edu_type.vocational_school"), count: stat.edu_type.khm_total },
            { name: t("edu_type.college"), count: stat.edu_type.college_total },
            { name: t("edu_type.technical_school"), count: stat.edu_type.technical_total },
          ]}
          graphHeight={720}
        >
          <BarChartCategory
            legendData={profEducationTypes}
            yAxisData={regions}
            color={[colors.light_blue, colors.deep_purple, colors.light_green]}
            seriesArr={[
              {
                name: t("edu_type.vocational_school"),
                data: stat.edu_type.khm_data,
              },
              {
                name: t("edu_type.college"),
                data: stat.edu_type.college_data,
              },
              {
                name: t("edu_type.technical_school"),
                data: stat.edu_type.technical_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-178}
            yAxisMargin={220}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            { name: t("ages.under_20"), count: stat.age.under_20_total },
            { name: t("ages.over_20"), count: stat.age.over_20_total },
          ]}
          graphHeight={336}
        >
          <BarChart
            legendData={prof_ages}
            xAxisData={regions}
            color={[colors.light_blue, colors.green]}
            seriesArr={[
              {
                name: t("ages.under_20"),
                data: stat.age.under_20_data,
                stack: "one",
              },
              {
                name: t("ages.over_20"),
                data: stat.age.over_20_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Paymeny type */}
        <GraphInfoCard
          title={t("header.payment_form")}
          stat={[
            { name: t("pay_form.grant"), count: stat.payment_form.grant_total },
            { name: t("pay_form.contract"), count: stat.payment_form.contract_total },
          ]}
          graphHeight={336}
        >
          <BarChart
            legendData={paymentForms}
            xAxisData={regions}
            color={[colors.orange, colors.blue]}
            seriesArr={[
              {
                name: t("pay_form.grant"),
                data: stat.payment_form.grant_data,
                stack: "one",
              },
              {
                name: t("pay_form.contract"),
                data: stat.payment_form.contract_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Courses */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            { name: t("courses.course_1"), count: stat.courses.course_1_total },
            { name: t("courses.course_2"), count: stat.courses.course_2_total },
            { name: t("courses.course_3"), count: stat.courses.course_3_total },
          ]}
          graphHeight={460}
        >
          <BarChart
            legendData={prof_courses}
            xAxisData={regions}
            color={[colors.blue, colors.pink, colors.yellow]}
            seriesArr={[
              {
                name: t("courses.course_1"),
                data: stat.courses.course_1_data,
                stack: "one",
              },
              {
                name: t("courses.course_2"),
                data: stat.courses.course_2_data,
                stack: "one",
              },
              {
                name: t("courses.course_3"),
                data: stat.courses.course_3_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Citizenships */}
        <GraphInfoCard
          title={t("header.citizenship")}
          stat={[
            { name: t("citizenships.uzbek"), count: stat.citizenship.uzbek_total },
            { name: t("citizenships.foreigner"), count: stat.citizenship.foreigner_total },
            { name: t("citizenships.stateless"), count: stat.citizenship.stateless_total },
            { name: t("citizenships.teen"), count: stat.citizenship.teen_total },
          ]}
          graphHeight={396}
        >
          <BarChart
            legendData={citizenships}
            xAxisData={regions}
            color={[colors.yellow, colors.light_green, colors.blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("citizenships.uzbek"),
                data: stat.citizenship.uzbek_data,
                stack: "one",
              },
              {
                name: t("citizenships.foreigner"),
                data: stat.citizenship.foreigner_data,
                stack: "one",
              },
              {
                name: t("citizenships.stateless"),
                data: stat.citizenship.stateless_data,
                stack: "one",
              },
              {
                name: t("citizenships.teen"),
                data: stat.citizenship.teen_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            { name: t("residence_places.own_house"), count: stat.residence_place.own_house_total },
            { name: t("residence_places.dormitory"), count: stat.residence_place.dormitory_total },
            {
              name: t("residence_places.rented_house"),
              count: stat.residence_place.rented_house_total,
            },
            {
              name: t("residence_places.relative_house"),
              count: stat.residence_place.relative_house_total,
            },
            {
              name: t("residence_places.familiar_house"),
              count: stat.residence_place.familiar_house_total,
            },
            { name: t("residence_places.other"), count: stat.residence_place.other_total },
          ]}
          graphHeight={649}
        >
          <BarChartCategory
            legendData={residencePlaces}
            yAxisData={regions}
            color={[
              colors.pink,
              colors.blue,
              colors.orange,
              colors.deep_purple,
              colors.light_blue,
              colors.yellow,
            ]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: stat.residence_place.own_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: stat.residence_place.dormitory_data,
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: stat.residence_place.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: stat.residence_place.relative_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: stat.residence_place.familiar_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.other"),
                data: stat.residence_place.other_data,
                stack: "one",
              },
            ]}
            barWidth={16}
            gridLeft={-178}
            yAxisMargin={215}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Education form */}
        <GraphInfoCard
          title={t("header.edu_form")}
          stat={[
            { name: t("edu_form.daytime"), count: stat.edu_form.daytime_total },
            { name: t("edu_form.evening"), count: stat.edu_form.evening_total },
            { name: t("edu_form.externally"), count: stat.edu_form.externally_total },
            { name: t("edu_form.dual"), count: stat.edu_form.dual_total },
          ]}
          graphHeight={460}
        >
          <BarChart
            legendData={profEducationForms}
            xAxisData={regions}
            color={[colors.pink, colors.blue, colors.orange, colors.deep_purple]}
            seriesArr={[
              {
                name: t("edu_form.daytime"),
                data: stat.edu_form.daytime_data,
                stack: "one",
              },
              {
                name: t("edu_form.evening"),
                data: stat.edu_form.evening_data,
                stack: "one",
              },
              {
                name: t("edu_form.externally"),
                data: stat.edu_form.externally_data,
                stack: "one",
              },
              {
                name: t("edu_form.dual"),
                data: stat.edu_form.dual_data,
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t("header.prof_info_text")}</p>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
