import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { ITablesEduForm } from "src/app/services/tables/type";
import { useEffect, useMemo, useState } from "react";
import { useGetTablesEduFormMutation } from "src/app/services/tables";
import { renderEmptyValue } from "src/pages/higher_education/tables/utils";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { CustomTable } from "src/pages/higher_education/tables/tablesUniversities/components/CustomTable";

export function TablesUniversityByEduFormTable() {
  const { t } = useTranslation();
  const [getFunc, { data, isLoading }] = useGetTablesEduFormMutation();
  const getColumnSearchProps = useColumnSearch();
  const [select, setSelect] = useState("all");

  useEffect(() => {
    getFunc(select);
  }, [select]);

  const payment_type_list = [
    {
      value: "all",
      label: t("tables.total"),
    },
    {
      value: "11",
      label: t("pay_form.grant"),
    },
    {
      value: "12",
      label: t("pay_form.contract"),
    },
  ];

  const excludedColumns = ["universityCode", "universityName", "universityOwnership"];

  const dataSourceEduForm = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total:
        item.bachelorDaytime +
        item.bachelorEvening +
        item.bachelorExternal +
        item.bachelorSpecialExternal +
        item.bachelorJoint +
        item.bachelorRemote +
        item.bachelorSecondDaytime +
        item.bachelorSecondExternal +
        item.masterDaytime +
        item.masterEvening +
        item.masterExternal +
        item.masterSpecialExternal +
        item.masterJoint +
        item.masterRemote,
    }));

    return newData;
  }, [data]);

  const columns: ColumnsType<ITablesEduForm> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      width: 200,
      ...getColumnSearchProps("universityName"),
    },
    {
      title: t("tables.ownership"),
      dataIndex: "universityOwnership",
      width: 80,
    },
    {
      title: t("tables.bachelor"),
      children: [
        {
          title: t("edu_form.daytime"),
          dataIndex: "bachelorDaytime",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.evening"),
          dataIndex: "bachelorEvening",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.externally"),
          dataIndex: "bachelorExternal",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.special_externally"),
          dataIndex: "bachelorSpecialExternal",
          align: "center",
          render: renderEmptyValue,
          width: 110,
        },
        {
          title: t("edu_form.joint"),
          dataIndex: "bachelorJoint",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.remote"),
          dataIndex: "bachelorRemote",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.second_daytime"),
          dataIndex: "bachelorSecondDaytime",
          align: "center",
          render: renderEmptyValue,
          width: 165,
        },
        {
          title: t("edu_form.second_evening"),
          dataIndex: "bachelorSecondEvening",
          align: "center",
          width: 150,
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.second_external"),
          dataIndex: "bachelorSecondExternal",
          align: "center",
          width: 150,
          render: renderEmptyValue,
        },
      ],
    },
    {
      title: t("tables.master"),
      children: [
        {
          title: t("edu_form.daytime"),
          dataIndex: "masterDaytime",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.evening"),
          dataIndex: "masterEvening",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.externally"),
          dataIndex: "masterExternal",
          align: "center",
          render: renderEmptyValue,
          width: 110,
        },
        {
          title: t("edu_form.special_externally"),
          dataIndex: "masterSpecialExternal",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.joint"),
          dataIndex: "masterJoint",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.remote"),
          dataIndex: "masterRemote",
          align: "center",
          render: renderEmptyValue,
        },
      ],
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>
      <CustomTable
        title="tables.eduForm"
        rowKey="universityCode"
        dataSource={dataSourceEduForm}
        columns={columns}
        pagination={false}
        excludedColumns={excludedColumns}
        size="small"
        scroll={{ x: 2000, y: 500 }}
        loading={isLoading}
        downloadUrl={`student/eduForm?paymentType=${select}`}
        select_list={payment_type_list}
        setSelect={setSelect}
        select={select}
      />
    </div>
  );
}
