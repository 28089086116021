import { ColumnsType } from "antd/es/table";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { useEffect, useState } from "react";
import { ConfigProvider, DatePicker, message, Select, Table } from "antd";
import {
  useGetCourseStatusQuery,
  useGetEduFormQuery,
  useGetEduTypeQuery,
  useGetOtmCodeAndNameQuery,
  useGetPaymentFormQuery,
  useGetStudentStatusTypeQuery,
  useUzAsboRentQuery,
} from "src/app/services/private";
import moment from "moment";
import { IUzasboReport } from "src/app/services/private/type";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { DownloadButton } from "src/components/downloadButton";
import { tablePaginationConfig } from "src/app/services/api/const";

export const uzAsboDateFormat = "YYYY/MM";

export default function UzasboRentStudent() {
  const { data: otmNameAndTin } = useGetOtmCodeAndNameQuery();
  const { data: studentStatusList } = useGetStudentStatusTypeQuery();
  const { data: paymentFormList } = useGetPaymentFormQuery();
  const { data: eudTypeList } = useGetEduTypeQuery();
  const { data: eudFormList } = useGetEduFormQuery();
  const { data: courseList } = useGetCourseStatusQuery();
  const getColumnSearchProps = useColumnSearch();
  const [university, setUniversity] = useState<string>();
  const [monthAndYear, setMonthAndYear] = useState<any>({});
  const [studentStatus, setStudentStatus] = useState<string>();
  const [paymentStatus, setPaymentStatus] = useState<string>();
  const [eduTypeStatus, setEduTypeStatus] = useState<string>();
  const [eduFormStatus, setEduFormStatus] = useState<string>();
  const [courseStatus, setCourseStatus] = useState<string>();

  const [inHemis, setExistInHemis] = useState<boolean>();
  const { isFetching, isError, error, currentData } = useUzAsboRentQuery(
    {
      existInHemis: inHemis ? inHemis : undefined,
      orgInn: university,
      courseCode: courseStatus,
      eduFormCode: eduFormStatus,
      eduTypeCode: eduTypeStatus,
      studentStatusCode: studentStatus,
      paymentFormCode: paymentStatus,
      ...monthAndYear,
    },
    {
      skip: !university || !monthAndYear.hasOwnProperty("month"),
    },
  );

  useEffect(() => {
    if (isError) {
      const err = error as FetchBaseQueryError;
      if (err?.status === 404) {
        message.error("Bu oydagi hisobot hali shakllanmagan.");
      }
    }
  }, [isError]);

  const getSoliqTypeColumns = (): ColumnsType<IUzasboReport> => {
    return [
      {
        title: "Manzil",
        dataIndex: "address",
        width: 160,
        align: "center",
      },
      {
        title: "Ro'yxatga olingan raqam",
        dataIndex: "registrationNumber",
        width: 200,
      },
      {
        title: "Boshlangan vaqt",
        dataIndex: "beginDate",
        width: 160,
        align: "center",
      },
      {
        title: "Tugagan vaqt",
        dataIndex: "endDate",
        width: 160,
        align: "center",
      },
      {
        title: "Bekor bo'lgan vaqt",
        dataIndex: "cancelDate",
        width: 160,
        align: "center",
      },
      {
        title: "Oylik ijara miqdori",
        dataIndex: "paymentMonthly",
        width: 300,
        align: "center",
      },
      {
        title: "FIO",
        dataIndex: "fullNameRent",
        width: 220,
        align: "center",
      },
      {
        title: "JSHSHIR",
        dataIndex: "pinflRent",
        width: 160,
        align: "center",
      },
      {
        title: "Holati",
        dataIndex: "statusRent",
        width: 160,
        align: "center",
      },
    ];
  };

  const getHemisTypeColumns = (): ColumnsType<any> => {
    return [
      {
        title: "JSHSHIR",
        dataIndex: "pinflHemis",
        width: 160,
        align: "center",
      },
      {
        title: "Yaratilgan sana",
        dataIndex: "createTs",
        width: 160,
        align: "center",
      },
      {
        title: "Tahrirlangan sana",
        dataIndex: "updateTs",
        width: 160,
        align: "center",
      },
      {
        title: "O'chirilgan sana",
        dataIndex: "deleteTs",
        width: 160,
        align: "center",
        render: (data) => <span>{data ? data : ""}</span>,
      },
      {
        title: "Kod",
        dataIndex: "studentHemisCode",
        width: 160,
        align: "center",
      },
      {
        //@ts-ignore
        title: "FIO",
        dataIndex: "fullNameHemis",
        ...getColumnSearchProps("fullNameHemis"),
        width: 300,
        align: "center",
      },
      {
        title: "OTM kod",
        dataIndex: "universityCode",
        width: 160,
        align: "center",
      },
      {
        title: "OTM stir",
        dataIndex: "universityTin",
        width: 160,
        align: "center",
      },
      {
        title: "OTM nomi",
        dataIndex: "universityName",
        width: 300,
        align: "center",
      },
      {
        title: "Kurs kod",
        dataIndex: "courseCode",
        width: 160,
        align: "center",
      },
      {
        title: "Kurs",
        dataIndex: "courseName",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim shakli kod",
        dataIndex: "eduFormCode",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim shakli",
        dataIndex: "eduFormName",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim turi kod",
        dataIndex: "eduTypeCode",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim turi",
        dataIndex: "eduTypeName",
        width: 160,
        align: "center",
      },
      {
        title: "To'lov shakli kod",
        dataIndex: "paymentFormCode",
        width: 160,
        align: "center",
      },
      {
        title: "To'lov shakli",
        dataIndex: "paymentFormName",
        width: 160,
        align: "center",
      },
      {
        title: "Talaba status kod",
        dataIndex: "studentStatusCode",
        width: 160,
        align: "center",
      },
      {
        title: "Talaba status",
        dataIndex: "studentStatusName",
        width: 160,
        align: "center",
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: "Hemis",
      dataIndex: "student_name",
      align: "center",
      children: getHemisTypeColumns(),
    },
    {
      title: "Soliq",
      dataIndex: "student_name",
      align: "center",
      children: getSoliqTypeColumns(),
    },
  ];

  const handlePaymentForm = (value: string) => {
    setPaymentStatus(value);
  };

  const handleEduType = (value: string) => {
    setEduTypeStatus(value);
  };

  const handleEduFrom = (value: string) => {
    setEduFormStatus(value);
  };
  const handleCourse = (value: string) => {
    setCourseStatus(value);
  };

  const handleDate = (value: any) => {
    setMonthAndYear({
      month: moment(value["$d"]).format("M"),
      year: moment(value["$d"]).format("YYYY"),
    });
  };

  function disabledDate(current: Date) {
    let customDate = moment().format("2022-09");
    return (
      (current && current < moment(customDate, "YYYY-MM")) ||
      current > moment(new Date(), "YYYY-MM")
    );
  }

  const handleChangeOtm = (value: string) => {
    setUniversity(value);
  };

  return (
    <div>
      <div className="uzasbo-search-container">
        <h2>
          Malumotlarni ko'rishingiz uchun eng kamida Universitet, va sanani tanlashingiz lozim
        </h2>
        <div className="filter-warapper">
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            style={{ width: 300 }}
            placeholder="OTM tanlang"
            onChange={handleChangeOtm}
            value={university}
            options={otmNameAndTin?.map((item) => ({ label: item.name, value: item.tin }))}
          />
          <DatePicker
            style={{ height: "36px", width: "230px" }}
            disabledDate={disabledDate}
            placeholder="Sanani tanlang"
            format={uzAsboDateFormat}
            picker="month"
            onChange={(e) => handleDate(e)}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talaba status"
            onChange={setStudentStatus}
            allowClear
            options={studentStatusList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="To'lov shakli"
            onChange={handlePaymentForm}
            allowClear
            options={paymentFormList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talim turi"
            onChange={handleEduType}
            allowClear
            options={eudTypeList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talim shakli"
            onChange={handleEduFrom}
            allowClear
            options={eudFormList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Kurslari"
            onChange={handleCourse}
            allowClear
            options={courseList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Hemis bo'yicha"
            onChange={setExistInHemis}
            allowClear
            options={[
              {
                label: "Jami",
                value: "",
              },
              {
                label: "Hemisda mavjud",
                value: "true",
              },
              {
                label: "Hemisdan mavjud emas",
                value: "false",
              },
            ]}
          />
        </div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowSelectedBg: "#e6f4ff",
              rowSelectedHoverBg: "#bae0ff",
            },
          },
        }}
      >
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Ijara va hemis malumotlari jamlanmasi</h3>

              <DownloadButton
                downloadUrl="/uzasbohemis/studentRentByFilter/excel"
                params={{
                  ...monthAndYear,
                  orgInn: university,
                  existInHemis: inHemis ? inHemis : undefined,
                  courseCode: courseStatus,
                  eduFormCode: eduFormStatus,
                  eduTypeCode: eduTypeStatus,
                  studentStatusCode: studentStatus,
                  paymentFormCode: paymentStatus,
                }}
              />
            </div>
          )}
          bordered
          rowKey={(val) => `${val.studentHemisCode}/${val.pinflHemis}/`}
          dataSource={currentData}
          columns={columns}
          pagination={tablePaginationConfig}
          rowSelection={{ type: "radio", columnWidth: 40 }}
          size="small"
          loading={isFetching}
          virtual={true}
          scroll={{ x: 3200, y: 600 }}
        />
      </ConfigProvider>
    </div>
  );
}
