import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { useEffect, useMemo, useState } from "react";
import { ISelect } from "src/app/services/prof_education/type";
import { Table, Select, Button } from "antd";
import "./index.scss";
import {
  useGetTablesGraduatedStudentsMutation,
  useGetTablesGraduatedYearQuery,
} from "src/app/services/tables";
import { ITablesGradutedStudents } from "src/app/services/tables/type";
import { DownloadIcon } from "src/assets/svg";
import { getExcel } from "src/app/services/public";
import { useTypedSelector } from "src/app/store";
export function TablesGraduatedStudents() {
  const { t } = useTranslation();
  const [getFunc, { data: admissionTc, isLoading: tcLoading }] =
    useGetTablesGraduatedStudentsMutation();
  const getColumnSearchProps = useColumnSearch();
  const { data } = useGetTablesGraduatedYearQuery();
  const [yearList, setYearList] = useState<ISelect[]>();
  const [valueYearSelect, setValueYearSelect] = useState<string>("2022");
  const [graduatedData, setGraduatedData] = useState<
    ITablesGradutedStudents[] | undefined
  >([]);
  const [loading, setLoading] = useState(false);
  const { colors } = useTypedSelector((state) => state.layout);

  useEffect(() => {
    getFunc({ graduationYearCode: valueYearSelect });
  }, [valueYearSelect]);

  useEffect(() => {
    setGraduatedData(admissionTc);
  }, [admissionTc]);

  useEffect(() => {
    const yearL = data?.map((year) => ({
      value: year.code,
      label: year.educationYear,
    }));
    if (yearL) {
      setYearList(yearL);
    }
  }, [data]);
  //

  const downloadExcelFile = async () => {
    setLoading(true);
    await getExcel(
      `graduatedStudent/bachelor/universityAndYear?graduationYearCode=${valueYearSelect}`,
    );
    setLoading(false);
  };

  const dataGraduated = useMemo(() => {
    const newData = graduatedData?.map((item) => ({
      ...item,
      total:
        item.pedagogyAndEducationCount +
        item.mathematicsCount +
        item.naturalSciencesCount +
        item.artCount +
        item.religiousStudiesCount +
        item.economicAndSocialCount +
        item.journalismAndInformationCount +
        item.lawCount +
        item.businessAndManagementCount +
        item.engineeringWorkCount +
        item.informationCommunicationTechnologiesCount +
        item.agricultureAndForestryCount +
        item.fisheryCount +
        item.veterinaryMedicineCount +
        item.healthCount +
        item.socialSecurity +
        item.serviceCount +
        item.transportCount +
        item.hygieneAndEnvironmentalProtectionCount +
        item.ensuringLegalOrderCount +
        item.productionAndProcessingCount +
        item.architectureAndConstructionCount +
        item.humanityCount +
        item.languagesCount +
        item.foreignUniversitySpecialtiesCount,
    }));

    return newData;
  }, [graduatedData]);

  const columns: ColumnsType<ITablesGradutedStudents> = [
    {
      //@ts-ignore
      title: t("tables.univerCode"),
      width: "150px",
      dataIndex: "universityCode",
      ...getColumnSearchProps("universityCode"),
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "university",
      ...getColumnSearchProps("university"),
      width: "300px",
    },
    {
      title: t("tables.ownership"),
      dataIndex: "ownership",
      width: "200px",
      align: "center",
    },
    {
      title: t("tables.pedagogyAndEducationCount"),
      dataIndex: "pedagogyAndEducationCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.mathematicsCount"),
      dataIndex: "mathematicsCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.naturalSciencesCount"),
      dataIndex: "naturalSciencesCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.artCount"),
      dataIndex: "artCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.religiousStudiesCount"),
      dataIndex: "religiousStudiesCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.economicAndSocialCount"),
      dataIndex: "economicAndSocialCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.journalismAndInformationCount"),
      dataIndex: "journalismAndInformationCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.lawCount"),
      dataIndex: "lawCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.businessAndManagementCount"),
      dataIndex: "businessAndManagementCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.engineeringWorkCount"),
      dataIndex: "engineeringWorkCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.informationCommunicationTechnologiesCount"),
      dataIndex: "informationCommunicationTechnologiesCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.agricultureAndForestryCount"),
      dataIndex: "agricultureAndForestryCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.fisheryCount"),
      dataIndex: "fisheryCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.veterinaryMedicineCount"),
      dataIndex: "veterinaryMedicineCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.healthCount"),
      dataIndex: "healthCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.socialSecurity"),
      dataIndex: "socialSecurity",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.serviceCount"),
      dataIndex: "serviceCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.transportCount"),
      dataIndex: "transportCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.hygieneAndEnvironmentalProtectionCount"),
      dataIndex: "hygieneAndEnvironmentalProtectionCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.ensuringLegalOrderCount"),
      dataIndex: "ensuringLegalOrderCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.productionAndProcessingCount"),
      dataIndex: "productionAndProcessingCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.architectureAndConstructionCount"),
      dataIndex: "architectureAndConstructionCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.humanityCount"),
      dataIndex: "humanityCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.languagesCount"),
      width: "200px",
      dataIndex: "languagesCount",
      align: "center",
    },
    {
      title: t("tables.foreignUniversitySpecialtiesCount"),
      dataIndex: "foreignUniversitySpecialtiesCount",
      width: "300px",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      width: "150px",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  const handleYearChange = (value: string) => {
    setValueYearSelect(value);
  };

  const defaultExcludedColumns = ["universityCode", "university", "ownership"];

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginTop: "40px" }}>
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{t("tables.graduated_table_title")}</h3>

              <div className="filter-container">
                <Select
                  style={{ width: 200 }}
                  onChange={handleYearChange}
                  value={valueYearSelect}
                  options={yearList}
                />
                <Button
                  className="download_btn"
                  onClick={downloadExcelFile}
                  loading={loading}
                >
                  {t("header.download")} <DownloadIcon color={colors.primary} />
                </Button>
              </div>
            </div>
          )}
          rowKey="universityCode"
          dataSource={dataGraduated}
          columns={columns}
          pagination={false}
          size="small"
          scroll={{ x: 1900, y: 500 }}
          loading={tcLoading}
          summary={(pageData) => {
            if (pageData.length === 0) return null;
            //@ts-ignore
            const totalCounts = columns.reduce((acc, { dataIndex }) => {
              if (defaultExcludedColumns.includes(dataIndex)) return acc;

              const totalCount = pageData.reduce((acc, next) => {
                //@ts-ignore
                return acc + (next[dataIndex] || 0);
              }, 0);

              return [...acc, totalCount];
            }, []);

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <b>{t("tables.total")}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  {//@ts-ignore
                  totalCounts?.map((count: number, index: number) => (
                    <Table.Summary.Cell
                      align="center"
                      index={index + 3}
                      key={index}
                    >
                      <b>{count}</b>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </div>
    </div>
  );
}
