import { DownloadIcon } from "src/assets/svg";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { downloadPrivateExcel } from "src/app/services/public";

export const DownloadButton = ({
  params,
  downloadUrl,
  disabled,
}: {
  downloadUrl: string;
  disabled?: boolean;
  params?: {};
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const downloadExcelFile = async () => {
    setLoading(true);
    await downloadPrivateExcel({
      downloadUrl,
      params,
    });
    setLoading(false);
  };

  return (
    <Button
      disabled={disabled}
      className="download_btn"
      onClick={downloadExcelFile}
      loading={loading}
    >
      {t("header.download")} <DownloadIcon />
    </Button>
  );
};
