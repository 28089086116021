import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProfResidencePlaceStatMutation } from "src/app/services/prof_education";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChartCategory from "src/components/chart/BarChartCategory";
import { genders, residencePlaces2 } from "src/pages/constants";
import { IStatResidencePlace } from "src/pages/type";
import { makeProfResidencePlaceData } from "../util";

export default function ProfResidencePlacePage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [getFunc, { data }] = useGetProfResidencePlaceStatMutation();
  const [stat, setStat] = useState<IStatResidencePlace>();

  useEffect(() => {
    getFunc();
  }, []);

  useEffect(() => {
    if (data) {
      setStat(makeProfResidencePlaceData(data));
    }
  }, [data]);

  return stat ? (
    <div className="eduform">
      <div className="graph-row">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: stat.gender.men_total },
            { name: t("genders.women"), count: stat.gender.women_total },
          ]}
          graphHeight={336}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={residencePlaces2}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: stat.gender.men_data,
              },
              {
                name: t("genders.women"),
                data: stat.gender.women_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-118}
            yAxisMargin={158}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t("header.prof_info_text")}</p>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
