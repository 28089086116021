import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useGetTablesPermanentRegionQuery } from "src/app/services/prof_education";
import { IPermanentRegion } from "src/app/services/prof_education/type";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";

export function ProfTablePermanentRegion() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetTablesPermanentRegionQuery();
  const getColumnSearchProps = useColumnSearch();

  const getOwnershipByStatus = (status: number) => {
    switch (status) {
      case 11:
        return "Davlat";
        break;
      case 12:
        return "Nodavlat";
        break;
      case 13:
        return "Xorijiy";
        break;
      case 14:
        return "Qo'shma";
        break;
    }
  };

  const excludedColumns = ["technical_college_name", "ownership_form"];

  const columns: ColumnsType<IPermanentRegion> = [
    {
      //@ts-ignore
      title: t("prof.name"),
      dataIndex: "technical_college_name",
      ...getColumnSearchProps("technical_college_name"),
      width: "300px",
    },
    {
      title: t("tables.ownership"),
      dataIndex: "ownership_form",
      align: "center",
      render: (status) => getOwnershipByStatus(status),
    },
    {
      title: t("regions.andijan"),
      dataIndex: "1703",
      align: "center",
    },
    {
      title: t("regions.bukhara"),
      dataIndex: "1706",
      align: "center",
    },
    {
      title: t("regions.jizzax"),
      dataIndex: "1708",
      align: "center",
    },
    {
      title: t("regions.qashqadaryo"),
      dataIndex: "1710",
      align: "center",
    },
    {
      title: t("regions.navai"),
      dataIndex: "1712",
      align: "center",
    },
    {
      title: t("regions.namangan"),
      dataIndex: "1714",
      align: "center",
    },
    {
      title: t("regions.samarqand"),
      dataIndex: "1718",
      align: "center",
    },
    {
      title: t("regions.surxondaryo"),
      dataIndex: "1722",
      align: "center",
    },
    {
      title: t("regions.sirdaryo"),
      dataIndex: "1724",
      align: "center",
    },
    {
      title: t("regions.tashkent_city"),
      dataIndex: "1726",
      align: "center",
    },
    {
      title: t("regions.tashkent_region"),
      dataIndex: "1727",
      align: "center",
    },
    {
      title: t("regions.fergana"),
      dataIndex: "1730",
      align: "center",
    },
    {
      title: t("regions.xorazm"),
      dataIndex: "1733",
      align: "center",
    },
    {
      title: t("regions.qoraqalpoq"),
      dataIndex: "1735",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "all",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div className="doctoralOrganization">
      <header className="doctoral_table_header">
        <h3>{t("tables.universityByRegion")}</h3>
      </header>
      <Table
        rowKey="technical_college_name"
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ x: 1900, y: 600 }}
        summary={(pageData) => {
          if (pageData.length === 0) return null;
          //@ts-ignore
          const totalCounts = columns.reduce((acc, { dataIndex, children }) => {
            if (excludedColumns.includes(dataIndex)) return acc;

            if (children) {
              const childrenCount = children.map((child: any) => {
                return pageData.reduce((acc, next) => {
                  //@ts-ignore
                  return acc + (next[child.dataIndex] || 0);
                }, 0);
              });

              return acc.concat(childrenCount);
            }

            const totalCount = pageData.reduce((acc, next) => {
              //@ts-ignore
              return acc + (next[dataIndex] || 0);
            }, 0);

            return [...acc, totalCount];
          }, []);

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <b>{t("tables.total")}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                {
                  //@ts-ignore
                  totalCounts.map((count: number, index: number) => (
                    <Table.Summary.Cell align="center" index={index + 2} key={index}>
                      <b>{count}</b>
                    </Table.Summary.Cell>
                  ))
                }
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        loading={isLoading}
      />
    </div>
  );
}
