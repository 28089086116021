import GraphInfoCard from "src/components/cards/graph_info";
import { useTranslation } from "react-i18next";
import LineChart from "src/components/chart/LineChart";
import {
  useGetGraduatedStudentsByYearQuery,
  useGetGraduatedStudentsQuery,
} from "src/app/services/private";
import { useEffect, useState } from "react";
import CustomRadioButton from "src/components/common/radio/RadioButton";
import BarChartCategory from "src/components/chart/BarChartCategory";
import { useTypedSelector } from "src/app/store";
import { GraduatedStudentsTable } from "src/pages/uzasboStatistics/graduatedStudentsByUniversity/graduatedStudentsTable";

function GraduatedStudentsByUniversity() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [isIncrease, setIsIncrease] = useState<boolean>(false);
  const [seriesArr, setSeriesArr] = useState<any[]>();
  const [render, setRender] = useState<number>(1);
  const [barchartData, setBarchartData] = useState<any[]>();
  const [barchartTitle, setBarchartTitle] = useState<any[]>();
  const [eduYear, setEduYear] = useState<string>("2020");
  const { data } = useGetGraduatedStudentsQuery({
    isIncrease: isIncrease,
  });

  const { data: dataByYear } = useGetGraduatedStudentsByYearQuery({
    isIncrease: isIncrease,
    eduYearCode: eduYear,
  });

  useEffect(() => {
    if (data) {
      const newData = data?.map((item) => ({
        name: item.university,
        data: [item.eduYear20202021, item.eduYear20212022, item.eduYear20222023],
      }));
      setSeriesArr(newData);
    }
  }, [data]);

  useEffect(() => {
    if (dataByYear) {
      const newTitle = dataByYear?.map((item) => item.university);
      const newData = dataByYear?.map((item) => item.avgGrade);
      setBarchartTitle(newTitle);
      setBarchartData(newData);
    }
  }, [dataByYear]);

  const ownershipList = [
    { label: "O'sish tartibida", value: true },
    { label: "Kamayish tartibida", value: false },
  ];

  const options = [
    {
      label: "Bitirgan  talabalar o'rtacha GPA foizi",
      value: 1,
    },
    {
      label: "Bitirgan  talabalar o'rtacha GPA foizi yillar kesimida",
      value: 2,
    },
    {
      label: "Bitirgan  talabalar o'rtacha GPA foizi jadvali",
      value: 3,
    },
  ];

  return (
    <div className="eduform">
      <div className="graph-row">
        <div
          style={{
            padding: "12px 6px",
            backgroundColor: "white",
            marginBottom: "16px",
            borderRadius: "8px",
          }}
        >
          <CustomRadioButton
            defaultValue={render}
            buttons={options}
            fontSize={18}
            buttonPaddingInline={8}
            onChange={(ev) => setRender(ev.target.value)}
            otherBg={true}
          />
        </div>

        {render === 1 && (
          <GraphInfoCard
            segmentedData={ownershipList}
            handleChange={setIsIncrease}
            segmentedValue={isIncrease}
            title="Oxirgi uch yildagi bitirgan talabalarning o'rtacha GPA foizi"
            graphHeight={720}
            mRight={50}
          >
            <LineChart
              seriesArr={seriesArr && seriesArr}
              legendData={data?.map((item) => item.university)}
              min={isIncrease === true ? 65 : 75}
            />
          </GraphInfoCard>
        )}
        {render === 2 && (
          <GraphInfoCard
            segmentedData={ownershipList}
            handleChange={setIsIncrease}
            segmentedValue={isIncrease}
            title="Bitirgan talabalarning  GPA ballari yillar kesimida"
            graphHeight={649}
            showSelection={true}
            setEduYear={setEduYear}
            eduYear={eduYear}
          >
            <BarChartCategory
              yAxisData={barchartTitle && barchartTitle}
              barWidth={32}
              color={[colors.blue]}
              showYAxisLabel={false}
              seriesArr={[
                {
                  name: "O'rtacha GPA bahosi",
                  data: barchartData && barchartData.reverse(),
                  label: {
                    show: true,
                    formatter: (value: any) => value.name,
                    color: "#fff",
                    fontSize: "16px",
                  },
                },
              ]}
              yAxisMargin={0}
            />
          </GraphInfoCard>
        )}
        {render === 3 && <GraduatedStudentsTable />}
      </div>
      <div className="text_info">
        <p>{t("header.students_info_text")}</p>
      </div>
    </div>
  );
}

export default GraduatedStudentsByUniversity;
