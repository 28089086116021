import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useGetUniversitiesMutation } from "src/app/services/universities";
import { IUniversityTable } from "src/app/services/universities/type";
import CustomRadioButton from "src/components/common/radio/RadioButton";
import OwnershipFilter from "src/components/filter/OwnershipFilter";
import PaginationFilter from "src/components/filter/PaginationFilter";
import RegionFilter from "src/components/filter/RegionFilter";
import SearchFilter from "src/components/filter/SearchFilter";
import { findOwnership } from "src/components/filter/constants";
import { language } from "src/provider/language/const";
import { handleMakeUrl, handleMakeUrl2, scrollTop } from "src/utils";
import UniversityCard from "./components/UniversityCard";
import "./university.scss";

export default function UniversitiesPage() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [universityType, setUniversityType] = useState("list");
  const [getFunc, { data }] = useGetUniversitiesMutation();

  useEffect(() => {
    if (universityType === "search") {
      let url = handleMakeUrl(searchParams);
      getFunc(url);
    } else {
      let url = handleMakeUrl2(searchParams);
      getFunc("limit=10000" + url);
    }
    scrollTop();
  }, [searchParams, universityType]);

  const [tableData, setTableData] = useState<IUniversityTable[]>([]);

  const makeTableData = () => {
    let size = Number(searchParams.get("size") || 10);
    let page = Number(searchParams.get("page") || 1);
    let arr: IUniversityTable[] = [];

    data?.results.forEach((item, index) => {
      arr.push({
        key: (page - 1) * size + index + 1,
        ...item,
      });
    });
    setTableData([...arr]);
  };

  useEffect(() => {
    if (data) makeTableData();
  }, [data]);

  const handleChangeType = (val: string) => {
    if (val === "list") setSearchParams({});
    setUniversityType(val);
  };

  //Data section
  const universityTypes = [
    { label: t("university.types.list"), value: "list" },
    { label: t("university.types.search"), value: "search" },
  ];

  const columns: ColumnsType<IUniversityTable> = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
    },
    {
      title: t("university.name"),
      dataIndex: `name_${language || "uz"}`,
      key: `name_${language || "uz"}`,
    },
    {
      title: t("filters.ownership"),
      dataIndex: "ownership_form",
      key: "ownership_form",
      render: (val) => (
        <div className="university-card-type">
          <span className={`university-card-type-${val}`}>{t(findOwnership(val))}</span>
        </div>
      ),
    },
    {
      title: t("university.website"),
      dataIndex: "website",
      key: "website",
      render: (val) => (
        <a href={val} target="_blank" rel="noreferrer">
          {val?.split("/")[2]}
        </a>
      ),
    },
    {
      title: t("header.statistics"),
      dataIndex: "stat_link",
      key: "stat_link",
      render: (val) => (
        <a href={val} target="_blank" rel="noreferrer">
          {val?.split("/")[2]}
        </a>
      ),
    },
  ];

  return (
    <div className="university">
      <div className="university-title">{t("university.list")}</div>
      <div
        className="header-content-menu university-types"
        style={{ width: language === "ru" ? 378 : language === "en" ? 314 : 343 }}
      >
        <CustomRadioButton
          buttons={universityTypes}
          fontSize={15}
          buttonPaddingInline={12}
          controlHeight={48}
          borderRadius={24}
          hasBg
          defaultValue="list"
          onChange={(ev) => handleChangeType(ev.target.value)}
        />
      </div>
      {universityType === "search" ? (
        <div className="university-search-content">
          <SearchFilter />
          <div className="university-filter">
            <RegionFilter />
            <OwnershipFilter />
          </div>
        </div>
      ) : (
        <>
          <div className="university-filter">
            <RegionFilter />
            <OwnershipFilter />
          </div>
          <div className="university-table">
            <Table
              size="small"
              scroll={{ x: true }}
              dataSource={tableData}
              columns={columns}
              bordered
              pagination={false}
            />
          </div>
        </>
      )}

      {universityType === "search" && (
        <div className="university-content">
          {data?.results?.map((item) => {
            return <UniversityCard key={item.id} {...item} />;
          })}
        </div>
      )}

      {universityType === "search" && <PaginationFilter total={data?.count} />}

      <div className="text_info">
        <p>{t('header.students_info_text')}</p>
      </div>
    </div>
  );
}
