import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";
import { ChevronDownIcon, EarthIcon } from "src/assets/svg";
import CustomDropdown from "src/components/common/dropdown";
import { LANGUAGE } from "src/provider/language/const";

function LanguageButton() {
  const { colors, darkMode } = useTypedSelector((state) => state.layout);
  const items = [
    {
      key: "uz",
      label: "O'z",
      onClick: () => handleChange("uz"),
    },
    {
      key: "ru",
      label: "Ру",
      onClick: () => handleChange("ru"),
    },
    {
      key: "en",
      label: "Eng",
      onClick: () => handleChange("en"),
    },
  ];

  //Change section
  const { i18n } = useTranslation();
  const handleChange = (val: string) => {
    i18n.changeLanguage(val);
    localStorage.setItem(LANGUAGE, val);
    window.location.reload();
  };

  return (
    <CustomDropdown menu={{ items }} arrow={{ pointAtCenter: true }} trigger={["click"]} placement="bottom">
      <Button
        className={`header-top-language header-top-language-${darkMode && "dark"}`}
        size="large"
        type="text"
        icon={<EarthIcon color={colors.primary} />}
      >
        <div className="flex-between">
          {items.find((el) => el.key === i18n.language)?.label}
          <ChevronDownIcon color={colors.primary} />
        </div>
      </Button>
    </CustomDropdown>
  );
}
export default LanguageButton;
