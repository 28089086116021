import { useMemo } from "react";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";

export function useUzAsboEduFormColumns<T>(): ColumnsType<T> {
  const { t } = useTranslation();
  const getColumnSearchProps = useColumnSearch();

  return useMemo(() => {
    return [
      {
        title: t("tables.univerCode"),
        dataIndex: "universityCode",
        ...getColumnSearchProps("universityCode"),
        align: "center",
        width: 100,
      },
      {
        title: t("tables.univerName"),
        dataIndex: "universityName",
        ...getColumnSearchProps("universityName"),
        align: "center",
        width: 250,
      },
      {
        title: t("tables.ownership"),
        dataIndex: "universityOwnershipName",
        align: "center",
        width: 80,
      },
      {
        title: t("tables.bachelor"),
        children: [
          {
            title: t("edu_form.daytime"),
            dataIndex: "bachelorDaytimeCount",
            width: 110,
            align: "center",
          },
          {
            title: t("edu_form.evening"),
            dataIndex: "bachelorEveningCount",
            width: 110,
            align: "center",
          },
          {
            title: t("edu_form.externally"),
            dataIndex: "bachelorExternalCount",
            width: 110,
            align: "center",
          },
          {
            title: t("edu_form.special_externally"),
            dataIndex: "bachelorSpecialExternalCount",
            align: "center",
            width: 110,
          },
          {
            title: t("edu_form.joint"),
            dataIndex: "bachelorJointCount",
            width: 110,
            align: "center",
          },
          {
            title: t("edu_form.remote"),
            dataIndex: "bachelorRemoteCount",
            width: 110,
            align: "center",
          },
          {
            title: t("edu_form.second_daytime"),
            dataIndex: "bachelorSecondDaytimeCount",
            align: "center",
            width: 165,
          },
          {
            title: t("edu_form.second_evening"),
            dataIndex: "bachelorSecondEveningCount",
            align: "center",
            width: 150,
          },
          {
            title: t("edu_form.second_external"),
            dataIndex: "bachelorSecondExternalCount",
            align: "center",
            width: 150,
          },
          {
            title: t("tables.total"),
            dataIndex: "bachelorTotalCount",
            align: "center",
            render: (total) => <b>{total}</b>,
            width: 80,
          },
        ],
      },
      {
        title: t("tables.master"),
        children: [
          {
            title: t("edu_form.daytime"),
            dataIndex: "masterDaytimeCount",
            width: 110,
            align: "center",
          },
          {
            title: t("edu_form.evening"),
            dataIndex: "masterEveningCount",
            width: 110,
            align: "center",
          },
          {
            title: t("edu_form.externally"),
            dataIndex: "masterExternalCount",
            align: "center",
            width: 110,
          },
          {
            title: t("edu_form.special_externally"),
            dataIndex: "masterSpecialExternalCount",
            align: "center",
            width: 110,
          },
          {
            title: t("edu_form.joint"),
            dataIndex: "masterJointCount",
            width: 110,
            align: "center",
          },
          {
            title: t("edu_form.remote"),
            dataIndex: "masterRemoteCount",
            width: 110,
            align: "center",
          },
          {
            title: t("tables.total"),
            dataIndex: "masterTotalCount",
            align: "center",
            render: (total) => <b>{total}</b>,
            width: 80,
          },
        ],
      },
      {
        title: t("tables.total"),
        dataIndex: "totalCount",
        align: "center",
        render: (total) => <b>{total}</b>,
        width: 80,
      },
    ] as ColumnsType<T>;
  }, []);
}
