import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProfPaymentFormStatMutation } from "src/app/services/prof_education";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  citizenships,
  genders,
  paymentForms,
  paymentForms1,
  prof_ages,
  prof_courses,
  residencePlaces
} from "src/pages/constants";
import { IStatPaymentForm } from "src/pages/type";
import { makeProfPaymentFormData } from "../util";

export default function ProfPaymentFormPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [getFunc, { data }] = useGetProfPaymentFormStatMutation();
  const [stat, setStat] = useState<IStatPaymentForm>();

  useEffect(() => {
    getFunc();
  }, []);

  useEffect(() => {
    if (data) {
      setStat(makeProfPaymentFormData(data));
    }
  }, [data]);

  return stat ? (
    <div className="eduform">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: stat.gender.men_total },
            { name: t("genders.women"), count: stat.gender.women_total },
          ]}
          graphHeight={144}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={paymentForms1}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: stat.gender.men_data,
              },
              {
                name: t("genders.women"),
                data: stat.gender.women_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-80}
            yAxisMargin={110}
          />
        </GraphInfoCard>

        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            { name: t("ages.under_20"), count: stat.age.under_20_total },
            { name: t("ages.over_20"), count: stat.age.over_20_total },
          ]}
          graphHeight={144}
        >
          <BarChartCategory
            legendData={prof_ages}
            yAxisData={paymentForms1}
            color={[colors.light_blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("ages.under_20"),
                data: stat.age.under_20_data,
              },
              {
                name: t("ages.over_20"),
                data: stat.age.over_20_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-80}
            yAxisMargin={110}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row-flex">
        {/* Citizenships */}
        <GraphInfoCard
          title={t("header.citizenship")}
          stat={[
            { name: t("citizenships.uzbek"), count: stat.citizenship.uzbek_total },
            { name: t("citizenships.foreigner"), count: stat.citizenship.foreigner_total },
            { name: t("citizenships.stateless"), count: stat.citizenship.stateless_total },
            { name: t("citizenships.teen"), count: stat.citizenship.teen_total },
          ]}
        >
          <BarChart
            legendData={citizenships}
            xAxisData={paymentForms}
            color={[colors.yellow, colors.light_green, colors.blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("citizenships.uzbek"),
                data: stat.citizenship.uzbek_data,
                stack: "one",
              },
              {
                name: t("citizenships.foreigner"),
                data: stat.citizenship.foreigner_data,
                stack: "one",
              },
              {
                name: t("citizenships.stateless"),
                data: stat.citizenship.stateless_data,
                stack: "one",
              },
              {
                name: t("citizenships.teen"),
                data: stat.citizenship.teen_data,
                stack: "one",
              },
            ]}
            gridBottom={70}
          />
        </GraphInfoCard>

        {/* Courses */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            { name: t("courses.course_1"), count: stat.courses.course_1_total },
            { name: t("courses.course_2"), count: stat.courses.course_2_total },
            { name: t("courses.course_3"), count: stat.courses.course_3_total },
          ]}
        >
          <BarChart
            legendData={prof_courses}
            xAxisData={paymentForms}
            color={[colors.blue, colors.pink, colors.yellow]}
            seriesArr={[
              {
                name: t("courses.course_1"),
                data: stat.courses.course_1_data,
                stack: "one",
              },
              {
                name: t("courses.course_2"),
                data: stat.courses.course_2_data,
                stack: "one",
              },
              {
                name: t("courses.course_3"),
                data: stat.courses.course_3_data,
                stack: "one",
              },
            ]}
            gridBottom={52}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            { name: t("residence_places.own_house"), count: stat.residence_place.own_house_total },
            { name: t("residence_places.dormitory"), count: stat.residence_place.dormitory_total },
            { name: t("residence_places.rented_house"), count: stat.residence_place.rented_house_total },
            { name: t("residence_places.relative_house"), count: stat.residence_place.relative_house_total },
            { name: t("residence_places.familiar_house"), count: stat.residence_place.familiar_house_total },
            { name: t("residence_places.other"), count: stat.residence_place.other_total },
          ]}
          graphHeight={204}
        >
          <BarChartCategory
            legendData={residencePlaces}
            yAxisData={paymentForms1}
            color={[colors.pink, colors.blue, colors.orange, colors.deep_purple, colors.light_blue, colors.yellow]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: stat.residence_place.own_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: stat.residence_place.dormitory_data,
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: stat.residence_place.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: stat.residence_place.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: stat.residence_place.familiar_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.other"),
                data: stat.residence_place.other_data,
                stack: "one",
              },
            ]}
            gridLeft={-76}
            yAxisMargin={110}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t("header.prof_info_text")}</p>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
