export const lyceums = [
  { name: `"Tabiiy va aniq fanlar"ga ixtisoslashtirilgan S.H.Sirojiddinov nomidagi respublika akademik litseyi` },
  { name: `"TIQXMMI" milliy tadqiqot universiteti "Interneshnl Xaus-Toshkent" akademik litseyi` },
  { name: `"TIQXMMI" MTU Qarshi irrigatsiya va agrotexnologiyalar instituti huzuridagi akademik litsey` },
  { name: `Сhirchiq davlat pedagogika universiteti akademik litseyi` },
  { name: `Alisher Navoiy nomli Toshkent davlat o‘zbek tili va adabiyoti universiteti akademik litseyi` },
  { name: `Andijon davlat tibbiyot instituti akademik litseyi` },
  { name: `Andijon davlat universiteti akademik litseyi` },
  { name: `Andijon mashinasozlik instituti akademik litsey` },
  { name: `Andijon qishloq xo‘jaligi va agrotexnologiyalar instituti qoshidagi akademik litsey` },
  { name: `Buxoro davlat tibbiyot instituti akademik litseyi` },
  { name: `Buxoro davlat universiteti Qorako‘l akademik litsey` },
  { name: `Buxoro muhandislik- texnologiya instituti akademik litseyi` },
  { name: `Farg‘ona davlat universiteti akademik litseyi` },
  { name: `Farg'ona politexnika instituti akademik litseyi` },
  { name: `Guliston davlat universiteti akademik litseyi` },
  {
    name: `I.М.Gubkin nomli Rossiya davlat neft va gaz (МТU) universitetining Toshkent shahridagi filiali akademik litseyi`,
  },
  { name: `Islom Karimov nomidagi Toshkent davlat texnika universiteti akademik litseyi` },
  { name: `Islom karimov nomidagi Toshkent davlat texnika universiteti Olmaliq filiali huzuridagi akademik litseyi` },
  { name: `Jahon iqtisodiyoti va diplomatiya universiteti akademik litseyi` },
  { name: `Jizzax davlat pedagogika universiteti akademik litsey` },
  { name: `Jizzax politexnika instituti akademik litseyi` },
  {
    name: `Muhammad al-Xorazmiy nomidagi Toshkent axborot texnologiyalari universiteti Farg‘ona filiali akademik litseyi`,
  },
  {
    name: `Muhammad al-Xorazmiy nomidagi Toshkent axborot texnologiyalari universiteti Qarshi filiali akademik litseyi`,
  },
  { name: `Namangan davlat universiteti akademik litseyi` },
  { name: `Namangan muhandislik texnologiya instituti akademik litseyi` },
  { name: `Namangan muhandislik-qurilish instituti akademik litseyi` },
  { name: `Navoiy davlat konchilik va texnologiyalar universiteti akademik litseyi` },
  { name: `Navoiy davlat pedagogika instituti akademik litseyi` },
  { name: `Nukus davlat pedagogika instituti akademik litseyi` },
  { name: `O‘zbekiston davlat jahon tillari universiteti akademik litseyi` },
  { name: `O‘zbekiston xalqaro islom akademiyasi akademik litseyi` },
  { name: `Qarshi davlat universiteti akademik litseyi` },
  { name: `Qarshi muhandislik-iqnisodiyot instituti akademik litseyi` },
  { name: `Qo'qon davlat pedagogika instituti akademik litseyi` },
  { name: `Qoraqalpoǵiston qishloq xójaligi va agrotexnologiyalar instituti akademik litseyi` },
  { name: `Qoraqalpoq davlat universiteti akademik litseyi` },
  {
    name: `Samarkand davlat veterinariya meditsinasi, chorvachilik va biotexnologiyalar universiteti akademik litseyi`,
  },
  { name: `Samarqand davlat arxitektura-qurilish universiteti akademik litseyi` },
  { name: `Samarqand davlat chet tillar instituti akademik litseyi` },
  { name: `Samarqand davlat tibbiyot universiteti akademik litseyi` },
  { name: `Samarqand davlat universiteti akademik litseyi` },
  { name: `Samarqand iqtisodiyot va servis instituti akademik litseyi` },
  { name: `Termiz Agrotexnologiyalar va innovatsion rivojlanish instituti akademik litseyi` },
  { name: `Termiz davlat universiteti akademik litseyi` },
  { name: `TERMIZ MUHANDISLIK-TEXNOLOGIYA INSTITUTI AKADEMIK LITSEYI` },
  { name: `Toshkent arxitektura-qurilish universiteti akademik litseyi` },
  { name: `Toshkent axborot texnologiyalari universiteti akademik litseyi` },
  { name: `Toshkent axborot texnologiyalari universiteti Samarqand filiali akademik litseyi` },
  { name: `Toshkent davlat agrar universiteti akademik litseyi` },
  { name: `Toshkent davlat iqtisodiyot universiteti akademik litseyi` },
  { name: `Toshkent davlat pedagogika universiteti akademik litseyi` },
  { name: `Toshkent davlat sharqshunoslik universiteti akademik litseyi` },
  { name: `Toshkent davlat stomatologiya instituti akademik litseyi` },
  { name: `Toshkent davlat transport universiteti akademik litseyi` },
  { name: `Toshkent davlat yuridik universiteti qoshidagi M.S.Vosiqova nomidagi akademik litseyi` },
  { name: `Toshkent farmatsevtika instituti akademik litseyi` },
  { name: `Toshkent kimyo-texnologiya instituti akademik litseyi` },
  { name: `Toshkent moliya instituti akademik litseyi` },
  { name: `Toshkent pediatriya tibbiyot instituti akademik litseyi` },
  { name: `Toshkent shahridagi Turin Politexnika Universiteti akademik litseyi` },
  { name: `Toshkent shahridagi xalqaro Vestminster universiteti akademik litseyi` },
  { name: `Toshkent tibbiyot akademiyasi akademik litseyi` },
  { name: `Toshkent tibbiyot akademiyasi Urganch filiali akademik litseyi` },
  { name: `Toshkent to‘qimachilik va yengil sanoat instituti akademik litseyi` },
  { name: `Urganch davlat universiteti akademik litseyi` },
  { name: `IIV Qoraqalpoq akademik litseyi` },
  { name: `IIV Аndijon akademik litseyi` },
  { name: `IIV Buxoro akademik litseyi` },
  { name: `IIV Jizzax akademik litseyi` },
  { name: `IIV Qashqadaryo akademik litseyi` },
  { name: `IIV Navoiy akademik litseyi` },
  { name: `IIV Namangan akademik litseyi` },
  { name: `IIV Samarqand akademik litseyi` },
  { name: `IIV Sirdaryo akademik litseyi` },
  { name: `IIV Surxondaryo akademik litseyi` },
  { name: `IIV 1-sonli Toshkent akademik litseyi` },
  { name: `IIV 2-sonli Toshkent akademik litseyi` },
  { name: `IIV Fargʼona akademik litseyi` },
  { name: `IIV Xorazm akademik litseyi` },
  { name: `IIV Nukus "Temurbeklar maktabi" harbiy akademik litseyi` },
  { name: `O'zbekiston Respublikasi Milliy gvardiyasi Harbiy musiqa akademik litseyi` },
  { name: `O‘zbekiston Respublikasi Milliy gvardiyasi Toshkent «Temurbeklar maktabi» harbiy-akademik litseyi` },
  { name: `Farg'ona "Temurbeklar maktabi" harbiy akademik litseyi` },
];
