import { ColumnsType } from "antd/es/table";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  message,
  Modal,
  Select,
  Table,
  Upload,
  UploadFile,
} from "antd";
import {
  useGetCourseStatusQuery,
  useGetEduFormQuery,
  useGetEduTypeQuery,
  useGetOtmCodeAndNameQuery,
  useGetPaymentFormQuery,
  useGetStudentStatusTypeQuery,
  useHemisReportQuery,
  useStudentSyncMutation,
} from "src/app/services/private";
import moment from "moment";
import { IUzasboReport } from "src/app/services/private/type";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { DownloadButton } from "src/components/downloadButton";
import { tablePaginationConfig } from "src/app/services/api/const";
import { DownloadIcon, UploadIcon } from "src/assets/svg";
import { fileValidator } from "src/utils/validator";
import dayjs from "dayjs";
import axios from "axios";
import { downloadFile } from "src/app/services/public";
export const uzAsboDateFormat = "YYYY/MM";

type FormData = {
  otm: string;
  date: Date;
  file: UploadFile[];
};

export default function CanShowAndChangeHemisDate() {
  const { data: otmNameAndTin } = useGetOtmCodeAndNameQuery();
  const { data: studentStatusList } = useGetStudentStatusTypeQuery();
  const { data: paymentFormList } = useGetPaymentFormQuery();
  const { data: eudTypeList } = useGetEduTypeQuery();
  const { data: eudFormList } = useGetEduFormQuery();
  const { data: courseList } = useGetCourseStatusQuery();
  const [setExcelFunc, { isLoading }] = useStudentSyncMutation();
  const getColumnSearchProps = useColumnSearch();
  const [university, setUniversity] = useState<string>();
  const [universityForModal, setUniversityForModal] = useState<string>();
  const [monthAndYear, setMonthAndYear] = useState<any>({});
  const [monthAndYearForModal, setMonthAndYearForModal] = useState<any>({});
  const [studentStatus, setStudentStatus] = useState<string>();
  const [paymentStatus, setPaymentStatus] = useState<string>();
  const [eduTypeStatus, setEduTypeStatus] = useState<string>();
  const [eduFormStatus, setEduFormStatus] = useState<string>();
  const [courseStatus, setCourseStatus] = useState<string>();
  const [checkDownloadOrUpload, setCheckDownloadOrUpload] = useState<number>();
  const { isFetching, isError, error, currentData } = useHemisReportQuery(
    {
      universityCode: university,
      courseCode: courseStatus,
      eduFormCode: eduFormStatus,
      eduTypeCode: eduTypeStatus,
      studentStatusCode: studentStatus,
      paymentFormCode: paymentStatus,
      ...monthAndYear,
    },
    {
      skip: !university || !monthAndYear.hasOwnProperty("month"),
    },
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isError) {
      const err = error as FetchBaseQueryError;
      if (err?.status === 404) {
        message.error("Bu oydagi hisobot hali shakllanmagan.");
      }
    }
  }, [isError]);

  const getHemisTypeColumns = (): ColumnsType<IUzasboReport> => {
    return [
      {
        title: "JSHSHIR",
        dataIndex: "pinfl",
        width: 160,
        align: "center",
      },
      {
        title: "Yaratilgan sana",
        dataIndex: "createTs",
        width: 160,
        align: "center",
      },
      {
        title: "Tahrirlangan sana",
        dataIndex: "updateTs",
        width: 160,
        align: "center",
      },
      {
        title: "O'chirilgan sana",
        dataIndex: "deleteTs",
        width: 160,
        align: "center",
        render: (data) => <span>{data ? data : ""}</span>,
      },
      {
        title: "Kod",
        dataIndex: "code",
        width: 160,
        align: "center",
      },
      {
        //@ts-ignore
        title: "FIO",
        dataIndex: "fullName",
        ...getColumnSearchProps("fullName"),
        width: 300,
        align: "center",
      },
      {
        title: "OTM kod",
        dataIndex: "universityCode",
        width: 160,
        align: "center",
      },
      {
        title: "OTM stir",
        dataIndex: "universityTin",
        width: 160,
        align: "center",
      },
      {
        title: "OTM nomi",
        dataIndex: "universityName",
        width: 300,
        align: "center",
      },
      {
        title: "Kurs",
        dataIndex: "courseName",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim shakli",
        dataIndex: "eduFormName",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim turi",
        dataIndex: "eduTypeName",
        width: 160,
        align: "center",
      },
      {
        title: "To'lov shakli",
        dataIndex: "paymentFormName",
        width: 160,
        align: "center",
      },
      {
        title: "Talaba status",
        dataIndex: "studentStatusName",
        width: 160,
        align: "center",
      },
    ];
  };

  const columns: ColumnsType<IUzasboReport> = [
    {
      title: "Hemis",
      dataIndex: "student_name",
      align: "center",
      children: getHemisTypeColumns(),
    },
  ];

  const handlePaymentForm = (value: string) => {
    setPaymentStatus(value);
  };

  const handleEduType = (value: string) => {
    setEduTypeStatus(value);
  };

  const handleEduFrom = (value: string) => {
    setEduFormStatus(value);
  };
  const handleCourse = (value: string) => {
    setCourseStatus(value);
  };

  const handleDate = (value: any) => {
    setMonthAndYear({
      month: moment(value["$d"]).format("M"),
      year: moment(value["$d"]).format("YYYY"),
    });
  };

  const handleDateForModal = (value: any) => {
    setMonthAndYearForModal({
      month: moment(value["$d"]).format("M"),
      year: moment(value["$d"]).format("YYYY"),
    });
  };

  function disabledDate(current: Date) {
    let customDate = moment().format("2022-09");
    return (
      (current && current < moment(customDate, "YYYY-MM")) ||
      current > moment(new Date(), "YYYY-MM")
    );
  }

  function disabledDateForModal(current: Date) {
    let customDate = moment().format("2024-01");
    return (
      (current && current < moment(customDate, "YYYY-MM")) ||
      current > moment(new Date(), "YYYY-MM")
    );
  }

  const handleChangeOtm = (value: string) => {
    setUniversity(value);
  };
  const handleChangeOtmForModal = (value: string) => {
    setUniversityForModal(value);
  };

  const uploadExcelFunction = () => {
    setCheckDownloadOrUpload(1);
    setIsModalOpen(true);
  };

  const downloadExcelFunction = () => {
    setCheckDownloadOrUpload(2);
    setIsModalOpen(true);
  };

  const downloadExcel = async () => {
    try {
      const { data } = await axios({
        url: `https://stat.edu.uz/api/uzasbohemis/notSynchronizationStudent/excel`,
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          ...monthAndYearForModal,
          universityCode: universityForModal,
        },
      });
      const blob = new Blob([data], { type: "application/vnd.ms-excel" });
      downloadFile(blob);
      handleCancel();
    } catch (err) {
      //@ts-ignore
      if (err?.response?.status === 404) {
        message.error("Topilmadi");
        handleCancel();
      }
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((data: FormData) => {
        const formData = new FormData();
        const [year, month] = dayjs(data.date).format("YYYY/M").split("/");

        formData.append("year", year);
        formData.append("universityCode", data.otm);
        formData.append("month", month);
        if (checkDownloadOrUpload === 1) {
          formData.append("file", data?.file[0]?.originFileObj as File);
        }

        if (checkDownloadOrUpload === 1) {
          setExcelFunc(formData)
            .unwrap()
            .then((res: { message: string }) => {
              message.success(res.message || "Yuklandi!");
              handleCancel();
            })
            .catch((err) => {
              if (err?.status === 404) {
                message.error("Bu oydagi hisobot hali shakillanmagan!");
                handleCancel();
              } else {
                message.error("Yuklashda xatolik!");
                handleCancel();
              }
            });
        } else if (checkDownloadOrUpload === 2) {
          downloadExcel();
        }
      })
      .catch(console.error);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="uzasbo-search-container">
        <h2>
          Malumotlarni ko'rishingiz uchun eng kamida Universitet, va sanani tanlashingiz lozim
        </h2>
        <div className="filter-warapper">
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            style={{ width: 300 }}
            placeholder="OTM tanlang"
            onChange={handleChangeOtm}
            value={university}
            options={otmNameAndTin?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <DatePicker
            style={{ height: "36px", width: "230px" }}
            disabledDate={disabledDate}
            placeholder="Sanani tanlang"
            format={uzAsboDateFormat}
            picker="month"
            onChange={(e) => handleDate(e)}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talaba status"
            onChange={setStudentStatus}
            allowClear
            options={studentStatusList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="To'lov shakli"
            onChange={handlePaymentForm}
            allowClear
            options={paymentFormList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talim turi"
            onChange={handleEduType}
            allowClear
            options={eudTypeList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talim shakli"
            onChange={handleEduFrom}
            allowClear
            options={eudFormList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Kurslari"
            onChange={handleCourse}
            allowClear
            options={courseList?.map((item) => ({ label: item.name, value: item.code }))}
          />
        </div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowSelectedBg: "#e6f4ff",
              rowSelectedHoverBg: "#bae0ff",
            },
          },
        }}
      >
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Oylik hemis malumotlari</h3>

              <div style={{ display: "flex", gap: "20px" }}>
                <Button className="download_btn" onClick={downloadExcelFunction}>
                  Farqlarni yuklab olish <DownloadIcon />
                </Button>
                <DownloadButton
                  downloadUrl="/uzasbohemis/studentHemisInfoByUniversity/excel"
                  params={{
                    ...monthAndYear,
                    universityCode: university,
                    courseCode: courseStatus,
                    eduFormCode: eduFormStatus,
                    eduTypeCode: eduTypeStatus,
                    studentStatusCode: studentStatus,
                    paymentFormCode: paymentStatus,
                  }}
                />
                <Button className="download_btn" onClick={uploadExcelFunction}>
                  Fayl yuklash <UploadIcon />
                </Button>
              </div>
            </div>
          )}
          bordered
          rowKey={(val) => `${val.code}/${val.universityCode}`}
          dataSource={currentData}
          columns={columns}
          pagination={tablePaginationConfig}
          rowSelection={{ type: "radio", columnWidth: 40 }}
          size="small"
          loading={isFetching}
          virtual={true}
          scroll={{ x: 3200, y: 600 }}
        />
      </ConfigProvider>

      <div>
        <Modal
          title={`${
            checkDownloadOrUpload === 1
              ? "Excel faylni yuklash modali"
              : checkDownloadOrUpload === 2
                ? "Farqlarni yuklab olish modali"
                : ""
          }`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button style={{ border: "1px solid #909090", color: "red" }} onClick={handleCancel}>
              Bekor qilish
            </Button>,

            <Button
              htmlType="button"
              style={{
                border: "1px solid #909090",
                backgroundColor: "#1676FD",
                color: "white",
              }}
              onClick={handleOk}
              loading={isLoading}
            >
              {checkDownloadOrUpload === 1 ? "Saqlash" : "Yuklab olish"}
            </Button>,
          ]}
        >
          <Form name="hemis-upload" layout="vertical" style={{ maxWidth: 600 }} form={form}>
            <Form.Item
              label="OTM ni tanlang"
              name="otm"
              rules={[{ required: true, message: "Iltimos OTM ni tanlang!" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
                }
                style={{ width: 470, border: "1px solid #909090", borderRadius: "8px" }}
                placeholder="OTM tanlang"
                onChange={handleChangeOtmForModal}
                options={otmNameAndTin?.map((item) => ({ label: item.name, value: item.code }))}
              />
            </Form.Item>

            <Form.Item
              label="Sanani tanlang"
              name="date"
              rules={[{ required: true, message: "Iltimos sanani ni tanlang!" }]}
            >
              <DatePicker
                style={{ height: "36px", width: "470px", border: "1px solid #909090" }}
                disabledDate={checkDownloadOrUpload === 1 ? disabledDate : disabledDateForModal}
                placeholder="Sanani tanlang"
                format={uzAsboDateFormat}
                picker="month"
                onChange={(e) => handleDateForModal(e)}
              />
            </Form.Item>
            {checkDownloadOrUpload === 1 && (
              <Form.Item
                label="Excel faylni tanlang"
                name="file"
                rules={[
                  {
                    required: true,
                    message: "Iltimos faylni yuklang!",
                    validator: (rule, value) => fileValidator(rule, value, 1),
                  },
                ]}
                valuePropName="fileList"
                getValueFromEvent={({ fileList }) => fileList}
              >
                <Upload beforeUpload={() => false} maxCount={1} accept=".xlsx, .xls">
                  <Button
                    style={{
                      border: "1px solid #909090",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#F0F3F7",
                    }}
                    icon={<UploadIcon />}
                  >
                    Faylni tanlash
                  </Button>
                </Upload>
              </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    </div>
  );
}
