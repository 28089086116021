import { useEffect, useState } from "react";
import {
  useGetCitizenshipAndGenderQuery,
  useGetCitizenshipAndAgeQuery,
  useGetCitizenshipAndCourseQuery,
} from "src/app/services/students";
import { IStudentAge, IStudentCitizenship, IStudentCourse, IStudentGender } from "../type";
import {
  makeStudentAge,
  makeStudentCitizenshipByCourse,
  makeStudentCourse,
  makeStudentGender,
} from "../util";

function useCitizenForm() {
  // Genders section
  const [gendersData, setGenders] = useState<IStudentGender>();
  const { data: genders } = useGetCitizenshipAndGenderQuery();
  useEffect(() => {
    if (genders) setGenders(makeStudentGender(genders));
  }, [genders]);

  // Ages section
  const [agesData, setAges] = useState<IStudentAge>();
  const { data: ages } = useGetCitizenshipAndAgeQuery();
  useEffect(() => {
    if (ages) setAges(makeStudentAge(ages));
  }, [ages]);

  // Courses section
  const [coursesData, setCourses] = useState<IStudentCourse>();
  const [coursesByCitizenshipData, setCoursesByCitizenship] = useState<IStudentCitizenship>();
  const { data: courses } = useGetCitizenshipAndCourseQuery();
  useEffect(() => {
    if (courses) {
      setCourses(makeStudentCourse(courses));
      setCoursesByCitizenship(makeStudentCitizenshipByCourse(courses));
    }
  }, [courses]);

  return {
    gendersData,
    agesData,
    coursesData,
    coursesByCitizenshipData,
  };
}

export default useCitizenForm;
