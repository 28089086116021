import { api } from "../api";
import {
  ITablesRegion,
  IRegionEduForm,
  ISpecialitiesEduForm,
  ISpecialityEduForm,
  IUniverEduForm,
  ITablesEmployeeForm,
  ITablesRank,
  ITablesPositionAndGender,
  ITablesAcademicDegree,
  ITablesGender,
  ITablesPayment,
  ITablesCourse,
  ITablesEduForm,
  ITablesGradutedStudents,
  IGraduatedYear,
  IGraduatedByRegion,
  IRegisterYear,
  IGraduatedBySpeciality,
} from "src/app/services/tables/type";

export const tablesAPi = api.injectEndpoints({
  endpoints: (build) => ({
    getTablesRegions: build.query<ITablesRegion[], any>({
      query: (params) => ({
        url: `statistic/table/student/region`,
        params: params,
      }),
    }),
    getTablesGender: build.mutation<ITablesGender[], string>({
      query: (params) => ({
        url: `statistic/table/student/genderAndEduType?paymentType=${params}`,
      }),
    }),
    getTablesEduTypePaymentType: build.query<ITablesPayment[], void>({
      query: () => "statistic/table/student/paymentType",
    }),
    getTablesEduForm: build.mutation<ITablesEduForm[], string>({
      query: (params) => ({
        url: `statistic/table/student/eduForm?paymentType=${params}`,
      }),
    }),
    getTablesCourse: build.mutation<ITablesCourse[], string>({
      query: (params) => ({
        url: `statistic/table/student/course?paymentType=${params}`,
      }),
    }),
    // api get univerEduForm endpoint
    getUniverEduForm: build.query<
      IUniverEduForm[],
      { eduTypeCode: number; regionCode: string | null }
    >({
      query: (params) => ({
        url: `student/statistic/speciality/univerEduForm?eduTypeCode=${params.eduTypeCode}&regionCode=${params.regionCode}`,
      }),
    }),
    // api get specialityEduForm endpoint
    getSpecialityEduForm: build.query<
      ISpecialityEduForm[],
      { univerCode: string | null; eduType: number }
    >({
      query: (params) => ({
        url: `student/statistic/speciality/specialityEduForm?eduTypeCode=${params.eduType}&univerCode=${params.univerCode}`,
      }),
    }),
    // api get specialitiesEduForm endpoint
    getSpecialitiesEduForm: build.query<ISpecialitiesEduForm[], any>({
      query: (params) => ({
        url: `student/statistic/speciality/specialityEduForm`,
        params: { univerCode: "all", ...params },
      }),
    }),
    // api get specialitiesEduForm endpoint
    getRegionEduForm: build.query<IRegionEduForm[], number | string>({
      query: (params = "all") => ({
        url: `student/statistic/speciality/regionEduForm?eduTypeCode=` + params,
      }),
    }),
    // api get tablesEmployeeForm endpoint
    getTablesEmployeeForm: build.query<ITablesEmployeeForm[], void>({
      query: () => "teacher/statistic/table/employeeForm",
    }),
    // api get tables university by country
    getTablesUniversityByCountry: build.query<any, void>({
      query: () => "statistic/table/student/country",
    }),
    // api get tablesRank endpoint
    getTablesRank: build.query<
      ITablesRank[],
      { ownership?: string; gender?: string }
    >({
      query: (params) => ({
        url: "teacher/statistic/table/rank",
        params,
      }),
    }),
    // api get TablesPositionAndGender endpoint
    getTablesPositionAndGender: build.query<ITablesPositionAndGender[], void>({
      query: () => "teacher/statistic/table/positionAndGender",
    }),
    // api get TablesAcademicDegree endpoint
    getTablesAcademicDegree: build.query<ITablesAcademicDegree[], void>({
      query: () => "teacher/statistic/table/academicDegree",
    }),
    // api get Tables Graduated Year endpoint
    getTablesGraduatedYear: build.query<IGraduatedYear[], void>({
      query: () => "statistic/graduatedStudent/educationYear",
    }),

    // api get Tables Graduated Year endpoint
    getDiplomaRegisterYear: build.query<IRegisterYear[], void>({
      query: () => "public/diplomaRegisterYears",
    }),
    // api get Tables Graduated  by region endpoint
    getTablesGraduatedByRegion: build.query<
      IGraduatedByRegion[],
      { eduYearCode?: string }
    >({
      query: (params) => ({
        url: "statistic/graduatedStudent/region",
        params,
      }),
    }),
    // api get Tables Graduated  by speciality endpoint
    getTablesGraduatedStudentsBySpeciality: build.query<
      IGraduatedBySpeciality[],
      { eduYearCode?: string }
    >({
      query: (params) => ({
        url: "statistic/graduatedStudent/speciality",
        params,
      }),
    }),
    //Get table Graduated students endpoint
    getTablesGraduatedStudents: build.mutation<
      ITablesGradutedStudents[],
      { graduationYearCode: string }
    >({
      query: (params) =>
        `statistic/graduatedStudent/bachelor/universityAndYear?graduationYearCode=${params.graduationYearCode}`,
    }),

    // get eduForm by Region
    getStudentsByEduFormByRegion: build.query<any, any>({
      query: (params) => ({
        url: `statistic/table/student/eduFormAndUniversityRegion`,
        params: params,
      }),
    }),
    // api download excel by region
    download: build.mutation<File, string>({
      query: (params) => ({
        url: `excel/` + params,
        method: "GET",
        headers: {
          responseType: "arraybuffer",
        },
      }),
    }),
  }),
});

export const {
  useGetTablesCourseMutation,
  useGetTablesEduFormMutation,
  useGetTablesEduTypePaymentTypeQuery,
  useGetTablesGenderMutation,
  useGetTablesRegionsQuery,
  useGetUniverEduFormQuery,
  useGetSpecialityEduFormQuery,
  useGetRegionEduFormQuery,
  useGetSpecialitiesEduFormQuery,
  useDownloadMutation,
  useGetTablesAcademicDegreeQuery,
  useGetTablesEmployeeFormQuery,
  useGetTablesPositionAndGenderQuery,
  useGetTablesRankQuery,
  useGetTablesGraduatedStudentsMutation,
  useGetTablesGraduatedYearQuery,
  useGetStudentsByEduFormByRegionQuery,
  useGetTablesUniversityByCountryQuery,
  useGetTablesGraduatedByRegionQuery,
  useGetDiplomaRegisterYearQuery,
  useGetTablesGraduatedStudentsBySpecialityQuery,
} = tablesAPi;
