import { useEffect, useState } from "react";
import {
  useGetStudentsCourseByAgeStatQuery,
  useGetStudentsCourseByGenderStatQuery,
  useGetStudentsCourseByPlaceStatQuery,
} from "src/app/services/students";
import { IStudentAge, IStudentGender, IStudentPlace } from "../type";
import { makeStudentAge, makeStudentGender, makeStudentPlace } from "../util";

function useCourse() {
  // Genders section
  const [gendersData, setGenders] = useState<IStudentGender>();
  const { data: genders } = useGetStudentsCourseByGenderStatQuery();
  useEffect(() => {
    if (genders) setGenders(makeStudentGender(genders));
  }, [genders]);

  // Ages section
  const [agesData, setAges] = useState<IStudentAge>();
  const { data: ages } = useGetStudentsCourseByAgeStatQuery();
  useEffect(() => {
    if (ages) setAges(makeStudentAge(ages));
  }, [ages]);

  // Places section
  const [placesData, setPlaces] = useState<IStudentPlace>();
  const { data: places } = useGetStudentsCourseByPlaceStatQuery();
  useEffect(() => {
    if (places) setPlaces(makeStudentPlace(places));
  }, [places]);

  return {
    gendersData,
    agesData,
    placesData,
  };
}

export default useCourse;
