import { List } from "antd";
import { useTranslation } from "react-i18next";
import { useGetDoctoralStatQuery } from "src/app/services/doctoral";

export function DoctoralStatList() {
  const { t } = useTranslation();
  const { data } = useGetDoctoralStatQuery();

  const list = [
    {
      title: t("doctoral.dc_count"),
      desc: t("doctoral.under_40"),
      count: data?.dc_count_birthday,
    },
    {
      title: t("doctoral.dc_count_woman"),
      desc: t("doctoral.under_40"),
      count: data?.dc_count_birthday_woman,
    },
    {
      title: t("doctoral.dc_count_man"),
      desc: t("doctoral.under_40"),
      count: data?.dc_count_birthday_man,
    },
    {
      title: t("doctoral.phd_count"),
      desc: t("doctoral.under_40"),
      count: data?.phd_count_birthday,
    },
    {
      title: t("doctoral.phd_count_woman"),
      desc: t("doctoral.under_40"),
      count: data?.phd_count_birthday_woman,
    },
    {
      title: t("doctoral.phd_count_man"),
      desc: t("doctoral.under_40"),
      count: data?.phd_count_birthday_man,
    },
    {
      title: t("doctoral.all_count"),
      desc: t("doctoral.all"),
      count: data?.all_doctorate,
    },
  ];

  return (
    <div className="doctoralStatList">
      <h3>{t("doctoral.doctoralStat")}</h3>
      <List
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta title={<span>{item.title}</span>} description={item.desc} />
            <span>{item.count}</span>
          </List.Item>
        )}
      />
    </div>
  );
}
