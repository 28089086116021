import { Button, Input, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType, ColumnType } from "antd/es/table";
import { tablePaginationConfig } from "src/app/services/api/const";
import { useNavigate } from "react-router-dom";
import { useGetSpecialityEduFormQuery } from "src/app/services/tables";
import { ISpecialityEduForm } from "src/app/services/tables/type";

type DataIndex = keyof ISpecialityEduForm;

export function TableCountByOrgDirectionTable({
  orgId,
  eduType,
}: {
  orgId: string | null;
  eduType: number;
}) {
  const { t } = useTranslation();
  const { data, isLoading } = useGetSpecialityEduFormQuery({ univerCode: orgId, eduType: eduType });
  const navigate = useNavigate();

  if (!orgId) return null;

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<ISpecialityEduForm> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<span>&#128269;</span>}
            size="small"
            style={{ width: 90 }}
          >
            {t("doctoral.search")}
          </Button>
          <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
            {t("doctoral.reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            {t("doctoral.filter")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {t("doctoral.close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <span>&#128269;</span>,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
  });

  const render = (value: number) => value || 0;

  const columns: ColumnsType<ISpecialityEduForm> = [
    {
      title: t("tables.direction_code"),
      dataIndex: "specialityCode",
    },
    {
      //@ts-ignore
      title: eduType === 11 ? t("tables.edu_direction") : t("tables.speciality"),
      dataIndex: "speciality",
      render,
      ...getColumnSearchProps("speciality"),
    },
    {
      title: t("edu_form.daytime"),
      dataIndex: "daytimeCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.evening"),
      dataIndex: "eveningCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.externally"),
      dataIndex: "externalCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.joint"),
      dataIndex: "jointCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.remote"),
      dataIndex: "remoteCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_daytime"),
      dataIndex: "secondDaytimeCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_evening"),
      dataIndex: "secondEveningCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_external"),
      dataIndex: "secondExternalCount",
      align: "center",
      render,
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="doctoralOrganization">
      <header className="doctoral_table_header">
        <Button size="small" onClick={navigateBack}>
          {`<`}
        </Button>
        <h3>{t("doctoral.count_stu_by_org_direction")}</h3>
      </header>
      <Table
        size="small"
        rowKey="speciality"
        scroll={{ x: true }}
        dataSource={data}
        columns={columns}
        pagination={tablePaginationConfig}
        loading={isLoading}
      />
    </div>
  );
}
