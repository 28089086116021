import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChartCategory from "src/components/chart/BarChartCategory";
import { genders, residencePlaces } from "src/pages/constants";
import { useTranslation } from "react-i18next";
import usePlace from "./usePlace";

function ResidencePlacePage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);

  const { gendersData } = usePlace();

  return (
    <div className="eduform">
      <div className="graph-row">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: gendersData?.men_total },
            { name: t("genders.women"), count: gendersData?.women_total },
          ]}
          graphHeight={336}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={residencePlaces}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: [...(gendersData?.men_data || [])].reverse(),
              },
              {
                name: t("genders.women"),
                data: [...(gendersData?.women_data || [])].reverse(),
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-118}
            yAxisMargin={158}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t('header.students_info_text')}</p>
      </div>
    </div>
  );
}

export default ResidencePlacePage;
