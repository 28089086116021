import { BrowserRouter as Router } from "react-router-dom";
import RoutElements from "./routes";

import LanguageProvider from "./provider/language";
import ReduxProvider from "./provider/redux";
import AntConfigProvider from "./provider/antConfig";


function App() {
  return (
    <LanguageProvider>
      <ReduxProvider>
        <Router>
          <AntConfigProvider>
            <RoutElements />
          </AntConfigProvider>
        </Router>
      </ReduxProvider>
    </LanguageProvider>
  );
}

export default App;
