import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { ITablesEduForm } from "src/app/services/tables/type";
import { useState } from "react";
import { useGetStudentsByEduFormByRegionQuery } from "src/app/services/tables";
import { renderEmptyValue } from "src/pages/higher_education/tables/utils";
import { Select, Space, Table } from "antd";
import { DownloadButton } from "src/components/downloadButton";
import { useGetUniversityOwnershipCodesQuery } from "src/app/services/private";

export function TablesUniversityByEduFormByRegion() {
  const { t } = useTranslation();

  const [ownershipCode, serOwnershipCode] = useState("all");
  const [paymentType, setPaymentType] = useState("all");

  const { data, isFetching } = useGetStudentsByEduFormByRegionQuery({
    paymentType: paymentType,
    ownershipCode: ownershipCode,
  });

  const { data: ownership } = useGetUniversityOwnershipCodesQuery();

  const payment_type_list = [
    {
      value: "all",
      label: t("tables.total"),
    },
    {
      value: "11",
      label: t("pay_form.grant"),
    },
    {
      value: "12",
      label: t("pay_form.contract"),
    },
  ];

  const columns: ColumnsType<ITablesEduForm> = [
    {
      title: t("prof.region_name"),
      dataIndex: "regionName",
      width: 200,
    },

    {
      title: t("tables.bachelor"),
      children: [
        {
          title: t("edu_form.daytime"),
          dataIndex: "bachelorDaytime",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.evening"),
          dataIndex: "bachelorEvening",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.externally"),
          dataIndex: "bachelorExternal",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.special_externally"),
          dataIndex: "bachelorSpecialExternal",
          align: "center",
          render: renderEmptyValue,
          width: 110,
        },
        {
          title: t("edu_form.joint"),
          dataIndex: "bachelorJoint",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.remote"),
          dataIndex: "bachelorRemote",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.second_daytime"),
          dataIndex: "bachelorSecondDaytime",
          align: "center",
          render: renderEmptyValue,
          width: 165,
        },
        {
          title: t("edu_form.second_evening"),
          dataIndex: "bachelorSecondEvening",
          align: "center",
          width: 150,
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.second_external"),
          dataIndex: "bachelorSecondExternal",
          align: "center",
          width: 150,
          render: renderEmptyValue,
        },
        {
          title: t("tables.total"),
          dataIndex: "bachelorTotal",
          align: "center",
          render: (total: number) => <b>{total}</b>,
          width: 100,
        },
      ],
    },
    {
      title: t("tables.master"),
      children: [
        {
          title: t("edu_form.daytime"),
          dataIndex: "masterDaytime",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.evening"),
          dataIndex: "masterEvening",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.externally"),
          dataIndex: "masterExternal",
          align: "center",
          render: renderEmptyValue,
          width: 110,
        },
        {
          title: t("edu_form.special_externally"),
          dataIndex: "masterSpecialExternal",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.joint"),
          dataIndex: "masterJoint",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("edu_form.remote"),
          dataIndex: "masterRemote",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("tables.total"),
          dataIndex: "masterTotal",
          align: "center",
          render: (total: number) => <b>{total}</b>,
          width: 100,
        },
      ],
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>

      <Table
        title={() => (
          <div className="flex-space-center">
            <h3>{t("tables.eduFormByRegion")}</h3>
            <Space>
              <Select
                style={{ width: 230 }}
                placeholder="To'lov shakli"
                onChange={setPaymentType}
                options={payment_type_list}
              />
              <Select
                style={{ width: 230 }}
                placeholder="Mulkchilik"
                onChange={serOwnershipCode}
                options={
                  ownership && [
                    { label: "JAMI", value: "all" },
                    ...ownership?.map((item) => ({ label: item.name, value: item.code })),
                  ]
                }
              />

              <DownloadButton
                downloadUrl="/excel/student/eduFormAndUniversityRegion"
                params={{
                  ownershipCode: ownershipCode && ownershipCode,
                  paymentType: paymentType && paymentType,
                }}
              />
            </Space>
          </div>
        )}
        rowKey="regionName"
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ x: 2000, y: 500 }}
        loading={isFetching}
      />
    </div>
  );
}
