import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { THEME_COLOR, changeTheme } from "src/app/slices/layoutSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import { DarkModeSvg, LightModeSvg } from "src/assets/svg";

function DarkmodeSwitch() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { darkMode } = useTypedSelector((state) => state.layout);

  useEffect(() => {
    let theme = localStorage.getItem(THEME_COLOR);

    dispatch(changeTheme(theme || "light"));
  }, []);

  return (
    <div className="header-darkmode" onClick={() => dispatch(changeTheme())}>
      {darkMode ? <DarkModeSvg /> : <LightModeSvg />} <p className='mode-text'>{t("header.dark_mode")}</p>
    </div>
  );
}

export default DarkmodeSwitch;
