import { useTranslation } from "react-i18next";
import "./footer.scss";

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-content">

          <div className="footer-content-left">
            <div className="footer-box">
              <h2>+998 71 203-13-24</h2>
              <p>{t("footer.contact_center")}</p>
            </div>
            <div className="footer-box">
              <h2>{t("footer.privacy_policy")}</h2>
              <p>© {t("footer.ministry")}</p>
            </div>
          </div>

          <div className="footer-box">
            <h2>{t("footer.owner")}</h2>
            <p>{t("footer.producer")}</p>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Footer;
