import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "src/layout";
import Custom404 from "src/pages/404";
import AdmissionPage from "src/pages/admission";
import CitizenshipPage from "src/pages/higher_education/students/citizenship";
import CoursesPage from "src/pages/higher_education/students/courses";
import EduFormPage from "src/pages/higher_education/students/edu_form";
import EduTypePage from "src/pages/higher_education/students/edu_type";
import PaymentFormPage from "src/pages/higher_education/students/payment_form";
import RegionsSectionPage from "src/pages/higher_education/students/regions_section";
import ResidencePlacePage from "src/pages/higher_education/students/residence_place";
import StudentAgePage from "src/pages/higher_education/students/student_age";
import TeachersPage from "src/pages/higher_education/teachers";
import UniversitiesPage from "src/pages/higher_education/universities";
import LyceumPage from "src/pages/lyceum";
import LyceumsListPage from "src/pages/lyceum/list";
import ProfAges from "src/pages/prof_education/ages";
import ProfCitizenshipPage from "src/pages/prof_education/citizenship";
import ProfCoursesPage from "src/pages/prof_education/courses";
import ProfEduFormPage from "src/pages/prof_education/edu_form";
import ProfEduTypePage from "src/pages/prof_education/edu_type";
import ProfEducationsListPage from "src/pages/prof_education/list";
import ProfPaymentFormPage from "src/pages/prof_education/payment_form";
import ProfRegionsSectionPage from "src/pages/prof_education/regions_section";
import ProfResidencePlacePage from "src/pages/prof_education/residence_place";
import DoctoralPage from "src/pages/doctoral";
import { scrollTop } from "src/utils";
import LyceumTeacherPage from "src/pages/lyceum/teachers";
import DoctoralByDirectionPage from "src/pages/doctoral/doctoralDirections";
import DoctoralByRegionsPage from "src/pages/doctoral/doctoralRegions";
import TablesByDirections from "src/pages/higher_education/tables/tablesDirections";
import TablesByUniversities from "src/pages/higher_education/tables/tablesUniversities";
import TablesTeachers from "src/pages/higher_education/tables/tableTeachers/index";
import ProfTables from "src/pages/prof_education/prof_tables/index";
import Login from "src/pages/login";
import OneIdAuth from "src/pages/auth";
import OtmReports from "src/pages/otmReports";
import PrivateRoute from "src/routes/privateRoute";
import AllStatPage from "src/pages/higher_education/all";
import { TablesGraduatedStudents } from "src/pages/higher_education/tables/TablesGraduatedStudents";
import UzasboStatistic from "src/pages/uzasboStatistics/uzasboStatistic";
import { UzAsboLayout } from "src/pages/uzasboStatistics";
import { UzASBOEduForm } from "src/pages/uzasboStatistics/UzASBOEduForm";
import { UzASBOAccommodation } from "src/pages/uzasboStatistics/UzASBOAccomodation";
import { UzAsboEmploymentStudent } from "src/pages/uzasboStatistics/UzASBOEmploymentStudent";
import UzasboRentStudent from "src/pages/uzasboStatistics/UzASBORentStudent";
import CanShowAndChangeHemisDate from "src/pages/uzasboStatistics/canShowAndChangeHemisDate";
import { Disability } from "src/pages/uzasboStatistics/disability";
import GraduatedStudentsByUniversity from "src/pages/uzasboStatistics/graduatedStudentsByUniversity";
import { TablesGraduatedStudentsByRegion } from "src/pages/higher_education/tables/TablesGraduatedStudentsByRegion";
import { TablesGraduatedStudentsBySpeciality } from "src/pages/higher_education/tables/TablesGraduatedStudentsBySpeciality";

function RoutElements() {
  const location = useLocation();

  useEffect(() => {
    scrollTop();
  }, [location.pathname]);

  return (
    <div className="root">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<AllStatPage />} />
          <Route path="/edu_form" element={<EduFormPage />} />
          <Route path="/payment_form" element={<PaymentFormPage />} />
          <Route path="/citizenship" element={<CitizenshipPage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/student_age" element={<StudentAgePage />} />
          <Route path="/residence_place" element={<ResidencePlacePage />} />
          <Route path="/regions_section" element={<RegionsSectionPage />} />

          <Route path="/teachers" element={<TeachersPage />} />
          <Route path="/universities" element={<UniversitiesPage />} />
          <Route path="/tables" element={<TablesByUniversities />} />
          <Route path="/students" element={<EduTypePage />} />
          <Route path="/prof-tables" element={<ProfTables />} />
          <Route path="/tables/directions" element={<TablesByDirections />} />
          <Route path="/tables/teachers" element={<TablesTeachers />} />
          <Route
            path="/tables/graduated"
            element={<TablesGraduatedStudents />}
          />
          <Route
            path="/tables/graduated-by-region"
            element={<TablesGraduatedStudentsByRegion />}
          />
          <Route
            path="/tables/graduated-by-speciality"
            element={<TablesGraduatedStudentsBySpeciality />}
          />

          {/* Lyceum section */}
          <Route path="/lyceum">
            <Route index element={<LyceumPage />} />
            <Route path="list" element={<LyceumsListPage />} />
            <Route path="teachers" element={<LyceumTeacherPage />} />
          </Route>

          {/* Prof education section */}
          <Route path="/prof">
            <Route index element={<ProfEduTypePage />} />
            <Route path="edu_form" element={<ProfEduFormPage />} />
            <Route path="payment_form" element={<ProfPaymentFormPage />} />
            <Route path="citizenship" element={<ProfCitizenshipPage />} />
            <Route path="courses" element={<ProfCoursesPage />} />
            <Route path="ages" element={<ProfAges />} />
            <Route
              path="residence_place"
              element={<ProfResidencePlacePage />}
            />
            <Route
              path="regions_section"
              element={<ProfRegionsSectionPage />}
            />
            <Route path="list" element={<ProfEducationsListPage />} />

            <Route path="*" element={<Custom404 />} />
          </Route>

          {/* Admission section */}
          <Route path="/admission" element={<AdmissionPage />} />

          {/* Doctoral studies */}
          <Route path="/doctoral" element={<DoctoralPage />} />
          <Route path="/doctoral/regions" element={<DoctoralByRegionsPage />} />
          <Route
            path="/doctoral/direction"
            element={<DoctoralByDirectionPage />}
          />

          <Route
            path="/uzasbo"
            element={
              <PrivateRoute>
                <UzAsboLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<UzasboStatistic />} />
            <Route path="eduForm" element={<UzASBOEduForm />} />
            <Route path="accommodation" element={<UzASBOAccommodation />} />
            <Route path="employment" element={<UzAsboEmploymentStudent />} />
            <Route path="contract" element={<OtmReports />} />
            <Route path="rent" element={<UzasboRentStudent />} />
            <Route
              path="showAndChangeHemis"
              element={<CanShowAndChangeHemisDate />}
            />
            <Route path="disability" element={<Disability />} />
            <Route
              path="graduated_section"
              element={<GraduatedStudentsByUniversity />}
            />
          </Route>
        </Route>

        <Route path="/otm" element={<Login />} />
        <Route path="/auth/oneId" element={<OneIdAuth />} />
        <Route path="*" element={<Custom404 />} />
      </Routes>
    </div>
  );
}

export default RoutElements;
