import { ConfigProvider, DropDownProps, Dropdown } from "antd";
import { useTypedSelector } from "src/app/store";

interface Props extends DropDownProps {}

function CustomDropdown({ children, ...rest }: Props) {
  const { colors } = useTypedSelector((state) => state.layout);

  return (
    <div className="radio-button">
      <ConfigProvider
        theme={{
          token: {
            colorBgBase: colors.white,
            controlItemBgHover: colors.bg_secondary,
          },
          components: {
            Dropdown: {
              colorText: colors.primary,
            },
          },
        }}
      >
        <Dropdown {...rest}>{children}</Dropdown>
      </ConfigProvider>
    </div>
  );
}

export default CustomDropdown;
