import { ColumnsType } from "antd/es/table";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { useEffect, useState } from "react";
import { ConfigProvider, DatePicker, message, Select, Table } from "antd";
import {
  useGetCourseStatusQuery,
  useGetEduFormQuery,
  useGetEduTypeQuery,
  useGetOtmCodeAndNameQuery,
  useGetPaymentFormQuery,
  useGetStudentStatusTypeQuery,
  useUzAsboReportQuery,
} from "src/app/services/private";
import moment from "moment";
import { IUzasboReport } from "src/app/services/private/type";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { DownloadButton } from "src/components/downloadButton";
import { tablePaginationConfig } from "src/app/services/api/const";

export const uzAsboDateFormat = "YYYY/MM";

export default function UzasboStatistic() {
  const { data: otmNameAndTin } = useGetOtmCodeAndNameQuery();
  const { data: studentStatusList } = useGetStudentStatusTypeQuery();
  const { data: paymentFormList } = useGetPaymentFormQuery();
  const { data: eudTypeList } = useGetEduTypeQuery();
  const { data: eudFormList } = useGetEduFormQuery();
  const { data: courseList } = useGetCourseStatusQuery();
  const getColumnSearchProps = useColumnSearch();
  const [university, setUniversity] = useState<string>();
  const [monthAndYear, setMonthAndYear] = useState<any>({});
  const [studentStatus, setStudentStatus] = useState<string>();
  const [paymentStatus, setPaymentStatus] = useState<string>();
  const [eduTypeStatus, setEduTypeStatus] = useState<string>();
  const [eduFormStatus, setEduFormStatus] = useState<string>();
  const [courseStatus, setCourseStatus] = useState<string>();

  const [inHemis, setExistInHemis] = useState<boolean>();
  const [inDoctorantura, setExistInDoctorantura] = useState<boolean>();
  const [isbudget, setIsbudget] = useState<boolean>();
  const { isFetching, isError, error, currentData } = useUzAsboReportQuery(
    {
      existInHemis: inHemis ? inHemis : undefined,
      existInDoctoral: inDoctorantura ? inDoctorantura : undefined,
      isBudget: isbudget ? isbudget : undefined,
      orgInn: university,
      courseCode: courseStatus,
      eduFormCode: eduFormStatus,
      eduTypeCode: eduTypeStatus,
      studentStatusCode: studentStatus,
      paymentFormCode: paymentStatus,
      ...monthAndYear,
    },
    {
      skip: !university || !monthAndYear.hasOwnProperty("month"),
    },
  );

  useEffect(() => {
    if (isError) {
      const err = error as FetchBaseQueryError;
      if (err?.status === 404) {
        message.error("Bu oydagi hisobot hali shakllanmagan.");
      }
    }
  }, [isError]);

  const getUzasboTypeColumns = (): ColumnsType<IUzasboReport> => {
    return [
      {
        title: "Tashkilot stir",
        dataIndex: "organizationInn",
        width: 160,
        align: "center",
      },
      {
        title: "Tashkilot nomi",
        dataIndex: "organizationName",
        width: 200,
        align: "center",
      },
      {
        title: "Sana",
        dataIndex: "dateUzasbo",
        width: 200,
      },
      {
        title: "JSHSHIR",
        dataIndex: "uzasboPinfl",
        width: 160,
        align: "center",
      },
      {
        title: "Plastik summa",
        dataIndex: "plasticSum",
        width: 160,
        align: "center",
      },
      {
        title: "To'lov shakli",
        dataIndex: "isBudget",
        width: 160,
        align: "center",
        render: (value) => (value ? "Davlat granti" : "Kontrakt"),
      },
      {
        title: "Naqt summa",
        dataIndex: "cashSum",
        width: 160,
        align: "center",
      },
      {
        //@ts-ignore
        title: "FIO",
        dataIndex: "studentName",
        ...getColumnSearchProps("studentName"),
        width: 300,
        align: "center",
      },
      {
        title: "To'lov maqsad",
        dataIndex: "calcType",
        width: 220,
        align: "center",
      },
      {
        title: "Table id",
        dataIndex: "tableId",
        width: 160,
        align: "center",
      },
      {
        title: "Izoh",
        dataIndex: "comment",
        width: 160,
        align: "center",
      },
      {
        title: "Uzasbo id",
        dataIndex: "uzasboId",
        width: 160,
        align: "center",
      },
    ];
  };

  const getContractEduTypeColumns = (): ColumnsType<IUzasboReport> => {
    return [
      {
        title: "Stipendiya turi",
        dataIndex: "scholarshipType",
        width: 160,
        align: "center",

        render: (data) => <span>{data ? data : ""}</span>,
      },
      {
        title: "Shartnoma sanasi",
        dataIndex: "contractDocDate",
        width: 160,
        align: "center",

        render: (data) => <span>{data ? data : ""}</span>,
      },
      {
        title: "Shartnoma raqami",
        dataIndex: "contractDocNumber",
        width: 160,
        align: "center",

        render: (data) => <span>{data ? data : ""}</span>,
      },
      {
        title: "O'quv yili",
        dataIndex: "eduYearName",
        width: 160,
        align: "center",
        render: (data) => <span>{data ? data : ""}</span>,
      },
    ];
  };

  const getHemisTypeColumns = (): ColumnsType<IUzasboReport> => {
    return [
      {
        title: "JSHSHIR",
        dataIndex: "hemisPinfl",
        width: 160,
        align: "center",
      },
      {
        title: "Yaratilgan sana",
        dataIndex: "createTs",
        width: 160,
        align: "center",
      },
      {
        title: "Tahrirlangan sana",
        dataIndex: "updateTs",
        width: 160,
        align: "center",
      },
      {
        title: "O'chirilgan sana",
        dataIndex: "deleteTs",
        width: 160,
        align: "center",
        render: (data) => <span>{data ? data : ""}</span>,
      },
      {
        title: "Kod",
        dataIndex: "code",
        width: 160,
        align: "center",
      },
      {
        //@ts-ignore
        title: "FIO",
        dataIndex: "fullName",
        ...getColumnSearchProps("fullName"),
        width: 300,
        align: "center",
      },
      {
        title: "OTM kod",
        dataIndex: "universityCode",
        width: 160,
        align: "center",
      },
      {
        title: "OTM stir",
        dataIndex: "universityTin",
        width: 160,
        align: "center",
      },
      {
        title: "OTM nomi",
        dataIndex: "universityName",
        width: 300,
        align: "center",
      },
      {
        title: "Kurs kod",
        dataIndex: "courseCode",
        width: 160,
        align: "center",
      },
      {
        title: "Kurs",
        dataIndex: "courseName",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim shakli kod",
        dataIndex: "eduFormCode",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim shakli",
        dataIndex: "eduFormName",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim turi kod",
        dataIndex: "eduTypeCode",
        width: 160,
        align: "center",
      },
      {
        title: "Ta'lim turi",
        dataIndex: "eduTypeName",
        width: 160,
        align: "center",
      },
      {
        title: "To'lov shakli kod",
        dataIndex: "paymentFormCode",
        width: 160,
        align: "center",
      },
      {
        title: "To'lov shakli",
        dataIndex: "paymentFormName",
        width: 160,
        align: "center",
      },
      {
        title: "Talaba status kod",
        dataIndex: "studentStatusCode",
        width: 160,
        align: "center",
      },
      {
        title: "Talaba status",
        dataIndex: "studentStatusName",
        width: 160,
        align: "center",
      },
    ];
  };

  const getDoctoralTypeColumns = (): ColumnsType<IUzasboReport> => {
    return [
      {
        title: "Tashkilot nomi",
        dataIndex: "doctoralOrganizationName",
        width: 160,
        align: "center",
      },
      {
        title: "Joylashgan hudud",
        dataIndex: "doctoralOrganizationAddress",
        width: 160,
        align: "center",
      },
      {
        title: "Tashkilot turi",
        dataIndex: "doctoralOrganizationType",
        width: 160,
        align: "center",
      },
      {
        title: "Talabgorning F.I.O",
        dataIndex: "doctoralStudentFullName",
        width: 160,
        align: "center",
        render: (data) => <span>{data ? data : ""}</span>,
      },
      {
        title: "Ixtisoslik nomi",
        dataIndex: "doctoralSpecialityName",
        width: 160,
        align: "center",
      },
      {
        title: "Ixtisoslik shifri",
        dataIndex: "doctoralSpecialityCode",
        width: 300,
        align: "center",
      },
      {
        title: "Ta'lim bosqichi",
        dataIndex: "doctoralEduType",
        width: 160,
        align: "center",
      },
      {
        title: "O'qishga kirgan yili",
        dataIndex: "doctoralBeginYear",
        width: 160,
        align: "center",
      },
      {
        title: "JSHSHIR",
        dataIndex: "doctoralPinfl",
        width: 300,
        align: "center",
      },
    ];
  };

  const columns: ColumnsType<IUzasboReport> = [
    {
      title: "Uzasbo",
      dataIndex: "student_name",
      align: "center",
      children: getUzasboTypeColumns(),
    },
    {
      title: "kontrak.edu.uz",
      dataIndex: "kontrak.edu.uz",
      align: "center",
      children: getContractEduTypeColumns(),
    },
    {
      title: "OY",
      dataIndex: "month1",
      align: "center",
      width: 160,
    },
    {
      title: "Yil",
      dataIndex: "year1",
      align: "center",
      width: 160,
    },
    {
      title: "Hemis",
      dataIndex: "student_name",
      align: "center",
      children: getHemisTypeColumns(),
    },
    {
      title: "Doktorantura",
      dataIndex: "Doctorantura",
      align: "center",
      children: getDoctoralTypeColumns(),
    },
  ];

  const handlePaymentForm = (value: string) => {
    setPaymentStatus(value);
  };

  const handleEduType = (value: string) => {
    setEduTypeStatus(value);
  };

  const handleEduFrom = (value: string) => {
    setEduFormStatus(value);
  };
  const handleCourse = (value: string) => {
    setCourseStatus(value);
  };

  const handleDate = (value: any) => {
    setMonthAndYear({
      month: moment(value["$d"]).format("M"),
      year: moment(value["$d"]).format("YYYY"),
    });
  };

  function disabledDate(current: Date) {
    let customDate = moment().format("2022-09");
    return (
      (current && current < moment(customDate, "YYYY-MM")) ||
      current > moment(new Date(), "YYYY-MM")
    );
  }

  const handleChangeOtm = (value: string) => {
    setUniversity(value);
  };

  return (
    <div>
      <div className="uzasbo-search-container">
        <h2>
          Malumotlarni ko'rishingiz uchun eng kamida Universitet, va sanani tanlashingiz lozim
        </h2>
        <div className="filter-warapper">
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            style={{ width: 300 }}
            placeholder="OTM tanlang"
            onChange={handleChangeOtm}
            value={university}
            options={otmNameAndTin?.map((item) => ({ label: item.name, value: item.tin }))}
          />
          <DatePicker
            style={{ height: "36px", width: "230px" }}
            disabledDate={disabledDate}
            placeholder="Sanani tanlang"
            format={uzAsboDateFormat}
            picker="month"
            onChange={(e) => handleDate(e)}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talaba status"
            onChange={setStudentStatus}
            allowClear
            options={studentStatusList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="To'lov shakli"
            onChange={handlePaymentForm}
            allowClear
            options={paymentFormList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talim turi"
            onChange={handleEduType}
            allowClear
            options={eudTypeList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Talim shakli"
            onChange={handleEduFrom}
            allowClear
            options={eudFormList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Kurslari"
            onChange={handleCourse}
            allowClear
            options={courseList?.map((item) => ({ label: item.name, value: item.code }))}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Hemis bo'yicha"
            onChange={setExistInHemis}
            allowClear
            options={[
              {
                label: "Jami",
                value: "",
              },
              {
                label: "Hemisda mavjud",
                value: "true",
              },
              {
                label: "Hemisdan mavjud emas",
                value: "false",
              },
            ]}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Doktorantura bo'yicha"
            onChange={setExistInDoctorantura}
            allowClear
            options={[
              {
                label: "Jami",
                value: "",
              },
              {
                label: "Doktoranturada mavjud",
                value: "true",
              },
              {
                label: "Doktoranturada mavjud emas",
                value: "false",
              },
            ]}
          />
          <Select
            style={{ width: 230 }}
            placeholder="Uzasbo to'lov shakli"
            onChange={setIsbudget}
            allowClear
            options={[
              {
                label: "Jami",
                value: "",
              },
              {
                label: "Davlat granti",
                value: "true",
              },
              {
                label: "Kontrakt",
                value: "false",
              },
            ]}
          />
        </div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowSelectedBg: "#e6f4ff",
              rowSelectedHoverBg: "#bae0ff",
            },
          },
        }}
      >
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Uzasbo va hemis malumotlari jamlanmasi</h3>

              <DownloadButton
                downloadUrl="/uzasbohemis/uzasboAndHemis/excel"
                params={{
                  ...monthAndYear,
                  orgInn: university,
                  existInHemis: inHemis ? inHemis : undefined,
                  existInDoctoral: inDoctorantura ? inDoctorantura : undefined,
                  isbudget: isbudget ? isbudget : undefined,
                  courseCode: courseStatus,
                  eduFormCode: eduFormStatus,
                  eduTypeCode: eduTypeStatus,
                  studentStatusCode: studentStatus,
                  paymentFormCode: paymentStatus,
                }}
              />
            </div>
          )}
          bordered
          rowKey={(val) =>
            `${val.organizationInn}/${val.uzasboId}/${val.tableId}/${val.uzasboPinfl}`
          }
          dataSource={currentData}
          columns={columns}
          pagination={tablePaginationConfig}
          rowSelection={{ type: "radio", columnWidth: 40 }}
          size="small"
          loading={isFetching}
          virtual={true}
          scroll={{ x: 3200, y: 600 }}
        />
      </ConfigProvider>
    </div>
  );
}
