export const themeColors = {
  light: {
    primary: "#303940",
    secondary: "#5A6167",
    tertiary: "#979EA4",
    white: "#ffffff",
    black: "#000000",
    divider_primary: "#e3e4e5",
    bg_secondary: "#F0F3F7",
    bg_tertiary: "#DFE2E5",
    brand_secondary: "#DEEDFA",
    green: "#43B1A0",
    light_green: "#A1DD75",
    pink: "#FF6482",
    blue: "#4DA2F1",
    light_blue: "#84D1ED",
    yellow: "#FFD426",
    light_yellow: "#e7f2a3",
    orange: "#ff7f00",
    light_orange: "#f2e2a3",
    deep_purple: "#7D7AFF",
    azureish_white: "#deedfa",
    nyanza: "#DFFADE",
    cultured: "#F4F5F7",
  },
  dark: {
    primary: "#FFFFFF", //
    secondary: "#8B98A5", //
    tertiary: "#979EA4", //
    white: "#0f1a30", //
    black: "#000000",
    divider_primary: "#9ABCD91F", //
    bg_secondary: "#15213a", //
    bg_tertiary: "#DFE2E5",
    brand_secondary: "#1b324b",
    green: "#2FD158", //
    light_green: "#A1DD75", //
    pink: "#FF6482", //
    blue: "#4DA2F1", //
    light_blue: "#84D1ED", //
    yellow: "#FFD426", //
    light_yellow: "#474939", //
    orange: "#ff7f00", //
    light_orange: "#535044", //
    deep_purple: "#7D7AFF", //
    azureish_white: "#2c3b47", //
    nyanza: "#7b8f7a", //
    cultured: "#293440",
  },
};
