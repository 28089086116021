import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

export default function PrivateRoute({ children }: { children: ReactNode }) {
  const isAuthorized = localStorage.getItem("token");
  if (!isAuthorized) {
    return <Navigate to="/" replace />;
  }

  return children;
}
