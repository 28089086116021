import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  ages,
  citizenships,
  courses,
  educationForms,
  genders,
  paymentForms,
  residencePlaces,
} from "src/pages/constants";
import useEduForm from "./useEduForm";
import './edu.scss'

function EduFormPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);

  const { gendersData, agesData, citizenshipData, paymentData, placesData, coursesData } = useEduForm();
  return (
    <div className="eduform">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: gendersData?.men_total },
            { name: t("genders.women"), count: gendersData?.women_total },
          ]}
          graphHeight={320}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={educationForms}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: gendersData?.men_data,
              },
              {
                name: t("genders.women"),
                data: gendersData?.women_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-130}
            yAxisMargin={180}
          />
        </GraphInfoCard>

        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            { name: t("ages.under_30"), count: agesData?.less_total },
            { name: t("ages.over_30"), count: agesData?.greater_total },
          ]}
          graphHeight={320}
        >
          <BarChartCategory
            legendData={ages}
            yAxisData={educationForms}
            color={[colors.light_blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("ages.under_30"),
                data: agesData?.less_data,
              },
              {
                name: t("ages.over_30"),
                data: agesData?.greater_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-130}
            yAxisMargin={180}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Citizenships */}
        <GraphInfoCard
          title={t("header.citizenship")}
          stat={[
            { name: t("citizenships.uzbek"), count: citizenshipData?.uzbek_total },
            { name: t("citizenships.foreigner"), count: citizenshipData?.foreigner_total },
            { name: t("citizenships.stateless"), count: citizenshipData?.stateless_total },
          ]}
          graphHeight={460}
        >
          <BarChartCategory
            legendData={citizenships}
            yAxisData={educationForms}
            color={[colors.yellow, colors.light_green, colors.blue]}
            seriesArr={[
              {
                name: t("citizenships.uzbek"),
                data: citizenshipData?.uzbek_data,
                stack: "one",
              },
              {
                name: t("citizenships.foreigner"),
                data: citizenshipData?.foreigner_data,
                stack: "one",
              },
              {
                name: t("citizenships.stateless"),
                data: citizenshipData?.stateless_data,
                stack: "one",
              },
            ]}
            gridLeft={-130}
            yAxisMargin={180}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Paymeny type */}
        <GraphInfoCard
          title={t("header.payment_form")}
          stat={[
            { name: t("pay_form.grant"), count: paymentData?.grant_total },
            { name: t("pay_form.contract"), count: paymentData?.contract_total },
          ]}
          graphHeight={460}
        >
          <BarChartCategory
            legendData={paymentForms}
            yAxisData={educationForms}
            color={[colors.orange, colors.light_blue]}
            seriesArr={[
              {
                name: t("pay_form.grant"),
                data: paymentData?.grant_data,
                stack: "one",
              },
              {
                name: t("pay_form.contract"),
                data: paymentData?.contract_data,
                stack: "one",
              },
            ]}
            gridLeft={-130}
            yAxisMargin={180}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Courses */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            { name: t("courses.course_1"), count: coursesData?.course_1_total },
            { name: t("courses.course_2"), count: coursesData?.course_2_total },
            { name: t("courses.course_3"), count: coursesData?.course_3_total },
            { name: t("courses.course_4"), count: coursesData?.course_4_total },
            { name: t("courses.course_5"), count: coursesData?.course_5_total },
            { name: t("courses.course_6"), count: coursesData?.course_6_total },
          ]}
          graphHeight={460}
        >
          <BarChartCategory
            legendData={courses}
            yAxisData={educationForms}
            color={[colors.blue, colors.pink, colors.yellow, colors.light_green, colors.light_blue, colors.orange]}
            seriesArr={[
              {
                name: t("courses.course_1"),
                data: coursesData?.course_1_data,
                stack: "one",
              },
              {
                name: t("courses.course_2"),
                data: coursesData?.course_2_data,
                stack: "one",
              },
              {
                name: t("courses.course_3"),
                data: coursesData?.course_3_data,
                stack: "one",
              },
              {
                name: t("courses.course_4"),
                data: coursesData?.course_4_data,
                stack: "one",
              },
              {
                name: t("courses.course_5"),
                data: coursesData?.course_5_data,
                stack: "one",
              },
              {
                name: t("courses.course_6"),
                data: coursesData?.course_6_data,
                stack: "one",
              },
            ]}
            gridLeft={-130}
            yAxisMargin={180}
          />
        </GraphInfoCard>
      </div>
      
      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            { name: t("residence_places.own_house"), count: placesData?.own_house_total },
            { name: t("residence_places.dormitory"), count: placesData?.dormitory_total },
            { name: t("residence_places.rented_house"), count: placesData?.rented_house_total },
            { name: t("residence_places.relative_house"), count: placesData?.relative_house_total },
            { name: t("residence_places.familiar_house"), count: placesData?.familiar_house_total },
          ]}
          graphHeight={460}
        >
          <BarChartCategory
            legendData={residencePlaces}
            yAxisData={educationForms}
            color={[colors.pink, colors.blue, colors.orange, colors.deep_purple, colors.light_blue]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: placesData?.own_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: placesData?.dormitory_data,
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: placesData?.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: placesData?.relative_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: placesData?.familiar_house_data,
                stack: "one",
              },
            ]}
            gridLeft={-130}
            yAxisMargin={180}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t('header.students_info_text')}</p>
      </div>
    </div>
  );
}

export default EduFormPage;
