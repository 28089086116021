import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProfCourseStatMutation } from "src/app/services/prof_education";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  genders,
  prof_ages,
  prof_courses,
  prof_courses_two,
  residencePlaces,
} from "src/pages/constants";
import { IStatCourse } from "src/pages/type";
import { makeProfCourseData } from "../util";

export default function ProfCoursesPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [getFunc, { data }] = useGetProfCourseStatMutation();
  const [stat, setStat] = useState<IStatCourse>();

  useEffect(() => {
    getFunc();
  }, []);

  useEffect(() => {
    if (data) {
      setStat(makeProfCourseData(data));
    }
  }, [data]);

  return stat ? (
    <div className="eduform">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: stat.gender.men_total },
            { name: t("genders.women"), count: stat.gender.women_total },
          ]}
          graphHeight={332}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={prof_courses_two}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: stat.gender.men_data,
              },
              {
                name: t("genders.women"),
                data: stat.gender.women_data,
              },
            ]}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-30}
            yAxisMargin={110}
          />
        </GraphInfoCard>

        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            { name: t("ages.under_20"), count: stat.age.under_20_total },
            { name: t("ages.over_20"), count: stat.age.over_20_total },
          ]}
          graphHeight={332}
        >
          <BarChartCategory
            legendData={prof_ages}
            yAxisData={prof_courses_two}
            color={[colors.light_blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("ages.under_20"),
                data: stat.age.under_20_data,
              },
              {
                name: t("ages.over_20"),
                data: stat.age.over_20_data,
              },
            ]}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-30}
            yAxisMargin={110}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            { name: t("residence_places.own_house"), count: stat.residence_place.own_house_total },
            { name: t("residence_places.dormitory"), count: stat.residence_place.dormitory_total },
            {
              name: t("residence_places.rented_house"),
              count: stat.residence_place.rented_house_total,
            },
            {
              name: t("residence_places.relative_house"),
              count: stat.residence_place.relative_house_total,
            },
            {
              name: t("residence_places.familiar_house"),
              count: stat.residence_place.familiar_house_total,
            },
            { name: t("residence_places.other"), count: stat.residence_place.other_total },
          ]}
        >
          <BarChart
            legendData={residencePlaces}
            xAxisData={prof_courses}
            color={[
              colors.pink,
              colors.light_blue,
              colors.orange,
              colors.deep_purple,
              colors.green,
            ]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: stat.residence_place.own_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: stat.residence_place.dormitory_data,
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: stat.residence_place.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: stat.residence_place.relative_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: stat.residence_place.familiar_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.other"),
                data: stat.residence_place.other_data,
                stack: "one",
              },
            ]}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t("header.prof_info_text")}</p>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
