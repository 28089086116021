import { api } from "../api";
import {
  IRegion,
  IStaffByYears,
  IStudentByCourseAndRegion,
  IStudentsAgeAndRegion,
  IStudentsByCourse,
  IStudentsByYears,
  IStudentsCitizenship,
  IStudentsGender,
  IUniversityRes,
} from "./type";

export const universitiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    //Get universities endpoint
    getUniversities: build.mutation<IUniversityRes, string>({
      query: (params) => ({
        url: "https://prof-emis.edu.uz/api/universities/list/?" + params,
      }),
    }),
    getRegions: build.query<IRegion[], void>({
      query: () => ({
        url: "https://prof-emis.edu.uz/api/universities/region/",
      }),
    }),
    // Get students gender endpoint
    getStudentsGender: build.query<IStudentsGender, void>({
      query: () => ({
        url: "https://emis.edu.uz/api/v1/statistics/students-gender",
      }),
    }),
    // Get students citizenship endpoint
    getStudentsCitizenship: build.query<IStudentsCitizenship, void>({
      query: () => ({
        url: "https://emis.edu.uz/api/v1/statistics/students-citizenships",
      }),
    }),
    // Get students by years endpoint
    getStudentsByYears: build.query<IStudentsByYears, void>({
      query: () => ({
        url: "https://emis.edu.uz/api/v1/statistics/students-by-years",
      }),
    }),
    // Get students by course endpoint
    getStudentsByCourse: build.query<IStudentsByCourse, void>({
      query: () => ({
        url: "https://emis.edu.uz/api/v1/statistics/students-by-course",
      }),
    }),
    // Get staff by gender endpoint
    getStaffByGender: build.query<IStudentsGender, void>({
      query: () => ({
        url: "https://emis.edu.uz/api/v1/statistics/staff-by-genders",
      }),
    }),
    // Get staff by gender endpoint
    getStaffByYears: build.query<IStaffByYears, void>({
      query: () => ({
        url: "https://emis.edu.uz/api/v1/statistics/staff-by-years",
      }),
    }),
    // Get students by course and region endpoint
    getStudentsByCourseAndRegion: build.query<IStudentByCourseAndRegion, void>({
      query: () => ({
        url: "https://emis.edu.uz/api/v1/statistics/students-by-course-and-region",
      }),
    }),
    // Get students by age and region endpoint
    getStudentsByAgeAndRegion: build.query<IStudentsAgeAndRegion, void>({
      query: () => ({
        url: "https://emis.edu.uz/api/v1/statistics/students-by-years-and-region",
      }),
    }),

  }),
});

export const {
  useGetUniversitiesMutation,
  useGetRegionsQuery,
  useGetStudentsGenderQuery,
  useGetStudentsCitizenshipQuery,
  useGetStudentsByYearsQuery,
  useGetStudentsByCourseQuery,
  useGetStaffByGenderQuery,
  useGetStaffByYearsQuery,
  useGetStudentsByCourseAndRegionQuery,
  useGetStudentsByAgeAndRegionQuery,
} = universitiesApi;
