import { useEffect, useState } from "react";
import {
  useGetEduTypeAndAgeQuery,
  useGetEduTypeAndGenderQuery,
  useGetEduTypeAndPaymentTypeQuery,
  useGetEduTypeByCourseQuery,
  useGetTypeAndAccommodationQuery,
  useGetTypeAndCitizenshipQuery,
  useGetEduTypeAndEduFormQuery,
} from "src/app/services/students";
import {
  IStudentAge,
  IStudentCitizenship,
  IStudentCourse,
  IStudentEduForm,
  IStudentGender,
  IStudentPayment,
  IStudentPlace,
} from "../type";
import {
  makeStudentAge,
  makeStudentCitizenship,
  makeStudentCourse,
  makeStudentEduForm,
  makeStudentGender,
  makeStudentPayment,
  makeStudentPlace,
} from "../util";
import { edu_type_stat } from "src/pages/constants";
import { IEDUTypeCard } from "src/pages/type";

function useEduType() {
  // Genders section
  const [gendersData, setGenders] = useState<IStudentGender>();
  const { data: genders } = useGetEduTypeAndGenderQuery();
  useEffect(() => {
    if (genders) setGenders(makeStudentGender(genders));
  }, [genders]);

  // Ages section
  const [agesData, setAges] = useState<IStudentAge>();
  const { data: ages } = useGetEduTypeAndAgeQuery();
  useEffect(() => {
    if (ages) setAges(makeStudentAge(ages));
  }, [ages]);

  // Citizenship section
  const [citizenshipData, setCitizenship] = useState<IStudentCitizenship>();
  const { data: citizenships } = useGetTypeAndCitizenshipQuery();
  useEffect(() => {
    if (citizenships) setCitizenship(makeStudentCitizenship(citizenships));
  }, [citizenships]);

  // Payment section
  const [paymentData, setPayment] = useState<IStudentPayment>();
  const { data: payments } = useGetEduTypeAndPaymentTypeQuery();
  useEffect(() => {
    if (payments) setPayment(makeStudentPayment(payments));
  }, [payments]);

  // Courses section
  const [coursesData, setCourses] = useState<IStudentCourse>();
  const { data: courses } = useGetEduTypeByCourseQuery();

  useEffect(() => {
    if (courses) setCourses(makeStudentCourse(courses));
  }, [courses]);

  // Places section
  const [placesData, setPlaces] = useState<IStudentPlace>();
  const { data: places } = useGetTypeAndAccommodationQuery();
  useEffect(() => {
    if (places) setPlaces(makeStudentPlace(places));
  }, [places]);

  // EduForm section
  const [eduFormData, setEduForm] = useState<IStudentEduForm>();
  const { data: eduForm } = useGetEduTypeAndEduFormQuery();
  useEffect(() => {
    if (eduForm) setEduForm(makeStudentEduForm(eduForm));
  }, [eduForm]);

  // EduType section
  const [eduTypeData, setEduType] = useState<IEDUTypeCard[]>();
  const { data: eduType } = useGetEduTypeAndGenderQuery();
  useEffect(() => {
    if (eduType && eduType.length > 0) {
      const res = edu_type_stat.map((item) => {
        const edufiltered = eduType?.filter((el) => el.eduType === item.key);
        return {
          ...item,
          total: edufiltered!.reduce((acc, el) => {
            return acc + el.count;
          }, 0),
          man: edufiltered!.find((item) => item.name === "Erkak")!.count,
          women: edufiltered!.find((item) => item.name === "Ayol")!.count,
        };
      });
      setEduType(res);
    }
  }, [eduType]);

  return {
    gendersData,
    agesData,
    citizenshipData,
    paymentData,
    coursesData,
    placesData,
    eduFormData,
    eduTypeData,
    courses,
    eduForm,
  };
}

export default useEduType;
