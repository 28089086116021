import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useGetRegionsQuery } from "src/app/services/universities";
import { useTypedSelector } from "src/app/store";
import { ArrowDownIcon } from "src/assets/svg";
import { giveLan } from "src/utils";

function RegionFilter() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const [searchParams, setSearchParams] = useSearchParams();
  const { Option } = Select;

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const handleChange = (val: string) => {
    handleMakeParams("region", val);
    handleMakeParams("page", "1");
  };

  const { data } = useGetRegionsQuery();

  return (
    <Select
      style={{ width: 176 }}
      allowClear
      placeholder={t("filters.region")}
      onChange={handleChange}
      defaultValue={searchParams.get("region")}
      suffixIcon={<ArrowDownIcon color={colors.primary} />}
    >
      {data?.map((item) => (
        <Option key={item.id + item.name_uz} value={String(item.id)}>
          {giveLan(item)}
        </Option>
      ))}
    </Select>
  );
}

export default RegionFilter;
