import Uzbekistan from "./map";
import { SVGMap } from "react-svg-map";
import { useState } from "react";
import "./styles.css";
import { useGetUniversitiesByRegionQuery } from "src/app/services/students";

function getLocationName(event: { target: { attributes: { name: { value: any } } } }) {
  return event.target.attributes.name.value;
}

type PointedLocation = {
  name: null | string;
  count?: number;
};

export const RegionMap = () => {
  const { data: regions = [] } = useGetUniversitiesByRegionQuery();
  const [pointedLocation, setPointedLocation] = useState<PointedLocation>({
    name: null,
    count: undefined,
  });
  const [tooltipStyle, setTooltipStyle] = useState({
    display: "none",
  });

  const handleLocationMouseOver = (event: any) => {
    const pointedLocation = getLocationName(event);
    setPointedLocation({
      name: pointedLocation,
      count: regions.find((el) => el.name === pointedLocation)?.count,
    });
  };

  function handleLocationMouseOut() {
    setPointedLocation({ name: null });
    setTooltipStyle({ display: "none" });
  }

  function handleLocationMouseMove(event: { clientY: number; clientX: number }) {
    const tooltipStyle = {
      display: "block",
      top: event.clientY - 80,
      left: event.clientX - 100,
    };
    setTooltipStyle(tooltipStyle);
  }

  return (
    <div>
      <h3 className="map_title">OTMlar joylashish hududi bo'yicha</h3>
      <section>
        <SVGMap
          className="region_map"
          locationClassName="region_map__location"
          map={Uzbekistan}
          onLocationMouseOver={handleLocationMouseOver}
          onLocationMouseOut={handleLocationMouseOut}
          onLocationMouseMove={handleLocationMouseMove}
        />
        <div className="region_map__tooltip" style={tooltipStyle}>
          <h3>{pointedLocation.name}</h3>
          {pointedLocation.count && <p>Jami: {pointedLocation.count}</p>}
        </div>
      </section>
    </div>
  );
};
