import { TablePaginationConfig } from "antd";

export const baseUrl_Prof = "https://prof-emis.edu.uz/api/";
export const baseUrl_Hemis = "https://stat.edu.uz/api/";
export const baseUrl_Doctoral = "https://api-phd.mininnovation.uz/api";
export const apiTagTypes = ["Auth"];

export const BASE_URL = "EDU_STATISTIC_BASE_URL";
export const baseUrlStrore = localStorage.getItem(BASE_URL) || baseUrl_Hemis;

export const tablePaginationConfig: TablePaginationConfig = {
  showSizeChanger: true,
  pageSizeOptions: [10, 20, 30, 50, 100, 200],
  size: "small",
  defaultPageSize: 30,
};

export const tablePaginationConfigTwo: TablePaginationConfig = {
  showSizeChanger: true,
  pageSizeOptions: [10, 20, 30, 50, 100, 200],
  size: "small",
  defaultPageSize: 10,
};
