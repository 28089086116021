import { ITablesBase, TablesUniversityData } from "src/app/services/tables/type";

export const renderEmptyValue = (value: number) => value || 0;

export const tablesFilter = (data?: ITablesBase[]) => {
  const all = {};
  data?.forEach((item) => {
    if (!all.hasOwnProperty(item.univerCode)) {
      Object.assign(all, {
        [item.univerCode]: {
          univerCode: item.univerCode,
          univerName: item.univerName,
          ownerShip: item.ownerShip,
          total: item.count,
          [`${item.eduType}${item.name}`]: item.count,
        },
      });
    } else {
      // @ts-ignore
      Object.assign(all[item.univerCode], {
        [`${item.eduType}${item.name}`]: item.count,
        // @ts-ignore
        total: all[item.univerCode].total + item.count,
      });
    }
  });
  return Object.values(all) as TablesUniversityData[];
};
