import { Button, Input, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType, ColumnType } from "antd/es/table";
import { IDoctoralCountByOrgDirection } from "src/app/services/doctoral/type";
import { ILanguage } from "src/app/services/api/type";
import { language } from "src/provider/language/const";
import { useGetDoctoralCountByOrgDirectionQuery } from "src/app/services/doctoral";
import { tablePaginationConfig } from "src/app/services/api/const";
import { useNavigate } from "react-router-dom";

type DataIndex = keyof IDoctoralCountByOrgDirection;

export function DoctoralCountByOrgDirectionTable({ orgId }: { orgId: string | null }) {
  const { t } = useTranslation();
  const { data, isLoading } = useGetDoctoralCountByOrgDirectionQuery(orgId, {
    skip: !orgId,
  });

  const navigate = useNavigate();

  if (!orgId) return null;

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<IDoctoralCountByOrgDirection> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<span>&#128269;</span>}
            size="small"
            style={{ width: 90 }}
          >
            {t("doctoral.search")}
          </Button>
          <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
            {t("doctoral.reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            {t("doctoral.filter")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {t("doctoral.close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <span>&#128269;</span>,
    onFilter: (value, record) =>
      (record[dataIndex] as ILanguage)[language]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
  });

  const columns: ColumnsType<IDoctoralCountByOrgDirection> = [
    {
      title: t("doctoral.direction"),
      dataIndex: "name",
      render: (name: ILanguage) => <span>{name[language]}</span>,
      ...getColumnSearchProps("name"),
    },
    {
      title: t("doctoral.doctoral"),
      dataIndex: "100",
      align: "center",
    },
    {
      title: t("doctoral.support_doctoral"),
      dataIndex: "200",
      align: "center",
    },
    {
      title: t("doctoral.intern_researcher"),
      dataIndex: "300",
      align: "center",
    },
    {
      title: t("doctoral.dc_independent_researcher"),
      dataIndex: "400",
      align: "center",
    },
    {
      title: t("doctoral.phd_independent_researcher"),
      dataIndex: "500",
      align: "center",
    },
    {
      title: t("doctoral.dc_purposeful_doctoral"),
      dataIndex: "600",
      align: "center",
    },
    {
      title: t("doctoral.phd_purposeful_doctoral"),
      dataIndex: "700",
      align: "center",
    },
    {
      title: t("doctoral.total"),
      dataIndex: "total",
      align: "center",
    },
  ];

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="doctoralOrganization">
      <header className="doctoral_table_header">
        <Button size="small" onClick={navigateBack}>
          {`<`}
        </Button>
        <h3>{t("doctoral.count_doc_by_org_direction")}</h3>
      </header>
      <Table
        size="small"
        rowKey="id"
        scroll={{ x: true }}
        dataSource={data}
        columns={columns}
        pagination={tablePaginationConfig}
        loading={isLoading}
      />
    </div>
  );
}
