import { useNavigate, useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import { useEffect } from "react";

const Auth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      navigate("/uzasbo", { replace: true });
    }
  }, []);

  return <Spin spinning={true} />;
};

export default Auth;
