import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { themeColors } from "src/static/theme";
import { IThemeColor } from "src/static/theme/type";

export interface ILayoutState {
  darkMode: boolean;
  colors: IThemeColor;
  viewOption: string;
}

export const THEME_COLOR = "EDU_STATISTIC_THEME_COLOR";
export const VIEW_OPTION = "EDU_STATISTIC_VIEW_OPTION";

export const isDarkTheme = localStorage.getItem(THEME_COLOR) === "dark";

const initialState: ILayoutState = {
  darkMode: isDarkTheme,
  colors: isDarkTheme ? themeColors.dark : themeColors.light,
  viewOption: localStorage.getItem(VIEW_OPTION) || "graphic",
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload === "light") state.darkMode = false;
      else if (action.payload === "dark") state.darkMode = true;
      else state.darkMode = !state.darkMode;

      if (state.darkMode) {
        localStorage.setItem(THEME_COLOR, "dark");
        state.colors = themeColors.dark;
      } else {
        localStorage.setItem(THEME_COLOR, "light");
        state.colors = themeColors.light;
      }

      for (const key in state.colors) {
        document.documentElement.style.setProperty("--color_" + key, state.colors[key as keyof IThemeColor]);
      }
    },
    changeViewOption: (state, action: PayloadAction<string>) => {
      state.viewOption = action.payload;
      localStorage.setItem(VIEW_OPTION, action.payload);
    },
  },
});

export const { changeTheme, changeViewOption } = layoutSlice.actions;

export default layoutSlice.reducer;
