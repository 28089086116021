export const languageRu = {
  header: {
    statistics: "Статистика",
    university: "Университет",
    graphic_visible: "Видимость графики",
    table_visible: "Видимость таблицы",
    edu_type: "Тип образования",
    edu_form: "Форма образования",
    payment_form: "Форма оплаты",
    citizenship: "Гражданство",
    courses: "Курсы",
    age: "Возраст",
    residence_place: "Место проживания",
    regions_section: "Провинция",
    by_gender: "По полу",
    dark_mode: "Тёмный режим",
    download: "Скачать",
    download_as_png: "Загрузить в формате PNG",
    download_as_csv: "Загрузить в формате CSV",
    download_as_pdf: "Загрузить в формате PDF",
    students_info_text:
      "Информация поступает в режиме реального времени из информационной системы HEMIS.",
    lyceum_info_text:
      "Информация поступает в режиме реального времени из информационной системы emis.edu.uz.",
    prof_info_text:
      "Информация поступает из информационной системы prof.edu.uz в режиме реального времени.",
    doctoral_info_text:
      "Информация поступает в режиме реального времени из информационной системы phd.mininnovation.uz.",
    menu: {
      higher: "Высшее образование",
      lyceum: "Академический лицей",
      prof: "Профессиональное образование",
      admission: "Допуск",
      students: "Студенты",
      teachers: "Учителя",
      universities: "Список вузов",
      table_second_tab: "В разделе направления",
      table_third_tab: "В разделе учителей",
      learners: "Учащиеся",
      lyceums: "Академические лицеи",
      institutions: "Учреждения",
      doctoral: "Докторантура",
      doctoral_common: "Общий",
      doctoral_by_regions: "По регионам",
      doctoral_by_directions: "По направлениям",
      tables: "Таблицы",
    },
  },
  footer: {
    contact_center: "Контактный центр",
    privacy_policy: "Политика конфиденциальности",
    ministry:
      "Министерство высшего образования, науки и инноваций УзРес на 2020-2023 годы",
    owner: "Центр развития цифровых образовательных технологий",
    producer: "Продюсер",
  },
  edu_type: {
    bachelor: "Бакалавр",
    master: "Магистр",
    ordinance: "Ординатура",
    doctorate_phd: "Докторантура PhD",
    doctorate_dsc: "Докторская DSc",
    common: "Общий",
    vocational_schools: "Профессиональные училища",
    colleges: "Колледжи",
    technical_schools: "Технические школы",
    vocational_school: "Профессиональное училище",
    college: "Колледж",
    technical_school: "Техникум",
    lyceum: "Академический лицей",
    transfer: "Перенос",
    second_specialty: "Вторая специальность",
    bachelor_technical_school:
      "Бакалавр (по собеседованию, выпускники техникума)",
  },
  edu_form: {
    daytime: "Дневное время",
    evening: "Вечер",
    externally: "Внешне",
    special_externally: "Особый внешний",
    joint: "Джойнт",
    remote: "Удаленный",
    dual: "Дуальное",
    second_evening: "Второе высшее (вечернее)",
    second_daytime: "Второе высшее (дневное время)",
    second_external: "Второе высшее (внешнее)",
  },
  pay_form: {
    grant: "Государственный грант",
    contract: "Платежный договор",
  },
  citizenships: {
    uzbek: "Гражданин Узбекистана",
    foreigner: "Гражданин чужой страны",
    stateless: "Лицо без гражданства",
    teen: "Несовершеннолетний",
  },
  courses: {
    course_1: "Курс 1",
    course_2: "Курс 2",
    course_3: "Курс 3",
    course_4: "Курс 4",
    course_5: "Курс 5",
    course_6: "Курс 6",
  },
  ages: {
    under_30: "до 30",
    over_30: "более 30",
    under_20: "до 20",
    over_20: "более 20",
    under_17: "до 17",
    over_17: "более 17",
    until_30: "до 30",
    age_30_35: "возраст 30-35",
    age_35_40: "возраст 35-40",
    age_40_45: "возраст 40-45",
    age_45_50: "возраст 45-50",
    age_50_55: "возраст 50-55",
    age_55_60: "возраст 55-60",
    over_60: "возраст 60+",
  },
  residence_places: {
    own_house: "В собственном доме",
    dormitory: "В студенческом общежитии",
    rented_house: "В арендованном доме",
    relative_house: "В доме родственника",
    familiar_house: "В доме знакомого",
    other: "Другой",
  },
  genders: {
    men: "Мужчины",
    women: "Женщины",
  },
  filters: {
    region: "Джойшаганский район",
    search: "Введите название университета для поиска",
    ownership: "Форма собственности",
    state: "Штат",
    non_state: "Негосударственное",
    foreign: "Иностранный",
    joint: "Джойнт",
  },
  teachers: {
    by_gender: "Учителя (по полу)",
    total_count: "Общее количество",
    staff: {
      leaders: "Руководящий состав",
      prorektor: "Вице-канцлер",
      dekan: "Декан",
      department_header: "Руководитель отдела",
    },
    s_potential: {
      title: "О научном потенциале учителя",
      skilled: "Опытный",
      unskilled: "Неквалифицированный",
    },
    s_level: {
      title: "Учителя по степеням",
      candidate_phd: "Кандидат наук, PhD",
      doctor_dcs: "Доктор наук, доктор наук",
    },
    ac_title: {
      title: "Педагоги по ученым званиям",
      untitled: "Без названия",
      docent: "Доцент",
      senior: "Старший научный сотрудник",
      professor: "Профессор",
    },
    position: {
      title: "По должности",
      intern: "Стажер-преподаватель",
      assistent: "Ассистент",
      senior: "Старший преподаватель",
    },
    work_type: {
      title: "По форме работы",
      main: "Основная работа",
      seat_external: "Сиденье (внешнее)",
      per_hour: "Почасово",
      seat_internal_add: "Место(внутреннее-добавить)",
      seat_internal_main: "Сиденье (внутреннее основное)",
    },
    by_citizenship: "Учителя по гражданству",
    by_age: "Учителя по возрасту",
  },
  university: {
    name: "Название высшего учебного заведения",
    list: "Список высших учебных заведений",
    website: "Веб-сайт",
    license_pdf: "Лицензия.PDF",

    types: {
      list: "Список университетов",
      search: "Поиск университета",
    },
  },
  admission: {
    title: "Статистика заявок за 2021-2023 годы",
    over_years: "За несколько лет",
    applications: "Количество приложений",
  },
  lyceum: {
    list: "Список академических лицеев",
    name: "Название Академического Лицея",
  },
  prof: {
    list: "Перечень профессиональных образовательных учреждений",
    name: "Название учреждения",
    type: "Тип учреждения",
    by_region: "По провинциям",
    region_name: "Название провинции",
    province_code: "Код региона",
    code: "Код учреждения",
  },
  doctoral: {
    doctoralStat: "Докторантура по статистике",
    dc_count: "Количество кандидатов (доктор наук)",
    dc_count_woman: "Количество кандидатов-женщин (доктор наук)",
    dc_count_man: "Количество кандидатов-мужчин (доктор наук)",
    phd_count: "Количество кандидатов (PhD)",
    phd_count_man: "Количество кандидатов-мужчин (докторов философии)",
    phd_count_woman: "Количество женщин-исследователей (докторов наук)",
    all_count: "общее количество подписчиков",
    all: "Все",
    under_40: "Не старше 40 лет",
    organization: {
      "100": "Высшие учебные заведения",
      "200": "Научные организации",
      "300": "Министерства",
      org_type: "ТИП ОРГАНИЗАЦИИ",
      org_count: "НОМЕР ОРГАНИЗАЦИИ",
      usr_org_count: "КОЛИЧЕСТВО ОБЪЕДИНЕННЫХ АДМИНОВ",
    },
    organizations: "Организации",
    org: "ОРГАНИЗАЦИЯ",
    org_count_by_region: "Количество университетов и институтов в провинции",
    count_by_researcher_region:
      "Число докторантов и научных руководителей в области",
    count_org_researcher_region:
      "Численность докторантов в организации региональная.",
    count_doc_by_org_direction:
      "Количество докторантов по направлению организации",
    count_stu_by_org_direction:
      "Количество студентов по направлению организации",
    count_doc_by_direction: "Количество докторантов по направлениям",
    higher_education: "Высшее учебное заведение",
    scientific_education: "Научная организация",
    placed_region: "РАСПОЛОЖЕНИЕ",
    itm_scientific_count: "КОЛИЧЕСТВО НАУЧНЫХ РУКОВОДИТЕЛЕЙ В ИТМ",
    otm_scientific_count: "КОЛИЧЕСТВО НАУЧНЫХ РУКОВОДИТЕЛЕЙ В ОТМ",
    itm_doctoral_count: "ЧИСЛО ДОКТОРАНТОВ В ИТМ",
    otm_doctoral_count: "КОЛИЧЕСТВО ДОкторантов В UTM",
    doctoral: "Докторантура",
    support_doctoral: "БАЗОВАЯ ДОКТОРАНтура",
    intern_researcher: "СТАЖЕР-ИССЛЕДОВАТЕЛЬ",
    dc_independent_researcher: "НЕЗАВИСИМЫЙ ИССЛЕДОВАТЕЛЬ, ДНЦ",
    phd_independent_researcher:
      "НЕЗАВИСИМЫЙ ИССЛЕДОВАТЕЛЬ, кандидат медицинских наук",
    dc_purposeful_doctoral: "ЦЕЛЕВАЯ ДОКТОРАНТА, DSC",
    phd_purposeful_doctoral: "ЦЕЛЕВАЯ ДОКТОРАНтура, PHD",
    total: "ОБЩИЙ",
    actions: "ДЕЙСТВИЯ",
    direction: "СПЕЦИАЛИЗАЦИЯ",
    direction_code: "Код направления",
    science: "НАУКА",
    code: "КОД",
    search: "Поиск",
    reset: "Очистить",
    close: "Закрыть",
    filter: "Фильтр",
  },
  regions: {
    andijan: "Andijon",
    bukhara: "Buxoro",
    fergana: "Farg‘ona",
    jizzax: "Jizzax",
    namangan: "Namangan",
    navai: "Navoiy",
    qashqadaryo: "Qashqadaryo",
    samarqand: "Samarqand",
    qoraqalpoq: "Qoraqalpog‘iston Resp",
    sirdaryo: "Sirdaryo",
    surxondaryo: "Surxondaryo",
    tashkent_city: "Toshkent shahri",
    tashkent_region: "Toshkent viloyati",
    xorazm: "Xorazm",
  },
  tables: {
    univerName: "Название университета",
    ownership: "Собственность",
    univerCode: "Код",
    total: "ИТОГО",
    universityByRegion: "Адрес постоянного проживания по регионам",
    title_bachelor: "Направления находятся в разделе Бакалавриат.",
    title_magistr: "Направления находятся в разделе Магистратура",
    title_gender: "По типу образования и полу",
    universityByGender: "Пол",
    man: "Мужчина",
    woman: "Женщина",
    bachelor: "Бакалавр",
    master: "Магистр",
    paymentType: "Тип платежа",
    eduType: "Тип образования",
    eduForm: "Форма образования",
    eduFormByRegion: "Форма образования и профиль региона расположения вузов",
    edu_direction: "Курс обучения",
    direction_code: "Код направления",
    speciality: "Специальность",
    speciality_code: "Код специальности",
    bachelor_by_region: "Количество бакалавров по регионам",
    bachelor_count: "Количество бакалавров",
    master_count: "Количество магистров",
    master_by_region: "Количество мастеров по регионам",
    count_bachelor_direction: "Всего по специальностям бакалавриата",
    count_master_direction: "Всего по специальностям магистратуры",
    candidate_of_science: "кандидат наук, PhD",
    doctor_of_science: "доктор наук, DSc",
    without_rank: "Без звания",
    dotsent: "Доцент",
    senior_researcher: "Старший научный сотрудник",
    professor: "Профессор",
    untitled: "Без названия",
    intern_teacher: "Стажер-преподаватель",
    assistant: "Ассистент",
    head_teacher: "Директор школы",
    head_of_department: "Глава департамента",
    main_workplace: "Основное рабочее место",
    placement: "Размещение (внутриначальное)",
    placement_subjunctive: "Размещение (сослагательное наклонение)",
    placement_external: "Размещение (внешнее)",
    per_hour: "в час",
    name_of_tablePaymentType: "Количество студентов в разделе оплаты",
    basic_contract: "Базовый контракт",
    student_count: "Количество студентов",
    withOutStipendCount: "Без учета стипендии",
    withStipendCount: "С учетом стипендии",
    super_contract: "Супер контракт",
    contract_foreign: "Контракт (иностранный)",
    hyper: "Гипер",
    pedagogyAndEducationCount: "Педагогика и образование",
    mathematicsCount: "Область математики",
    naturalSciencesCount: "Область естественных наук",
    artCount: "Область искусства",
    religiousStudiesCount: "Религиоведение",
    economicAndSocialCount: "Экономика и социальный сектор",
    journalismAndInformationCount: "Журналистика и информационная индустрия",
    lawCount: "Область права",
    businessAndManagementCount: "Бизнес и менеджмент",
    engineeringWorkCount: "Область инженерной работы",
    informationCommunicationTechnologiesCount:
      "Область информационных и коммуникационных технологий",
    agricultureAndForestryCount:
      "Область сельского хозяйства и экономики Армении",
    fisheryCount: "Количество рыболовства",
    veterinaryMedicineCount: "Ветеринария",
    healthCount: "Здоровье",
    socialSecurity: "Социальное обеспечение",
    serviceCount: "Область обслуживания",
    transportCount: "Транспортное поле",
    hygieneAndEnvironmentalProtectionCount:
      "Область гигиены и защиты окружающей среды",
    ensuringLegalOrderCount: "Обеспечение правопорядка",
    productionAndProcessingCount: "Области производства и переработки",
    architectureAndConstructionCount: "Архитектура и строительство",
    humanityCount: "Гуманитарные науки",
    languagesCount: "Поле Языки",
    graduated_table_title: "Количество выпускников по годам и отраслям",
    foreignUniversitySpecialtiesCount:
      "Специализации иностранных университетов",
    graduated_select_two: "Количество выпускников в сегменте ПМЖ",
    graduated_select_three:
      "Количество выпускников по направлению специальности",
    graduated: "В выпускном отделении",
    prof_count_by_work: "Профессора-преподаватели по форме работы",
    prof_count_by_academic_degree:
      "Профессора-преподаватели по ученым степеням",
    prof_count_by_gender: "Преподаватели по должностям и полу",
    prof_count_by_academic_title: "Профессора-преподаватели по ученым званиям",
  },
};
