import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PROFILE, profile, TOKEN, token } from "src/app/services/auth/const";
import { IProfile } from "src/app/services/auth/type";
import { baseUrl_Hemis, baseUrl_Prof, baseUrlStrore } from "../services/api/const";
import type { RootState } from "../store";

export interface IAuthState {
  profile?: IProfile;
  token?: string;
  baseUrl?: string;
  isAuthenticated: boolean;
}

const initialState: IAuthState = {
  profile: profile ? JSON.parse(profile) : undefined,
  token: token || "",
  baseUrl: baseUrlStrore,
  isAuthenticated: token ? true : false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = "";
      state.profile = undefined;
      state.isAuthenticated = false;

      localStorage.removeItem(TOKEN);
      localStorage.removeItem(PROFILE);
    },
    changeBaseUrl: (state, action: PayloadAction<"hemis" | "prof">) => {
      let { payload } = action;
      if (payload === "hemis") state.baseUrl = baseUrl_Hemis;
      else state.baseUrl = baseUrl_Prof;
    },
  },
});

export const { logout, changeBaseUrl } = authSlice.actions;
export default authSlice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
