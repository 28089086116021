import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import {
  courses,
  educationForms,
  university_name,
  educationTypes,
  regions,
} from "src/pages/constants";
import "./all.scss";
import AllTotalCard from "src/pages/higher_education/all/components/allTotalCard";
import PieChart from "src/components/chart/PieChart";
import {
  useGetCommonUniversityOwnershipQuery,
  useGetStudentsCountByCourseQuery,
  useGetStudentsCountByEduTypeAndOwnershipQuery,
  useGetStudentsCountByEduTypeQuery,
  useGetStudentsCountByGenderAndOwnershipQuery,
  useGetStudentsCountByPaymentTypeQuery,
  useGetTeachersCountByAcademicDegreeQuery,
  useGetTeachersCountByAcademicRankQuery,
  useGetTeachersCountByGenderQuery,
} from "src/app/services/students";
import UseAll from "src/pages/higher_education/all/useAll";
import { useEffect, useState } from "react";
import BarChartCategoryTwo from "src/components/chart/BarchartCategoryTwo";

import { RegionMap } from "src/pages/higher_education/all/components/RegionMap/RegionMap";
import {
  IStudentCountByCourse,
  IStudentCountByEduForm,
  IStudentCountByEduType,
  IStudentCountByOwnership,
  IStudentCountByPaymentType,
  ITeacherCuntByAcademicDegree,
  ITeachersCuntByAcademicRank,
} from "src/app/services/students/type";

function AllStatPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const { data: ownership } = useGetCommonUniversityOwnershipQuery();
  const { data: genderAndOwnership } = useGetStudentsCountByGenderAndOwnershipQuery();
  const { data: countEduType } = useGetStudentsCountByEduTypeQuery();
  const { data: countByCourse } = useGetStudentsCountByCourseQuery();
  const { data: countByPaymentType } = useGetStudentsCountByPaymentTypeQuery();
  const { data: countByEduForm } = useGetStudentsCountByEduTypeAndOwnershipQuery();
  const { data: teachersCountByGender } = useGetTeachersCountByGenderQuery();
  const { data: teachersCountByAcademicRank } = useGetTeachersCountByAcademicRankQuery();
  const { data: teachersCountByAcademicDegree } = useGetTeachersCountByAcademicDegreeQuery();
  const {
    universityData,
    studentsTop5Data,
    studentsTop5Label,
    universityAddress,
    studentAddress,
    placeTop5data,
    placeTop5Label,
    studentsTopFive,
    studentsTopFiveLabel,
    studentByOwnership,
    studentCount,
    universitiesCount,
    teachersCount,
  } = UseAll();

  const data = [
    {
      title: "OTMlar soni",
      value: 210,
    },
    {
      title: "Professor-o'qituvchilar soni",
      value: teachersCount && teachersCount,
    },
    {
      title: "Talabalar soni",
      value: studentCount && studentCount,
    },
  ];

  const ownershipList = [
    { label: t("tables.total"), value: t("tables.total") },
    { label: t("filters.state"), value: t("filters.state") },
    { label: t("filters.non_state"), value: t("filters.non_state") },
    { label: t("filters.foreign"), value: t("filters.foreign") },
  ];

  const [genderSegmentValue, setGenderSegmentValue] = useState<string>("JAMI");
  const [eudTypeSegmentValue, setEduTypeSegmentValue] = useState<string>("JAMI");
  const [courseSegmentValue, setCourseSegmentValue] = useState<string>("JAMI");
  const [paymentSegmentValue, setPaymentSegmentValue] = useState<string>("JAMI");
  const [eduFormSegmentValue, setEduFormSegmentValue] = useState<string>("JAMI");
  const [teachersGenderSegmentValue, setTeachersGenderSegmentValue] = useState<string>("JAMI");
  const [teachersAcademicRankSegmentValue, setTeachersAcademicRankSegmentValue] =
    useState<string>("JAMI");
  const [teachersAcademicDegreeSegmentValue, setTeachersAcademicDegreeSegmentValue] =
    useState<string>("JAMI");

  const [genderTotal, setGenderTotal] = useState<IStudentCountByOwnership>();
  const [eduTypeTotal, setEduTypeTotal] = useState<IStudentCountByEduType>();
  const [courseTotal, setCourseTotal] = useState<IStudentCountByCourse>();
  const [paymentTotal, setPaymentTotal] = useState<IStudentCountByPaymentType>();
  const [eduFormTotal, setEduFormTotal] = useState<IStudentCountByEduForm>();
  const [teachersTotal, setTeachersTotal] = useState<IStudentCountByOwnership>();
  const [rankTotal, setRankTotal] = useState<ITeachersCuntByAcademicRank>();
  const [degreeTotal, setDegreeTotal] = useState<ITeacherCuntByAcademicDegree>();

  useEffect(() => {
    const newObj = { maleCount: 0, femaleCount: 0 };
    genderAndOwnership?.forEach((item) => {
      newObj.maleCount = newObj.maleCount + item.maleCount;
      newObj.femaleCount = newObj.femaleCount + item.femaleCount;
    });
    setGenderTotal(newObj);
  }, [genderAndOwnership]);

  useEffect(() => {
    const newObj = { maleCount: 0, femaleCount: 0 };
    teachersCountByGender?.map((item) => {
      newObj.maleCount = newObj.maleCount + item.maleCount;
      newObj.femaleCount = newObj.femaleCount + item.femaleCount;
    });
    setTeachersTotal(newObj);
  }, [teachersCountByGender]);

  useEffect(() => {
    const newObj = { bachelorCount: 0, masterCount: 0, ordinaturaCount: 0 };
    countEduType?.map((item) => {
      newObj.bachelorCount = newObj.bachelorCount + item.bachelorCount;
      newObj.masterCount = newObj.masterCount + item.masterCount;
      newObj.ordinaturaCount = newObj.ordinaturaCount + item.ordinaturaCount;
    });
    setEduTypeTotal(newObj);
  }, [countEduType]);

  useEffect(() => {
    const newObj = {
      course1Count: 0,
      course2Count: 0,
      course3Count: 0,
      course4Count: 0,
      course5Count: 0,
      course6Count: 0,
    };
    countByCourse?.map((item) => {
      newObj.course1Count = newObj.course1Count + item.course1Count;
      newObj.course2Count = newObj.course2Count + item.course2Count;
      newObj.course3Count = newObj.course3Count + item.course3Count;
      newObj.course4Count = newObj.course4Count + item.course4Count;
      newObj.course5Count = newObj.course5Count + item.course5Count;
      newObj.course6Count = newObj.course6Count + item.course6Count;
    });
    setCourseTotal(newObj);
  }, [countByCourse]);

  useEffect(() => {
    const newObj = {
      secondEveningCount: 0,
      secondDaytimeCount: 0,
      secondExternalCount: 0,
      eveningCount: 0,
      daytimeCount: 0,
      specialExternalCount: 0,
      jointCount: 0,
      externalCount: 0,
      remoteCount: 0,
    };
    countByEduForm?.map((item) => {
      newObj.secondEveningCount = newObj.secondEveningCount + item.secondEveningCount;
      newObj.secondDaytimeCount = newObj.secondDaytimeCount + item.secondDaytimeCount;
      newObj.secondExternalCount = newObj.secondExternalCount + item.secondExternalCount;
      newObj.eveningCount = newObj.eveningCount + item.eveningCount;
      newObj.daytimeCount = newObj.daytimeCount + item.daytimeCount;
      newObj.specialExternalCount = newObj.specialExternalCount + item.specialExternalCount;
      newObj.jointCount = newObj.jointCount + item.jointCount;
      newObj.externalCount = newObj.externalCount + item.externalCount;
      newObj.remoteCount = newObj.remoteCount + item.remoteCount;
    });
    setEduFormTotal(newObj);
  }, [countByEduForm]);

  useEffect(() => {
    const newObj = { grandCount: 0, contractCount: 0 };
    countByPaymentType?.map((item) => {
      newObj.grandCount = newObj.grandCount + item.grandCount;
      newObj.contractCount = newObj.contractCount + item.contractCount;
    });
    setPaymentTotal(newObj);
  }, [countByPaymentType]);

  useEffect(() => {
    const newObj = {
      withoutRankCount: 0,
      dotsentCount: 0,
      seniorResearcherCount: 0,
      professorCount: 0,
    };
    teachersCountByAcademicRank?.map((item) => {
      newObj.withoutRankCount = newObj.withoutRankCount + item.withoutRankCount;
      newObj.dotsentCount = newObj.dotsentCount + item.dotsentCount;
      newObj.seniorResearcherCount = newObj.seniorResearcherCount + item.seniorResearcherCount;
      newObj.professorCount = newObj.professorCount + item.professorCount;
    });
    setRankTotal(newObj);
  }, [teachersCountByAcademicRank]);

  useEffect(() => {
    const newObj = {
      withoutDegreeCount: 0,
      scienceCandidateCount: 0,
      scienceDoctorCount: 0,
    };
    teachersCountByAcademicDegree?.map((item) => {
      newObj.withoutDegreeCount = newObj.withoutDegreeCount + item.withoutDegreeCount;
      newObj.scienceCandidateCount = newObj.scienceCandidateCount + item.scienceCandidateCount;
      newObj.scienceDoctorCount = newObj.scienceDoctorCount + item.scienceDoctorCount;
    });
    setDegreeTotal(newObj);
  }, [teachersCountByAcademicDegree]);

  const getData = (data: any, state: string) => {
    if (state !== "JAMI") {
      return data?.find((item: any) => item.ownership === state);
    }
  };

  const genderData = getData(genderAndOwnership, genderSegmentValue);
  const studentCountByEduType = getData(countEduType, eudTypeSegmentValue);
  const studentCountByCourse = getData(countByCourse, courseSegmentValue);
  const studentCountByPaymentType = getData(countByPaymentType, paymentSegmentValue);
  const studentCountByEduForm = getData(countByEduForm, eduFormSegmentValue);
  const teachersCountByGen = getData(teachersCountByGender, teachersGenderSegmentValue);
  const teachersCountByAca = getData(teachersCountByAcademicRank, teachersAcademicRankSegmentValue);
  const teachersCountByAcaD = getData(
    teachersCountByAcademicDegree,
    teachersAcademicDegreeSegmentValue,
  );

  return (
    <div className="edutype">
      <>
        <div className="edutype-type">
          {data?.map((item, index) => {
            return <AllTotalCard {...item} key={index} />;
          })}
        </div>
        <div className="graph-row-flex">
          {/* Otm count by ownership */}
          <GraphInfoCard title="OTMlar soni mulkchilik shakli bo'yicha" graphHeight={396}>
            <PieChart
              seriesArr={[
                {
                  value: 114,
                  name: t("filters.state"),
                },
                {
                  value: 67,
                  name: t("filters.non_state"),
                },
                {
                  value:29,
                  name: t("filters.foreign"),
                },
              ]}
              color={[colors.green, colors.blue, colors.yellow]}
            />
          </GraphInfoCard>

          {/* Otm count by corparative */}
          <GraphInfoCard title={`OTMlar soni tashkiliy turi bo'yicha`} graphHeight={396}>
            <BarChart
              legendData={[]}
              xAxisData={university_name}
              color={[colors.light_green]}
              seriesArr={[
                {
                  name: "OTMlar soni",
                  data: universityData,
                },
              ]}
            />
          </GraphInfoCard>
        </div>

        <div className="graph-row">
          <div className="map">
            <RegionMap />
          </div>
        </div>

        <div className="graph-row-flex">
          {/* gender */}
          <GraphInfoCard
            title="Talabalar soni jins kesimida"
            graphHeight={396}
            segmentedData={ownershipList}
            handleChange={setGenderSegmentValue}
            segmentedValue={genderSegmentValue}
          >
            <PieChart
              seriesArr={[
                {
                  value:
                    genderSegmentValue !== "JAMI" ? genderData?.maleCount : genderTotal?.maleCount,
                  name: t("genders.men"),
                },
                {
                  value:
                    genderSegmentValue !== "JAMI"
                      ? genderData?.femaleCount
                      : genderTotal?.femaleCount,
                  name: t("genders.women"),
                },
              ]}
              color={[colors.blue, colors.pink]}
              marginTop={0}
            />
          </GraphInfoCard>

          {/* edu type */}
          <GraphInfoCard
            title="Talabalar soni ta'lim turi kesimida"
            segmentedData={ownershipList}
            graphHeight={396}
            handleChange={setEduTypeSegmentValue}
            segmentedValue={eudTypeSegmentValue}
          >
            <BarChart
              legendData={[]}
              xAxisData={educationTypes}
              color={[colors.light_green]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data:
                    eudTypeSegmentValue !== "JAMI"
                      ? [
                          studentCountByEduType?.bachelorCount,
                          studentCountByEduType?.masterCount,
                          studentCountByEduType?.ordinaturaCount,
                        ]
                      : [
                          eduTypeTotal?.bachelorCount,
                          eduTypeTotal?.masterCount,
                          eduTypeTotal?.ordinaturaCount,
                        ],
                },
              ]}
            />
          </GraphInfoCard>
        </div>
        <div className="graph-row">
          {/* course */}
          <GraphInfoCard
            title="Talabalar soni kurslar kesimida"
            segmentedData={ownershipList}
            graphHeight={396}
            handleChange={setCourseSegmentValue}
            segmentedValue={courseSegmentValue}
          >
            <BarChart
              legendData={[]}
              xAxisData={courses}
              color={[colors.light_green]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data:
                    courseSegmentValue !== "JAMI"
                      ? [
                          studentCountByCourse?.course1Count,
                          studentCountByCourse?.course2Count,
                          studentCountByCourse?.course3Count,
                          studentCountByCourse?.course4Count,
                          studentCountByCourse?.course5Count,
                          studentCountByCourse?.course6Count,
                        ]
                      : [
                          courseTotal?.course1Count,
                          courseTotal?.course2Count,
                          courseTotal?.course3Count,
                          courseTotal?.course4Count,
                          courseTotal?.course5Count,
                          courseTotal?.course6Count,
                        ],
                },
              ]}
            />
          </GraphInfoCard>
        </div>

        <div className="graph-row-flex">
          {/* payment type */}
          <GraphInfoCard
            title="Talabalar soni to'lov shakli kesimida"
            segmentedData={ownershipList}
            graphHeight={396}
            handleChange={setPaymentSegmentValue}
            segmentedValue={paymentSegmentValue}
            titleW={true}
          >
            <PieChart
              seriesArr={[
                {
                  value:
                    paymentSegmentValue !== "JAMI"
                      ? studentCountByPaymentType?.grandCount
                      : paymentTotal?.grandCount,
                  name: t("pay_form.grant"),
                },
                {
                  value:
                    paymentSegmentValue !== "JAMI"
                      ? studentCountByPaymentType?.contractCount
                      : paymentTotal?.contractCount,
                  name: t("pay_form.contract"),
                },
              ]}
              color={[colors.blue, colors.pink]}
              marginTop={0}
            />
          </GraphInfoCard>
          {/* edu form*/}
          <GraphInfoCard
            title="Talabalar soni ta'lim shakli kesimida"
            segmentedData={ownershipList}
            graphHeight={396}
            handleChange={setEduFormSegmentValue}
            segmentedValue={eduFormSegmentValue}
            titleW={true}
          >
            <BarChartCategory
              legendData={educationForms}
              yAxisData={educationForms}
              color={[
                colors.blue,
                colors.orange,
                colors.deep_purple,
                colors.light_blue,
                colors.blue,
                colors.blue,
                colors.blue,
                colors.blue,
              ]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data:
                    eduFormSegmentValue !== "JAMI"
                      ? [
                          studentCountByEduForm?.secondEveningCount,
                          studentCountByEduForm?.secondDaytimeCount,
                          studentCountByEduForm?.secondExternalCount,
                          studentCountByEduForm?.eveningCount,
                          studentCountByEduForm?.daytimeCount,
                          studentCountByEduForm?.remoteCount,
                          studentCountByEduForm?.specialExternalCount,
                          studentCountByEduForm?.jointCount,
                          studentCountByEduForm?.externalCount,
                        ]
                      : [
                          eduFormTotal?.secondEveningCount,
                          eduFormTotal?.secondDaytimeCount,
                          eduFormTotal?.secondExternalCount,
                          eduFormTotal?.eveningCount,
                          eduFormTotal?.daytimeCount,
                          eduFormTotal?.remoteCount,
                          eduFormTotal?.specialExternalCount,
                          eduFormTotal?.jointCount,
                          eduFormTotal?.externalCount,
                        ],
                },
              ]}
              gridLeft={-125}
              yAxisMargin={160}
            />
          </GraphInfoCard>
        </div>

        <div className="graph-row">
          {/* region */}
          <GraphInfoCard
            title={`Respublikada ta'lim olayotgan talabalar  soni  OTM joylashgan hudud kesimida `}
            graphHeight={336}
          >
            <BarChart
              legendData={[]}
              xAxisData={regions}
              color={[colors.light_blue]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data: universityAddress,
                },
              ]}
              barWidth={50}
              xAxisRotate={15}
              gridLeft={30}
            />
          </GraphInfoCard>
        </div>

        <div className="graph-row-flex">
          {/* edu forn*/}
          <GraphInfoCard title=" Talabalar eng zich joylashgan top 5 ta hudud " graphHeight={396}>
            <BarChartCategoryTwo
              legendData={placeTop5Label}
              yAxisData={placeTop5Label}
              color={[colors.orange]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data: placeTop5data,
                },
              ]}
              gridLeft={-125}
              yAxisMargin={160}
            />
          </GraphInfoCard>
          {/* edu forn*/}
          <GraphInfoCard
            title="Eng ko'p oliy ma'lumotli kadrlar yetishib chiqayotgan top 5 ta hudud"
            graphHeight={396}
          >
            <BarChartCategoryTwo
              legendData={studentsTop5Label}
              yAxisData={studentsTop5Label}
              color={[colors.orange]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data: studentsTop5Data,
                },
              ]}
              gridLeft={-125}
              yAxisMargin={160}
            />
          </GraphInfoCard>
        </div>
        <div className="graph-row">
          {/* region */}
          <GraphInfoCard
            title={`Respublikada ta'lim olayotgan talabalar  soni  doimiy yashash viloyati kesimida `}
            graphHeight={336}
          >
            <BarChart
              legendData={[]}
              xAxisData={regions}
              color={[colors.green]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data: studentAddress,
                },
              ]}
              barWidth={50}
              xAxisRotate={15}
              gridLeft={30}
            />
          </GraphInfoCard>
        </div>
        <div className="graph-row">
          {/* top five Otm */}
          <GraphInfoCard title="Eng ko'p talabaga ega  top 5 ta OTM" graphHeight={396}>
            <BarChartCategoryTwo
              legendData={studentsTopFiveLabel}
              yAxisData={studentsTopFiveLabel}
              color={[colors.blue]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data: studentsTopFive,
                },
              ]}
              gridLeft={-230}
              yAxisMargin={300}
            />
          </GraphInfoCard>
        </div>
        <div className="graph-row-flex">
          {/* students count by otm ownership */}
          <GraphInfoCard title="Talabalar soni OTM mulkchilik shakli kesimida" graphHeight={396}>
            <BarChart
              legendData={[]}
              xAxisData={["filters.state", "filters.non_state", "filters.foreign"]}
              color={[colors.light_green]}
              seriesArr={[
                {
                  name: "Talabalar soni",
                  data: studentByOwnership,
                },
              ]}
            />
          </GraphInfoCard>
          {/* Teachers count by gender */}
          <GraphInfoCard
            title="Professor-o'qituvchilar jins kesimida"
            segmentedData={ownershipList}
            graphHeight={396}
            handleChange={setTeachersGenderSegmentValue}
            segmentedValue={teachersGenderSegmentValue}
            titleW={true}
          >
            <PieChart
              seriesArr={[
                {
                  value:
                    teachersGenderSegmentValue !== "JAMI"
                      ? teachersCountByGen?.maleCount
                      : teachersTotal?.maleCount,
                  name: t("genders.men"),
                },
                {
                  value:
                    teachersGenderSegmentValue !== "JAMI"
                      ? teachersCountByGen?.femaleCount
                      : teachersTotal?.femaleCount,
                  name: t("genders.women"),
                },
              ]}
              color={[colors.blue, colors.pink]}
              marginTop={0}
            />
          </GraphInfoCard>
        </div>
        <div className="graph-row-flex">
          {/* Teachers count by academic rank  */}
          <GraphInfoCard
            title="Professor-o'qituvchilar ilmiy unvon kesimida"
            segmentedData={ownershipList}
            graphHeight={396}
            handleChange={setTeachersAcademicRankSegmentValue}
            segmentedValue={teachersAcademicRankSegmentValue}
            titleW={true}
          >
            <PieChart
              seriesArr={[
                {
                  value:
                    teachersAcademicRankSegmentValue !== "JAMI"
                      ? teachersCountByAca?.withoutRankCount
                      : rankTotal?.withoutRankCount,
                  name: t("teachers.ac_title.untitled"),
                },
                {
                  value:
                    teachersAcademicRankSegmentValue !== "JAMI"
                      ? teachersCountByAca?.dotsentCount
                      : rankTotal?.dotsentCount,
                  name: t("teachers.ac_title.docent"),
                },
                {
                  value:
                    teachersAcademicRankSegmentValue !== "JAMI"
                      ? teachersCountByAca?.seniorResearcherCount
                      : rankTotal?.seniorResearcherCount,
                  name: t("teachers.ac_title.senior"),
                },
                {
                  value:
                    teachersAcademicRankSegmentValue !== "JAMI"
                      ? teachersCountByAca?.professorCount
                      : rankTotal?.professorCount,
                  name: t("teachers.ac_title.professor"),
                },
              ]}
              color={[colors.blue, colors.pink, colors.green, colors.yellow]}
              marginTop={0}
            />
          </GraphInfoCard>
          {/* Teachers count by  */}
          <GraphInfoCard
            title="Professor-o'qituvchilar ilmiy daraja kesimida"
            segmentedData={ownershipList}
            graphHeight={396}
            handleChange={setTeachersAcademicDegreeSegmentValue}
            segmentedValue={teachersAcademicDegreeSegmentValue}
            titleW={true}
          >
            <PieChart
              seriesArr={[
                {
                  value:
                    teachersAcademicDegreeSegmentValue !== "JAMI"
                      ? teachersCountByAcaD?.withoutDegreeCount
                      : degreeTotal?.withoutDegreeCount,
                  name: t("teachers.s_potential.unskilled"),
                },
                {
                  value:
                    teachersAcademicDegreeSegmentValue !== "JAMI"
                      ? teachersCountByAcaD?.scienceCandidateCount
                      : degreeTotal?.scienceCandidateCount,
                  name: t("teachers.s_level.candidate_phd"),
                },
                {
                  value:
                    teachersAcademicDegreeSegmentValue !== "JAMI"
                      ? teachersCountByAcaD?.scienceDoctorCount
                      : degreeTotal?.scienceDoctorCount,
                  name: t("teachers.s_level.doctor_dcs"),
                },
              ]}
              color={[colors.blue, colors.pink, colors.green]}
              marginTop={0}
            />
          </GraphInfoCard>
        </div>
      </>

      <div className="text_info">
        <p>{t("header.students_info_text")}</p>
      </div>
    </div>
  );
}

export default AllStatPage;
