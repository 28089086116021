import { useEffect, useState } from "react";
import {
  useGetStudentsGenderQuery,
  useGetStudentsCitizenshipQuery,
  useGetStudentsByYearsQuery,
  useGetStudentsByCourseQuery,
  useGetStudentsByCourseAndRegionQuery,
  useGetStudentsByAgeAndRegionQuery,
} from "src/app/services/universities";
import {
  DataAgeAndRegion,
  DataCitizen,
  DataCourse,
  DataGender,
  DataRegion,
  DataYears,
} from "src/app/services/universities/type";

function useLyceum() {
  // Genders section
  const [gendersData, setGenders] = useState<DataGender>();
  const { data: genders } = useGetStudentsGenderQuery();
  useEffect(() => {
    if (genders) setGenders(genders.data);
  }, [genders]);

  // Citizenship section
  const [citizenshipData, setCitizenship] = useState<DataCitizen>();
  const { data: citizenship } = useGetStudentsCitizenshipQuery();
  useEffect(() => {
    if (citizenship) setCitizenship(citizenship.data);
  }, [citizenship]);

  // Ages section
  const [agesData, setAges] = useState<DataYears>();
  const { data: ages } = useGetStudentsByYearsQuery();
  useEffect(() => {
    if (ages) setAges(ages.data);
  }, [ages]);

  // Course section
  const [courseData, setCourse] = useState<DataCourse>();
  const { data: course } = useGetStudentsByCourseQuery();
  useEffect(() => {
    if (course) setCourse(course.data);
  }, [course]);

  // Course and Region section
  const [courseRegionData, setCourseRegion] = useState<DataRegion>();
  const { data: courseRegion } = useGetStudentsByCourseAndRegionQuery();
  useEffect(() => {
    if (courseRegion) setCourseRegion(courseRegion?.data);
  }, [courseRegion]);

  // Age and Region section
  const [AgeRegionData, setAgeRegion] = useState<DataAgeAndRegion>();
  const { data: ageRegion } = useGetStudentsByAgeAndRegionQuery();
  useEffect(() => {
    if (ageRegion) setAgeRegion(ageRegion.data);
  }, [ageRegion]);

  return {
    gendersData,
    citizenshipData,
    agesData,
    courseData,
    courseRegionData,
    AgeRegionData,
  };
}

export default useLyceum;
