import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { useEffect, useState } from "react";
import {
  useGetProfTableEducationFormRegionQuery,
  useGetProfTableEducationFormTcMutation,
} from "src/app/services/prof_education";
import {
  IProfEducationFormRegion,
  IProfEducationFormTc,
  ISelect,
} from "src/app/services/prof_education/type";
import { ProfCustomTable } from "src/pages/prof_education/prof_tables/components/ProfCustomTable";
import { Table, Select, Button } from "antd";
import { tablePaginationConfigTwo } from "src/app/services/api/const";
import "../tableProf.scss";
import { eduTypeList } from "src/pages/constants";
import { useSearchParams } from "react-router-dom";
import { useTypedSelector } from "src/app/store";
export function ProfTableEducationForm() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetProfTableEducationFormRegionQuery();
  const [getFunc, { data: eudTypeTc, isLoading: tcLoading }] =
    useGetProfTableEducationFormTcMutation();
  const getColumnSearchProps = useColumnSearch();
  const [regionList, setRegionList] = useState<ISelect[]>();
  const [valueRegionSelect, setValueRegionSelect] = useState<string>("");
  const [valueEduTypeSelect, setValueEduTypeSelect] = useState<string>("");
  const [eduTypeData, setEduTypeData] = useState<IProfEducationFormTc[] | undefined>([]);
  const [_, setParam] = useSearchParams();
  const { darkMode } = useTypedSelector((state) => state.layout);

  useEffect(() => {
    const regionL = data?.map((region) => ({
      value: region.region_code,
      label: region.region_name,
    }));
    if (regionL) {
      setRegionList(regionL);
    }
  }, [data]);

  useEffect(() => {
    getFunc({ region_code: valueRegionSelect, education_type: valueEduTypeSelect });
  }, [valueRegionSelect, valueEduTypeSelect]);

  useEffect(() => {
    setEduTypeData(eudTypeTc);
  }, [eudTypeTc]);

  const onInstituteClick = (technical_id: number) => {
    setParam({
      technical_id: `${technical_id}`,
    });
  };
  //
  const excludedColumns = ["region_code", "region_name"];

  const columns: ColumnsType<IProfEducationFormRegion> = [
    {
      title: t("prof.province_code"),
      dataIndex: "region_code",
    },
    {
      //@ts-ignore
      title: t("prof.region_name"),
      dataIndex: "region_name",
      ...getColumnSearchProps("region_name"),
      width: "300px",
    },
    {
      title: t("edu_form.daytime"),
      dataIndex: "day_time",
      align: "center",
    },
    {
      title: t("edu_form.externally"),
      dataIndex: "external",
      align: "center",
    },
    {
      title: t("edu_form.evening"),
      dataIndex: "evening",
      align: "center",
    },
    {
      title: t("edu_form.dual"),
      dataIndex: "dual",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "all",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  const columnsTc: ColumnsType<IProfEducationFormTc> = [
    {
      //@ts-ignore
      title: t("prof.code"),
      width: "150px",
      dataIndex: "technical_college_id",
      ...getColumnSearchProps("technical_college_id"),
    },
    {
      //@ts-ignore
      title: t("prof.name"),
      dataIndex: "technical_college_name",
      ...getColumnSearchProps("technical_college_name"),
      width: "300px",
    },
    {
      title: t("tables.ownership"),
      dataIndex: "ownership_form",
      render: (data) => (
        <p>
          {data === 11
            ? t("filters.state")
            : data === 12
              ? t("filters.non_state")
              : data === 13
                ? t("filters.foreign")
                : t("filters.joint")}
        </p>
      ),
      align: "center",
    },
    {
      title: t("prof.type"),
      dataIndex: "education_type",
      render: (data) => (
        <p>
          {data === 5
            ? t("edu_type.technical_school")
            : data === 3
              ? t("edu_type.vocational_school")
              : t("edu_type.college")}
        </p>
      ),
      align: "center",
    },
    {
      title: t("edu_form.daytime"),
      dataIndex: "day_time",
      align: "center",
    },
    {
      title: t("edu_form.externally"),
      dataIndex: "external",
      align: "center",
    },
    {
      title: t("edu_form.evening"),
      dataIndex: "evening",
      align: "center",
    },
    {
      title: t("edu_form.dual"),
      dataIndex: "dual",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "all",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
    {
      title: t("doctoral.actions"),
      dataIndex: "technical_college_id",
      render: (code) => (
        <Button
          size="small"
          style={{ color: `${darkMode ? "white" : "black"}` }}
          onClick={() => onInstituteClick(code)}
        >
          &#128065;
        </Button>
      ),
    },
  ];

  const handleRegionChange = (value: string) => {
    setValueRegionSelect(value);
  };
  const handleEduTypeChange = (value: string) => {
    setValueEduTypeSelect(value);
  };

  return (
    <div style={{ width: "100%" }}>
      <ProfCustomTable
        title="Talabalar soni ta'lim shakli bo'yicha"
        rowKey="region_code"
        dataSource={data}
        columns={columns}
        pagination={false}
        excludedColumns={excludedColumns}
        size="small"
        loading={isLoading}
        scroll={{ x: true }}
      />
      <div style={{ marginTop: "40px" }}>
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Jami o'quv muassasalari talabalari soni ta'lim shakli bo'yicha</h3>

              <div className="filter-container">
                <Select
                  style={{ width: 200 }}
                  onChange={handleRegionChange}
                  value={valueRegionSelect === "" ? t("prof.by_region") : valueRegionSelect}
                  options={regionList}
                />
                <Select
                  defaultValue="Viloyat bo'yicha"
                  style={{ width: 200 }}
                  onChange={handleEduTypeChange}
                  value={valueEduTypeSelect === "" ? t("prof.type") : valueEduTypeSelect}
                  options={eduTypeList}
                />
              </div>
            </div>
          )}
          rowKey="technical_college_id"
          dataSource={eduTypeData}
          columns={columnsTc}
          pagination={tablePaginationConfigTwo}
          size="small"
          scroll={{ x: true }}
          loading={tcLoading}
        />
      </div>
    </div>
  );
}
