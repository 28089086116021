import axios from "axios";
import { message } from "antd";

export const getExcel = async (downloadUrl?: string, isAuth = false) => {
  if (downloadUrl) {
    try {
      const res = await fetch(`https://stat.edu.uz/api/excel/${downloadUrl}`, {
        headers: {
          "Response-Type": "arraybuffer",
          "Content-Type": "application/vnd.ms-excel",
          ...(isAuth
            ? {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }
            : {}),
        },
      });
      const blob = await res.blob();
      downloadFile(blob);
    } catch (err) {
      console.error(err);
    }
  }
};

export const downloadPrivateExcel = async ({
  downloadUrl = "",
  params,
}: {
  downloadUrl: string;
  params?: {};
}) => {
  try {
    const { data } = await axios({
      url: `https://stat.edu.uz/api${downloadUrl}`,
      method: "GET",
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params,
    });
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });
    downloadFile(blob);
  } catch (err) {
    //@ts-ignore
    if (err?.response?.status === 404) {
      message.error("Topilmadi");
    }
  }
};

export function downloadFile(blob: Blob) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.download = "report.xlsx";
  document.body.append(link);
  link.style.display = "none";
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
}
