import ReactEcharts from "echarts-for-react";
import { useTypedSelector } from "src/app/store";
import { useEffect, useState } from "react";
import { IGraduated } from "src/app/services/private/type";

interface Props {
  color?: string[];
  seriesArr: any;
  legendSize?: number;
  gridBottom?: number;
  marginTop?: number;
  legendData?: any;
  min?: number;
}

export default function LineChart({
  seriesArr,
  marginTop = -20,
  legendData,
  legendSize = 14,
  min,
}: Props) {
  const { colors } = useTypedSelector((state) => state.layout);
  const [series, setSeries] = useState<any[]>();

  useEffect(() => {
    if (seriesArr) {
      const newData = seriesArr?.map((item: IGraduated) => ({
        ...item,
        type: "line",
        smooth: true,
        symbolSize: 5,
        lineStyle: {
          width: 2,
        },
      }));
      setSeries(newData);
    }
  }, [seriesArr]);

  const legendTextStyle = {
    color: colors.tertiary,
    fontSize: legendSize,
  };

  const axisLabelStyle = {
    fontSize: 14,
    color: colors.primary,
  };

  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      data: legendData,
      align: "auto",
      bottom: 15,
      left: 130,
      textStyle: legendTextStyle,
    },
    grid: {
      left: "0%",
      right: "4%",
      bottom: "22%",
      containLabel: true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          label: {
            formatter: function (params: any) {
              return `${params.value}`;
            },
          },
        },
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisLabel: axisLabelStyle,
      data: ["2020-2021", "2021-2022", "2022-2023"],
    },
    yAxis: {
      type: "value",
      axisLabel: {
        ...axisLabelStyle,
        align: "left",
        margin: 70,
      },
      min: min,
    },
    series: series && series,
  };

  return <ReactEcharts option={option} style={{ marginTop: marginTop }} />;
}
