import type { ColumnsType } from "antd/es/table";
import { IEduTypeGender, IPlacePayment } from "./type";

export const educationTypes = [
  "edu_type.bachelor",
  "edu_type.master",
  "edu_type.ordinance",
  // "edu_type.doctorate_phd",
  // "edu_type.doctorate_dsc",
];
export const educationTypes1 = [
  "edu_type.ordinance",
  "edu_type.master",
  "edu_type.bachelor",
  // "edu_type.doctorate_phd",
  // "edu_type.doctorate_dsc",
];

export const typeByCourse_stat = {
  first: [],
  second: [],
  third: [],
  fourth: [],
  fifth: [],
  sixth: [],
};

export const typeAndCitizin_stat = {
  stateless: [],
  citizen: [],
  foreign: [],
};
export const typeAndAccommodation_stat = {
  own_home: [],
  dormitory: [],
  family: [],
  relative: [],
  rented: [],
};
export const typeAndFrom_stat = {
  evening: [],
  daytime: [],
  external: [],
  special_external: [],
  remote: [],
  joint: [],
  second_evening: [],
  second_daytime: [],
  second_external: [],
};
export const profEducationTypes = [
  "edu_type.vocational_schools",
  "edu_type.colleges",
  "edu_type.technical_schools",
];
export const educationForms = [
  "edu_form.externally",
  "edu_form.joint",
  "edu_form.special_externally",
  "edu_form.remote",
  "edu_form.daytime",
  "edu_form.evening",
  "edu_form.second_external",
  "edu_form.second_daytime",
  "edu_form.second_evening",
];

export const educationFormsMaster = [
  "edu_form.evening",
  "edu_form.daytime",
  "edu_form.remote",
  "edu_form.special_externally",
  "edu_form.joint",
  "edu_form.externally",
];
export const profEducationForms = [
  "edu_form.externally",
  "edu_form.daytime",
  "edu_form.evening",
  "edu_form.dual",
];

export const profEducationForms2 = [
  "edu_form.dual",
  "edu_form.evening",
  "edu_form.daytime",
  "edu_form.externally",
];

export const paymentForms = ["pay_form.contract", "pay_form.grant"];
export const paymentForms1 = ["pay_form.grant", "pay_form.contract"];
export const citizenships = [
  "citizenships.foreigner",
  "citizenships.uzbek",
  "citizenships.stateless",
  "citizenships.teen",
];
export const citizenships2 = [
  "citizenships.foreigner",
  "citizenships.teen",
  "citizenships.uzbek",
  "citizenships.stateless",
];
export const citizenships3 = [
  "citizenships.stateless",
  "citizenships.uzbek",
  "citizenships.teen",
  "citizenships.foreigner",
];
export const courses = [
  "courses.course_1",
  "courses.course_2",
  "courses.course_3",
  "courses.course_4",
  "courses.course_5",
  "courses.course_6",
];
export const university_name = ["Akademiya", "Filial", "Institut", "Universitet"];
export const prof_courses = ["courses.course_1", "courses.course_2", "courses.course_3"];

export const prof_courses_two = ["courses.course_3", "courses.course_2", "courses.course_1"];
export const lyceum_courses = ["courses.course_1", "courses.course_2"];
export const ages = ["ages.under_30", "ages.over_30"];
export const prof_ages = ["ages.under_20", "ages.over_20"];
export const prof_ages2 = ["ages.over_20", "ages.under_20"];
export const lyceum_ages = ["ages.under_17", "ages.over_17"];
export const genders = ["genders.women", "genders.men"];
export const residencePlaces = [
  "residence_places.rented_house",
  "residence_places.own_house",
  "residence_places.relative_house",
  "residence_places.dormitory",
  "residence_places.familiar_house",
];
export const  residencePlaces2 = [
  "residence_places.other",
  "residence_places.dormitory",
  "residence_places.rented_house",
  "residence_places.familiar_house",
  "residence_places.relative_house",
  "residence_places.own_house",
];
export const scientific_levels = [
  "teachers.s_potential.unskilled",
  "teachers.s_level.candidate_phd",
  "teachers.s_level.doctor_dcs",
  "Boshqa",
];
export const academic_titles = [
  "teachers.ac_title.untitled",
  "teachers.ac_title.docent",
  "teachers.ac_title.senior",
  "teachers.ac_title.professor",
  "Boshqa",
];
export const teacherAges = [
  "ages.until_30",
  "ages.age_30_35",
  "ages.age_35_40",
  "ages.age_40_45",
  "ages.age_45_50",
  "ages.age_50_55",
  "ages.age_55_60",
  "ages.over_60",
];

export type EduTypeAttribute =
  | "bachelor"
  | "master"
  | "ordinance"
  | "doctorate_phd"
  | "doctorate_dsc"
  | "vocational_schools"
  | "colleges"
  | "technical_schools";

export const edu_type_stat = [
  {
    type: "bachelor",
    key: "Bakalavr",
    total: 0,
    man: 0,
    women: 0,
  },
  {
    type: "master",
    key: "Magistr",
    total: 0,
    man: 0,
    women: 0,
  },
  {
    type: "ordinance",
    key: "Ordinatura",
    total: 0,
    man: 0,
    women: 0,
  },
];

export const prof_stat = [
  {
    type: "vocational_schools",
    total: 0,
    man: 0,
    women: 0,
  },
  {
    type: "colleges",
    total: 0,
    man: 0,
    women: 0,
  },
  {
    type: "technical_schools",
    total: 0,
    man: 0,
    women: 0,
  },
];

export const findProfEduType = (id: number) => {
  if (id === 3) return "vocational_schools";
  else if (id === 4) return "colleges";
  else return "technical_schools";
};

export const regions = [
  "Andijon viloyati",
  "Buxoro viloyati",
  "Farg'ona viloyati",
  "Jizzax viloyati",
  "Namangan viloyati",
  "Navoiy viloyati",
  "Qashqadaryo viloyati",
  "Qoraqalpog'iston Resp.",
  "Samarqand viloyati",
  "Sirdaryo viloyati",
  "Surxondaryo viloyati",
  "Toshkent shahri",
  "Toshkent viloyati",
  "Xorazm viloyati",
];

export const eduTypeGenderColumns: ColumnsType<IEduTypeGender> = [
  {
    title: "Jinsi",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Kunduzgi",
    dataIndex: "kunduzgi",
    key: "kunduzgi",
  },
  {
    title: "Kechki",
    dataIndex: "kechki",
    key: "kechki",
  },
  {
    title: "Sirtqi",
    dataIndex: "sirtqi",
    key: "sirtqi",
  },
  {
    title: "Maxsus sirtqi",
    dataIndex: "maxsus_sirtqi",
    key: "maxsus_sirtqi",
  },
  {
    title: "Qo'shma",
    dataIndex: "qoshma",
    key: "qoshma",
  },
  {
    title: "Masofaviy",
    dataIndex: "masofaviy",
    key: "masofaviy",
  },
];

export const placePaymentColumns: ColumnsType<IPlacePayment> = [
  {
    title: "Ta’lim shakli",
    dataIndex: "name",
    key: "name",
    fixed: "left",
  },
  {
    title: "O‘z uyida",
    children: [
      {
        title: "Davlat granti",
        dataIndex: "own_grant",
        key: "own_grant",
      },
      {
        title: "To‘lov shartnoma",
        dataIndex: "own_contract",
        key: "own_contract",
      },
    ],
  },
  {
    title: "Qarindoshining uyida",
    children: [
      {
        title: "Davlat granti",
        dataIndex: "relative_grant",
        key: "relative_grant",
      },
      {
        title: "To‘lov shartnoma",
        dataIndex: "relative_contract",
        key: "relative_contract",
      },
    ],
  },
  {
    title: "Qarindoshining uyida",
    children: [
      {
        title: "Tanishining uyida",
        dataIndex: "familiar_grant",
        key: "familiar_grant",
      },
      {
        title: "To‘lov shartnoma",
        dataIndex: "familiar_contract",
        key: "familiar_contract",
      },
    ],
  },
  {
    title: "Qarindoshining uyida",
    children: [
      {
        title: "Tanishining uyida",
        dataIndex: "dormitory_grant",
        key: "dormitory_grant",
      },
      {
        title: "To‘lov shartnoma",
        dataIndex: "dormitory_contract",
        key: "dormitory_contract",
      },
    ],
  },
];
export const payment_type_stat = [
  {
    key: "To‘lov-shartnoma",
  },
  {
    key: "Davlat granti",
  },
];

export const eduTypeList = [
  {
    value: "5",
    label: "Texnikum",
  },
  {
    value: "3",
    label: "Kasb-hunar maktabi",
  },
  {
    value: "4",
    label: "Kollej",
  },
];
