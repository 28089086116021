import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { ITablesPayment } from "src/app/services/tables/type";
import { useGetTablesEduTypePaymentTypeQuery } from "src/app/services/tables";
import { renderEmptyValue } from "src/pages/higher_education/tables/utils";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { CustomTable } from "src/pages/higher_education/tables/tablesUniversities/components/CustomTable";
import { useMemo } from "react";

export function TablesUniversityByPaymentTable() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetTablesEduTypePaymentTypeQuery();
  const getColumnSearchProps = useColumnSearch();
  const excludedColumns = ["universityCode", "universityName", "universityOwnership"];

  const dataSourcePayment = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total: item.bachelorGrand + item.bachelorContract + item.masterGrand + item.masterContract,
    }));

    return newData;
  }, [data]);

  const columns: ColumnsType<ITablesPayment> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      width: 400,
      ...getColumnSearchProps("univerName"),
    },
    {
      title: t("tables.ownership"),
      dataIndex: "universityOwnership",
    },
    {
      title: t("tables.bachelor"),
      children: [
        {
          title: t("pay_form.grant"),
          dataIndex: "bachelorGrand",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("pay_form.contract"),
          dataIndex: "bachelorContract",
          align: "center",
          render: renderEmptyValue,
        },
      ],
    },
    {
      title: t("tables.master"),
      children: [
        {
          title: t("pay_form.grant"),
          dataIndex: "masterGrand",
          align: "center",
          render: renderEmptyValue,
        },
        {
          title: t("pay_form.contract"),
          dataIndex: "masterContract",
          align: "center",
          render: renderEmptyValue,
        },
      ],
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  return (
    <div>
      <CustomTable
        title="tables.paymentType"
        rowKey="universityCode"
        dataSource={dataSourcePayment}
        columns={columns}
        pagination={false}
        excludedColumns={excludedColumns}
        size="small"
        scroll={{ x: true, y: 500 }}
        loading={isLoading}
        downloadUrl="student/paymentType"
      />
    </div>
  );
}
