import {
  IPaymentTypeByTotal,
  IStudent,
  IStudentAgeRes,
  IStudentCitizenRes,
  IStudentCourseRes,
  IStudentEduFormRes,
  IStudentEduTypeRes,
  IStudentPlaceRes,
  IStudentRegionRes,
} from "src/app/services/students/type";
import {
  IStudentAge,
  IStudentCitizenship,
  IStudentCourse,
  IStudentEduForm,
  IStudentEduType,
  IStudentGender,
  IStudentPayment,
  IStudentPlace,
} from "./type";

export const makeStudentEduType = (arr: IStudent[]) => {
  let bachelor = makeStudent(arr.filter((el) => el.name === "Bakalavr"));
  let master = makeStudent(arr.filter((el) => el.name === "Magistr"));
  let ordinance = makeStudent(arr.filter((el) => el.name === "Ordinatura"));
  let obj: IStudentEduType = {
    bachelor_total: bachelor.total,
    bachelor_data: bachelor.data,
    master_total: master.total,
    master_data: master.data,
    ordinance_total: ordinance.total,
    ordinance_data: ordinance.data,
  };
  return obj;
};

export const makeStudentEduForm = (arr: IStudent[]) => {
  let externally = makeStudent(arr.filter((el) => el.name === "Sirtqi"));
  let joint = makeStudent(arr.filter((el) => el.name === "Qo‘shma"));
  let special_externally = makeStudent(arr.filter((el) => el.name === "Maxsus sirtqi"));
  let remote = makeStudent(arr.filter((el) => el.name === "Masofaviy"));
  let daytime = makeStudent(arr.filter((el) => el.name === "Kunduzgi"));
  let evening = makeStudent(arr.filter((el) => el.name === "Kechki"));
  let second_external = makeStudent(arr.filter((el) => el.name === "Ikkinchi oliy (sirtqi)"));
  let second_daytime = makeStudent(arr.filter((el) => el.name === "Ikkinchi oliy (kunduzgi)"));
  let second_evening = makeStudent(arr.filter((el) => el.name === "Ikkinchi oliy (kechki)"));
  let obj: IStudentEduForm = {
    externally_total: externally.total,
    externally_data: externally.data,
    joint_total: joint.total,
    joint_data: joint.data,
    special_externally_total: special_externally.total,
    special_externally_data: special_externally.data,
    remote_total: remote.total,
    remote_data: remote.data,
    daytime_total: daytime.total,
    daytime_data: daytime.data,
    evening_total: evening.total,
    evening_data: evening.data,
    second_external_total: second_external.total,
    second_external_data: second_external.data,
    second_daytime_total: second_daytime.total,
    second_daytime_data: second_daytime.data,
    second_evening_total: second_evening.total,
    second_evening_data: second_evening.data,
  };
  return obj;
};

export const makeStudentGender = (arr: IStudent[]) => {
  let men = makeStudent(arr.filter((el) => el.name === "Erkak"));
  let women = makeStudent(arr.filter((el) => el.name === "Ayol"));
  let obj: IStudentGender = {
    men_total: men.total,
    men_data: men.data,
    women_total: women.total,
    women_data: women.data,
  };
  return obj;
};

export const makeStudentAge = (arr: IStudent[]) => {
  let less = makeStudent(arr.filter((el) => el.name === "lessThan"));
  let greater = makeStudent(arr.filter((el) => el.name === "greaterThan"));
  let obj: IStudentAge = {
    less_total: less.total,
    less_data: less.data,
    greater_total: greater.total,
    greater_data: greater.data,
  };
  return obj;
};

export const makeStudentCitizenship = (arr: IStudent[]) => {
  let uzbek = makeStudent(arr.filter((el) => el.name === "O‘zbekiston Respublikasi fuqarosi"));
  let foreigner = makeStudent(arr.filter((el) => el.name === "Xorijiy davlat fuqarosi"));
  let stateless = makeStudent(arr.filter((el) => el.name === "Fuqaroligi yo‘q shaxslar"));
  let obj: IStudentCitizenship = {
    uzbek_total: uzbek.total,
    uzbek_data: uzbek.data,
    foreigner_total: foreigner.total,
    foreigner_data: foreigner.data,
    stateless_total: stateless.total,
    stateless_data: stateless.data,
  };
  return obj;
};

export const makeStudentCitizenshipByCourse = (arr: IStudentCitizenRes[]) => {
  const newArray = arr.map((c) => {
    const el = { ...c };
    el.name = c.citizenship;
    Object.assign(el, { course: c.name });
    return el;
  });

  return makeStudentCitizenship(newArray);
};

export const makeStudentPayment = (arr: IStudent[]) => {
  let grant = makeStudent(arr.filter((el) => el.name === "Davlat granti"));
  let contract = makeStudent(arr.filter((el) => el.name === "To‘lov-shartnoma"));
  let obj: IStudentPayment = {
    grant_total: grant.total,
    grant_data: grant.data,
    contract_total: contract.total,
    contract_data: contract.data,
  };
  return obj;
};

export const makeStudentCourse = (arr: IStudent[]) => {
  let course_1 = makeStudent(arr.filter((el) => el.name === "1-kurs"));
  let course_2 = makeStudent(arr.filter((el) => el.name === "2-kurs"));
  let course_3 = makeStudent(arr.filter((el) => el.name === "3-kurs"));
  let course_4 = makeStudent(arr.filter((el) => el.name === "4-kurs"));
  let course_5 = makeStudent(arr.filter((el) => el.name === "5-kurs"));
  let course_6 = makeStudent(arr.filter((el) => el.name === "6-kurs"));
  let obj: IStudentCourse = {
    course_1_total: course_1.total,
    course_1_data: course_1.data,
    course_2_total: course_2.total,
    course_2_data: course_2.data,
    course_3_total: course_3.total,
    course_3_data: course_3.data,
    course_4_total: course_4.total,
    course_4_data: course_4.data,
    course_5_total: course_5.total,
    course_5_data: course_5.data,
    course_6_total: course_6.total,
    course_6_data: course_6.data,
  };
  return obj;
};

export const makeStudentPlace = (arr: IStudent[]) => {
  let own_house = makeStudent(arr.filter((el) => el.name === "O‘z uyida"));
  let dormitory = makeStudent(arr.filter((el) => el.name === "Talabalar turar joyida"));
  let rented_house = makeStudent(arr.filter((el) => el.name === "Ijaradagi uyda"));
  let relative_house = makeStudent(arr.filter((el) => el.name === "Qarindoshining uyida"));
  let familiar_house = makeStudent(arr.filter((el) => el.name === "Tanishining uyida"));
  let obj: IStudentPlace = {
    own_house_total: own_house.total,
    own_house_data: own_house.data,
    dormitory_total: dormitory.total,
    dormitory_data: dormitory.data,
    rented_house_total: rented_house.total,
    rented_house_data: rented_house.data,
    relative_house_total: relative_house.total,
    relative_house_data: relative_house.data,
    familiar_house_total: familiar_house.total,
    familiar_house_data: familiar_house.data,
  };
  return obj;
};

/////////////// Helper funstions ///////////////
const makeStudent = (arr: any[]) => {
  // Fill arr section
  let filled_arr: any[] = [];
  if (arr[0]?.eduForm) filled_arr = fillEduForm(arr);
  else if (arr[0]?.course) filled_arr = fillCourse(arr);
  else if (arr[0]?.age) filled_arr = fillAge(arr);
  else if (arr[0]?.accommodation) filled_arr = fillPlace(arr);
  else if (arr[0]?.region) filled_arr = fillRegion(arr);
  else if (arr[0]?.eduType) filled_arr = fillEduType(arr);
  else if (arr[0]?.paymentType) filled_arr = fillPaymentType(arr);
  else if (arr[0]?.citizenship) filled_arr = fillCitizenship(arr);

  // Make essentials section
  let total = 0;
  let data: number[] = [];
  filled_arr.forEach((item) => {
    total += item.count;
    data.push(item.count);
  });
  return {
    total,
    data,
  };
};

const fillEduForm = (arr: IStudentEduFormRes[]) => {
  let filled: IStudentEduFormRes[] = [];
  eduForms.forEach((item) => {
    let obj = arr.find((el) => el.eduForm === item);
    filled.push({
      eduForm: item,
      name: arr[0].name,
      count: obj ? obj.count : 0,
    });
  });
  return filled;
};

const fillCourse = (arr: IStudentCourseRes[]) => {
  let filled: IStudentCourseRes[] = [];
  courses.forEach((item) => {
    let obj = arr.find((el) => el.course === item);
    filled.push({
      course: item,
      name: arr[0].name,
      count: obj ? obj.count : 0,
    });
  });
  return filled;
};

const fillAge = (arr: IStudentAgeRes[]) => {
  let filled: IStudentAgeRes[] = [];
  ages.forEach((item) => {
    let obj = arr.find((el) => el.age === item);
    filled.push({ age: item, name: arr[0].name, count: obj ? obj.count : 0 });
  });
  return filled;
};

const fillPlace = (arr: IStudentPlaceRes[]) => {
  let filled: IStudentPlaceRes[] = [];
  places.forEach((item) => {
    let obj = arr.find((el) => el.accommodation === item);
    filled.push({
      accommodation: item,
      name: arr[0].name,
      count: obj ? obj.count : 0,
    });
  });
  return filled;
};

const fillRegion = (arr: IStudentRegionRes[]) => {
  let filled: IStudentRegionRes[] = [];
  regions.forEach((item) => {
    let obj = arr.find((el) => el.region === item);
    filled.push({
      region: item,
      name: arr[0].name,
      count: obj ? obj.count : 0,
    });
  });
  return filled;
};

const fillEduType = (arr: IStudentEduTypeRes[]) => {
  let filled: IStudentEduTypeRes[] = [];
  eduTypes.forEach((item) => {
    let obj = arr.find((el) => el.eduType === item);
    filled.push({
      eduType: item,
      name: arr[0].name,
      count: obj ? obj.count : 0,
    });
  });
  return filled;
};

const fillPaymentType = (arr: IPaymentTypeByTotal[]) => {
  let filled: IPaymentTypeByTotal[] = [];
  paymentTypes.forEach((item) => {
    let obj = arr.find((el) => el.paymentType === item);
    filled.push({
      paymentType: item,
      name: arr[0].name,
      count: obj ? obj.count : 0,
    });
  });
  return filled;
};

const fillCitizenship = (arr: IStudentCitizenRes[]) => {
  let filled: IStudentCitizenRes[] = [];
  citizenship.forEach((item) => {
    let obj = arr.find((el) => el.citizenship === item);
    filled.push({
      citizenship: item,
      name: arr[0].name,
      count: obj ? obj.count : 0,
    });
  });
  return filled;
};

const eduForms = [
  "Ikkinchi oliy (kechki)",
  "Ikkinchi oliy (kunduzgi)",
  "Ikkinchi oliy (sirtqi)",
  "Kechki",
  "Kunduzgi",
  "Masofaviy",
  "Maxsus sirtqi",
  "Qo‘shma",
  "Sirtqi",
];

const eduTypes = ["Bakalavr", "Magistr", "Ordinatura"];

const paymentTypes = ["Davlat granti", "To‘lov-shartnoma"];

const courses = ["1-kurs", "2-kurs", "3-kurs", "4-kurs", "5-kurs", "6-kurs"];

const ages = ["lessThan", "greaterThan"];

const citizenship = [
  "Xorijiy davlat fuqarosi",
  "O‘zbekiston Respublikasi fuqarosi",
  "Fuqaroligi yo‘q shaxslar",
];

const places = [
  "Ijaradagi uyda",
  "O‘z uyida",
  "Qarindoshining uyida",
  "Talabalar turar joyida",
  "Tanishining uyida",
];

const regions = [
  "Andijon viloyati",
  "Buxoro viloyati",
  "Jizzax viloyati",
  "Qashqadaryo viloyati",
  "Navoiy viloyati",
  "Namangan viloyati",
  "Samarqand viloyati",
  "Surxondaryo viloyati",
  "Sirdaryo viloyati",
  "Toshkent shahri",
  "Toshkent viloyati",
  "Farg‘ona viloyati",
  "Xorazm viloyati",
  "Qoraqalpog‘iston Resp.",
];

export const filterSeriesData = (name: string, arr?: any[], reverse = false) => {
  if (!arr) return [];

  if (reverse) {
    return arr
      .filter((el) => el.eduType === name)
      .reverse()
      .map((el) => el.count);
  }

  return arr.filter((el) => el.eduType === name).map((el) => el.count);
};
