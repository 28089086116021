import { api } from "../api";
import {
  IGenderRegion,
  IPermanentRegion,
  IProfAdmissionTc,
  IProfAdmissionTypeRegion,
  IProfAgeStat,
  IProfCitizenshipStat,
  IProfCourseRegion,
  IProfCourseStat,
  IProfCourseTc,
  IProfEducationFormClassifier,
  IProfEducationFormRegion,
  IProfEducationFormTc,
  IProfEduFormStat,
  IProfEduTypeStat,
  IProfGenderTc,
  IProfListRes,
  IProfPaymentFormStat,
  IProfRegionStat,
  IProfResidencePlaceStat,
  IProfTableTcParams,
} from "./type";

export const universitiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    //Get edu type endpoint
    getProfEduTypeStat: build.mutation<IProfEduTypeStat, string | void>({
      query: (params = "") => ({ url: "public-stats/education-type/" + params }),
    }),
    //Get edu form endpoint
    getProfEduFormStat: build.mutation<IProfEduFormStat, string | void>({
      query: (params = "") => ({ url: "public-stats/education-form/" + params }),
    }),
    //Get payment form endpoint
    getProfPaymentFormStat: build.mutation<IProfPaymentFormStat, string | void>({
      query: (params = "") => ({ url: "public-stats/admission-type/" + params }),
    }),
    //Get citizenship endpoint
    getProfCitizenshipStat: build.mutation<IProfCitizenshipStat, string | void>({
      query: (params = "") => ({ url: "public-stats/citizenship/" + params }),
    }),
    //Get course endpoint
    getProfCourseStat: build.mutation<IProfCourseStat, string | void>({
      query: (params = "") => ({ url: "public-stats/course/" + params }),
    }),
    //Get age endpoint
    getProfAgeStat: build.mutation<IProfAgeStat, string | void>({
      query: (params = "") => ({ url: "public-stats/age/" + params }),
    }),
    //Get residence place endpoint
    getProfResidencePlaceStat: build.mutation<IProfResidencePlaceStat, string | void>({
      query: (params = "") => ({ url: "public-stats/current-live-place/" + params }),
    }),
    //Get region endpoint
    getProfRegionStat: build.mutation<IProfRegionStat, string | void>({
      query: (params = "") => ({ url: "public-stats/region/" + params }),
    }),
    //Get region endpoint
    getProfList: build.mutation<IProfListRes, string | void>({
      query: (params = "") => ({ url: "public-stats/tc/?" + params }),
    }),
    // api get Prof permanent region endpoint
    getTablesPermanentRegion: build.query<IPermanentRegion[], void>({
      query: () => "https://prof-emis.edu.uz/api/public-stats/table-permanent-region/",
    }),
    //Get Prof table gender region endpoint
    getProfTableGenderRegion: build.query<IGenderRegion[], void>({
      query: () => "https://prof-emis.edu.uz/api/public-stats/table-gender-region/",
    }),
    //Get Prof table gender tc endpoint
    getProfTableGenderTc: build.mutation<IProfGenderTc[], IProfTableTcParams>({
      query: (params) =>
        Object.values(params).length !== 0
          ? `https://prof-emis.edu.uz/api/public-stats/table-gender-tc/?${
              params.region_code && `&region_code=${params.region_code}`
            }${params.education_type && `&education_type=${params.education_type}`}`
          : `https://prof-emis.edu.uz/api/public-stats/table-gender-tc/`,
    }),
    //Get Prof table admission type region  endpoint
    getProfTableAdmissionTypeRegion: build.query<IProfAdmissionTypeRegion[], void>({
      query: () => "https://prof-emis.edu.uz/api/public-stats/table-admission-type-region/",
    }),
    //Get Prof table admission type Tc  endpoint
    getProfTableAdmissionTypeTc: build.mutation<IProfAdmissionTc[], IProfTableTcParams>({
      query: (params) =>
        Object.values(params).length !== 0
          ? `https://prof-emis.edu.uz/api/public-stats/table-admission-type-tc/?${
              params.region_code && `&region_code=${params.region_code}`
            }${params.education_type && `&education_type=${params.education_type}`}`
          : `https://prof-emis.edu.uz/api/public-stats/table-admission-type-tc/`,
    }),
    //Get Prof table course region endpoint
    getProfTableCourseRegion: build.query<IProfCourseRegion[], void>({
      query: () => "https://prof-emis.edu.uz/api/public-stats/table-course-region/",
    }),

    //Get Prof table course region endpoint
    getProfTableCourseTc: build.mutation<IProfCourseTc[], IProfTableTcParams>({
      query: (params) =>
        Object.values(params).length !== 0
          ? `https://prof-emis.edu.uz/api/public-stats/table-course-tc/?${
              params.region_code && `&region_code=${params.region_code}`
            }${params.education_type && `&education_type=${params.education_type}`}`
          : `https://prof-emis.edu.uz/api/public-stats/table-course-tc/`,
    }),

    //Get Prof table education form region endpoint
    getProfTableEducationFormRegion: build.query<IProfEducationFormRegion[], void>({
      query: () => "https://prof-emis.edu.uz/api/public-stats/table-education-form-region/",
    }),
    //Get Prof table education form Tc endpoint
    getProfTableEducationFormTc: build.mutation<IProfEducationFormTc[], IProfTableTcParams>({
      query: (params) =>
        Object.values(params).length !== 0
          ? `https://prof-emis.edu.uz/api/public-stats/table-education-form-tc/?${
              params.region_code && `&region_code=${params.region_code}`
            }${params.education_type && `&education_type=${params.education_type}`}`
          : `https://prof-emis.edu.uz/api/public-stats/table-education-form-tc/`,
    }),
    //Get Prof table education form classifier endpoint
    getProfTableEducationFormClassifier: build.mutation<
      IProfEducationFormClassifier[],
      { id: string }
    >({
      query: (params) =>
        `https://prof-emis.edu.uz/api/public-stats/table-education-form-classifier/?&technical_college_id=${params.id}`,
    }),
  }),
});

export const {
  useGetProfEduTypeStatMutation,
  useGetProfEduFormStatMutation,
  useGetProfPaymentFormStatMutation,
  useGetProfCitizenshipStatMutation,
  useGetProfCourseStatMutation,
  useGetProfAgeStatMutation,
  useGetProfResidencePlaceStatMutation,
  useGetProfRegionStatMutation,
  useGetProfListMutation,
  useGetTablesPermanentRegionQuery,
  useGetProfTableGenderRegionQuery,
  useGetProfTableGenderTcMutation,
  useGetProfTableAdmissionTypeTcMutation,
  useGetProfTableAdmissionTypeRegionQuery,
  useGetProfTableCourseRegionQuery,
  useGetProfTableCourseTcMutation,
  useGetProfTableEducationFormRegionQuery,
  useGetProfTableEducationFormTcMutation,
  useGetProfTableEducationFormClassifierMutation,
} = universitiesApi;
