import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TablesEmployeeForm } from "src/pages/higher_education/tables/tableTeachers/components/tablesEmployeeForm";
import { TablesPositionAndGender } from "src/pages/higher_education/tables/tableTeachers/components/TablesPositionAndGender";
import "./tableTeachers.scss";
import { TablesAcademicDegreeForm } from "src/pages/higher_education/tables/tableTeachers/components/TablesAcademicDegree";
import { TablesRankForm } from "src/pages/higher_education/tables/tableTeachers/components/TablesRank";

function TablesTeachers() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const regionId = params.get("regionId");
  const id = params.get("id");

  return (
    <div className="doctoral">
      {!regionId && id === null && <TablesEmployeeForm />}
      {!regionId && id === "2" && <TablesAcademicDegreeForm />}
      {!regionId && id === "3" && <TablesPositionAndGender />}
      {!regionId && id === "4" && <TablesRankForm />}
      <div className="text_info" style={{ width: "100%" }}>
        <p>{t("header.students_info_text")}</p>
      </div>
    </div>
  );
}

export default TablesTeachers;
