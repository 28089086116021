import CountUp from "react-countup";
import { EduTypeAttribute } from "src/pages/constants";
import { BookIcon, BriefcaseIcon, GovermentIcon, KeyIcon, ZapIcon } from "src/assets/svg";
import { useTypedSelector } from "src/app/store";
import { useTranslation } from "react-i18next";

interface Props {
  type: string;
  total: number;
  man: number;
  women: number;
}

const calcPercentage = (part: number, total: number) => {
  return ((part / total) * 100).toFixed(2);
};

function EduTypeCard({ type, total, man, women }: Props) {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);

  const edu_type_data = {
    bachelor: {
      title: t("edu_type.bachelor"),
      icon: <KeyIcon color={colors.secondary} />,
    },
    master: {
      title: t("edu_type.master"),
      icon: <BriefcaseIcon color={colors.secondary} />,
    },
    ordinance: {
      title: t("edu_type.ordinance"),
      icon: <GovermentIcon color={colors.secondary} />,
    },
    doctorate_phd: {
      title: t("edu_type.doctorate_phd"),
      icon: <ZapIcon color={colors.secondary} />,
    },
    doctorate_dsc: {
      title: t("edu_type.doctorate_dsc"),
      icon: <BookIcon color={colors.secondary} />,
    },
    vocational_schools: {
      title: t("edu_type.vocational_schools"),
      icon: <KeyIcon color={colors.secondary} />,
    },
    colleges: {
      title: t("edu_type.colleges"),
      icon: <BriefcaseIcon color={colors.secondary} />,
    },
    technical_schools: {
      title: t("edu_type.technical_schools"),
      icon: <GovermentIcon color={colors.secondary} />,
    },
  };
  let obj = edu_type_data[type as EduTypeAttribute];

  return (
    <div className="edutype-card">
      <div className="edutype-card-content">
        <div className="edutype-card-icon">{obj.icon}</div>
        <div className="edutype-card-title">{obj.title}</div>
        <p>{t("edu_type.common")}</p>
        <div className="edutype-card-total">
          <CountUp end={total} duration={2.75} separator=" " />
        </div>
        <div className="edutype-card-counts">
          <div>
            <p>{t("genders.men")}</p>
            <div className="edutype-card-count">
              <h3>{man}</h3> <span className="man">{calcPercentage(man, total)}%</span>
            </div>
          </div>
          <div>
            <p>{t("genders.women")}</p>
            <div className="edutype-card-count">
              <h3>{women}</h3> <span className="women">{calcPercentage(women, total)}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EduTypeCard;
