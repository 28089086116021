import { ConfigProvider } from "antd";
import { ProviderProps } from "../type";
import { useTypedSelector } from "src/app/store";

function AntConfigProvider({ children }: ProviderProps) {
  const { colors } = useTypedSelector((state) => state.layout);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primary,
          colorBgBase: colors.bg_secondary,
          colorBorder: colors.divider_primary,
          fontFamily: "Inter",
        },
        components: {
          Button: {
            controlHeight: 40,
            colorBorder: "none",
            colorBgTextHover: colors.bg_tertiary,
            controlItemBgHover: colors.bg_tertiary,
          },
          // Modal: {
          //   borderRadius: 32,
          // },
          Table: {
            borderRadius: 4,
            colorBgBase: "red",
            colorBgContainer: colors.white,
            colorBorder: colors.divider_primary,
            colorText: colors.primary,
            colorTextHeading: colors.primary,
          },
          // Menu: {
          //   activeBarBorderWidth: 0,
          //   fontSize: 18,
          // },
          // Input: {
          //   fontSize: 18,
          //   controlHeight: 46,
          //   colorText: colors.gray_900,
          //   colorTextPlaceholder: colors.gray_500,
          //   colorBorder: colors.gray_300,
          //   colorBgContainer: colors.gray_50,
          //   borderRadius: 12,
          // },
          Select: {
            fontSize: 14,
            controlHeight: 36,
            controlHeightLG: 48,
            colorText: colors.primary,
            colorTextPlaceholder: colors.primary,
            borderRadius: 8,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

export default AntConfigProvider;
