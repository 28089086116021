import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";

export interface ISeries {
  name: string;
  data: number[] | undefined;
  stack?: string;
  label?: any;
}
export interface ISeriesTwo {
  name: string;
  data: { value: number; name: string }[] | undefined;
  stack?: string;
}

interface Props {
  legendData: string[];
  xAxisData: string[];
  color: string[];
  seriesArr: ISeries[];
  barWidth?: number;
  gridBottom?: number;
  xAxisRotate?: number;
  yAxisRotate?: number;
  legendSize?: number;
  showTooltip?: boolean;
  barGap?: number;
  gridLeft?: number;
}

const calcTotal = (seriesData: { value: number }[]) => {
  if (!seriesData || !seriesData.length) return "";

  const data = seriesData.reduce((acc, seriesData) => {
    return acc + seriesData.value;
  }, 0);
  var n = new Intl.NumberFormat().format(data);
  return n;
};

function BarChart({
  legendData,
  xAxisData,
  color,
  seriesArr,
  barWidth = 50,
  gridBottom = 40,
  xAxisRotate = 0,
  yAxisRotate = 0,
  gridLeft = 8,
  legendSize = 14,
  showTooltip = true,
}: Props) {
  //Translation section
  const { t } = useTranslation();
  const makeTranslationData = (data: string[]) => {
    return data.map((item) => t(item));
  };

  //Chart section
  const { colors } = useTypedSelector((state) => state.layout);
  const emphasisStyle = {
    label: {
      color: colors.primary,
    },
  };

  const seriesItemStyle = {
    borderColor: colors.white,
    borderWidth: 0.5,
    borderRadius: 6,
  };

  const axisLabelStyle = {
    fontSize: 14,
    color: colors.primary,
    interval: 0,
  };

  const legendTextStyle = {
    color: colors.tertiary,
    fontSize: legendSize,
  };

  let series: any = [];
  seriesArr?.forEach((item: any) => {
    series?.push({ ...item, type: "bar", emphasis: emphasisStyle, itemStyle: seriesItemStyle });
  });

  const option = {
    title: {},
    legend: {
      data: makeTranslationData(legendData),
      align: "auto",
      bottom: 0,
      textStyle: legendTextStyle,
    },
    grid: {
      left: gridLeft,
      right: 8,
      top: 8,
      bottom: gridBottom,
      containLabel: true,
      tooltip: {
        show: showTooltip,
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          label: {
            formatter: function (params: any) {
              return `${params.value}: ${calcTotal(params.seriesData)}`;
            },
          },
        },
      },
    },
    barWidth: barWidth,
    color: color,
    tooltip: {},
    xAxis: {
      data: makeTranslationData(xAxisData),
      name: "X Axis",
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: { ...axisLabelStyle, rotate: xAxisRotate },
    },
    yAxis: {
      axisLabel: { ...axisLabelStyle, rotate: yAxisRotate },
    },
    series: series,
  };

  return <ReactEcharts option={option} />;
}

export default BarChart;
