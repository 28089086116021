export const languageEn = {
  header: {
    statistics: "Statistics",
    university: "University",
    graphic_visible: "Graphic visibility",
    table_visible: "Table visibility",
    edu_type: "Education type",
    edu_form: "Education form",
    payment_form: "Payment form",
    citizenship: "Citizenship",
    courses: "Courses",
    age: "Age",
    residence_place: "Place of residence",
    regions_section: "In regions section",
    by_gender: "By gender",
    dark_mode: "Dark mode",
    download: "Download",
    download_as_png: "Download as PNG",
    download_as_csv: "Download as CSV",
    download_as_pdf: "Download as PDF",
    students_info_text:
      "The information is received in real time from the HEMIS information system",
    lyceum_info_text:
      "Information is received in real time from the emis.edu.uz information system",
    prof_info_text:
      "Information is being received from the prof.edu.uz information system in real time",
    doctoral_info_text:
      "Information is received in real time from the phd.mininnovation.uz information system",
    menu: {
      higher: "Higher Education",
      lyceum: "Academic Lyceum",
      prof: "Professional education",
      admission: "Admission",
      students: "Students",
      teachers: "Teachers",
      universities: "Universities",
      table_second_tab: "In the directions section",
      table_third_tab: "In the section of teachers",
      learners: "Learners",
      lyceums: "Academic lyceums",
      institutions: "Institutions",
      doctoral: "Doctoral",
      doctoral_common: "Common",
      doctoral_by_regions: "By regions",
      doctoral_by_directions: "By directions",
      tables: "Tables",
    },
  },
  footer: {
    contact_center: "Contact center",
    privacy_policy: "Privacy policy",
    ministry:
      "UzRes Ministry of Higher Education, Science and Innovation 2020-2023",
    owner: "Center for the Development of Digital Educational Technologies",
    producer: "Producer",
  },
  edu_type: {
    bachelor: "Bachelor",
    master: "Master's degree",
    ordinance: "Ordinatura",
    doctorate_phd: "Doctorate PhD",
    doctorate_dsc: "Doctorate DSc",
    common: "Common",
    vocational_schools: "Vocational Schools",
    colleges: "Colleges",
    technical_schools: "Technical Schools",
    vocational_school: "Vocational School",
    college: "College",
    technical_school: "Technikum",
    lyceum: "Academic lyceum",
    transfer: "Transfer",
    second_specialty: "Second specialty",
    bachelor_technical_school:
      "Bachelor (by interview, technical school graduates)",
  },
  edu_form: {
    daytime: "Daytime",
    evening: "Evening",
    externally: "Externally",
    special_externally: "Special externally",
    joint: "Joint",
    remote: "Remote",
    dual: "Dual",
    second_evening: "Second Higher (evening)",
    second_daytime: "Second Higher (day time)",
    second_external: "Second higher (external)",
  },
  pay_form: {
    grant: "State grant",
    contract: "Payment-contract",
  },
  citizenships: {
    uzbek: "Citizen of Uzbekistan",
    foreigner: "Citizen of a foreign country",
    stateless: "Stateless person",
    teen: "Minor",
  },
  courses: {
    course_1: "Course 1",
    course_2: "Course 2",
    course_3: "Course 3",
    course_4: "Course 4",
    course_5: "Course 5",
    course_6: "Course 6",
  },
  ages: {
    under_30: "under 30",
    over_30: "over 30",
    under_20: "under 20",
    over_20: "over 20",
    under_17: "under 17",
    over_17: "over 17",
    until_30: "until 30",
    age_30_35: "ages 30-35",
    age_35_40: "ages 35-40",
    age_40_45: "ages 40-45",
    age_45_50: "ages 45-50",
    age_50_55: "ages 50-55",
    age_55_60: "ages 55-60",
    over_60: "age 60+",
  },
  residence_places: {
    own_house: "In own house",
    dormitory: "In a student accommodation",
    rented_house: "In a rented house",
    relative_house: "In a relative's house",
    familiar_house: "In a familiar's house",
    other: "Other",
  },
  genders: {
    men: "Men",
    women: "Women",
  },
  filters: {
    region: "Region",
    search: "Enter university name to search",
    ownership: "Form of ownership",
    state: "State",
    non_state: "Non-state",
    foreign: "Foreign",
    joint: "Joint",
  },
  teachers: {
    by_gender: "Teachers (By Gender)",
    total_count: "Total Count",
    staff: {
      leaders: "Leading staff",
      prorektor: "Vice-Chancellor",
      dekan: "Dean",
      department_header: "Head of Department",
    },
    s_potential: {
      title: "On the scientific potential of teachers",
      skilled: "Skilled",
      unskilled: "Unskilled",
    },
    s_level: {
      title: "Teachers by degree",
      candidate_phd: "Candidate of Science, PhD",
      doctor_dcs: "Doctor of Science, DSc",
    },
    ac_title: {
      title: "Teachers by academic title",
      untitled: "Untitled",
      docent: "Docent",
      senior: "Senior researcher",
      professor: "Professor",
    },
    position: {
      title: "By Position",
      intern: "Intern-teacher",
      assistent: "Assistant",
      senior: "Senior teacher",
    },
    work_type: {
      title: "According to the form of work",
      main: "Main Job",
      seat_external: "Seat (external)",
      per_hour: "Hourly",
      seat_internal_add: "Seat(internal-add)",
      seat_internal_main: "Seat(internal main)",
    },
    by_citizenship: "Teachers by Citizenship",
    by_age: "Teachers by age",
  },
  university: {
    name: "Name of higher education institution",
    list: "List of higher education institutions",
    website: "Website",
    license_pdf: "License.PDF",

    types: {
      list: "List of Universities",
      search: "University search",
    },
  },
  admission: {
    title: "Application statistics for 2021-2023",
    over_years: "Over years",
    applications: "Number of applications",
  },
  lyceum: {
    list: "List of academic lyceums",
    name: "Name of Academic Lyceum",
  },
  prof: {
    list: "List of professional educational institutions",
    name: "Institution Name",
    type: "Type of institution",
    by_region: "By province",
    region_name: "Province name",
    province_code: "Region code",
    code: "Institution code",
  },
  doctoral: {
    doctoralStat: "Doctorate in statistics",
    dc_count: "Number of Candidates (DSc)",
    dc_count_woman: "Number of Female Candidates (DSc)",
    dc_count_man: "Number of Male Candidates (DSc)",
    phd_count: "Number of Candidates (PhD)",
    phd_count_man: "Number of Male Candidates (PhD)",
    phd_count_woman: "Number of female researchers (PhD)",
    all_count: "total number of followers",
    all: "Everything",
    under_40: "Not over 40 years old",
    organization: {
      "100": "Higher educational institutions",
      "200": "Scientific organizations",
      "300": "Ministries",
      org_type: "TYPE OF ORGANIZATION",
      org_count: "ORGANIZATION NO",
      usr_org_count: "NUMBER OF COMBINED ADMINS",
    },
    organizations: "Organizations",
    org: "ORGANIZATION",
    org_count_by_region:
      "Number of universities and institutes in the province",
    count_by_researcher_region:
      "The number of doctoral students and scientific supervisors in the province",
    count_org_researcher_region:
      "The number of doctoral students in the organization is regional",
    count_doc_by_org_direction:
      "The number of doctoral students according to the direction of the organization",
    count_doc_by_direction: "The number of doctoral students by fields",
    higher_education: "Institution of higher education",
    scientific_education: "Scientific organization",
    placed_region: "AREA OF LOCATION",
    itm_scientific_count: "NUMBER OF SCIENTIFIC LEADERS IN ITM",
    otm_scientific_count: "NUMBER OF SCIENTIFIC LEADERS AT OTM",
    itm_doctoral_count: "NUMBER OF DOCTORAL STUDENTS AT ITM",
    otm_doctoral_count: "NUMBER OF DOCTORAL STUDENTS AT UTM",
    doctoral: "DOCTORAL STUDIES",
    support_doctoral: "BASIC DOCTORAL STUDIES",
    intern_researcher: "INTERN-RESEARCHER",
    dc_independent_researcher: "INDEPENDENT RESEARCHER, DSC",
    phd_independent_researcher: "INDEPENDENT RESEARCHER, PHD",
    dc_purposeful_doctoral: "TARGETED DOCTORAL STUDIES, DSC",
    phd_purposeful_doctoral: "PURPOSE-BASED DOCTORAL STUDIES, PHD",
    total: "TOTAL",
    actions: "ACTIONS",
    direction: "SPECIALIZATION",
    direction_code: "Direction code",
    science: "SCIENCE",
    code: "CODE",
    search: "Search",
    reset: "Clear",
    close: "Close",
    filter: "Filter",
  },
  regions: {
    andijan: "Andijon",
    bukhara: "Buxoro",
    fergana: "Farg‘ona",
    jizzax: "Jizzax",
    namangan: "Namangan",
    navai: "Navoiy",
    qashqadaryo: "Qashqadaryo",
    samarqand: "Samarqand",
    qoraqalpoq: "Qoraqalpog‘iston Resp",
    sirdaryo: "Sirdaryo",
    surxondaryo: "Surxondaryo",
    tashkent_city: "Tashkent City",
    tashkent_region: "Tashkent Region",
    xorazm: "Xorazm",
  },
  tables: {
    univerName: "University name",
    ownership: "Ownership",
    univerCode: "Code",
    total: "All",
    universityByRegion: "The address of permanent residence is in the region",
    title_bachelor: "Directions are in the Bachelor section",
    title_magistr: "Directions are in the Master's section",
    title_gender: "By type of education and gender",
    universityByGender: "Gender",
    man: "Man",
    woman: "Woman",
    bachelor: "Bachelor",
    master: "Master",
    paymentType: "Payment Type",
    eduType: "Education Type",
    eduForm: "Education Form",
    eduFormByRegion:
      "The form of education and the cross-section of the region where HEIs are located",
    edu_direction: "Course of Study",
    direction_code: "Direction code",
    speciality: "Speciality",
    speciality_code: "Specialty code",
    bachelor_by_region: "The number of bachelors by region",
    bachelor_count: "Bachelors count",
    master_count: "Masters count",
    master_by_region: "The number of masters by region",
    count_bachelor_direction: "Total Undergraduate specialties",
    count_master_direction: "Total master's specialties",
    candidate_of_science: "Candidate of Science, PhD",
    doctor_of_science: "Doctor of Science, DSc",
    without_rank: "Without rank",
    dotsent: "Dotsent",
    senior_researcher: "Senior researcher",
    professor: "Professor",
    untitled: "Untitled",
    intern_teacher: "Intern-teacher",
    assistant: "Assistant",
    head_teacher: "Head teacher",
    head_of_department: "Head of the department",
    main_workplace: "Main workplace",
    placement: "Placement (intra-primary)",
    placement_subjunctive: "Placement (subjunctive)",
    placement_external: "Placement (external)",
    per_hour: "Per hour",
    name_of_tablePaymentType: "The number of students in the payment section",
    basic_contract: "Basic contract",
    student_count: "Student count",
    withOutStipendCount: "WithOut Stipend Count",
    withStipendCount: "With Stipend Count",
    super_contract: "Super contract",
    contract_foreign: "Contract (foreign)",
    hyper: "Hyper",
    pedagogyAndEducationCount: "Pedagogy and education",
    mathematicsCount: "The field of mathematics",
    naturalSciencesCount: "The field of natural sciences",
    artCount: "The field of art",
    religiousStudiesCount: "Religious studies",
    economicAndSocialCount: "Economy and Social Sector",
    journalismAndInformationCount: "Journalism and information industry",
    lawCount: "The field of law",
    businessAndManagementCount: "Business and management",
    engineeringWorkCount: "Field of engineering work",
    informationCommunicationTechnologiesCount:
      "The field of information and communication technologies",
    agricultureAndForestryCount:
      "The field of agriculture and Armenian economy",
    fisheryCount: "Fishery Count",
    veterinaryMedicineCount: "Veterinary Medicine",
    healthCount: "Health",
    socialSecurity: "Social Security",
    serviceCount: "Service Area",
    transportCount: "Transport field",
    hygieneAndEnvironmentalProtectionCount:
      "The field of hygiene and environmental protection",
    ensuringLegalOrderCount: "Ensuring Legal Order",
    productionAndProcessingCount: "Production and Processing Areas",
    architectureAndConstructionCount: "Architecture and Construction",
    humanityCount: "Humanities Field",
    languagesCount: "Languages field",
    graduated_table_title: "Number of graduates by years and sectors",
    foreignUniversitySpecialtiesCount: "Foreign University Specialties",
    graduated_select_two:
      "The number of graduates in the segment of permanent residence",
    graduated_select_three: "The number of graduates in the specialty section",
    graduated: "In the graduate section",
    prof_count_by_work: "Professors-teachers according to the form of work",
    prof_count_by_academic_degree: "Professors-teachers by academic degree",
    prof_count_by_gender: "Professors by position and gender",
    prof_count_by_academic_title: "Professors-teachers by academic title",
  },
};
