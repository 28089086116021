import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";

interface Props {
  legendData: string[];
  xAxisData: string[];
  color?: string[];
  seriesArr?: any;
  barWidth?: number;
  gridBottom?: number;
  gridLeft?: number;
  interval?: number;
}
const calcTotal = (seriesData: { value: number }[]) => {
  if (!seriesData || !seriesData.length) return "";

  const data = seriesData.reduce((acc, seriesData) => {
    return acc + seriesData.value;
  }, 0);
  var n = new Intl.NumberFormat().format(data);
  return n;
};

export default function BarChartCategoryColumn({
  legendData,
  xAxisData,
  color,
  seriesArr,
  barWidth = 50,
  gridBottom = 40,
  gridLeft = 8,
  interval = 100,
}: Props) {
  //Translation section
  const { t } = useTranslation();
  const makeTranslationData = (data: string[]) => {
    return data.map((item) => t(item));
  };

  //Chart section
  const { colors } = useTypedSelector((state) => state.layout);

  // interface ISeries {
  //   type: "bar";
  // }

  const emphasisStyle = {
    label: {
      color: colors.primary,
    },
  };

  const seriesItemStyle = {
    borderColor: colors.white,
    borderWidth: 0.5,
    borderRadius: 6,
  };

  const legendTextStyle = {
    color: colors.tertiary,
    fontSize: 14,
  };
  const axisLabelStyle = {
    fontSize: 14,
    color: colors.primary,
  };

  let series: any = [];
  seriesArr?.forEach((item: any) => {
    series?.push({
      ...item,
      type: "bar",
      emphasis: emphasisStyle,
      itemStyle: seriesItemStyle,
    });
  });

  const option = {
    title: {},
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    color: color || [colors.blue, colors.pink],
    barWidth: barWidth,
    legend: {
      data: makeTranslationData(legendData),
      align: "auto",
      bottom: 0,
      textStyle: legendTextStyle,
    },
    grid: {
      left: 8,
      right: 8,
      top: 8,
      bottom: gridBottom,
      containLabel: true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          label: {
            formatter: function (params: any) {
              return `${params.value}: ${calcTotal(params.seriesData)}`;
            },
          },
        },
      },
    },
    xAxis: [
      {
        type: "category",
        data: makeTranslationData(xAxisData),
        axisPointer: {
          type: "shadow",
        },
      },
    ],
    yAxis: {
      interval,
      axisLabel: axisLabelStyle,
    },
    series: series,
  };

  return <ReactEcharts option={option} />;
}
