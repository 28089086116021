import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { tablePaginationConfig } from "src/app/services/api/const";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetUniverEduFormQuery } from "src/app/services/tables";
import { IUniverEduForm } from "src/app/services/tables/type";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { useTypedSelector } from "src/app/store";

export function TableOrganizationByRegionTable({
  regionId,
  eduType,
}: {
  regionId: string | null;
  eduType: number;
}) {
  const { t } = useTranslation();
  const { data, isLoading } = useGetUniverEduFormQuery({
    eduTypeCode: eduType,
    regionCode: regionId,
  });
  const navigate = useNavigate();
  const [_, setParams] = useSearchParams();
  const getColumnSearchProps = useColumnSearch();
  const { darkMode } = useTypedSelector((state) => state.layout);

  if (!regionId) return null;

  const onOrgClick = (org_id: number) => {
    setParams((params) => {
      params.append("orgId", `${org_id}`);
      return params;
    });
  };

  const render = (value: number) => value || 0;

  const columns: ColumnsType<IUniverEduForm> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
    },
    {
      //@ts-ignore
      title: "OTM",
      dataIndex: "university",
      ...getColumnSearchProps("university"),
      render,
    },
    {
      title: t("edu_form.daytime"),
      dataIndex: "daytimeCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.evening"),
      dataIndex: "eveningCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.externally"),
      dataIndex: "externalCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.joint"),
      dataIndex: "jointCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.remote"),
      dataIndex: "remoteCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_daytime"),
      dataIndex: "secondDaytimeCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_evening"),
      dataIndex: "secondEveningCount",
      align: "center",
      render,
    },
    {
      title: t("edu_form.second_external"),
      dataIndex: "secondExternalCount",
      align: "center",
      render,
    },
    {
      title: t("tables.total"),
      dataIndex: "total",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
    {
      title: t("doctoral.actions"),
      dataIndex: "universityCode",
      align: "center",
      render: (org_id) => (
        <Button
          size="small"
          style={{ color: `${darkMode ? "white" : "black"}` }}
          onClick={() => onOrgClick(org_id)}
        >
          &#128065;
        </Button>
      ),
    },
  ];

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="doctoralOrganization">
      <header className="doctoral_table_header">
        <Button size="small" onClick={navigateBack}>
          {`<`}
        </Button>
        <h3>Tashkilotdagi {eduType === 11 ? "bakalavrlar" : "magistrlar"} soni viloyat kesimida</h3>
      </header>
      <Table
        scroll={{ x: true }}
        size="small"
        rowKey="universityCode"
        dataSource={data}
        columns={columns}
        pagination={tablePaginationConfig}
        loading={isLoading}
      />
    </div>
  );
}
