import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import { ages, courses, genders, residencePlaces } from "src/pages/constants";
import { useTranslation } from "react-i18next";
import useCourse from "./useCourse";

function CoursesPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);

  const { gendersData, agesData, placesData } = useCourse();

  return (
    <div className="eduform">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            { name: t("genders.men"), count: gendersData?.men_total },
            { name: t("genders.women"), count: gendersData?.women_total },
          ]}
          graphHeight={332}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={courses}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: [...(gendersData?.men_data || [])].reverse(),
              },
              {
                name: t("genders.women"),
                data: [...(gendersData?.women_data || [])].reverse(),
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-30}
            yAxisMargin={110}
          />
        </GraphInfoCard>

        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            { name: t("ages.under_30"), count: agesData?.less_total },
            { name: t("ages.over_30"), count: agesData?.greater_total },
          ]}
          graphHeight={332}
        >
          <BarChartCategory
            legendData={ages}
            yAxisData={courses}
            color={[colors.light_blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("ages.under_30"),
                data: [...(agesData?.less_data || [])].reverse(),
              },
              {
                name: t("ages.over_30"),
                data: [...(agesData?.greater_data || [])].reverse(),
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-30}
            yAxisMargin={110}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Place of residence */}
        <GraphInfoCard
          title={t("header.residence_place")}
          stat={[
            { name: t("residence_places.own_house"), count: placesData?.own_house_total },
            { name: t("residence_places.dormitory"), count: placesData?.dormitory_total },
            { name: t("residence_places.rented_house"), count: placesData?.rented_house_total },
            { name: t("residence_places.relative_house"), count: placesData?.relative_house_total },
            { name: t("residence_places.familiar_house"), count: placesData?.familiar_house_total },
          ]}
        >
          <BarChart
            legendData={residencePlaces}
            xAxisData={courses}
            color={[colors.pink, colors.light_blue, colors.orange, colors.deep_purple, colors.green]}
            seriesArr={[
              {
                name: t("residence_places.own_house"),
                data: placesData?.own_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.dormitory"),
                data: placesData?.dormitory_data,
                stack: "one",
              },
              {
                name: t("residence_places.rented_house"),
                data: placesData?.rented_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.relative_house"),
                data: placesData?.relative_house_data,
                stack: "one",
              },
              {
                name: t("residence_places.familiar_house"),
                data: placesData?.familiar_house_data,
                stack: "one",
              },
            ]}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t('header.students_info_text')}</p>
      </div>
    </div>
  );
}

export default CoursesPage;
