import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import {useGetTablesRankQuery} from "src/app/services/tables";
import {ITablesRank} from "src/app/services/tables/type";
import {useColumnSearch} from "src/pages/higher_education/tables/useColumnSearch";
import {CustomTable} from "src/pages/higher_education/tables/tablesUniversities/components/CustomTable";
import {useMemo, useState} from "react";
import {Select, Space, Table} from "antd";
import {DownloadButton} from "src/components/downloadButton";
import {useGetGenderQuery, useGetUniversityOwnershipCodesQuery} from "src/app/services/private";

export function TablesRankForm() {
    const {t} = useTranslation();
    const getColumnSearchProps = useColumnSearch();
    const [gender, setGender] = useState<string>();
    const [ownership, setOwnership] = useState<string>();
    const {data, isLoading} = useGetTablesRankQuery({gender, ownership});
    const {data: ownershipList} = useGetUniversityOwnershipCodesQuery()
    const dataSourceAcademic = useMemo(() => {
        const newData = data?.map((item) => ({
            ...item,
            total:
                item.dotsentCount +
                item.seniorResearcherCount +
                item.professorCount +
                item.withoutRankCount,
        }));

        return newData;
    }, [data]);
    const {data: genderList} = useGetGenderQuery();

    const columns: ColumnsType<ITablesRank> = [
        {
            title: t("tables.univerCode"),
            dataIndex: "universityCode",
        },
        {
            //@ts-ignore
            title: t("tables.univerName"),
            width: "250px",
            dataIndex: "universityName",
            ...getColumnSearchProps("universityName"),
        },
        {
            title: t("tables.ownership"),
            dataIndex: "universityOwnership",
            align: "center",
        },
        {
            title: t("tables.dotsent"),
            dataIndex: "dotsentCount",
            align: "center",
        },
        {
            title: t("tables.senior_researcher"),
            dataIndex: "seniorResearcherCount",
            width: "250px",
            align: "center",
        },
        {
            title: t("tables.professor"),
            dataIndex: "professorCount",
            align: "center",
        },
        {
            title: t("tables.untitled"),
            width: "200px",
            dataIndex: "withoutRankCount",
            align: "center",
        },
        {
            title: t("tables.total"),
            dataIndex: "total",
            align: "center",
            render: (total) => <b>{total}</b>,
        },
    ];

    return (
        <div>
            <Table
                title={() => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h3>Professor-o'qituvchilar ilmiy unvon bo'yicha</h3>

                        <Space>
                            <Select
                                style={{width: 230}}
                                placeholder="Jinsi bo'yicha"
                                onChange={setGender}
                                options={
                                    genderList && [
                                        ...genderList?.map((item) => ({label: item.name, value: item.code})),
                                    ]
                                }
                                onClear={() => setGender(undefined)}
                                allowClear
                            />

                            <Select
                                style={{width: 230}}
                                placeholder="Mulkchilik bo'yicha"
                                onChange={setOwnership}
                                onClear={() => setOwnership(undefined)}
                                allowClear
                                options={
                                    ownershipList && [
                                        ...ownershipList?.map((item) => ({label: item.name, value: item.code})),
                                    ]
                                }
                            />

                            <DownloadButton
                                downloadUrl="/excel/teacher/rank"
                                params={{
                                    gender: gender && gender,
                                    ownership: ownership && ownership,
                                }}
                            />
                        </Space>
                    </div>
                )}
                rowKey="universityCode"
                dataSource={dataSourceAcademic}
                columns={columns}
                pagination={false}
                size="small"
                loading={isLoading}
                scroll={{x: true, y: 500}}
            />
        </div>
    );
}
