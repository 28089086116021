import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { IDoctoralStatOrganization } from "src/app/services/doctoral/type";
import { useGetDoctoralStatOrganizationQuery } from "src/app/services/doctoral";

export function DoctoralOrganization() {
  const { t } = useTranslation();
  const { data } = useGetDoctoralStatOrganizationQuery();

  const columns: ColumnsType<IDoctoralStatOrganization> = [
    {
      title: t("doctoral.organization.org_type"),
      dataIndex: "type_org_id",
      render: (org_id: number) => t(`doctoral.organization.${org_id}`),
    },
    {
      title: t("doctoral.organization.org_count"),
      dataIndex: "org_count",
      align: "center",
    },
    {
      title: t("doctoral.organization.usr_org_count"),
      dataIndex: "usr_org_count",
      align: "center",
    },
  ];

  return (
    <div className="doctoralOrganization">
      <header className="doctoral_table_header">
        <h3>{t("doctoral.organizations")}</h3>
      </header>
      <Table rowKey="type_org_id" dataSource={data} columns={columns} pagination={false} />
    </div>
  );
}
