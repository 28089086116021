import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetRegionsQuery } from "src/app/services/universities";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import PieChart from "src/components/chart/PieChart";
import { giveLan } from "src/utils";
import { lyceum_ages, lyceum_courses } from "../constants";
import useLyceum from "src/pages/lyceum/useLyceum";

function LyceumPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const { data } = useGetRegionsQuery();
  const [regions, setRegions] = useState<string[]>([]);
  const { gendersData, citizenshipData, agesData, courseData, courseRegionData, AgeRegionData } =
    useLyceum();

  const makeData = () => {
    if (data) {
      let arr: string[] = [];
      data.forEach((item) => {
        arr.push(giveLan(item));
      });
      setRegions(arr);
    }
  };

  useEffect(() => {
    makeData();
  }, [data]);

  return (
    <div className="">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard title={t("header.by_gender")} graphHeight={396}>
          <PieChart
            seriesArr={[
              { value: gendersData?.male.count, name: t("genders.men") },
              { value: gendersData?.female.count, name: t("genders.women") },
            ]}
            color={[colors.light_blue, colors.pink]}
          />
        </GraphInfoCard>
        {/* Citizenship */}
        <GraphInfoCard title={t("header.citizenship")} graphHeight={396}>
          <PieChart
            seriesArr={[
              {
                value: citizenshipData && citizenshipData?.["1"]?.count,
                name: t("citizenships.uzbek"),
              },
              {
                value: citizenshipData && citizenshipData?.["2"]?.count,
                name: t("citizenships.foreigner"),
              },
              {
                value: citizenshipData && citizenshipData?.["3"]?.count,
                name: t("citizenships.stateless"),
              },
            ]}
            color={[colors.light_green, colors.deep_purple, colors.yellow]}
            legendSize={11}
            gridBottom={-7}
          />
        </GraphInfoCard>
        {/* Scientific potential */}
        <GraphInfoCard title={t("header.age")} graphHeight={396}>
          <PieChart
            seriesArr={[
              {
                value: agesData && agesData?.["<17"],
                name: t("ages.under_17"),
              },
              {
                value: agesData && agesData?.[">=17"],
                name: t("ages.over_17"),
              },
            ]}
            color={[colors.green, colors.light_blue]}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Courses */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            {
              name: t("courses.course_1"),
              count: courseData && courseData["1"]?.count,
            },
            {
              name: t("courses.course_2"),
              count: courseData && courseData["2"]?.count,
            },
          ]}
          graphHeight={460}
        >
          <BarChart
            legendData={lyceum_courses}
            xAxisData={regions}
            color={[colors.orange, colors.blue]}
            seriesArr={[
              {
                name: t("courses.course_1"),
                data: courseRegionData && [
                  courseRegionData["Andijon viloyati"]?.["1"].count,
                  courseRegionData["Buxoro viloyati"]?.["1"].count,
                  courseRegionData["Jizzax viloyati"]?.["1"].count,
                  courseRegionData["Qashqadaryo viloyati"]?.["1"].count,
                  courseRegionData["Navoiy viloyati"]?.["1"].count,
                  courseRegionData["Namangan viloyati"]?.["1"].count,
                  courseRegionData["Samarqand viloyati"]?.["1"].count,
                  courseRegionData["Surxondaryo viloyati"]?.["1"].count,
                  courseRegionData["Sirdaryo viloyati"]?.["1"].count,
                  courseRegionData["Toshkent shahri"]?.["1"].count,
                  courseRegionData["Toshkent viloyati"]?.["1"].count,
                  courseRegionData["Farg'ona viloyati"]?.["1"].count,
                  courseRegionData["Xorazm viloyati"]?.["1"].count,
                  courseRegionData["Qoraqalpog'iston Respublikasi"]?.["1"].count,
                ],
                stack: "one",
              },
              {
                name: t("courses.course_2"),
                data: courseRegionData && [
                  courseRegionData["Andijon viloyati"]?.["2"].count,
                  courseRegionData["Buxoro viloyati"]?.["2"].count,
                  courseRegionData["Jizzax viloyati"]?.["2"].count,
                  courseRegionData["Qashqadaryo viloyati"]?.["2"].count,
                  courseRegionData["Navoiy viloyati"]?.["2"].count,
                  courseRegionData["Namangan viloyati"]?.["2"].count,
                  courseRegionData["Samarqand viloyati"]?.["2"].count,
                  courseRegionData["Surxondaryo viloyati"]?.["2"].count,
                  courseRegionData["Sirdaryo viloyati"]?.["2"].count,
                  courseRegionData["Toshkent shahri"]?.["2"].count,
                  courseRegionData["Toshkent viloyati"]?.["2"].count,
                  courseRegionData["Farg'ona viloyati"]?.["2"].count,
                  courseRegionData["Xorazm viloyati"]?.["2"].count,
                  courseRegionData["Qoraqalpog'iston Respublikasi"]?.["2"].count,
                ],
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            { name: t("ages.under_17"), count: agesData && agesData?.["<17"] },
            { name: t("ages.over_17"), count: agesData && agesData?.[">=17"] },
          ]}
          graphHeight={336}
        >
          <BarChart
            legendData={lyceum_ages}
            xAxisData={regions}
            color={[colors.light_blue, colors.green]}
            seriesArr={[
              {
                name: t("ages.under_17"),
                data: AgeRegionData && [
                  AgeRegionData["Andijon viloyati"]?.["<17"],
                  AgeRegionData["Buxoro viloyati"]?.["<17"],
                  AgeRegionData["Jizzax viloyati"]?.["<17"],
                  AgeRegionData["Qashqadaryo viloyati"]?.["<17"],
                  AgeRegionData["Navoiy viloyati"]?.["<17"],
                  AgeRegionData["Namangan viloyati"]?.["<17"],
                  AgeRegionData["Samarqand viloyati"]?.["<17"],
                  AgeRegionData["Surxondaryo viloyati"]?.["<17"],
                  AgeRegionData["Sirdaryo viloyati"]?.["<17"],
                  AgeRegionData["Toshkent shahri"]?.["<17"],
                  AgeRegionData["Toshkent viloyati"]?.["<17"],
                  AgeRegionData["Farg'ona viloyati"]?.["<17"],
                  AgeRegionData["Xorazm viloyati"]?.["<17"],
                  AgeRegionData["Qoraqalpog'iston Respublikasi"]?.["<17"],
                ],
                stack: "one",
              },
              {
                name: t("ages.over_17"),
                data: AgeRegionData && [
                  AgeRegionData["Andijon viloyati"]?.[">=17"],
                  AgeRegionData["Buxoro viloyati"]?.[">=17"],
                  AgeRegionData["Jizzax viloyati"]?.[">=17"],
                  AgeRegionData["Qashqadaryo viloyati"]?.[">=17"],
                  AgeRegionData["Navoiy viloyati"]?.[">=17"],
                  AgeRegionData["Namangan viloyati"]?.[">=17"],
                  AgeRegionData["Samarqand viloyati"]?.[">=17"],
                  AgeRegionData["Surxondaryo viloyati"]?.[">=17"],
                  AgeRegionData["Sirdaryo viloyati"]?.[">=17"],
                  AgeRegionData["Toshkent shahri"]?.[">=17"],
                  AgeRegionData["Toshkent viloyati"]?.[">=17"],
                  AgeRegionData["Farg'ona viloyati"]?.[">=17"],
                  AgeRegionData["Xorazm viloyati"]?.[">=17"],
                  AgeRegionData["Qoraqalpog'iston Respublikasi"]?.[">=17"],
                ],
                stack: "one",
              },
            ]}
            barWidth={48}
            xAxisRotate={15}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t("header.lyceum_info_text")}</p>
      </div>
    </div>
  );
}

export default LyceumPage;
