import {
  useGetTeacherByCitizenshipStatQuery,
  useGetTeacherByEmployeeFormStatQuery,
  useGetTeacherByGenderStatQuery,
  useGetTeacherByPositionStatQuery,
  useGetTeacherByChiefPositionQuery,
  useGetTeacherByAgeStatQuery,
  useGetTeacherByAcademicDegreeQuery,
  useGetTeacherByAcademicRankQuery,
} from "src/app/services/teacher";
import {
  makeTeacherAcademicDegree,
  makeTeacherAcademicRank,
  makeTeacherAge,
} from "src/pages/higher_education/teachers/utils";

export const useTeachers = () => {
  const { data: positionData } = useGetTeacherByPositionStatQuery();
  const { data: genderData } = useGetTeacherByGenderStatQuery();
  const { data: employeeFormData } = useGetTeacherByEmployeeFormStatQuery();
  const { data: citizenshipData } = useGetTeacherByCitizenshipStatQuery();
  const { data: chiefPositionData } = useGetTeacherByChiefPositionQuery();
  const { data: academicDegreeDataRes } = useGetTeacherByAcademicDegreeQuery();
  const { data: academicRankDataRes } = useGetTeacherByAcademicRankQuery();
  const { data: ageDataRes } = useGetTeacherByAgeStatQuery();

  const ageData = makeTeacherAge(ageDataRes);
  const academicRankData = makeTeacherAcademicRank(academicRankDataRes);
  const academicDegreeData = makeTeacherAcademicDegree(academicDegreeDataRes);

  return {
    positionData,
    genderData,
    employeeFormData,
    citizenshipData,
    ageData,
    academicDegreeData,
    chiefPositionData,
    academicRankData,
  };
};
