import "./doctoral.scss";
import { DoctoralStatList } from "./components/DoctoralStatList";
import { DoctoralOrganization } from "./components/DoctoralOrganizations";
import { DoctoralOrganizationChart } from "./components/DoctoralOrganizationChart";
import { useTranslation } from "react-i18next";

function DoctoralPage() {
  const { t } = useTranslation();

  return (
    <div className="doctoral">
      <section className="stat_org_box">
        <DoctoralStatList />
        <DoctoralOrganization />
      </section>
      <DoctoralOrganizationChart />
      <div className="text_info" style={{width:'100%'}}>
        <p>{t("header.doctoral_info_text")}</p>
      </div>
    </div>
  );
}

export default DoctoralPage;
