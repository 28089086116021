import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useGetProfListMutation } from "src/app/services/prof_education";
import { IProfList } from "src/app/services/prof_education/type";
import OwnershipFilter from "src/components/filter/OwnershipFilter";
import PaginationFilter from "src/components/filter/PaginationFilter";
import ProfEduTypeFilter from "src/components/filter/ProfEduTypeFilter";
import RegionFilter from "src/components/filter/RegionFilter";
import SearchFilter from "src/components/filter/SearchFilter";
import { findOwnership, profEduTypes } from "src/components/filter/constants";
import { language } from "src/provider/language/const";
import { handleMakeUrl, scrollTop } from "src/utils";

export default function ProfEducationsListPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [getFunc, { data }] = useGetProfListMutation();

  useEffect(() => {
    let url = handleMakeUrl(searchParams);
    getFunc(url);

    scrollTop();
  }, [searchParams]);

  const [tableData, setTableData] = useState<IProfList[]>([]);

  const makeTableData = () => {
    let size = Number(searchParams.get("size") || 10);
    let page = Number(searchParams.get("page") || 1);
    let arr: IProfList[] = [];

    data?.results.forEach((item, index) => {
      arr.push({
        key: (page - 1) * size + index + 1,
        ...item,
      });
    });
    setTableData([...arr]);
  };

  useEffect(() => {
    if (data) makeTableData();
  }, [data]);

  //Data section
  const columns: ColumnsType<IProfList> = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
    },
    {
      title: t("prof.name"),
      dataIndex: `name_${language || "uz"}`,
      key: `name_${language || "uz"}`,
    },
    {
      title: t("filters.ownership"),
      dataIndex: "ownership_form",
      key: "ownership_form",
      render: (val) => (
        <div className="university-card-type">
          <span className={`university-card-type-${val}`}>{t(findOwnership(val))}</span>
        </div>
      ),
    },
    {
      title: t("header.edu_type"),
      dataIndex: "education_type",
      key: "education_type",
      render: (val: number) => t(profEduTypes.find((el) => el.id === val)?.name || ""),
    },
  ];

  return (
    <div className="university">
      <div className="university-title" style={{ width: language === "ru" ? 500 : 400 }}>
        {t("prof.list")}
      </div>

      <div className="university-search-content">
        <SearchFilter />
        <div className="university-filter" style={{ paddingTop: 24 }}>
          <RegionFilter />
          <OwnershipFilter />
          <ProfEduTypeFilter />
        </div>
      </div>

      <div className="university-table" style={{ marginTop: 24 }}>
        <Table dataSource={tableData} columns={columns} bordered pagination={false} />
      </div>

      <PaginationFilter total={data?.count} />
      <div className="text_info">
        <p>{t("header.prof_info_text")}</p>
      </div>
    </div>
  );
}
