import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Table, Select, Button } from "antd";
import {
  useGetTablesGraduatedStudentsBySpecialityQuery,
  useGetTablesGraduatedYearQuery,
} from "src/app/services/tables";
import {
  IGraduatedBySpeciality,
  IGraduatedYear,
} from "src/app/services/tables/type";
import { DownloadIcon } from "src/assets/svg";
import { getExcel } from "src/app/services/public";
import { useTypedSelector } from "src/app/store";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
export function TablesGraduatedStudentsBySpeciality() {
  const { t } = useTranslation();
  const getColumnSearchProps = useColumnSearch();
  const { data } = useGetTablesGraduatedYearQuery();
  const [yearList, setYearList] = useState<IGraduatedYear[]>();
  const [tableData, setTableData] = useState<IGraduatedBySpeciality[]>();
  const [valueYearSelect, setValueYearSelect] = useState<string>("2022");
  const { data: admissionTc, isFetching: tcLoading } =
    useGetTablesGraduatedStudentsBySpecialityQuery(
      { eduYearCode: valueYearSelect },
      {
        skip: !valueYearSelect,
      },
    );
  const [loading, setLoading] = useState(false);
  const { colors } = useTypedSelector((state) => state.layout);

  useEffect(() => {
    if (admissionTc) {
      const newArray = admissionTc.map((item, index) => ({ ...item, index }));
      setTableData(newArray);
    }
  }, [admissionTc]);

  useEffect(() => {
    const yearL = data?.filter((year) => parseInt(year.code) > 2020);
    if (yearL) {
      setYearList(yearL);
    }
  }, [data]);

  const downloadExcelFile = async () => {
    setLoading(true);
    await getExcel(
      `graduatedStudent/speciality?eduYearCode=${valueYearSelect}`,
    );
    setLoading(false);
  };

  const columns: ColumnsType<IGraduatedBySpeciality> = [
    {
      title: t("tables.univerCode"),
      width: "60px",
      dataIndex: "universityCode",
      align: "center",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      ...getColumnSearchProps("universityName"),
      width: "100px",
      align: "center",
    },
    {
      title: t("tables.ownership"),
      dataIndex: "ownership",
      width: "40px",
      align: "center",
    },
    {
      //@ts-ignore

      title: t("tables.speciality_code"),
      dataIndex: "specialityCode",
      width: "50px",
      align: "center",
      ...getColumnSearchProps("specialityCode"),
    },
    {
      //@ts-ignore
      title: t("tables.speciality"),
      dataIndex: "specialityName",
      ...getColumnSearchProps("specialityName"),
      width: "100px",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "count",
      width: "50px",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  const handleYearChange = (value: string) => {
    setValueYearSelect(value);
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginTop: "40px" }}>
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>{t("tables.graduated_select_three")}</h3>

              <div className="filter-container">
                <Select
                  style={{ width: 200 }}
                  onChange={handleYearChange}
                  value={valueYearSelect}
                  options={yearList?.map((item) => ({
                    label: item.educationYear,
                    value: item.code,
                  }))}
                />
                <Button
                  className="download_btn"
                  onClick={downloadExcelFile}
                  loading={loading}
                >
                  {t("header.download")} <DownloadIcon color={colors.primary} />
                </Button>
              </div>
            </div>
          )}
          rowKey={(record) => `${record.index}`}
          dataSource={tableData}
          columns={columns}
          pagination={false}
          size="small"
          scroll={{ x: 1200, y: 500 }}
          loading={tcLoading}
        />
      </div>
    </div>
  );
}
