import { useTranslation } from "react-i18next";
import "./admission.scss";
import AdmissionCard from "./components/AdmissionCard";

function AdmissionPage() {
  const { t } = useTranslation();

  return (
    <div className="admission">
      <AdmissionCard
        title={t("edu_type.bachelor")}
        color="rgb(165 211 240)"
        counts={[948211, 1072118, 948491, 894279]}
      />
      <AdmissionCard
        title={t("edu_type.master")}
        color="rgba(231, 242, 163, 0.50)"
        counts={[79108, 25689, 20054, 33172]}
      />
      <AdmissionCard
        title={t("edu_type.lyceum")}
        color="rgba(255, 222, 188, 0.50)"
        counts={[35614, 31755, 33884, 44356]}
      />
      <AdmissionCard
        title={t("edu_type.vocational_school")}
        color="rgba(209, 240, 235, 0.50)"
        counts={[103812, 105791, 129324, 129834]}
      />
      <AdmissionCard
        title={t("edu_type.college")}
        color="rgba(215, 241, 196, 0.50)"
        counts={[112213, 65518, 35088]}
      />
      <AdmissionCard
        title={t("edu_type.technical_school")}
        color="rgba(253, 239, 184, 0.50)"
        counts={[161374, 144682, 140764]}
      />
      <AdmissionCard
        title={t("edu_type.transfer")}
        color="rgba(227, 231, 251, 0.50)"
        counts={[60010, 29325, 28218, 41868]}
      />
      <AdmissionCard
        title={t("edu_type.second_specialty")}
        color="rgba(252, 215, 225, 0.50)"
        counts={[31785, 32113, 38757, 37587]}
      />
      <AdmissionCard
        title={t("edu_type.bachelor_technical_school")}
        color="rgba(252, 115, 125, 0.50)"
        counts={[0, 9773, 9468, 6867]}
      />
    </div>
  );
}

export default AdmissionPage;
