import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProfTablePermanentRegion } from "src/pages/prof_education/prof_tables/components/ProfTablePermanentRegion";
import { ProfTableGenderRegion } from "src/pages/prof_education/prof_tables/components/ProfTableGenderRegion";
import { ProfTableAdmissionTypeRegion } from "src/pages/prof_education/prof_tables/components/ProfTableAdmissionTypeRegion";
import { ProfTableCourse } from "src/pages/prof_education/prof_tables/components/ProfTableCourse";
import { ProfTableEducationForm } from "src/pages/prof_education/prof_tables/components/ProfTableEducationForm";
import { ProfTableEducationFormClassifier } from "src/pages/prof_education/prof_tables/components/ProfTableEducationFormClassifier";

function ProfTables() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const technical_id = params.get("technical_id");
  const id = params.get("id");

  return (
    <div className="doctoral">
      {!technical_id && id === null && <ProfTablePermanentRegion />}
      {!technical_id && id === "1" && <ProfTableGenderRegion />}
      {!technical_id && id === "2" && <ProfTableAdmissionTypeRegion />}
      {!technical_id && id === "3" && <ProfTableCourse />}
      {!technical_id && id === "4" && <ProfTableEducationForm />}
      {technical_id && <ProfTableEducationFormClassifier technical_id={technical_id} />}

      <div className="text_info" style={{ width: "100%" }}>
        <p>{t("header.prof_info_text")}</p>
      </div>
    </div>
  );
}

export default ProfTables;
