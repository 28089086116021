import { FloatButton } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import { ArrowUpIcon } from "src/assets/svg";
import { scrollTop } from "src/utils";
import Footer from "./footer";
import Header from "./header";
import "./layout.scss";

function Layout() {
  const { pathname } = useLocation();
  const isUniversity =
    pathname === "/universities" || pathname === "/lyceum/list" || pathname === "/prof/list";

  return (
    <div className="layout">
      <Header />
      <div className={`layout-content ${isUniversity && "layout-content-university"}`}>
        <div className="container">
          <Outlet />
        </div>
      </div>

      <Footer />
      <FloatButton.BackTop
        onClick={scrollTop}
        shape="circle"
        type="primary"
        style={{ right: 32 }}
        icon={<ArrowUpIcon color="#fff" />}
      />
    </div>
  );
}

export default Layout;
