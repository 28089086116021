import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { IDoctoralStatCountByRegions } from "src/app/services/doctoral/type";
import { ILanguage } from "src/app/services/api/type";
import { language } from "src/provider/language/const";
import { useGetDoctoralStatCountByRegionsQuery } from "src/app/services/doctoral";
import { useSearchParams } from "react-router-dom";

export function DoctoralCountByRegionTable() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetDoctoralStatCountByRegionsQuery();
  const [_, setParam] = useSearchParams();

  const onRegionClick = (regionId: number) => {
    setParam({
      regionId: `${regionId}`,
    });
  };

  const columns: ColumnsType<IDoctoralStatCountByRegions> = [
    {
      title: t("doctoral.placed_region"),
      dataIndex: "name",
      render: (name: ILanguage) => <span>{name[language]}</span>,
    },
    {
      title: t("doctoral.itm_scientific_count"),
      dataIndex: "researcher_itm_count",
      align: "center",
    },
    {
      title: t("doctoral.otm_scientific_count"),
      dataIndex: "researcher_otm_count",
      align: "center",
    },
    {
      title: t("doctoral.itm_doctoral_count"),
      dataIndex: "apply_doc_itm_count",
      align: "center",
    },
    {
      title: t("doctoral.otm_doctoral_count"),
      dataIndex: "apply_doc_otm_count",
      align: "center",
    },
    {
      title: t("doctoral.actions"),
      dataIndex: "region",
      render: (regionId) => (
        <Button size="small" onClick={() => onRegionClick(regionId)}>
          &#128065;
        </Button>
      ),
    },
  ];

  return (
    <div className="doctoralOrganization">
      <header className="doctoral_table_header">
        <h3>{t("doctoral.count_by_researcher_region")}</h3>
      </header>
      <Table
        rowKey="apply_doc_otm_count"
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ x: true }}
        loading={isLoading}
      />
    </div>
  );
}
