import { ConfigProvider, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import {
  useGetSpecialitiesEduFormQuery,
  useGetTablesUniversityByCountryQuery,
} from "src/app/services/tables";
import { ISpecialitiesEduForm } from "src/app/services/tables/type";
import { tablePaginationConfig } from "src/app/services/api/const";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { DownloadButton } from "src/components/downloadButton";

export function TablesUniversityByCountry() {
  const { t } = useTranslation();

  const getColumnSearchProps = useColumnSearch();

  const { data: country, isFetching } = useGetTablesUniversityByCountryQuery();

  console.log("country", country);

  const render = (value: number) => value || 0;

  const columns: ColumnsType<ISpecialitiesEduForm> = [
    {
      title: t("tables.univerCode"),
      dataIndex: "universityCode",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      ...getColumnSearchProps("universityName"),
      render,
    },
    {
      title: t("tables.ownership"),
      dataIndex: "ownership",
      align: "center",
      render,
    },
    {
      title: "Birlashgan Arab Amirliklari",
      dataIndex: "map",
      align: "center",
      width: 300,
      render: (value: any) => value.AE,
    },
    {
      title: "Afg‘oniston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.AF,
    },
    {
      title: "Armaniston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.AM,
    },
    {
      title: "Avstraliya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.AU,
    },
    {
      title: "Ozarbayjon",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.AZ,
    },
    {
      title: "Bangladesh",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.BD,
    },
    {
      title: "Belorussiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.BY,
    },
    {
      title: "Xitoy",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.CN,
    },
    {
      title: "Germaniya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.DE,
    },
    {
      title: "Misr",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.EG,
    },
    {
      title: "Buyuk Britaniya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.GB,
    },
    {
      title: "Gruziya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.GE,
    },
    {
      title: "Gresiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.GR,
    },
    {
      title: "Gonkong",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.HK,
    },
    {
      title: "Vengriya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.HU,
    },
    {
      title: "Indoneziya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.ID,
    },
    {
      title: "Isroil",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.IL,
    },
    {
      title: "Hindiston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.IN,
    },
    {
      title: "Eron",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.IR,
    },
    {
      title: "Italiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.IT,
    },
    {
      title: "Yamayka",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.JM,
    },
    {
      title: "Iordaniya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.JO,
    },
    {
      title: "Yaponiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.JP,
    },
    {
      title: "Qirg‘iziston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.KG,
    },
    {
      title: "Koreya xalq-demokratik respublikasi",
      dataIndex: "map",
      align: "center",
      width: 300,
      render: (value: any) => value.KP,
    },
    {
      title: "Janubiy Koreya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.KR,
    },
    {
      title: "Quvayt",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.KW,
    },
    {
      title: "Qozog‘iston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.KZ,
    },
    {
      title: "Sent-Lyus",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.LC,
    },
    {
      title: "Shrilanka",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.LK,
    },
    {
      title: "Monako",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.MC,
    },
    {
      title: "Sen-Marten",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.MF,
    },
    {
      title: "Malayziya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.MY,
    },
    {
      title: "Nigeriya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.NG,
    },
    {
      title: "Nepal",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.NP,
    },
    {
      title: "Yangi Zellandiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.NZ,
    },
    {
      title: "Filippin",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.PH,
    },
    {
      title: "Pokiston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.PK,
    },
    {
      title: "Polsha",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.PL,
    },
    {
      title: "Ruminiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.RO,
    },
    {
      title: "Serbia",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.RS,
    },
    {
      title: "Rossiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.RU,
    },
    {
      title: "Saudiya Arabistoni",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.SA,
    },
    {
      title: "Shvesiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.SE,
    },
    {
      title: "Senegal",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.SN,
    },
    {
      title: "Suriya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.SY,
    },
    {
      title: "Tojikiston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.TJ,
    },
    {
      title: "Turkmaniston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.TM,
    },
    {
      title: "Tunis",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.TN,
    },
    {
      title: "Turkiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.TR,
    },
    {
      title: "Ukraina",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.UA,
    },
    {
      title: "Amerika Qo‘shma Shtatlari",
      dataIndex: "map",
      align: "center",
      width: 300,
      render: (value: any) => value.US,
    },
    {
      title: "O‘zbekiston",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.UZ,
    },
    {
      title: "Vetnam",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.VN,
    },
    {
      title: "Yaman",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.YE,
    },
    {
      title: "Janubiy Afrika",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.ZA,
    },
    {
      title: "Zambiya",
      dataIndex: "map",
      align: "center",
      render: (value: any) => value.ZM,
    },
  ];

  return (
    <div className="doctoralOrganization">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowSelectedBg: "#e6f4ff",
              rowSelectedHoverBg: "#bae0ff",
            },
          },
        }}
      >
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Talabalar soni davlatlar kesimida</h3>

              <Space>
                <DownloadButton downloadUrl="/excel/student/country" />
              </Space>
            </div>
          )}
          rowKey="universityName"
          dataSource={country}
          columns={columns}
          pagination={tablePaginationConfig}
          rowSelection={{ type: "radio", columnWidth: 40 }}
          size="small"
          scroll={{ x: 10100, y: 600 }}
          loading={isFetching}
        />
      </ConfigProvider>
    </div>
  );
}
