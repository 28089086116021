import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { useEffect } from "react";
import { useGetProfTableEducationFormClassifierMutation } from "src/app/services/prof_education";
import { IProfEducationFormClassifier } from "src/app/services/prof_education/type";
import { Button, Table } from "antd";
import { tablePaginationConfigTwo } from "src/app/services/api/const";
import "../tableProf.scss";
import { useNavigate } from "react-router-dom";

interface iProps {
  technical_id: string;
}
export function ProfTableEducationFormClassifier({ technical_id }: iProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getFunc, { data: classifierTc, isLoading: tcLoading }] =
    useGetProfTableEducationFormClassifierMutation();
  const getColumnSearchProps = useColumnSearch();

  useEffect(() => {
    getFunc({ id: technical_id });
  }, [technical_id]);
  //

  const columnsTc: ColumnsType<IProfEducationFormClassifier> = [
    {
      title: t("prof.code"),
      width: "150px",
      dataIndex: "classifier_code",
    },
    {
      //@ts-ignore
      title: t("prof.name"),
      dataIndex: "classifier_name",
      ...getColumnSearchProps("classifier_name"),
      width: "300px",
    },
    {
      title: t("edu_form.daytime"),
      dataIndex: "day_time",
      align: "center",
    },
    {
      title: t("edu_form.externally"),
      dataIndex: "external",
      align: "center",
    },
    {
      title: t("edu_form.evening"),
      dataIndex: "evening",
      align: "center",
    },
    {
      title: t("edu_form.dual"),
      dataIndex: "dual",
      align: "center",
    },
    {
      title: t("tables.total"),
      dataIndex: "all",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginTop: "40px" }}>
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button size="small" onClick={navigateBack}>
                {`<`}
              </Button>
              <h3>Jami o'quv muassasalari talabalari soni kursi bo'yicha</h3>
            </div>
          )}
          rowKey="classifier_code"
          dataSource={classifierTc}
          columns={columnsTc}
          pagination={tablePaginationConfigTwo}
          size="small"
          scroll={{ x: true }}
          loading={tcLoading}
        />
      </div>
    </div>
  );
}
