import { api } from "../api";
import {
  IDoctoralCountByDirection,
  IDoctoralCountByOrgDirection,
  IDoctoralOrganizationCountByRegions,
  IDoctoralStat,
  IDoctoralStatCountByRegions,
  IDoctoralStatOrganization,
  IDoctoralStatUniversitiesByRegions,
} from "./type";
import { baseUrl_Doctoral } from "src/app/services/api/const";

export const doctoralApi = api.injectEndpoints({
  endpoints: (build) => ({
    //Get Statistika doktorantura endpoint
    getDoctoralStat: build.query<IDoctoralStat, void>({
      query: () => ({
        url: `${baseUrl_Doctoral}/statistics/doctorate_stat`,
        headers: { Authorization: "Basic bXllZHVAZWR1LnV6OmVHITE0ZTREbi9cb1RWflVDTlhxRw==" },
      }),
    }),
    //Get Tashkilotlar endpoint
    getDoctoralStatOrganization: build.query<IDoctoralStatOrganization[], string | void>({
      query: (params = "") => ({
        url: `${baseUrl_Doctoral}/statistics/org_count_by_type_and_org_admin` + params,
      }),
    }),
    //Get Viloyatdagi universitet va institutlar soni endpoint
    getDoctoralStatUniversitiesByRegions: build.query<
      IDoctoralStatUniversitiesByRegions[],
      string | void
    >({
      query: (params = "") => ({
        url: `${baseUrl_Doctoral}/statistics/org_count_by_region_and_type` + params,
      }),
    }),
    //Get Viloyatdagi doktorantlar va ilmiy rahbarlar soni endpoint
    getDoctoralStatCountByRegions: build.query<IDoctoralStatCountByRegions[], string | void>({
      query: (params = "") => ({
        url: `${baseUrl_Doctoral}/statistics/count_by_apply_doc_researcher_region` + params,
      }),
    }),
    //Get Tashkilotdagi doktorantlar soni viloyat kesimida endpoint
    getDoctoralOrganizationCountByRegions: build.query<
      IDoctoralOrganizationCountByRegions[],
      string | number | null
    >({
      query: (region_id) => ({
        url: `${baseUrl_Doctoral}/statistics/count_by_apply_doc_researcher_region`,
        params: {
          region_id,
        },
      }),
    }),
    //Get Tashkilot yo'nalishi bo'yicha doktorantlar soni endpoint
    getDoctoralCountByOrgDirection: build.query<
      IDoctoralCountByOrgDirection[],
      string | number | null
    >({
      query: (org_id) => ({
        url: `${baseUrl_Doctoral}/statistics/count_apply_doc_by_direction`,
        params: {
          org_id,
        },
      }),
    }),
    //Get Yo‘nalishlar bo‘yicha doktorantlar soni endpoint
    getDoctoralCountByDirection: build.query<IDoctoralCountByDirection[], string | void>({
      query: (params = "") => ({
        url: `${baseUrl_Doctoral}/statistics/count_apply_doc_by_direction` + params,
      }),
    }),
  }),
});

export const {
  useGetDoctoralStatQuery,
  useGetDoctoralCountByDirectionQuery,
  useGetDoctoralCountByOrgDirectionQuery,
  useGetDoctoralOrganizationCountByRegionsQuery,
  useGetDoctoralStatCountByRegionsQuery,
  useGetDoctoralStatOrganizationQuery,
  useGetDoctoralStatUniversitiesByRegionsQuery,
} = doctoralApi;
