import { useTranslation } from "react-i18next";
import GraphInfoCard from "src/components/cards/graph_info";
import { language } from "src/provider/language/const";
import { useGetDoctoralStatUniversitiesByRegionsQuery } from "src/app/services/doctoral";
import BarChart from "src/components/chart/BarChart";
import { useTypedSelector } from "src/app/store";

export function DoctoralOrganizationChart() {
  const { t } = useTranslation();
  const { data } = useGetDoctoralStatUniversitiesByRegionsQuery();
  const { colors } = useTypedSelector((state) => state.layout);

  return (
    <div className="doctoralOrganizationChart">
      <GraphInfoCard title={t("doctoral.org_count_by_region")}>
        <BarChart
          color={[colors.light_blue, colors.green]}
          legendData={["doctoral.higher_education", "doctoral.scientific_education"]}
          xAxisData={data?.map((el) => el.name[language]) || []}
          seriesArr={[
            {
              name: t("doctoral.higher_education"),
              data: data?.map((el) => el["100"]),
              stack: "one",
            },
            {
              name: t("doctoral.scientific_education"),
              data: data?.map((el) => el["200"]),
              stack: "one",
            },
          ]}
          barWidth={35}
          xAxisRotate={20}
        />
      </GraphInfoCard>
    </div>
  );
}
