import type { UploadFile } from "antd";
import { RuleObject } from "rc-field-form/lib/interface";

export const fileValidator = async (
  rule: RuleObject,
  files: UploadFile[],
  maxCount?: number
): Promise<void> => {
  if (rule.required && files.length !== maxCount) throw "Iltimos faylni yuklang";
};
