import { useEffect, useState } from "react";
import {
  useGetCommonStudentAddressQuery,
  useGetCommonUniversityAddressQuery,
  useGetCommonUniversityUniversityTypeQuery,
  useGetEduTypeAndGenderQuery,
  useGetStudentsByOwnershipQuery,
  useGetStudentsTopFiveOtmQuery,
} from "src/app/services/students";
import { useGetTeacherByGenderStatQuery } from "src/app/services/teacher";

function useAll() {
  // ownership
  const [universityData, setUniversityData] = useState<number[]>();
  const [universitiesCount, setUniversitiesCount] = useState<number>();
  const { data: university } = useGetCommonUniversityUniversityTypeQuery();
  useEffect(() => {
    if (university) {
      const newArray = university.map((item) => item.count);
      const count = university.reduce((acc, item) => acc + item.count, 0);
      setUniversitiesCount(count);
      setUniversityData(newArray);
    }
  }, [university]);

  // university address
  const [universityAddress, setUniversityAddress] = useState<number[]>();
  const [placeTop5data, setPlaceTop5Data] = useState<number[]>();
  const [placeTop5Label, setPlaceTop5Label] = useState<string[]>();
  const { data: addressData } = useGetCommonUniversityAddressQuery();
  useEffect(() => {
    if (addressData) {
      const newArray = addressData.map((item) => item.count);
      let d = [...addressData].sort((p1, p2) =>
        p1.count < p2.count ? 1 : p1.count > p2.count ? -1 : 0,
      );
      const top5Data = d.slice(0, 5).map((item) => item.count);
      const top5Name = d.slice(0, 5).map((item) => item.region);
      setUniversityAddress(newArray);
      setPlaceTop5Data(top5Data);
      setPlaceTop5Label(top5Name);
    }
  }, [addressData]);

  // student address
  const [studentAddress, setStudentAddress] = useState<number[]>();
  const [studentsTop5Data, setStudentsTop5Data] = useState<number[]>();
  const [studentsTop5Label, setStudentsTop5Label] = useState<string[]>();
  const { data: sAddressData } = useGetCommonStudentAddressQuery();
  useEffect(() => {
    if (sAddressData) {
      const newArray = sAddressData.map((item) => item.count);
      let d = [...sAddressData].sort((p1, p2) =>
        p1.count < p2.count ? 1 : p1.count > p2.count ? -1 : 0,
      );
      const top5Data = d.slice(0, 5).map((item) => item.count);
      const top5Name = d.slice(0, 5).map((item) => item.region);
      setStudentsTop5Data(top5Data);
      setStudentsTop5Label(top5Name);
      setStudentAddress(newArray);
    }
  }, [sAddressData]);

  // Students top five university
  const [studentsTopFive, setStudentsTopFive] = useState<number[]>();
  const [studentsTopFiveLabel, setStudentsTopFiveLabel] = useState<string[]>();
  const { data: studentsFive } = useGetStudentsTopFiveOtmQuery();
  useEffect(() => {
    if (studentsFive) {
      const newArray = [...studentsFive]
        .sort((p1, p2) => p1.count - p2.count)
        .map((item) => item.count);
      const newArrayLabel = [...studentsFive]
        .sort((p1, p2) => p1.count - p2.count)
        .map((item) => item.name);
      setStudentsTopFiveLabel(newArrayLabel);
      setStudentsTopFive(newArray);
    }
  }, [studentsFive]);

  // ownership
  const [studentByOwnership, setStudentByOwnership] = useState<number[]>();
  const { data: universityOwnership } = useGetStudentsByOwnershipQuery();
  useEffect(() => {
    if (universityOwnership) {
      const newArray = universityOwnership.map((item) => item.count);
      setStudentByOwnership(newArray);
    }
  }, [universityOwnership]);

  // Student Count
  const [studentCount, setStudentCount] = useState<number>();
  const { data: allStudentCount } = useGetEduTypeAndGenderQuery();
  useEffect(() => {
    if (allStudentCount) {
      const count = allStudentCount.reduce((acc, item) => acc + item.count, 0);
      setStudentCount(count);
    }
  }, [allStudentCount]);

  // Teachers count
  const [teachersCount, setTeachersCount] = useState<number>();
  const { data: teachersCountData } = useGetTeacherByGenderStatQuery();
  useEffect(() => {
    if (teachersCountData) {
      const count = teachersCountData.reduce((acc, item) => acc + item.count, 0);
      setTeachersCount(count);
    }
  }, [teachersCountData]);

  return {
    universityData,
    universityAddress,
    studentAddress,
    placeTop5data,
    placeTop5Label,
    studentsTop5Data,
    studentsTop5Label,
    studentsTopFive,
    studentsTopFiveLabel,
    studentByOwnership,
    studentCount,
    universitiesCount,
    teachersCount,
  };
}

export default useAll;
