import { CheckboxOptionType, ConfigProvider, Radio, RadioGroupProps } from "antd";
import { useTypedSelector } from "src/app/store";

interface Props extends RadioGroupProps {
  buttons?: CheckboxOptionType[];
  fontSize?: number;
  controlHeight?: number;
  buttonPaddingInline?: number;
  hasBg?: boolean;
  borderRadius?: number;
  otherBg?: boolean;
}

function CustomRadioButton({
  buttons,
  fontSize,
  controlHeight,
  buttonPaddingInline,
  hasBg,
  borderRadius,
  otherBg,
  ...rest
}: Props) {
  const { colors } = useTypedSelector((state) => state.layout);

  return (
    <div className="radio-button">
      <ConfigProvider
        theme={{
          token: {
            colorBgBase: hasBg ? colors.bg_secondary : colors.white,
            colorBorder: "none",
          },
          components: {
            Radio: {
              buttonCheckedBg: otherBg ? "#DEEDFA" : colors.white,
              colorText: otherBg ? "#09090a" : colors.tertiary,
              fontSize: fontSize,
              controlHeight: controlHeight,
              buttonPaddingInline: buttonPaddingInline,
              borderRadius: borderRadius || 10,
            },
          },
        }}
      >
        <Radio.Group {...rest}>
          {buttons?.map((item, index) => {
            return (
              <Radio.Button
                key={"radio_button" + index}
                value={item.value}
                disabled={item.disabled}
              >
                {item.label}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </ConfigProvider>
    </div>
  );
}

export default CustomRadioButton;
