import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useColumnSearch } from "src/pages/higher_education/tables/useColumnSearch";
import { Fragment, useMemo, useState } from "react";
import { Button, Table } from "antd";
import { DownloadIcon } from "src/assets/svg";
import { getExcel } from "src/app/services/public";
import { useTypedSelector } from "src/app/store";
import { useOtmReportQuery } from "src/app/services/private";
import { ContractType, OtmReport } from "src/app/services/private/type";

type Summary = {
  studentCount: number;
  withOutStipendCount: number;
  withStipendCount: number;
};

export default function OtmReports() {
  const { t } = useTranslation();
  const { data, isLoading } = useOtmReportQuery();
  const getColumnSearchProps = useColumnSearch();
  const [loading, setLoading] = useState(false);
  const { colors } = useTypedSelector((state) => state.layout);

  const dataSourceReport = useMemo(() => {
    const newData = data?.map((item) => ({
      ...item,
      total: item.contractTypes.reduce(
        (acc, item) => acc + item.withStipendCount + item.withOutStipendCount,
        0,
      ),
      withStipendCount: item.contractTypes.reduce((acc, item) => acc + item.withStipendCount, 0),
      withOutStipendCount: item.contractTypes.reduce(
        (acc, item) => acc + item.withOutStipendCount,
        0,
      ),
    }));

    return newData;
  }, [data]);

  const getContractTypeColumns = (index: number): ColumnsType<OtmReport> => {
    return [
      {
        title: t("tables.student_count"),
        dataIndex: "contractTypes",
        width: "200px",
        align: "center",
        render: (data: any) => <span>{data[index] ? data[index]?.studentCount : 0}</span>,
      },

      {
        title: t("tables.withStipendCount"),
        dataIndex: "contractTypes",
        align: "center",
        width: "200px",
        render: (data: ContractType[]) => (
          <span>{data[index] ? data[index]?.withStipendCount : 0}</span>
        ),
      },
      {
        title: t("tables.withOutStipendCount"),
        dataIndex: "contractTypes",
        width: "200px",
        align: "center",
        render: (data: any) => <span>{data[index] ? data[index]?.withOutStipendCount : 0}</span>,
      },
    ];
  };

  const columns: ColumnsType<OtmReport> = [
    {
      //@ts-ignore
      title: t("tables.univerCode"),
      dataIndex: "hemisExternalCode",
      ...getColumnSearchProps("hemisExternalCode"),
      width: "100px",
    },
    {
      //@ts-ignore
      title: t("tables.univerName"),
      dataIndex: "universityName",
      ...getColumnSearchProps("universityName"),
      width: "300px",
    },
    {
      title: t("tables.basic_contract"),
      dataIndex: "contractTypes",
      align: "center",
      children: getContractTypeColumns(0),
    },
    {
      title: t("tables.super_contract"),
      dataIndex: "contractTypes",
      align: "center",
      children: getContractTypeColumns(1),
    },
    {
      title: t("tables.hyper"),
      dataIndex: "contractTypes",
      align: "center",
      children: getContractTypeColumns(2),
    },
    {
      title: t("tables.contract_foreign"),
      dataIndex: "contractTypes",
      align: "center",
      children: getContractTypeColumns(3),
    },
    {
      title: "Stipendiyasiz jami",
      dataIndex: "withOutStipendCount",
      width: "200px",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
    {
      title: "Stipendiyali jami",
      dataIndex: "withStipendCount",
      width: "200px",
      align: "center",
      render: (total) => <b>{total}</b>,
    },

    {
      title: t("tables.total"),
      dataIndex: "total",
      width: "100px",
      align: "center",
      render: (total) => <b>{total}</b>,
    },
  ];

  const downloadExcelFile = async () => {
    setLoading(true);
    await getExcel("billing/contractCountReportByContractTypeExcel", true);
    setLoading(false);
  };

  return (
    <div>
      <Table
        title={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>{t("tables.name_of_tablePaymentType")}</h3>

            <Button className="download_btn" onClick={downloadExcelFile} loading={loading}>
              {t("header.download")} <DownloadIcon color={colors.primary} />
            </Button>
          </div>
        )}
        rowKey="univerCode"
        dataSource={dataSourceReport}
        columns={columns}
        pagination={false}
        size="small"
        loading={isLoading}
        scroll={{ x: 2000, y: 500 }}
        summary={(pageData) => {
          if (pageData.length === 0) return null;

          const total: Summary[] = [];

          pageData.forEach((next) => {
            next.contractTypes.forEach((type, index) => {
              if (total[index]) {
                total[index] = {
                  studentCount: total[index].studentCount + type["studentCount"],
                  withOutStipendCount:
                    total[index].withOutStipendCount + type["withOutStipendCount"],
                  withStipendCount: total[index].withStipendCount + type["withStipendCount"],
                };
              } else {
                total[index] = {
                  studentCount: type["studentCount"],
                  withOutStipendCount: type["withOutStipendCount"],
                  withStipendCount: type["withStipendCount"],
                };
              }
            });
          });

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <b>{t("tables.total")}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                {total.map((count, index: number) => (
                  <Fragment key={index}>
                    <Table.Summary.Cell align="center" index={index + 1}>
                      <b>{count.studentCount}</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center" index={index + 2}>
                      <b>{count.withOutStipendCount}</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center" index={index + 3}>
                      <b>{count.withStipendCount}</b>
                    </Table.Summary.Cell>
                  </Fragment>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
}
