import { useEffect, useState } from "react";
import {
  useGetStudentsEduFormByAgeStatQuery,
  useGetStudentsEduFormByCitizenshipStatQuery,
  useGetStudentsEduFormByCourseStatQuery,
  useGetStudentsEduFormByGenderStatQuery,
  useGetStudentsEduFormByPaymentStatQuery,
  useGetStudentsEduFormByPlaceStatQuery,
} from "src/app/services/students";
import {
  IStudentAge,
  IStudentCitizenship,
  IStudentCourse,
  IStudentGender,
  IStudentPayment,
  IStudentPlace,
} from "../type";
import {
  makeStudentAge,
  makeStudentCitizenship,
  makeStudentCourse,
  makeStudentGender,
  makeStudentPayment,
  makeStudentPlace
} from "../util";

function useEduForm() {
  // Genders section
  const [gendersData, setGenders] = useState<IStudentGender>();
  const { data: genders } = useGetStudentsEduFormByGenderStatQuery();
  useEffect(() => {
    if (genders) setGenders(makeStudentGender(genders));
  }, [genders]);

  // Ages section
  const [agesData, setAges] = useState<IStudentAge>();
  const { data: ages } = useGetStudentsEduFormByAgeStatQuery();
  useEffect(() => {
    if (ages) setAges(makeStudentAge(ages));
  }, [ages]);

  // Citizenship section
  const [citizenshipData, setCitizenship] = useState<IStudentCitizenship>();
  const { data: citizenships } = useGetStudentsEduFormByCitizenshipStatQuery();
  useEffect(() => {
    if (citizenships) setCitizenship(makeStudentCitizenship(citizenships));
  }, [citizenships]);

  // Payment section
  const [paymentData, setPayment] = useState<IStudentPayment>();
  const { data: payments } = useGetStudentsEduFormByPaymentStatQuery();
  useEffect(() => {
    if (payments) setPayment(makeStudentPayment(payments));
  }, [payments]);

  // Courses section
  const [coursesData, setCourses] = useState<IStudentCourse>();
  const { data: courses } = useGetStudentsEduFormByCourseStatQuery();
  useEffect(() => {
    if (courses) setCourses(makeStudentCourse(courses));
  }, [courses]);

  // Places section
  const [placesData, setPlaces] = useState<IStudentPlace>();
  const { data: places } = useGetStudentsEduFormByPlaceStatQuery();
  useEffect(() => {
    if (places) setPlaces(makeStudentPlace(places));
  }, [places]);

  return {
    gendersData,
    agesData,
    citizenshipData,
    paymentData,
    coursesData,
    placesData,
  };
}

export default useEduForm;
