import { useTranslation } from "react-i18next";
import { IUniversity } from "src/app/services/universities/type";
import { useTypedSelector } from "src/app/store";
import {
  EarthIcon,
  FacebookIcon,
  FileIcon,
  InsragramIcon,
  LocationIcon,
  PhoneIcon,
  TelegramIcon,
  YoutubeIcon,
} from "src/assets/svg";
import { findOwnership } from "src/components/filter/constants";

interface Props extends IUniversity {}

function UniversityCard({
  name_uz,
  website,
  telegram,
  instagram,
  youtube,
  facebook,
  licence_pdf_file,
  phone_number,
  address,
  ownership_form,
  location,
}: Props) {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);

  return (
    <div className="university-card">
      <div className="university-card-content">
        <div className="university-card-type">
          <span className={`university-card-type-${ownership_form}`}>
            {t(findOwnership(ownership_form))}
          </span>
        </div>
        <div className="university-card-title">{name_uz}</div>
        <div className="university-card-info">
          {address && (
            <a href={location} target="_blank" rel="noreferrer" style={{ color: "blue" }}>
              <LocationIcon color={colors.primary} /> {address}
            </a>
          )}
          {phone_number && phone_number !== "numri yug" && (
            <p>
              <PhoneIcon color={colors.primary} /> +{phone_number}
            </p>
          )}
        </div>
      </div>

      <div className="university-card-addititon">
        <div className="university-card-links">
          {website && (
            <a href={website} target="_blank" rel="noreferrer">
              <EarthIcon color={colors.primary} /> {t("university.website")}
            </a>
          )}
          {telegram && (
            <a href={telegram} target="_blank" rel="noreferrer">
              <TelegramIcon color={colors.primary} /> Telegram
            </a>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noreferrer">
              <InsragramIcon color={colors.primary} /> Instagram
            </a>
          )}
          {youtube && (
            <a href={youtube} target="_blank" rel="noreferrer">
              <YoutubeIcon color={colors.primary} /> Youtube
            </a>
          )}
          {facebook && (
            <a href={facebook} target="_blank" rel="noreferrer">
              <FacebookIcon color={colors.primary} /> Facebook
            </a>
          )}
        </div>

        {licence_pdf_file && (
          <div className="university-card-pdf">
            <a href={licence_pdf_file} download target="_blank" rel="noreferrer">
              <FileIcon color={colors.primary} /> {t("university.license_pdf")}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default UniversityCard;
