import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/app/store";
import GraphInfoCard from "src/components/cards/graph_info";
import BarChart from "src/components/chart/BarChart";
import BarChartCategory from "src/components/chart/BarChartCategory";
import { ages, citizenships, courses, genders } from "src/pages/constants";
import useCitizenForm from "src/pages/higher_education/students/citizenship/useCitizenship";

function CitizenshipPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const { gendersData, agesData, coursesData, coursesByCitizenshipData } = useCitizenForm();

  return (
    <div className="eduform">
      <div className="graph-row-flex">
        {/* Gender */}
        <GraphInfoCard
          title={t("header.by_gender")}
          stat={[
            {
              name: t("genders.men"),
              count: gendersData?.men_total,
            },
            {
              name: t("genders.women"),
              count: gendersData?.women_total,
            },
          ]}
          graphHeight={192}
        >
          <BarChartCategory
            legendData={genders}
            yAxisData={citizenships}
            color={[colors.blue, colors.pink]}
            seriesArr={[
              {
                name: t("genders.men"),
                data: gendersData?.men_data,
              },
              {
                name: t("genders.women"),
                data: gendersData?.women_data,
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-120}
            yAxisMargin={160}
          />
        </GraphInfoCard>

        {/* Age */}
        <GraphInfoCard
          title={t("header.age")}
          stat={[
            {
              name: t("ages.under_30"),
              count: agesData?.less_total,
            },
            {
              name: t("ages.over_30"),
              count: agesData?.greater_total,
            },
          ]}
          graphHeight={192}
        >
          <BarChartCategory
            legendData={ages}
            yAxisData={citizenships}
            color={[colors.light_blue, colors.deep_purple]}
            seriesArr={[
              {
                name: t("ages.under_30"),
                data: agesData?.less_data,
                stack: "one",
              },
              {
                name: t("ages.over_30"),
                data: agesData?.greater_data,
                stack: "one",
              },
            ]}
            barWidth={12}
            xAxisShow={false}
            gridBottom={24}
            gridLeft={-120}
            yAxisMargin={160}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Courses */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            { name: t("courses.course_1"), count: coursesData?.course_1_total },
            { name: t("courses.course_2"), count: coursesData?.course_2_total },
            { name: t("courses.course_3"), count: coursesData?.course_3_total },
            { name: t("courses.course_4"), count: coursesData?.course_4_total },
            { name: t("courses.course_5"), count: coursesData?.course_5_total },
            { name: t("courses.course_6"), count: coursesData?.course_6_total },
          ]}
        >
          <BarChart
            legendData={courses}
            xAxisData={citizenships}
            color={[
              colors.blue,
              colors.pink,
              colors.yellow,
              colors.light_green,
              colors.light_blue,
              colors.orange,
            ]}
            seriesArr={[
              {
                name: t("courses.course_1"),
                data: coursesData?.course_1_data,
                stack: "one",
              },
              {
                name: t("courses.course_2"),
                data: coursesData?.course_2_data,
                stack: "one",
              },
              {
                name: t("courses.course_3"),
                data: coursesData?.course_3_data,
                stack: "one",
              },
              {
                name: t("courses.course_4"),
                data: coursesData?.course_4_data,
                stack: "one",
              },
              {
                name: t("courses.course_5"),
                data: coursesData?.course_5_data,
                stack: "one",
              },
              {
                name: t("courses.course_6"),
                data: coursesData?.course_6_data,
                stack: "one",
              },
            ]}
          />
        </GraphInfoCard>
      </div>

      <div className="graph-row">
        {/* Courses reverse version */}
        <GraphInfoCard
          title={t("header.courses")}
          stat={[
            { name: t("citizenships.foreigner"), count: coursesByCitizenshipData?.foreigner_total },
            { name: t("citizenships.uzbek"), count: coursesByCitizenshipData?.uzbek_total },
            { name: t("citizenships.stateless"), count: coursesByCitizenshipData?.stateless_total },
          ]}
        >
          <BarChart
            xAxisData={courses}
            legendData={citizenships}
            color={[colors.pink, colors.light_blue, colors.orange]}
            seriesArr={[
              {
                name: t("citizenships.foreigner"),
                data: coursesByCitizenshipData?.foreigner_data,
                stack: "one",
              },
              {
                name: t("citizenships.uzbek"),
                data: coursesByCitizenshipData?.uzbek_data,
                stack: "one",
              },
              {
                name: t("citizenships.stateless"),
                data: coursesByCitizenshipData?.stateless_data,
                stack: "one",
              },
            ]}
          />
        </GraphInfoCard>
      </div>
      <div className="text_info">
        <p>{t('header.students_info_text')}</p>
      </div>
    </div>
  );
}

export default CitizenshipPage;
