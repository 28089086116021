import {privateApi} from "../api";
import {
    IGraduated,
    IGraduatedByUniversity,
    IGraduatedByYear,
    IOtmCodeAndName,
    IStatusCode,
    IUzAsboAccomodation,
    IUzAsboAccomodationParams,
    IUzAsboEduForm,
    IUzAsboEduFormParams,
    IUzAsboEmploymentStudent,
    IUzAsboEmploymentStudentParams,
    IUzasboParams,
    IUzasboReport,
    OtmReport,
} from "./type";

export const PrivateTables = privateApi.injectEndpoints({
    endpoints: (build) => ({
        otmReport: build.query<OtmReport[], void>({
            query: () => "billing/contractCountReportByContractType",
        }),

        uzAsboReport: build.query<IUzasboReport[], IUzasboParams>({
            query: (params) => ({
                url: `uzasbohemis/getStudentByFilter`,
                params,
            }),
        }),

        HemisReport: build.query<any, IUzasboParams>({
            query: (params) => ({
                url: `uzasbohemis/studentHemisInfoByUniversity`,
                params,
            }),
        }),

        DisabilityReport: build.query<any, any>({
            query: (params) => ({
                url: `uzasbohemis/disability/eduFormByMonthAndYear`,
                params,
            }),
        }),

        studentSync: build.mutation<any, FormData>({
            query: (data) => ({
                url: `uzasbohemis/studentSynch`,
                body: data,
                method: "POST",
            }),
        }),

        getOtmCodeAndName: build.query<IOtmCodeAndName[], void>({
            query: () => "public/universityNameAndTin",
        }),

        getStudentStatusType: build.query<IStatusCode[], void>({
            query: () => "/public/studentStatusType",
        }),

        getPaymentForm: build.query<IStatusCode[], void>({
            query: () => "/public/paymentForm",
        }),

        getEduType: build.query<IStatusCode[], void>({
            query: () => "/public/eduType",
        }),

        getEduForm: build.query<IStatusCode[], void>({
            query: () => "/public/eduForm",
        }),

        getGender: build.query<IStatusCode[], void>({
            query: () => "/public/gender",
        }),

        getCourseStatus: build.query<IStatusCode[], void>({
            query: () => "/public/course",
        }),
        getAccommodations: build.query<IStatusCode[], void>({
            query: () => "/public/accommodation",
        }),
        getUniversityOwnershipCodes: build.query<IStatusCode[], void>({
            query: () => "/public/ownership",
        }),

        getUzAsboEduForm: build.query<IUzAsboEduForm[], IUzAsboEduFormParams>({
            query: (params) => ({
                url: "/uzasbohemis/eduFormByMonthAndYear",
                params,
            }),
        }),
        getUzAsboAccomodation: build.query<IUzAsboAccomodation[], IUzAsboAccomodationParams>({
            query: (params) => ({
                url: "/uzasbohemis/accommodationByMonthAndYear",
                params,
            }),
        }),
        getUzAsboEmploymentStudent: build.query<
            IUzAsboEmploymentStudent[],
            IUzAsboEmploymentStudentParams
        >({
            query: (params) => ({
                url: "/uzasbohemis/employmentStudent/eduYearAndQuarter",
                params,
            }),
        }),

        uzAsboRent: build.query<IUzasboReport[], IUzasboParams>({
            query: (params) => ({
                url: `uzasbohemis/studentRentByFilter`,
                params,
            }),
        }),

        getGraduatedStudents: build.query<IGraduated[], { isIncrease: boolean }>({
            query: (params) => ({
                url: `statistic/graduatedStudent/averageGrade`,
                params,
            }),
        }),
        getGraduatedStudentsByYear: build.query<
            IGraduatedByYear[],
            { isIncrease: boolean; eduYearCode?: string }
        >({
            query: (params) => ({
                url: `statistic/graduatedStudent/averageGradeByEduYear`,
                params,
            }),
        }),
        getGraduatedStudentsByUniversity: build.query<IGraduatedByUniversity[], void>({
            query: () => ({
                url: `statistic/graduatedStudent/allAverageGradeByUniversity`,
            }),
        }),
    }),
});

export const {
    useOtmReportQuery,
    useUzAsboReportQuery,
    useGetOtmCodeAndNameQuery,
    useGetStudentStatusTypeQuery,
    useGetPaymentFormQuery,
    useGetGenderQuery,
    useGetCourseStatusQuery,
    useGetEduFormQuery,
    useGetEduTypeQuery,
    useGetUzAsboAccomodationQuery,
    useGetUzAsboEduFormQuery,
    useGetAccommodationsQuery,
    useGetUniversityOwnershipCodesQuery,
    useGetUzAsboEmploymentStudentQuery,
    useUzAsboRentQuery,
    useHemisReportQuery,
    useStudentSyncMutation,
    useDisabilityReportQuery,
    useGetGraduatedStudentsQuery,
    useGetGraduatedStudentsByYearQuery,
    useGetGraduatedStudentsByUniversityQuery
} = PrivateTables;
