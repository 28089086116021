import { CheckboxOptionType, Select, Statistic } from "antd";
import "./graphinfo.scss";
import CustomRadioButton from "src/components/common/radio/RadioButton";

interface IGrapfInfoStat {
  name: string;
  count?: number;
}

interface Props {
  title: string;
  stat?: IGrapfInfoStat[];
  children?: React.ReactNode;
  graphHeight?: number;
  segmentedData?: CheckboxOptionType[];
  handleChange?: any;
  segmentedValue?: string | boolean;
  titleW?: boolean;
  mRight?: number;
  showSelection?: boolean;
  setEduYear?: any;
  eduYear?: string;
}

const yearOptions = [
  {
    label: "2020-2021",
    value: "2020",
  },
  {
    label: "2021-2022",
    value: "2021",
  },
  {
    label: "2022-2023",
    value: "2022",
  },
  {
    label: "2023-2024",
    value: "2023",
  },
];

function GraphInfoCard({
  title,
  stat,
  children,
  graphHeight,
  segmentedData,
  handleChange,
  segmentedValue,
  titleW = false,
  mRight = 0,
  showSelection,
  setEduYear,
  eduYear,
}: Props) {
  return (
    <div className="graphinfo">
      <div className="graphinfo-content">
        <div className={`${(segmentedData!?.length > 0 || showSelection) && "grahp-flex"} mb`}>
          <div className={`graphinfo-title ${titleW && "w-200"}`}>{title}</div>
          {!!stat && (
            <div className="graphinfo-stat">
              {stat.map((item) => {
                return (
                  <div className="graphinfo-stat-card" key={item.name}>
                    <p>{item.name}</p>
                    <h2>
                      <Statistic value={item.count} groupSeparator={" "} />
                    </h2>
                  </div>
                );
              })}
            </div>
          )}

          <div className="grahp-flex" style={{ gap: "20px" }}>
            {segmentedData && (
              <div style={{ marginRight: `${mRight}px` }}>
                <CustomRadioButton
                  buttons={segmentedData}
                  fontSize={13}
                  buttonPaddingInline={12}
                  controlHeight={36}
                  hasBg
                  onChange={(e) => handleChange(e.target.value)}
                  value={segmentedValue}
                  defaultValue={segmentedValue}
                  otherBg={true}
                />
              </div>
            )}

            {showSelection && (
              <Select
                style={{ width: 160 }}
                placeholder="Bitirgan yili"
                onChange={setEduYear}
                options={yearOptions}
                defaultValue={eduYear}
              />
            )}
          </div>
        </div>

        <div className={`graphinfo-graph graphinfo-graph-${graphHeight}`}>{children}</div>
      </div>
    </div>
  );
}

export default GraphInfoCard;
